import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { SettlementStatusBadge } from 'domains/billing/components';
import { QueryParams } from 'domains/billing/pages/OrganizationSettlementsPage';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  Checkbox,
  DateRangePicker,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { FilterDrawer, FilterDrawerProps } from 'layout';
import {
  accountGroups,
  organizationSettlementStatuses,
} from 'services/constants';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  accountGroup: string;
  status: string[];
  fromDate: Moment | null;
  toDate: Moment | null;
}

const FiltersDrawer = ({ params, setParam, ...props }: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    accountGroup: '',
    status: [],
    fromDate: null,
    toDate: null,
  });

  const areFiltersSelected = !!(
    state.accountGroup ||
    state.status.length ||
    state.fromDate ||
    state.toDate
  );

  useEffect(() => {
    if (props.open)
      setState({
        accountGroup: params.accountGroup,
        status: params.status,
        fromDate: params.fromDate,
        toDate: params.toDate,
      });
  }, [params, props.open]);

  const onFiltersApply = () => {
    const filtersToApply = {
      ...state,
      fromDate: state.fromDate?.format('YYYY-MM-DD'),
      toDate: state.toDate?.format('YYYY-MM-DD'),
    };

    setParam(
      Object.keys(filtersToApply).map((key) => [
        key,
        filtersToApply[key as keyof State],
      ])
    );
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.keys(state).map((key) => [key, '']));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-period"
          id="panel-period-header"
        >
          <Typography>{t('filters.period')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DateRangePicker
            disableFuture
            // don't set values from state, because
            // when user attempts to set date manually we get validation errors
            // that reset the state in onChange handler
            value={[params.fromDate, params.toDate]}
            onChange={(value, { validationError }) =>
              setState((prevState) => ({
                ...prevState,
                fromDate: validationError[0] ? null : value[0],
                toDate: validationError[1] ? null : value[1],
              }))
            }
            slotProps={{
              textField: {
                size: 'medium',
                placeholder: t('filters.period'),
              },
            }}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-issuer"
          id="panel-issuer-header"
        >
          <Typography>{t('int.filters.accountGroup')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth>
            <Select<string>
              size="medium"
              displayEmpty
              value={state.accountGroup}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  accountGroup: e.target.value,
                }))
              }
              renderValue={(selected) => {
                if (!selected)
                  return (
                    <Typography color="text.disabled">
                      {t('int.filters.accountGroup')}
                    </Typography>
                  );

                return t(`accountGroup.${selected}`);
              }}
            >
              <MenuItem value="">
                <Typography color="text.disabled">
                  {t('int.filters.accountGroup')}
                </Typography>
              </MenuItem>
              {accountGroups.map((item) => (
                <MenuItem key={item} value={item}>
                  {t(`accountGroup.${item}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-status"
          id="panel-status-header"
        >
          <Typography>{t('filters.state')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <FormGroup>
              {organizationSettlementStatuses.map((status) => (
                <FormControlLabel
                  key={status}
                  checked={state.status.includes(status)}
                  control={<Checkbox />}
                  onChange={() =>
                    setState((prevState) => ({
                      ...prevState,
                      status: xor(prevState.status, [status]),
                    }))
                  }
                  label={<SettlementStatusBadge status={status} />}
                />
              ))}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </FilterDrawer>
  );
};

export default FiltersDrawer;
