import { Redirect } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { useCanUser } from 'services/rbac';
import {
  adminPaths,
  cardholderPaths,
  orgOnboardingPaths,
  whiteLabeledPaths,
} from './paths';

const RootRedirect = () => {
  const {
    state: { isComplianceRiskWhiteLabelApp },
  } = useGlobalState();
  const canUser = useCanUser();
  const { isOrgInOnboardingNew } = useIsOrgInOnboarding();

  if (isOrgInOnboardingNew)
    return <Redirect to={orgOnboardingPaths.orgOnboarding} />;

  if (isComplianceRiskWhiteLabelApp)
    return <Redirect to={whiteLabeledPaths.settingsOrganization} />;

  return canUser('dashboard-page:visit') ? (
    <Redirect to={adminPaths.dashboard} />
  ) : (
    <Redirect to={cardholderPaths.wallet} />
  );
};

export default RootRedirect;
