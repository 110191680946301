import { useMemo } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { QueryParams } from 'domains/transaction/pages/GlobalTransactionsPage';
import { Chip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { getFilterChipText, PageFilterChips } from 'layout';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
}

const FilterChips = ({ params, selectedFiltersCount, setParam }: Props) => {
  const { t } = useTranslation();

  const periodChipText = useMemo(() => {
    if (!params.fromDate && !params.toDate) return '';
    const array = [];
    if (params.fromDate)
      array.push(moment(params.fromDate).format('D MMM YYYY'));
    if (params.toDate) array.push(moment(params.toDate).format('D MMM YYYY'));
    return array.join(' - ');
  }, [params.fromDate, params.toDate]);

  if (!selectedFiltersCount) return null;

  return (
    <>
      <PageFilterChips>
        {(params.fromDate || params.toDate) && (
          <Chip
            label={periodChipText}
            onDelete={() =>
              setParam([
                ['toDate', ''],
                ['fromDate', ''],
              ])
            }
          />
        )}

        {!!params.receipt && (
          <Chip
            label={getFilterChipText(
              t(`transactionReceiptStatuses.${params.receipt}`),
              1,
              t('filters.receipts')
            )}
            onDelete={() => setParam('receipt', '')}
          />
        )}
      </PageFilterChips>
    </>
  );
};

export default FilterChips;
