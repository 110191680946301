import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { useFlags } from 'services/featureflags';

/**
 * Returns cards that can be issued / requested by users.
 */
const useAvailableCardConfigSettings = (isCardRequestFlow: boolean = false) => {
  const {
    state: { cardConfigSettings, featureModules },
  } = useGlobalState();
  const { requestCardForAnotherMemberEnabled } = useFlags();

  return useMemo(
    () =>
      cardConfigSettings
        .filter(
          (item) =>
            item.canBeIssued &&
            // Disable premium cards for card request flow
            (!item.premiumProductType || !isCardRequestFlow) &&
            // Allow only single-use cards for card request flow
            // when requestCardForAnotherMemberEnabled is enabled
            (!isCardRequestFlow ||
              !requestCardForAnotherMemberEnabled ||
              item.maxUsage === 1) &&
            // Disable single-use cards if FM is disabled
            (featureModules.SINGLE_USE_CARDS || item.maxUsage !== 1)
        )
        // Group by cardConfigGroup
        .filter(
          (cardConfig, i, filteredCardConfigSettings) =>
            filteredCardConfigSettings.findIndex(
              (config) => config.cardConfigGroup === cardConfig.cardConfigGroup
            ) === i
        ),
    [cardConfigSettings, featureModules, isCardRequestFlow]
  );
};

export default useAvailableCardConfigSettings;
