import { useEffect, useMemo, useRef, useState } from 'react';
import intersection from 'lodash/intersection';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { MembersPageFilters } from 'domains/member/components';
import { UserDetailsPage } from 'domains/member/pages';
import {
  DataGrid,
  GridSortModel,
  LoaderWithOverlay,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useCurrentApp from 'hooks/useCurrentApp';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  Card,
  DEFAULT_PAGE_LIMIT,
  Member,
  MemberDetails,
  memberStatuses,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { EXTERNAL_ADMIN_ROLES } from 'services/rbac';
import { isSortValid } from 'services/utils';
import useColumns from './useColumns';

const getSortModel = (sort: string): any => [
  {
    field: sort.slice(1),
    sort: sort.slice(0, 1) === '+' ? 'asc' : 'desc',
  },
];

const getQueryParams = (qs: string, allowedSortKeys: string[]) => {
  const { sort, q, role, status } = Object.fromEntries(
    new URLSearchParams(qs).entries()
  );

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '+status',
    q: q ? q.trim() : '',
    role: role ? intersection(role.split(','), EXTERNAL_ADMIN_ROLES) : [],
    status: status ? intersection(status.split(','), memberStatuses) : [],
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({ role, status }: QueryParams) =>
  +!!role.length + +!!status.length;

// Using both ids is an exception.
// For this page it's needed as memberId is not unique
const getRowId = (memberId: string, orgId: string) => {
  if (!memberId || !orgId) return '';
  return `${memberId}-org-${orgId}`;
};

interface State {
  isLoading: boolean;
  members: Member[];
  hasNextPage: boolean;
  totalCount: number;
}

const UsersPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:orgId/:memberId',
    true
  );

  const { allowedSortKeys, columns } = useColumns();
  const location = useLocation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const showPageError = useShowPageError();
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(getQueryParams(location.search, allowedSortKeys));
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const { isPortalApp } = useCurrentApp();
  const rowSelectionModel = useMemo(() => {
    const model = getRowId(detailsParams?.memberId, detailsParams?.orgId);
    return model ? [model] : [];
  }, [detailsParams?.memberId, detailsParams?.orgId]);

  const [state, setState] = useState<State>({
    isLoading: true,
    members: [],
    hasNextPage: false,
    totalCount: 0,
  });

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      setState((state) => ({
        ...state,
        isLoading: true,
      }));
      const { sort, q, role, status } = paramsRef.current;
      const memberEndpoint = isPortalApp ? api.getPartnerUsers : api.getMembers;
      const { members, hasNextPage, totalCount } = await memberEndpoint({
        page,
        limit,
        sort,
        q: q || undefined,
        role: role.length ? role.join() : undefined,
        status: status.length ? status.join() : undefined,
      });

      if (!mounted.current) return;
      setState((state) => ({
        isLoading: false,
        members: isLoadMore ? [...state.members, ...members] : members,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });

    paramsRef.current = getQueryParams(location.search, allowedSortKeys);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const onMemberUpdate = (member: MemberDetails, cards: Card[]) => {
    setState((prevState) => ({
      ...prevState,
      members: prevState.members.map((item) =>
        item.id === member.id && item.organizationId === member.organizationId
          ? { ...item, ...member, cards }
          : item
      ),
    }));
  };

  const handleSortModelChange = (sort: GridSortModel) => {
    if (state.isLoading || !state.members.length) return;
    const s = sort[0];
    if (!s) return;
    setQueryParam('sort', `${s.sort === 'asc' ? '+' : '-'}${s.field}`);
  };

  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);

  return (
    <>
      <PageHeader>
        <PageTitle
          title={isPortalApp ? t('usersPage.members') : t('usersPage.users')}
        />
        <MembersPageFilters
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          membersCount={state.totalCount}
        />
      </PageHeader>
      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid<Member>
          apiRef={dataGridRef}
          getRowId={(row) => getRowId(row.id, row.organizationId) || ''}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          rowCount={state.totalCount}
          rowSelectionModel={rowSelectionModel}
          initialState={{
            sorting: {
              sortModel: getSortModel(paramsRef.current.sort),
            },
          }}
          loading={state.isLoading}
          rows={state.members}
          columns={columns}
          columnVisibilityModel={{
            embedded: !isDetailsPageOpen,
            lastActiveAt: !isDetailsPageOpen,
            registeredAt: !isDetailsPageOpen,
            drawerPlaceholder: isDetailsPageOpen,
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ id, row }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else
              history.push(
                `${url}/${row.organizationId}/${row.id}${location.search}`
              );
          }}
          onSortModelChange={handleSortModelChange}
          slots={{
            noRowsOverlay: () => <NothingFound $top={0} $bottom={0} />,
            loadingOverlay: () => null,
          }}
        />

        <Route
          path={`${path}/:orgId/:memberId`}
          children={({ match }) => (
            <UserDetailsPage open={!!match} onUpdate={onMemberUpdate} />
          )}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(UsersPage, { permission: 'users-page:visit' });
