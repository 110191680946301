import NotificationsMenu from './NotificationsMenu';
import ReceiptInboxButton from './ReceiptInboxButton';
import UserMenu from './UserMenu';
import { MenuSection, StyledMainHeader } from './style';
import withLightNavigationModeTheme from './withLightNavigationModeTheme';

const PortalMainHeader = () => {
  return (
    <StyledMainHeader>
      <MenuSection>
        <ReceiptInboxButton />
        <NotificationsMenu />
        <UserMenu />
      </MenuSection>
    </StyledMainHeader>
  );
};

export default withLightNavigationModeTheme(PortalMainHeader);
