import { useEffect, useState } from 'react';
import { Moment } from 'moment/moment';
import { ReceiptFilter } from 'domains/transaction/components';
import { QueryParams } from 'domains/transaction/pages/GlobalTransactionsPage';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import { TransactionReceiptStatus } from 'services/constants';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  receipt: TransactionReceiptStatus | '';
  fromDate: Moment | null;
  toDate: Moment | null;
}

const FiltersDrawer = ({ params, setParam, ...props }: Props) => {
  const [state, setState] = useState<State>({
    receipt: '',
    fromDate: null,
    toDate: null,
  });
  const areFiltersSelected = !!(
    state.receipt ||
    state.fromDate ||
    state.toDate
  );

  useEffect(() => {
    if (props.open)
      setState({
        receipt: params.receipt,
        fromDate: params.fromDate,
        toDate: params.toDate,
      });
  }, [params, props.open]);

  const onFiltersApply = () => {
    setParam(
      Object.entries({
        ...state,
        fromDate: state.fromDate?.startOf('day')?.format(),
        toDate: state.toDate?.endOf('day')?.format(),
      })
    );
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.keys(state).map((key) => [key, '']));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <DateFilter
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate: fromDate?.startOf('day') || null,
            toDate: toDate?.endOf('day') || null,
          }))
        }
      />

      <ReceiptFilter
        value={state.receipt}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, receipt: value }))
        }
      />
    </FilterDrawer>
  );
};

export default FiltersDrawer;
