import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { getOnboardingKeyTypeFromUrl } from 'domains/onboarding/utils';
import { OnboardingSectionType } from 'services/constants';
import { useTanstackQuery } from 'services/network/useTanstackQuery';

// Get corresponding tasks based on the page - section tasks or group tasks
export const useGetTasks = (tasksExist: boolean = true) => {
  const {
    state: { organization },
  } = useGlobalState();
  const { sectionKey, groupId = '' } = useParams<{
    sectionKey: string;
    taskId: string;
    groupId?: string;
  }>();
  const {
    useGetOnboardingTasks,
    useGetOnboardingGroupTasks,
  } = useTanstackQuery();

  const currentSectionType = useMemo(
    () => getOnboardingKeyTypeFromUrl<OnboardingSectionType>(sectionKey),
    [sectionKey]
  );

  const { data: tasksData, isFetching, error } = useGetOnboardingTasks(
    organization!.id,
    currentSectionType,
    {
      enabled: !groupId && tasksExist,
    }
  );

  const {
    data: groupTasksData,
    isFetching: areGroupTasksLoading,
    error: getGroupTasksError,
  } = useGetOnboardingGroupTasks(organization!.id, groupId, {
    enabled: !!groupId,
  });

  return {
    tasks: tasksData?.tasks || groupTasksData?.tasks || [],
    areTasksLoading: isFetching || areGroupTasksLoading,
    tasksError: error || getGroupTasksError,
  };
};
