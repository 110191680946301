import { useMemo } from 'react';
import largeImage from 'assets/images/cardDesignBackup/largeImage.png';
import smallImage from 'assets/images/cardDesignBackup/smallImage.png';
import { CardConfig, CardDesign, CardDesignLayout } from 'services/constants';
import { logError } from 'services/monitoring';
import { useGlobalState } from './index';

const CARD_DESIGN_FALLBACK: CardDesign = {
  id: '1',
  name: 'Card',
  smallImage,
  largeImage,
  layout: CardDesignLayout.physical,
  textColor: '#19242a',
  active: true,
  defaultDesign: true,
  cardConfig: CardConfig.pliantBlue,
};

const useCardDesign = (cardDesignId: string) => {
  const {
    state: { cardDesigns },
  } = useGlobalState();

  return useMemo(() => {
    if (cardDesignId === 'FALLBACK') return CARD_DESIGN_FALLBACK;

    const design = cardDesigns.find((item) => item.id === cardDesignId);

    if (design) {
      return design;
    } else {
      logError(new Error('Card design not found'), { cardDesignId });
      return CARD_DESIGN_FALLBACK;
    }
  }, [cardDesigns, cardDesignId]);
};

export default useCardDesign;
