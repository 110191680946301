import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import CountryFlag from 'components/CountryFlag';
import FormatMoney from 'components/FormatMoney';
import LabelValueItem from 'domains/creditAndCompliance/pages/CompliancePage/BankSubmissionSubPage/ReviewDialog/LabelValueItem';
import {
  Box,
  Chip,
  DataGrid,
  Grid,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import {
  Money,
  OrganizationGroup,
  OrganizationGroupMember,
  OrganizationGroupType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import GroupDetailsMenu from './GroupDetailsMenu';
import useColumns from './useColumns';

interface Props extends DetailsDrawerProps {
  refetchOrgGroups: () => void;
}

interface State {
  isLoading: boolean;
  memberOrgs: OrganizationGroupMember[];
  orgGroup: OrganizationGroup | null;
  totalLimit: Money | null;
}

const GroupDetailsPage = ({ refetchOrgGroups, ...props }: Props) => {
  const { organizationGroupId } = useParams<{ organizationGroupId: string }>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const [state, setState] = useState<State>({
    isLoading: true,
    orgGroup: null,
    memberOrgs: [],
    totalLimit: null,
  });
  const idRef = useRef(organizationGroupId);
  const { columns } = useColumns(
    state.orgGroup?.type === OrganizationGroupType.ru
  );

  const getData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const [
        orgGroup,
        { organizations: memberOrgs, totalLimit },
      ] = await Promise.all([
        api.getOrganizationGroup(organizationGroupId),
        api.getOrganizationGroupMembers(organizationGroupId),
      ]);
      if (!mounted.current || organizationGroupId !== idRef.current) return;
      setState((state) => ({
        ...state,
        isLoading: false,
        memberOrgs,
        orgGroup,
        totalLimit,
      }));
    } catch (error) {
      if (!mounted.current || organizationGroupId !== idRef.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      setState((state) => ({
        ...state,
        error,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    if (!organizationGroupId) return;
    idRef.current = organizationGroupId;
    getData();
  }, [organizationGroupId]);

  return (
    <DetailsDrawer
      {...props}
      PaperProps={{
        sx: {
          maxWidth: '1200px',
        },
      }}
      actionsComponent={
        state.orgGroup && (
          <GroupDetailsMenu
            orgGroup={state.orgGroup}
            refetchOrgGroup={getData}
            refetchOrgGroups={refetchOrgGroups}
          />
        )
      }
    >
      {state.orgGroup && (
        <>
          <DetailsDrawerHeader
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h5" component="span">
              {state.orgGroup?.name.value}
            </Typography>
            <Box display="flex" mt={0.5}>
              <Chip
                label={t(`int.organizationGroupType.${state.orgGroup.type}`)}
                size="small"
                sx={{ mr: 1 }}
              />
              <Chip
                label={
                  <>
                    <CountryFlag code={state.orgGroup.country} mr={1} />
                    {state.orgGroup.country}
                  </>
                }
                size="small"
              />
            </Box>
          </DetailsDrawerHeader>

          <DetailsDrawerContent>
            <Grid
              container
              direction="column"
              spacing={2}
              p={3}
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.divider}`,
              })}
            >
              {state.orgGroup.tradeRegisterNumber && (
                <LabelValueItem
                  label={t(
                    'int.organizationGroupDetailsPage.tradeRegisterNumber'
                  )}
                  value={state.orgGroup.tradeRegisterNumber}
                />
              )}
              {state.orgGroup.foundationDate && (
                <LabelValueItem
                  label={t('int.organizationGroupDetailsPage.foundationDate')}
                  value={state.orgGroup.foundationDate}
                />
              )}
              {state.orgGroup.birthDate && (
                <LabelValueItem
                  label={t('int.organizationGroupDetailsPage.birthDate')}
                  value={state.orgGroup.birthDate}
                />
              )}
              {state.orgGroup.crefoId && (
                <LabelValueItem
                  label={t('int.organizationGroupDetailsPage.crefoId')}
                  value={state.orgGroup.crefoId}
                />
              )}
              {state.orgGroup.duns && (
                <LabelValueItem
                  label={t('int.organizationGroupDetailsPage.duns')}
                  value={state.orgGroup.duns}
                />
              )}
              {state.orgGroup.maxLimit?.value && (
                <LabelValueItem
                  label={t('int.organizationGroupDetailsPage.maxLimit')}
                  value={<FormatMoney value={state.orgGroup.maxLimit.value} />}
                />
              )}
              {state.totalLimit && (
                <LabelValueItem
                  label={t('int.organizationGroupDetailsPage.totalLimit')}
                  value={<FormatMoney value={state.totalLimit} />}
                />
              )}
            </Grid>

            {state.memberOrgs?.length > 0 && (
              <Box p={2}>
                <DataGrid<OrganizationGroupMember>
                  columns={columns}
                  rows={state.memberOrgs}
                />
              </Box>
            )}
          </DetailsDrawerContent>
        </>
      )}

      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(GroupDetailsPage);
