declare global {
  interface Window {
    env: any;
  }
}

export type EnvType = {
  NODE_ENV: 'development' | 'production';
  REACT_APP_TARGET_ENV: 'development' | 'staging' | 'prod';
  REACT_APP_PARTNER_SCOPE:
    | 'pliant'
    | 'pliant-internal'
    | 'bbva'
    | 'bizaway'
    | 'intertours'
    | 'commerzbank'
    | 'portal';
  REACT_APP_BUILD_TARGET: 'external' | 'internal';
  REACT_APP_AUTH0_DOMAIN: string;
  REACT_APP_AUTH0_CLIENT_ID: string;
  REACT_APP_AUTH0_AUDIENCE: string;
  REACT_APP_API_URL: string;
  REACT_APP_ASSETS_URL: string;
  REACT_APP_DARKLY_CLIENT_SIDE_ID: string;
  REACT_APP_PCI_API_URL: string;
  REACT_APP_DOCUMENTS_URL: string;
  REACT_APP_LOCOIA_DEBUG: string;
  REACT_APP_INTERCOM_APP_ID: string;
  REACT_APP_LEXOFFICE_APP_BASE_URL: string;
  REACT_APP_COMMIT: string;
};

export const env: EnvType = { ...process.env, ...window.env };
