import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { adminPaths, partnerPaths } from 'components/App/ExternalApp/paths';
import { PartnerStatusBadge } from 'components/Badge/PartnerStatusBadge';
import { useGlobalState } from 'context/GlobalState';
import { isPartnerBasedSource } from 'domains/partner/utils';
import { IntegrationImage } from 'domains/settings/components';
import { IntegrationItem } from 'domains/settings/pages/IntegrationsPage';
import AuthorizationRevokeComponent from 'domains/settings/pages/IntegrationsPage/IntegrationDialog/AuthorizationRevokeComponent';
import { getStaticIntegrationsData } from 'domains/settings/utils';
import { Box, Button, LoaderWithOverlay, Typography } from 'elements';
import { env } from 'env';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import useUrls from 'hooks/useUrls';
import { ContentContainer, PageContent } from 'layout';
import {
  IntegrationNameType,
  PartnerAuthStatus,
  PartnerIds,
  PartnerIdType,
  PartnerOrgAuthDetails,
  PartnerScope,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';

interface State {
  partnerDetails: PartnerOrgAuthDetails | null;
  isLoading: boolean;
}

const EmbeddedPartnerPage = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const showPageError = useShowPageError();
  const { HELP_CENTER_EXPORTING_TRANSACTIONS_LEXOFFICE_URL } = useUrls();
  const {
    dispatch,
    state: { organization, partnerConfig },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    partnerDetails: null,
    isLoading: false,
  });

  const orgHasSourcePartner =
    !!organization && isPartnerBasedSource(organization.partnerId);

  const getIntegrationTexts = () => {
    const partnerStaticData = getStaticIntegrationsData(
      t,
      i18n,
      {
        HELP_CENTER_EXPORTING_TRANSACTIONS_LEXOFFICE_URL,
      },
      // use custom text when active
      state.partnerDetails?.status === PartnerAuthStatus.active
    );

    // all circula partner instances should use the same translations
    let circulaId;
    if (
      organization!.partnerId &&
      ([
        PartnerIds.circula,
        PartnerIds.circulaDemo,
        PartnerIds.circulaEmbedded,
        PartnerIds.circulaFullyEmbedded,
      ] as PartnerIdType[]).includes(organization!.partnerId)
    ) {
      circulaId = PartnerIds.circula;
    }

    return partnerStaticData[
      (circulaId || organization!.partnerId) as keyof typeof partnerStaticData
    ];
  };

  const integration: Partial<IntegrationItem> | null = state.partnerDetails && {
    name: organization!.partnerName! as IntegrationNameType,
    ...getIntegrationTexts(),
    visible: true,
    status: state.partnerDetails?.status || PartnerAuthStatus.inactive,
    partnerDetails: state.partnerDetails,
  };

  const getData = useCallback(async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const partnerDetails = await api.getPartnerOrgAuthDetails(
        organization!.partnerId,
        organization!.id
      );

      // update organization partner (source)
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { partnerOrgAuthDetails: partnerDetails },
      });

      if (!mounted.current) return;

      setState((prevState) => ({
        ...prevState,
        partnerDetails,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      showPageError();
      logError(error);
    }
  }, []);

  useEffect(() => {
    if (!orgHasSourcePartner) return;
    getData();
  }, []);

  if (!orgHasSourcePartner)
    return <Redirect to={adminPaths.settingsIntegrations} />;

  return (
    <PageContent pr={0} pb={0}>
      {integration && (
        <Box display="flex" height="100%">
          <ContentContainer mr={5}>
            <Box mb={4} display="flex" alignItems="center">
              <Typography variant="h3" mr={2}>
                {integration.title}
              </Typography>
              <PartnerStatusBadge status={integration.status!} />
            </Box>

            <Typography variant="subtitle1" mt={2} mb={6}>
              {integration.subtitle}
            </Typography>

            {integration.description?.map((str, index) => (
              <Typography key={index} mt={1}>
                {str}
              </Typography>
            ))}

            {integration.subDescription && (
              <Typography mt={4}>{integration.subDescription}</Typography>
            )}

            <Box mt={4}>
              {integration.status === PartnerAuthStatus.inactive ? (
                <Button
                  onClick={() =>
                    history.push(
                      `${partnerPaths.partners}/${organization!.partnerId}`
                    )
                  }
                  disabled={
                    !canUser('partners:change') || !integration.partnerDetails
                  }
                >
                  {t('partnerPage.connectButton')}
                </Button>
              ) : (
                <AuthorizationRevokeComponent
                  integration={integration}
                  onPartnerUnauthorized={getData}
                  disabled={
                    // allow revoke on testing envs
                    env.REACT_APP_TARGET_ENV === 'prod' &&
                    !!partnerConfig &&
                    [
                      PartnerScope.embeddedWallet,
                      PartnerScope.fullyEmbedded,
                    ].includes(partnerConfig.partnerScope) &&
                    integration.status === PartnerAuthStatus.active
                  }
                />
              )}
            </Box>
          </ContentContainer>

          <IntegrationImage
            $lg
            integrationName={integration.name!}
            partnerId={organization!.partnerId}
          />
        </Box>
      )}
      <LoaderWithOverlay loading={state.isLoading} />
    </PageContent>
  );
};

export default withPageConfig(EmbeddedPartnerPage, {
  permission: 'partners:view',
});
