import React, { MouseEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import {
  ArchiveIcon,
  DotsThreeOutlineVerticalIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  OrganizationRepresentativeInternalStatus,
  RelatedPerson,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  rep: RelatedPerson;
}

interface State {
  isLoading: boolean;
  isConfirmArchiveDialogOpen: boolean;
}

const RepresentativeMenu = ({ rep }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    actions: { fetchRelatedPersons },
  } = useCreditAndComplianceContext();

  const [state, setState] = useState<State>({
    isLoading: false,
    isConfirmArchiveDialogOpen: false,
  });

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const archiveRepresentative = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      await api.archiveOrganizationRelatedPersons(
        organization!.id,
        rep.id.value
      );

      await fetchRelatedPersons();

      if (!mounted) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const showArchiveMenuItem = [
    OrganizationRepresentativeInternalStatus.draft,
    OrganizationRepresentativeInternalStatus.ready,
  ].includes(rep.internalStatus.value);

  const hasMenu = showArchiveMenuItem;

  return (
    <>
      {hasMenu && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setMenuAnchorEl(e.currentTarget);
          }}
        >
          <DotsThreeOutlineVerticalIcon />
        </IconButton>
      )}

      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          setMenuAnchorEl(null);
        }}
      >
        {showArchiveMenuItem && (
          <MenuItem
            disabled={state.isLoading}
            onClick={(e) => {
              e.stopPropagation();
              setState((prevState) => ({
                ...prevState,
                isConfirmArchiveDialogOpen: true,
              }));
            }}
          >
            <ListItemIcon>
              <ArchiveIcon />
            </ListItemIcon>
            <ListItemText>{t('int.representativeMenu.archive')}</ListItemText>
          </MenuItem>
        )}
      </Menu>

      <ConfirmDialog
        open={state.isConfirmArchiveDialogOpen}
        onClose={(e) => {
          e.stopPropagation();
          setState((prevState) => ({
            ...prevState,
            isConfirmArchiveDialogOpen: false,
          }));
        }}
        onSuccess={(e) => {
          e.stopPropagation();
          archiveRepresentative();
        }}
        title={t('int.representativeMenu.archiveConfirmTitle')}
        description={
          <Trans
            i18nKey="int.representativeMenu.archiveConfirmDescription"
            values={{ name: `${rep.givenName.value} ${rep.surName.value}` }}
            components={{ b: <b /> }}
          />
        }
        loading={state.isLoading}
      />
    </>
  );
};

export default RepresentativeMenu;
