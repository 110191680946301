import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { CardAccount } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const MAX_ACCOUNT_NAME_LENGTH = 255;

interface FormValues {
  newAccountName: string;
}

interface Props extends DialogProps {
  cardAccount: CardAccount;
  onClose: () => void;
  onSuccess: (cardAccount: CardAccount) => void;
}

const RenameCardAccountDialog = ({
  onSuccess,
  cardAccount,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, featureModules },
  } = useGlobalState();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      newAccountName: '',
    },
    onSubmit: async ({ newAccountName }) => {
      try {
        const updatedCardAccount = await api.renameCardAccount(
          organization!.id,
          cardAccount.id,
          newAccountName
        );
        if (!mounted.current) return;
        onSuccess(updatedCardAccount);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const isAccountNameTooLong =
    formik.values.newAccountName.trim().length > MAX_ACCOUNT_NAME_LENGTH;

  const isSubmitDisabled =
    formik.isSubmitting ||
    isAccountNameTooLong ||
    (featureModules.MULTI_CARD_ACCOUNT &&
      (!formik.values.newAccountName.trim() ||
        isAccountNameTooLong ||
        formik.values.newAccountName === cardAccount.name.value));

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('renameCardAccountDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="rename-card-account-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            {featureModules.MULTI_CARD_ACCOUNT && (
              <Grid item xs={12}>
                <TextField
                  {...formik.getFieldProps('newAccountName')}
                  label={t('renameCardAccountDialog.accountName')}
                  error={isAccountNameTooLong}
                  helperText={
                    isAccountNameTooLong
                      ? t('renameCardAccountDialog.accountNameTooLongError', {
                          limit: MAX_ACCOUNT_NAME_LENGTH,
                        })
                      : t('renameCardAccountDialog.currentAccountName', {
                          name: cardAccount.name.value,
                        })
                  }
                  autoFocus
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="rename-card-account-form"
          type="submit"
        >
          {t('renameCardAccountDialog.renameAccount')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(RenameCardAccountDialog);
