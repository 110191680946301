import { isAxiosError } from 'axios';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  OnboardingTaskActions,
  TaskTitle,
} from 'domains/onboarding/components';
import { Box, LoaderWithOverlay, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  NetworkErrorCode,
  OnboardingItemStatus,
  OnboardingTaskTaxId,
  TaskNavigationPropsBase,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import TaxIdInputGroup from './TaxIdInputGroup';

export interface Props extends TaskNavigationPropsBase {
  task: OnboardingTaskTaxId;
  onUpdate: (newTask: OnboardingTaskTaxId) => void;
}

const TaxIdTask = ({
  isReadOnly,
  task,
  taskNavigationItems,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const { useUpdateTaxId } = useTanstackQuery();
  const { mutate: orgTaxIdMutate } = useUpdateTaxId({
    onSuccess: (response) => {
      if (!mounted.current) return;
      onUpdate(response);
    },
    onError: (error, variables) => {
      if (!mounted.current) return;
      formik.setSubmitting(false);
      const { data } = variables;
      if (getNetworkErrorCode(error) === NetworkErrorCode.invalidTaxId) {
        const invalidTaxId: string =
          (isAxiosError(error) && error.response?.data?.message) || '';
        const invalidTaxIdIndex = data.taxResidences.findIndex(
          (item) => item.taxId === invalidTaxId
        );
        if (invalidTaxIdIndex > -1) {
          formik.setFieldError(
            `taxResidences.[${invalidTaxIdIndex}].taxId`,
            t('errors.invalidTin')
          );
          return;
        }
      }
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    },
  });

  const {
    data: { taxResidences },
  } = task;

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      taxResidences: taxResidences.value?.length
        ? taxResidences.value
        : [
            {
              taxId: '',
              taxResidence: '',
              primary: true,
            },
          ],
    },
    onSubmit: (values) => {
      orgTaxIdMutate({
        organizationId: organization!.id,
        taskId: task.id,
        data: {
          taxResidences: values.taxResidences,
        },
      });
    },
  });

  const actionBtnDisabled = formik.values.taxResidences.some(
    (item) => !item.taxResidence || !item.taxId
  );

  return (
    <>
      <PageTitle pt={0} title={<TaskTitle task={task} />} />

      <Box mb={5} display="flex" alignItems="center">
        <Typography variant="body2" color="textSecondary">
          {t('orgOnboardingTaxIdTask.description')}
        </Typography>
      </Box>

      <TaxIdInputGroup
        disabled={isReadOnly || !taxResidences.editable}
        addFieldDisabled={actionBtnDisabled}
        isLoading={formik.isSubmitting}
        errors={formik.errors?.taxResidences ?? []}
        taxResidences={formik.values.taxResidences}
        onFieldsNumberChange={(taxResidences) =>
          formik.setFieldValue('taxResidences', taxResidences)
        }
        onChange={(index, key, value) => {
          formik.setFieldValue(`taxResidences.${[index]}.${key}`, value);
          if (key === 'taxId')
            formik.setFieldError(`taxResidences.${[index]}.${key}`, '');
          // remove error for taxId when residence is changed
          else formik.setFieldError(`taxResidences.${[index]}.taxId`, '');
        }}
        onErrorChange={(errors) => formik.setErrors({ taxResidences: errors })}
      />

      <Box mt={4}>
        <OnboardingTaskActions
          isReadOnly={isReadOnly || !taxResidences.editable}
          taskNavigationItems={taskNavigationItems}
          disabled={
            formik.isSubmitting ||
            (task.status !== OnboardingItemStatus.requiresAction &&
              !formik.dirty) ||
            actionBtnDisabled
          }
          onSuccess={formik.handleSubmit}
        />
      </Box>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default TaxIdTask;
