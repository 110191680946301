import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountCurrency } from 'domains/card/hooks';
import { isCreditOrgAvailableLimitLow } from 'domains/organization/utils';
import { BankIcon, Box, Button, Divider, Typography } from 'elements';
import { CardAccount, OrganizationAccountType } from 'services/constants';
import { getPath } from 'services/utils';

interface Props {
  activeCardAccounts: CardAccount[];
}

const MultiAccountsSingleCurrencyContent = ({ activeCardAccounts }: Props) => {
  const { t } = useTranslation();
  const currency = useCardAccountCurrency();
  const {
    state: { organization },
  } = useGlobalState();
  const totalAvailable = {
    value: activeCardAccounts.reduce(
      (accumulator, item) =>
        accumulator +
        (item.accountType.value === OrganizationAccountType.credit
          ? item.availableLimit.value.value
          : item.balance.value.value),
      0
    ),
    currency: currency.code,
  };
  const totalCreditLimit = {
    value: activeCardAccounts.reduce(
      (accumulator, item) => accumulator + item.creditLimit.value.value,
      0
    ),
    currency: currency.code,
  };
  const shouldShowCreditLimit = activeCardAccounts.some(
    (item) => item.accountType.value === OrganizationAccountType.credit
  );

  return (
    <>
      <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}>
        <Typography variant="subtitle1" flexGrow={1}>
          {t('dashboardPage.availableFundsAndLimit')}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          component={RouterLink}
          to={generatePath(getPath('cardAccounts'), {
            orgId: organization!.id,
          })}
        >
          {t('dashboardPage.viewAllAccounts')}
        </Button>
      </Box>

      <Box display="flex" flexWrap="wrap" mb={2}>
        <Box>
          <Typography
            variant="caption2"
            mb={0.5}
            component="div"
            color="text.secondary"
          >
            {t('dashboardPage.available')}
            {shouldShowCreditLimit && (
              <> / {t('dashboardPage.creditLimitTotal')}</>
            )}
          </Typography>
          <Typography
            variant="h5"
            color={
              shouldShowCreditLimit &&
              isCreditOrgAvailableLimitLow(totalAvailable, totalCreditLimit)
                ? 'warning.main'
                : 'text.primary'
            }
          >
            <FormatMoney value={totalAvailable} />
            {shouldShowCreditLimit && (
              <Typography
                variant="subtitle1"
                component="span"
                color="text.secondary"
              >
                {' '}
                / <FormatMoney value={totalCreditLimit} />
              </Typography>
            )}
          </Typography>
        </Box>
        <Divider orientation="vertical" sx={{ height: 'auto', mx: 3 }} />
        <Box>
          <Typography
            variant="caption2"
            mb={0.5}
            component="div"
            color="text.secondary"
          >
            {t('dashboardPage.accounts')}
          </Typography>
          <Box display="flex" alignItems="center">
            <BankIcon sx={{ mr: 1 }} />
            <Typography variant="h6">
              {t('dashboardPage.accountsCount', {
                count: activeCardAccounts.length,
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MultiAccountsSingleCurrencyContent;
