import React from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import {
  CardCategoryControlIndicator,
  CardDateControlIndicator,
  CardLocationControlIndicator,
  CardMerchantControlIndicator,
  CardTimeControlIndicator,
} from 'domains/card/components';
import { CardTile } from 'domains/card/components/CardTile';
import {
  CardRequestFormValues,
  IssueCardFormValues,
} from 'domains/card/dialogs';
import { useCardAccountCurrency } from 'domains/card/hooks';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import {
  Box,
  Chip,
  Paper,
  PaperLabeledValue,
  ProjectorScreenIcon,
  Stack,
  Typography,
  UsersIcon,
} from 'elements';
import { CardSimpleStatus } from 'services/constants';
import {
  convertDineroToMoney,
  dineroFromFloat,
  formatMoney,
} from 'services/utils';

const areCardRequestFormValues = (
  values: CardRequestFormValues | IssueCardFormValues
): values is CardRequestFormValues =>
  (values as CardRequestFormValues).comment !== undefined;

interface Props {
  values: CardRequestFormValues | IssueCardFormValues;
}

const IssueRequestCardSummaryContent = ({ values }: Props) => {
  const { t, i18n } = useTranslation();
  const {
    state: { projects, featureModules },
  } = useGlobalState();
  const currencyDisplay = useCurrencyDisplayMode();
  const currency = useCardAccountCurrency(values.cardAccountId);
  const customNames = areCardRequestFormValues(values)
    ? {
        customFirstName: null,
        customLastName: null,
      }
    : {
        customFirstName: values.customFirstName,
        customLastName: values.customLastName,
      };
  const purpose = values.purpose.trim();

  const renderTeamBadge = () => {
    if (!values.teamId) return null;

    const team = values.member!.teams.find(
      (item) => item.teamId === values.teamId
    )!;

    return (
      <Box display="flex" alignItems="center" my={0.5} mr={2}>
        <UsersIcon />
        <Chip label={team.name} sx={{ ml: 1 }} size="small" />
      </Box>
    );
  };

  const renderProjectBadge = () => {
    if (!values.projectId) return null;

    const project = projects!.find((item) => item.id === values.projectId)!;

    return (
      <Box display="flex" alignItems="center" my={0.5} mr={2}>
        <ProjectorScreenIcon />
        <Chip label={project.name} sx={{ ml: 1 }} size="small" />
      </Box>
    );
  };

  return (
    <>
      {(values.teamId || values.projectId) && (
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          pt={0.5}
          pb={2.5}
          mb={4}
          borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
        >
          {renderTeamBadge()}
          {renderProjectBadge()}
        </Box>
      )}
      <Box display="flex" mb={3}>
        <Box mr={3}>
          <CardTile
            card={{
              cardName: values.cardName.trim(),
              cardDesignId: values.cardDesignId,
              chFirstName: values.member!.firstName,
              chLastName: values.member!.lastName,
              ...customNames,
              simpleStatus: CardSimpleStatus.active,
              expiryDate: null,
            }}
            pan="**** **** **** ****"
          />
        </Box>
        <Box flexGrow="1">
          {values.cardConfigSetting!.maxUsage !== 1 && (
            <PaperLabeledValue
              label={t('cardLimit.limit')}
              value={
                <Trans
                  i18nKey={`cardLimitFrequency.limitWithFrequencies.${values.limitRenewFrequency}`}
                  values={{
                    limit: formatMoney(
                      convertDineroToMoney(
                        dineroFromFloat(values.limit, currency)
                      ),
                      i18n.language,
                      { currencyDisplay, fractionalPart: true }
                    ),
                  }}
                />
              }
            />
          )}
          <PaperLabeledValue
            label={t('cardLimit.transactionLimit')}
            value={
              <FormatMoney
                value={convertDineroToMoney(
                  dineroFromFloat(values.transactionLimit, currency)
                )}
                fractionalPart
              />
            }
          />
          {!values.cardConfigSetting!.validityPeriodEnabled && (
            <PaperLabeledValue
              label={t('issueRequestCardSummaryContent.validityPeriod')}
              value={`${values.expiryPeriodMonths} ${t(
                'issueRequestCardSummaryContent.months'
              )}`}
            />
          )}
          {purpose && (
            <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                mt={-0.5}
              >
                {t('issueRequestCardSummaryContent.purpose')}
              </Typography>
              <Typography variant="body2">{purpose}</Typography>
            </Paper>
          )}
        </Box>
      </Box>

      {values.cardConfigSetting!.validityPeriodEnabled && (
        <PaperLabeledValue
          label={t('issueRequestCardSummaryContent.validityPeriod')}
          value={`${moment(values.validFrom).format('D MMM YYYY')} - ${moment(
            values.validTo
          ).format('D MMM YYYY')}`}
        />
      )}

      {featureModules.CARD_CONTROLS &&
        (!!values.categoryControl?.values.length ||
          !!values.merchantControl?.values.length ||
          !!values.dateControl?.values.length ||
          !!values.timeControl?.values.length ||
          !!values.locationControl?.values.length) && (
          <Box
            mt={4}
            pt={2}
            sx={(theme) => ({
              borderTop: `1px solid ${theme.palette.divider}`,
            })}
          >
            <Typography variant="overline" component="div" py={0.5}>
              {t('issueRequestCardSummaryContent.cardControls')}
            </Typography>
            <CardCategoryControlIndicator control={values.categoryControl} />
            <CardMerchantControlIndicator control={values.merchantControl} />
            <CardDateControlIndicator control={values.dateControl} />
            <CardTimeControlIndicator control={values.timeControl} />
            <CardLocationControlIndicator control={values.locationControl} />
          </Box>
        )}

      {values.customFields.filter((v) => !!v).length > 0 && (
        <Box
          mt={4}
          pt={2}
          sx={(theme) => ({
            borderTop: `1px solid ${theme.palette.divider}`,
          })}
        >
          <Typography variant="overline" component="div" py={0.5}>
            {t('issueRequestCardSummaryContent.customFields')}
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            spacing={1}
            useFlexGap
          >
            {values.customFields.map((field, index) => {
              if (!field) return null;
              const {
                id,
                label,
                mandatoryOnAutomation,
              } = values.customFieldsResponse[index];
              const text =
                label +
                (mandatoryOnAutomation
                  ? ` (${t('cardRequestDialog_v2.attributesStep.mandatory')})`
                  : '') +
                ': ' +
                (typeof field === 'string' ? field : field.name);

              return <Chip key={id} label={text} size="small" />;
            })}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default IssueRequestCardSummaryContent;
