import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Paper,
  PaperProps,
  Typography,
} from 'elements';

const ToastPadding = 40;

const Width = {
  sm: 444,
  md: 700,
  lg: 900,
};
type PageToastWidth = keyof typeof Width;

export interface PageToastProps extends BoxProps {
  width: PageToastWidth;
  children?: React.ReactNode;
  PaperPropsSx?: PaperProps['sx'];
  paperProps?: PaperProps;
  isDetailsPageOpen?: boolean;
}

// todo: create a story later
export const PageToast = ({
  children,
  width = 'md',
  PaperPropsSx = [],
  paperProps,
  sx = [],
  isDetailsPageOpen = false,
  ...props
}: PageToastProps) => {
  return (
    <Box
      sx={[
        {
          position: 'absolute',
          left: ToastPadding,
          right: ToastPadding,
          bottom: 40,
          display: 'flex',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <Box flex={1} display="flex" position="relative">
        <Paper
          sx={[
            {
              maxWidth: Width[width],
              width: 1,
              px: 3,
              py: 2,
              position: 'absolute',
              bottom: 0,
              right: '50%',
              transform: 'translateX(50%)',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            },
            ...(Array.isArray(PaperPropsSx) ? PaperPropsSx : [PaperPropsSx]),
          ]}
          {...paperProps}
        >
          {children}
        </Paper>
      </Box>

      {/* placeholder, when details page is open */}
      <Box width={isDetailsPageOpen ? `calc(600px - ${ToastPadding}px)` : 0} />
    </Box>
  );
};

export interface SelectionPageToastProps extends PageToastProps {
  onSuccess: () => void;
  onCancel: () => void;
  cancelButtonProps?: Omit<ButtonProps, 'onClick'>;
  confirmButtonProps?: Omit<ButtonProps, 'onClick'>;
}

// toast (island) for table pages with Selection (with Action buttons)
export const SelectionPageToast = ({
  children,
  width = 'lg',
  onSuccess,
  onCancel,
  confirmButtonProps,
  cancelButtonProps,
  PaperPropsSx = [],
  ...props
}: SelectionPageToastProps) => {
  return (
    <PageToast
      width={width}
      PaperPropsSx={[
        { flexDirection: width === 'sm' ? 'column' : 'row' },
        ...(Array.isArray(PaperPropsSx) ? PaperPropsSx : [PaperPropsSx]),
      ]}
      {...props}
    >
      <Typography component="div" variant="subtitle1" mr="auto" pr={1} my={1}>
        {children}
      </Typography>

      <Box
        flexShrink={0}
        display="flex"
        width={width === 'sm' ? '100%' : 'auto'}
        mt={width === 'sm' ? 2 : 0}
      >
        {cancelButtonProps?.children && (
          <Button
            fullWidth={width === 'sm'}
            sx={{ mr: 1 }}
            onClick={onCancel}
            {...cancelButtonProps}
          />
        )}

        <Button
          fullWidth={width === 'sm'}
          onClick={onSuccess}
          {...confirmButtonProps}
        />
      </Box>
    </PageToast>
  );
};
