import React from 'react';
import { FormHelperText } from '@mui/material';
import { useFormik } from 'formik';
import { xor } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { transformCardConfigs } from 'domains/card/utils';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InfoIcon,
  LoaderWithOverlay,
  Tooltip,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  ActivatedAndAvailableTpcConfigs,
  CardConfig,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  configs: string[];
}

interface Props extends DialogProps {
  tpcConfigs: ActivatedAndAvailableTpcConfigs;
  onSuccess: (tpcConfigs: ActivatedAndAvailableTpcConfigs) => void;
  onClose: () => void;
}

const UpdateActivatedTpcConfigsDialog = ({
  tpcConfigs,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { requestCardForAnotherMemberEnabled } = useFlags();
  const {
    state: { organization },
    dispatch,
  } = useGlobalState();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      configs: tpcConfigs.activatedConfigs,
    },
    validate: ({ configs }) => {
      if (
        (configs.length === 1 &&
          configs.includes(CardConfig.commercialChoice200)) ||
        (configs.length === 2 &&
          configs.includes(CardConfig.commercialChoice200) &&
          configs.includes(CardConfig.pliantVirtualTravel)) ||
        (configs.includes(CardConfig.commercialChoice) &&
          configs.some((item) => item.includes('_CC_')))
      ) {
        return {
          configs: (
            <Trans
              i18nKey="int.updateActivatedTpcConfigsDialog.error"
              components={{ ul: <ul />, li: <li /> }}
            />
          ),
        };
      }
    },
    onSubmit: async ({ configs }, { setSubmitting }) => {
      try {
        const data = await api.updateActivatedTpcConfigs(organization!.id, {
          configs,
        });
        const { cardConfigs: cardConfigSettings } = await api
          .getCardConfigSettings(organization!.id)
          .then((data) =>
            transformCardConfigs(data, requestCardForAnotherMemberEnabled)
          )
          .catch((error) => {
            logError(error);
            return { cardConfigs: [] };
          });
        dispatch({
          type: 'SET_CARD_CONFIG_SETTINGS',
          payload: { cardConfigSettings },
        });
        if (!mounted.current) return;
        onSuccess(data);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const isSubmitDisabled =
    xor(tpcConfigs.activatedConfigs, formik.values.configs).length === 0 ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('int.updateActivatedTpcConfigsDialog.title')}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" mb={4}>
          {t('int.updateActivatedTpcConfigsDialog.description')}
          <Tooltip title={t('int.updateActivatedTpcConfigsDialog.tooltip')}>
            <InfoIcon
              fontSize="small"
              sx={{
                ml: 0.5,
                lineHeight: 1,
                verticalAlign: 'top',
                color: 'text.secondary',
              }}
            />
          </Tooltip>
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          id="update-activated-tpc-configs-form"
          noValidate
        >
          <FormControl error={!!formik.errors.configs}>
            <FormLabel>TPC sub-types</FormLabel>
            <FormGroup>
              {Object.entries(tpcConfigs.availableConfigs).map(
                ([config, isAvailable]) => (
                  <FormControlLabel
                    key={config}
                    label={t(`cardNamesForSubtypes.${config}`)}
                    {...formik.getFieldProps({
                      name: 'configs',
                      type: 'checkbox',
                      value: config,
                    })}
                    disabled={!isAvailable || formik.isSubmitting}
                    control={<Checkbox />}
                  />
                )
              )}
            </FormGroup>
            {formik.errors.configs && (
              <FormHelperText>{formik.errors.configs}</FormHelperText>
            )}
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="update-activated-tpc-configs-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(UpdateActivatedTpcConfigsDialog);
