import { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import InviteMemberButtonWithDialog from 'domains/member/components/InviteMemberButtonWithDialog';
import { useAvailableRoles } from 'domains/member/utils';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  LoadingButton,
  Menu,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { DEFAULT_PAGE_LIMIT, Member, MemberStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import MemberDetailsMenuWrapper from './MemberDetailsMenuWrapper';

interface State {
  anchorEl: HTMLElement | null;
  isLoading: boolean;
  members: Member[];
}

const InviteMemberButtonWithMenu = () => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const { isInviteMemberAllowed } = useAvailableRoles();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    anchorEl: null,
    isLoading: false,
    members: [],
  });

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: event.currentTarget,
    }));
  };

  const closeMenu = () => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: null,
    }));
  };

  const getData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      // no need to fetch more than 1 page
      const { members } = await api.getMembers({
        limit: DEFAULT_PAGE_LIMIT,
        organizationId: organization!.id,
        page: 0,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        members,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), {
        variant: 'error',
      });
      logError(error);
    }
  };

  useEffect(() => {
    if (canUser('onboarding-members-list:view')) getData();
  }, []);

  if (!isInviteMemberAllowed && !canUser('onboarding-members-list:view'))
    return null;

  return (
    <>
      <LoadingButton
        className={!!state.anchorEl ? 'selected' : ''}
        loading={state.isLoading}
        onClick={openMenu}
        sx={{ whiteSpace: 'nowrap' }}
        variant="outlined"
      >
        {t('inviteMemberButtonWithMenu.inviteMembers')}
      </LoadingButton>

      <Menu
        anchorEl={state.anchorEl}
        keepMounted
        onClose={closeMenu}
        open={!!state.anchorEl}
        MenuListProps={{
          sx: {
            overflow: 'hidden',
            minWidth: 420,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '90vh',
          },
        }}
      >
        <Typography variant="subtitle1" pb={2}>
          {t('inviteMemberButtonWithMenu.title')}
        </Typography>

        <List sx={{ overflow: 'auto', flex: 1 }}>
          {state.members.map((member) => {
            const isInvited = member.status === MemberStatus.invited;
            const isDeactivated = member.status === MemberStatus.deactivated;
            return (
              <ListItem
                key={member.id}
                secondaryAction={
                  canUser('member-details:view') && (
                    <MemberDetailsMenuWrapper
                      memberId={member.id}
                      onUpdate={getData}
                      onClose={closeMenu}
                    />
                  )
                }
              >
                <ListItemText
                  sx={{
                    ...(isInvited || isDeactivated
                      ? {
                          color: 'text.disabled',
                          '.MuiListItemText-secondary': { color: 'inherit' },
                        }
                      : {}),
                  }}
                  primary={[
                    member.firstName,
                    member.lastName,
                    ...(isInvited ? [`(${t('memberStatuses.INVITED')})`] : []),
                    ...(isDeactivated
                      ? [`(${t('memberStatuses.DEACTIVATED')})`]
                      : []),
                  ].join(' ')}
                  secondary={member.email}
                />
              </ListItem>
            );
          })}
        </List>

        <Box display="flex" justifyContent="flex-end" pt={2}>
          <Button variant="outlined" sx={{ mr: 2 }} onClick={closeMenu}>
            {t('common.button.cancel')}
          </Button>

          <InviteMemberButtonWithDialog
            isNewDesign
            startIcon={null}
            onDialogOpen={closeMenu}
            onSuccess={getData}
          />
        </Box>
      </Menu>
    </>
  );
};

export default InviteMemberButtonWithMenu;
