import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { Autocomplete, FormControl } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingItemStatus,
  CustomFieldOption,
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const MIN_ALLOWED_CHARS = 3;

type SelectedType = CustomFieldOption | 'new';

interface Props {
  selected: SelectedType;
  disabledIds: string[];
  onChange: (selectedId: SelectedType) => void;
}

interface State {
  inputValue: string;
  items: CustomFieldOption[];
  isOpen: boolean;
  isLoading: boolean;
}

const MappingDropdownOptionsAutocomplete = ({
  selected,
  disabledIds,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { fieldId } = useParams<{ fieldId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    state: { organization },
  } = useGlobalState();
  const value = selected === 'new' ? null : selected;

  const [state, setState] = useState<State>({
    inputValue: value?.name?.trim() || '',
    items: [],
    isOpen: false,
    isLoading: true,
  });

  const getData = async () => {
    try {
      const { customFieldOptions } = await api.getCustomFieldOptions({
        transactionCustomFieldId: fieldId,
        organizationId: organization!.id,
        q: state.inputValue.trim(),
        status: AccountingItemStatus.active,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        items: customFieldOptions,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isOpen: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    let active = true;

    if (state.inputValue.length < MIN_ALLOWED_CHARS || !fieldId) return;

    setState((prevState) => ({ ...prevState, isLoading: true }));

    setTimeout(() => {
      if (active) getData();
    }, DEFAULT_AUTOCOMPLETE_DELAY);

    return () => {
      active = false;
    };
  }, [state.inputValue.trim()]);

  return (
    <FormControl sx={{ width: 300 }}>
      <Autocomplete<CustomFieldOption, false, false, false>
        searchIcon
        open={state.isOpen}
        onOpen={() =>
          setState((prevState) => ({
            ...prevState,
            isOpen: prevState.inputValue.length >= MIN_ALLOWED_CHARS,
          }))
        }
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            options: value ? prevState.items : [],
            isOpen: false,
          }))
        }
        loadingText={t('common.loading')}
        placeholder={t('codatSyncSetupDialog.dropdownOptions.createNewOption')}
        options={state.items}
        loading={state.isLoading}
        value={value}
        inputValue={state.inputValue}
        getOptionDisabled={(option) => disabledIds.includes(option.id)}
        onInputChange={(e, inputValue) => {
          if (state.inputValue !== inputValue.trim()) {
            setState((prevState) => ({
              ...prevState,
              inputValue,
              isOpen: inputValue.length >= MIN_ALLOWED_CHARS,
            }));
          }
        }}
        onChange={(e, value) => onChange(value || 'new')}
        filterOptions={(x) => x}
        isOptionEqualToValue={(option) => value?.id === option?.id}
        getOptionLabel={(option) => option.name}
      />
    </FormControl>
  );
};

export default MappingDropdownOptionsAutocomplete;
