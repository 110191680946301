import {
  Box,
  ClockCountdownIcon,
  PlugsConnectedIcon,
  PlugsIcon,
  Typography,
} from 'elements';
import { PartnerAuthStatus, PartnerName } from 'services/constants';

interface Props {
  name: PartnerName | null;
  status?: PartnerAuthStatus;
}

const PartnerIndicator = ({ name, status }: Props) => {
  return (
    <Box display="flex" alignItems="center">
      {status === PartnerAuthStatus.active && (
        <PlugsConnectedIcon fontSize="small" color="inherit" />
      )}
      {status === PartnerAuthStatus.pending && (
        <ClockCountdownIcon fontSize="small" color="inherit" />
      )}
      {status === PartnerAuthStatus.inactive && (
        <PlugsIcon fontSize="small" color="inherit" />
      )}
      <Typography variant="body2" color="inherit" ml={1} noWrap>
        {name}
      </Typography>
    </Box>
  );
};

export default PartnerIndicator;
