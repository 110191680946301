import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import {
  ActionBox,
  ActionBoxActions,
  ActionBoxTitle,
  Button,
  LoaderWithOverlay,
  Typography,
  VerticalCardRequestIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Card,
  CardRequest,
  CardSimpleStatus,
  CardStatus,
  CardType,
  Team,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

export interface Props {
  card: Card;
  managerTeam?: Team;
  isCardholderApp?: boolean;
  onUpdate: (card: Card) => void;
}

const CardRequestDetails = ({
  card,
  isCardholderApp,
  managerTeam,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { requestCardForAnotherMemberEnabled } = useFlags();
  const canUser = useCanUser();
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [cardRequest, setCardRequest] = useState<CardRequest | null>(null);
  const confirmDialogRef = useRef({
    title: '',
    description: '',
    onSuccess: () => {},
  });

  const getCardRequest = async () => {
    try {
      setIsLoading(true);
      const data = await api.getCardRequest(card.cardId);

      if (!mounted.current) return;
      setCardRequest(data);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const cancelRequest = async () => {
    try {
      setIsConfirming(true);
      await api.cancelCardRequest(card.cardId);

      if (!mounted.current) return;
      onUpdate({
        ...card,
        simpleStatus: CardSimpleStatus.terminated,
        status: CardStatus.requestCancelled,
      });
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsConfirming(false);
      logError(error);
    }
  };

  const rejectRequest = async () => {
    try {
      setIsConfirming(true);
      await api.rejectCardRequest(card.cardId);
      const data = await api.getCard(card.cardId);

      if (!mounted.current) return;
      onUpdate(data);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsConfirming(false);
      logError(error);
    }
  };

  const approveRequest = async () => {
    try {
      setIsConfirming(true);
      await api.approveCardRequest(card.cardId);
      const data = await api.getCard(card.cardId);

      if (!mounted.current) return;
      onUpdate(data);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsConfirming(false);
      logError(error);
    }
  };

  useEffect(() => {
    getCardRequest();
  }, [card.cardId]);

  const renderActions = () => {
    if (isCardholderApp)
      return (
        <Button
          onClick={() => {
            confirmDialogRef.current = {
              title: t('cardRequestDetails.cancelConfirmDialog.title'),
              description: t(
                'cardRequestDetails.cancelConfirmDialog.description'
              ),
              onSuccess: cancelRequest,
            };
            setIsConfirmDialogOpen(true);
          }}
          color="error"
        >
          {t('cardRequestDetails.cancelRequest')}
        </Button>
      );

    if (
      canUser(
        'card-request:approve',
        cardRequest,
        managerTeam,
        requestCardForAnotherMemberEnabled
      )
    )
      return (
        <>
          <Button
            onClick={() => {
              confirmDialogRef.current = {
                title: t('cardRequestDetails.rejectConfirmDialog.title'),
                description: t(
                  'cardRequestDetails.rejectConfirmDialog.description'
                ),
                onSuccess: rejectRequest,
              };
              setIsConfirmDialogOpen(true);
            }}
            color="error"
          >
            {t('common.button.reject')}
          </Button>
          <Button
            onClick={() => {
              confirmDialogRef.current = {
                title: t('cardRequestDetails.approveConfirmDialog.title'),
                description: t(
                  'cardRequestDetails.approveConfirmDialog.description'
                ),
                onSuccess: approveRequest,
              };
              setIsConfirmDialogOpen(true);
            }}
            color="success"
          >
            {t('common.button.approve')}
          </Button>
        </>
      );

    return (
      <Typography variant="body2" color="text.secondary">
        {t('cardRequestDetails.missingPermission')}
      </Typography>
    );
  };

  return (
    <>
      <ActionBox
        icon={
          (!isLoading || !!cardRequest) && (
            <VerticalCardRequestIcon fontSize="inherit" />
          )
        }
        sx={{
          position: 'relative',
          minHeight: 110,
          borderRadius: isCardholderApp ? 1 : 0,
        }}
      >
        {cardRequest && (
          <>
            <ActionBoxTitle sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="inherit" flexGrow={1} noWrap>
                {t('cardRequestDetails.title')}
              </Typography>
              <Typography variant="caption" flexShrink={0} ml={0.5}>
                {moment(cardRequest.createdAt).format('D MMM YYYY HH:mm')}
              </Typography>
            </ActionBoxTitle>
            {cardRequest.type === CardType.singleUse
              ? cardRequest.purpose || '-'
              : cardRequest.comment}
            <ActionBoxActions>{renderActions()}</ActionBoxActions>
          </>
        )}
        <LoaderWithOverlay loading={isLoading} size="small" />
      </ActionBox>

      <ConfirmDialog
        open={isConfirmDialogOpen}
        loading={isConfirming}
        onClose={() => setIsConfirmDialogOpen(false)}
        onSuccess={confirmDialogRef.current.onSuccess}
        title={confirmDialogRef.current.title}
        description={confirmDialogRef.current.description}
      />
    </>
  );
};

export default CardRequestDetails;
