import { useTranslation } from 'react-i18next';
import { StatusBadge, StatusBadgeProps } from 'elements';
import { OnboardingItemStatus } from 'services/constants';

const getStatusColor = (
  status: OnboardingItemStatus
): StatusBadgeProps['color'] => {
  switch (status) {
    case OnboardingItemStatus.completed:
    case OnboardingItemStatus.verified:
      return 'success';
    case OnboardingItemStatus.submitted:
      return 'info';
    default:
      return 'primary';
  }
};

interface Props extends Omit<StatusBadgeProps, 'color' | 'label'> {
  status: OnboardingItemStatus;
}

const OnboardingStatusBadge = ({ status, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <StatusBadge
      label={t(`onboardingStatus.${status}`)}
      color={getStatusColor(status)}
      {...props}
    />
  );
};

export default OnboardingStatusBadge;
