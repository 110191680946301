import React, { useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  isCodatAccSystemConnected,
  isCustomLocoiaAccountingSystem,
} from 'domains/settings/utils';
import { useWaitForExportFlowTransactionsProcessing } from 'domains/transaction/hooks';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingSettings,
  AccountingSystem,
  ApiIntegrationStatus,
  DownloadExportFlowFilePayload,
  ExportFlowSummary,
  ExportFormat,
  InternalExportFormat,
  internalExportFormats,
  MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_ZIP_RECEIPTS,
  ReceiptDateExportMode,
  TransactionExportStatus,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  downloadZipFile,
  getFileNameFromHeader,
  getGenericErrorMsg,
} from 'services/utils';
import ExportFormats from './ExportFormats';
import useAccountingSystemExport from './useAccountingSystemExport';

const getDefaultFormat = (
  accountingSettings: AccountingSettings,
  isExported: boolean,
  isApiExportPermitted: boolean
): ExportFormat | InternalExportFormat => {
  if (accountingSettings.useAccountingExport) {
    if (isCodatAccSystemConnected(accountingSettings)) return ExportFormat.api;

    switch (accountingSettings.accountingSystem) {
      case AccountingSystem.procountor:
        return ExportFormat.csvMerged;

      case AccountingSystem.netvisor:
        return ExportFormat.csv;

      case AccountingSystem.camt:
        return ExportFormat.xml;

      case AccountingSystem.datev:
        if (
          isExported &&
          accountingSettings.exportFormats.includes(ExportFormat.api)
        )
          return ExportFormat.xml;
        if (
          !isExported &&
          isApiExportPermitted &&
          accountingSettings.exportFormats.includes(ExportFormat.api)
        )
          return ExportFormat.api;
        if (accountingSettings.exportFormats.includes(ExportFormat.csv))
          return ExportFormat.csv;
        if (accountingSettings.exportFormats.includes(ExportFormat.xml))
          return ExportFormat.xml;
        break;

      case AccountingSystem.lexOffice:
        if (!isExported && isApiExportPermitted) return ExportFormat.api;
        break;
    }
  }

  if (isCustomLocoiaAccountingSystem(accountingSettings))
    return InternalExportFormat.customCsv;

  return ExportFormat.csv;
};

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (isAsync?: boolean) => void;
  isExported: boolean;
  transactionIds: string[];
  exportFlowSummary: ExportFlowSummary;
}

interface State {
  isIncludeReceiptsSelected: boolean;
  format: ExportFormat | InternalExportFormat;
  isLoading: boolean;
}

const ExportTransactionsDialog = ({
  onSuccess,
  isExported,
  exportFlowSummary,
  transactionIds,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { datevExportEnabled } = useFlags();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { featureModules, organization, accountingSettings, member },
  } = useGlobalState();

  const isBookingDateInUse = ![
    ReceiptDateExportMode.receiptDate,
    ReceiptDateExportMode.dynamic,
  ].includes(accountingSettings!.receiptDateExportMode);

  const isDatevApiExportPermitted =
    accountingSettings!.accountingSystem === AccountingSystem.datev &&
    datevExportEnabled &&
    canUser('transactions-datev-api:export');
  const isLocoiaApiExportPermitted =
    accountingSettings!.accountingSystem === AccountingSystem.lexOffice;

  const [state, setState] = useState<State>({
    isIncludeReceiptsSelected: featureModules.RECEIPT_MANAGEMENT,
    format: getDefaultFormat(
      accountingSettings!,
      isExported,
      isDatevApiExportPermitted || isLocoiaApiExportPermitted
    ),
    isLoading: false,
  });
  const waitForExportFlowTransactionsProcessing = useWaitForExportFlowTransactionsProcessing();

  const isCustomAccSystemExportEnabled = useAccountingSystemExport();

  const exportTransactionsAsync = async () => {
    if (state.isLoading) return;
    const exportFormatToPass =
      state.format === InternalExportFormat.customCsv
        ? ExportFormat.csv
        : state.format;

    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      await api.updateTransactionsExport({
        organizationId: organization!.id,
        transactionIds,
        newExportStatus: TransactionExportStatus.exporting,
        exportFormat: exportFormatToPass,
        withReceipts: state.isIncludeReceiptsSelected, // handled on BE only for Custom CSV case
      });

      if (!mounted.current) return;

      const toastMsg = () => {
        if (state.format === InternalExportFormat.customCsv) {
          if (member.email) return 'customCsvExportInProcessMsg';
          return 'customCsvExportInProcessMsgGeneric';
        }

        return 'apiExportInProcessMsg';
      };

      enqueueSnackbar(
        t(`exportTransactionsDialog.${toastMsg()}`, { email: member.email }),
        {
          autoHideDuration: 5000,
        }
      );

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      onSuccess(true);
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const exportTransactions = async () => {
    if (
      transactionIds.length >
        MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_ZIP_RECEIPTS &&
      state.isIncludeReceiptsSelected
    ) {
      enqueueSnackbar(t('exportTransactionsDialog.limitTransactionsWarning'), {
        variant: 'error',
      });
      return;
    }

    if (state.isLoading) return;
    try {
      let accountingExportId;
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      if (!isExported) {
        const exportFormat =
          state.format === ExportFormat.csvMerged ||
          state.format === ExportFormat.csvSmartExcel
            ? ExportFormat.csv
            : (state.format as ExportFormat);

        const data = await api.updateTransactionsExport({
          organizationId: organization!.id,
          transactionIds,
          newExportStatus: TransactionExportStatus.exported,
          exportFormat,
        });
        if (!mounted.current) return;
        accountingExportId = data.accountingExportId;
        await waitForExportFlowTransactionsProcessing({
          organizationId: organization!.id,
          exportStatus: TransactionExportStatus.exported,
          accountingExportId,
          totalCount: transactionIds.length,
        });
        if (!mounted.current) return;
      }

      const response = await api.downloadExportFlowFile({
        organizationId: organization!.id,
        withReceipts: state.isIncludeReceiptsSelected,
        common: !isCustomAccSystemExportEnabled,
        format: state.format as
          | ExportFormat.csv
          | ExportFormat.xml
          | ExportFormat.csvMerged
          | ExportFormat.csvSmartExcel,
        transactionIds: accountingExportId ? undefined : transactionIds,
        accountingExportId,
        accountingSystem: isCustomAccSystemExportEnabled
          ? (accountingSettings!
              .accountingSystem as DownloadExportFlowFilePayload['accountingSystem'])
          : undefined,
      });

      if (!mounted.current) return;
      const fileName = getFileNameFromHeader(response.headers);
      downloadZipFile(fileName, response.data);
      onSuccess();
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {isExported
          ? t('exportTransactionsDialog.exportedTitle')
          : t('exportTransactionsDialog.inExportQueueTitle')}
      </DialogTitle>
      <DialogContent>
        {featureModules.RECEIPT_MANAGEMENT &&
          !isExported &&
          !!exportFlowSummary.missingReceipts && (
            <Alert severity="error" sx={{ marginBottom: 1 }}>
              <AlertTitle>
                {t('exportTransactionsDialog.missingReceiptTitle')}
              </AlertTitle>
              {t('exportTransactionsDialog.missingReceiptDescription')}
            </Alert>
          )}

        {!isExported &&
          !!(
            exportFlowSummary.missingSubcategory ||
            exportFlowSummary.missingVatRate
          ) && (
            <Alert severity="warning" sx={{ marginBottom: 1 }}>
              <AlertTitle>
                {t('exportTransactionsDialog.missingInfoTitle')}
              </AlertTitle>
              {t('exportTransactionsDialog.missingInfoDescription')}
            </Alert>
          )}

        {!isExported &&
          featureModules.MANAGER_TX_REVIEWS &&
          !!exportFlowSummary.missingReview && (
            <Alert severity="warning" sx={{ marginBottom: 1 }}>
              <AlertTitle>
                {t('exportTransactionsDialog.missingReviewTitle')}
              </AlertTitle>
              {t('exportTransactionsDialog.missingReviewDescription')}
            </Alert>
          )}

        {!isExported && (
          <Alert severity="info" sx={{ marginBottom: 1 }}>
            <Trans
              i18nKey={'exportTransactionsDialog.inExportQueueDescription'}
              components={{ b: <b />, u: <u /> }}
            />
          </Alert>
        )}

        {isExported && (
          <Typography variant="body2">
            {t('exportTransactionsDialog.exportedDescription')}
          </Typography>
        )}

        <Table sx={{ marginTop: 2, marginBottom: 3 }}>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: '48%' }}>
                <Typography variant="subtitle2">
                  {isBookingDateInUse
                    ? t('exportTransactionsDialog.transactionPeriod')
                    : t(
                        'exportTransactionsDialog.transactionPeriodReceiptDate'
                      )}
                </Typography>
              </TableCell>
              <TableCell>
                {`${moment(exportFlowSummary.earliestTransaction).format(
                  'D MMM YYYY'
                )} - ${moment(exportFlowSummary.latestTransaction).format(
                  'D MMM YYYY'
                )}`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  {t('exportTransactionsDialog.totalNumberOfTransactions')}
                </Typography>
              </TableCell>
              <TableCell>{exportFlowSummary.selected}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <ExportFormats
          isExported={isExported}
          isLoading={state.isLoading}
          isIncludeReceiptsSelected={state.isIncludeReceiptsSelected}
          format={state.format}
          onIncludeReceiptsChange={(checked) =>
            setState((prevState) => ({
              ...prevState,
              isIncludeReceiptsSelected: checked,
            }))
          }
          onSave={(format) => {
            setState((prevState) => ({
              ...prevState,
              format,
            }));
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {isCustomAccSystemExportEnabled &&
          accountingSettings!.accountingSystem !== AccountingSystem.datev
            ? t('common.button.close')
            : t('common.button.cancel')}
        </Button>
        <Button
          disabled={
            state.isLoading ||
            (accountingSettings!.apiIntegrationStatus !==
              ApiIntegrationStatus.connected &&
              [ExportFormat.api, InternalExportFormat.customCsv].includes(
                state.format
              ))
          }
          onClick={
            state.format === ExportFormat.api ||
            internalExportFormats.includes(state.format as InternalExportFormat)
              ? exportTransactionsAsync
              : exportTransactions
          }
        >
          {isCustomAccSystemExportEnabled &&
          accountingSettings!.accountingSystem !== AccountingSystem.datev
            ? t('exportTransactionsDialog.generateExportFiles')
            : t('exportTransactionsDialog.submit')}
        </Button>
      </DialogActions>

      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(ExportTransactionsDialog);
