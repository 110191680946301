import { Money } from 'services/constants';

const isCreditOrgAvailableLimitLow = (
  availableLimit: Money | null,
  limit: Money | null
) => {
  if (!availableLimit || !limit || !limit.value) return false;
  return (availableLimit.value / limit.value) * 100 <= 20;
};

export default isCreditOrgAvailableLimitLow;
