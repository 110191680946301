import { Link } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingStatusBadge } from 'domains/onboarding/components';
import {
  getNavigationPath,
  getUrlFromOnboardingKeyType,
  SectionIconByType,
} from 'domains/onboarding/utils';
import {
  Avatar,
  CaretRightIcon,
  IconButton,
  Paper,
  PaperProps,
  Typography,
} from 'elements';
import { OnboardingSection } from 'services/constants';

interface Props extends PaperProps {
  section: OnboardingSection;
}

const SectionItem = ({ children, section, ...props }: Props) => {
  const {
    state: { organization },
  } = useGlobalState();

  const Icon = SectionIconByType[section.type];

  return (
    <Paper
      variant="outlined"
      sx={{ p: 2, display: 'flex', alignItems: 'center', '&+&': { mt: 2 } }}
      {...props}
    >
      <Avatar
        variant="rounded"
        sx={{ bgcolor: (theme) => theme.variables.warmGrey[200] }}
      >
        {Icon && <Icon color="primary" />}
      </Avatar>

      <Typography variant="h6" component="div" ml={2} flex={1}>
        {children}
      </Typography>

      <OnboardingStatusBadge status={section.status} />

      <IconButton
        component={Link}
        sx={{ ml: 2 }}
        to={{
          pathname: getNavigationPath(
            organization!.id,
            getUrlFromOnboardingKeyType(section.type)
          ),
        }}
      >
        <CaretRightIcon />
      </IconButton>
    </Paper>
  );
};

export default SectionItem;
