import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  MAX_RECEIPT_FILE_SIZE,
  MAX_RECEIPT_FILE_SIZE_MB,
} from 'domains/transaction/constants';
import { Box, Dropzone, DropzoneErrorCode, FileRejection } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Receipt, Transaction } from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import useRequest from 'services/network/useRequest';

interface State {
  file: File | null;
  isLoading: boolean;
  progress: number;
  hasError: boolean;
}

interface Props {
  isInPreviewDialog?: boolean;
  transaction: Transaction;
  showMissingReceiptMessage?: boolean;
  receiptId?: string;
  onUploadStart: () => void;
  onSuccess: (receipt: Receipt) => void;
  onFail: (error: unknown) => void;
  disabled: boolean;
}

const ReceiptDropzone = ({
  isInPreviewDialog,
  transaction,
  showMissingReceiptMessage,
  receiptId,
  onUploadStart,
  onSuccess,
  onFail,
  disabled,
}: Props) => {
  const { ereceiptUploadEnabled } = useFlags();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const request = useRequest();
  const api = useImperativeApi();
  const {
    state: { organization },
  } = useGlobalState();
  const missingReceiptNotificationEnabled = !!organization?.missingReceiptNotificationEnabled;

  const [state, setState] = useState<State>({
    file: null,
    isLoading: false,
    progress: 0,
    hasError: false,
  });

  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      const [file] = acceptedFiles;
      try {
        onUploadStart();
        setState({ file, isLoading: true, progress: 0, hasError: false });
        const response = receiptId
          ? await api.replaceReceipt(receiptId, file, (progress) => {
              if (!mounted.current) return;
              setState((state) => ({ ...state, progress }));
            })
          : await api.uploadReceipt(
              transaction.transactionId,
              file,
              (progress) => {
                if (!mounted.current) return;
                setState((state) => ({ ...state, progress }));
              }
            );
        if (!mounted.current) return;
        setState({
          file: null,
          isLoading: false,
          progress: 0,
          hasError: false,
        });
        onSuccess(response);
      } catch (error) {
        if (!mounted.current) return;
        onFail(error);
        if (!mounted.current) return;
        setState((state) => ({ ...state, hasError: true, isLoading: false }));
        logError(error);
      }
    },
    [request, transaction]
  );

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      if (
        fileRejections.length === 1 &&
        fileRejections[0].errors.length === 1 &&
        fileRejections[0].errors[0].code === DropzoneErrorCode.FileTooLarge
      ) {
        enqueueSnackbar(
          t('receiptDropzone.fileIsTooBigErrorMessage', {
            size: MAX_RECEIPT_FILE_SIZE_MB,
          }),
          {
            variant: 'error',
          }
        );
      }
    },
    [enqueueSnackbar]
  );

  const onDragEnter = useCallback(() => {
    setState((state) =>
      state.hasError
        ? { file: null, isLoading: false, progress: 0, hasError: false }
        : state
    );
  }, []);

  const onFileDialogCancel = useCallback(() => {
    setState((state) =>
      state.hasError
        ? { file: null, isLoading: false, progress: 0, hasError: false }
        : state
    );
  }, []);

  const getLabelKey = () => {
    if (receiptId) return 'receiptDropzone.replaceReceipt';
    if (showMissingReceiptMessage && missingReceiptNotificationEnabled)
      return 'receiptDropzone.uploadMissingReceipt';
    return 'receiptDropzone.uploadReceipt';
  };

  return (
    <Box my={isInPreviewDialog ? 0 : 2}>
      <Dropzone
        variant={isInPreviewDialog ? 'error' : 'default'}
        progress={state.progress}
        file={state.file}
        isLoading={state.isLoading}
        disabled={!!state.file || state.isLoading || disabled}
        error={
          state.hasError ? t('receiptDropzone.uploadErrorMessage') : undefined
        }
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
        onFileDialogCancel={onFileDialogCancel}
        onDragEnter={onDragEnter}
        dropzoneIdleProps={{
          description: (
            <Trans
              i18nKey={getLabelKey()}
              components={{
                u: <u />,
              }}
            />
          ),
          secondaryDescription: ereceiptUploadEnabled
            ? t('receiptDropzone.dropzoneLabel_v2', {
                format: '*.jpg, *.png, *.pdf, *.xml',
              })
            : t('receiptDropzone.dropzoneLabel'),
          dragRejectDescription: ereceiptUploadEnabled
            ? t('receiptDropzone.dropRejectLabel_v2', {
                format: '*.jpg, *.png, *.pdf, *.xml',
              })
            : t('receiptDropzone.dropRejectLabel'),
        }}
        multiple={false}
        accept={
          ereceiptUploadEnabled
            ? 'image/jpeg, image/png, application/pdf, application/xml, text/xml'
            : 'image/jpeg, image/png, application/pdf'
        }
        maxSize={MAX_RECEIPT_FILE_SIZE}
        dataTestId="receipt-dropzone"
      />
    </Box>
  );
};

export default ReceiptDropzone;
