import { ComponentType, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLinkProps } from 'react-router-dom';
import { DatabaseIcon, SvgIconProps, TreeStructureIcon } from 'elements';

export interface Tab {
  label: ReactNode;
  pathId: string;
  testId: string;
  intercomTarget?: string;
  Icon: ComponentType<SvgIconProps>;
  children?: ReactNode;
  isActive?: NavLinkProps['isActive'];
  items?: Omit<Tab, 'Icon' | 'items'>[];
}

const usePortalTabs = (): Tab[] => {
  const { t } = useTranslation();

  return [
    {
      label: t('navigation.dataAndInsightsTab'),
      pathId: 'organizations',
      testId: 'data-and-insights-tab',
      intercomTarget: 'sidebar-data-and-insights-button',
      Icon: DatabaseIcon,
      items: [
        {
          label: t('navigation.organizations'),
          pathId: 'organizations',
          testId: 'organizations-sub-tab',
          intercomTarget: 'organizations-button',
        },
        {
          label: t('navigation.members'),
          pathId: 'users',
          testId: 'users-sub-tab',
          intercomTarget: 'users-button',
        },
      ],
    },
    {
      label: t('navigation.managementTab'),
      pathId: 'partnerMembers',
      testId: 'management-tab',
      intercomTarget: 'sidebar-management-button',
      Icon: TreeStructureIcon,
      items: [
        {
          label: t('navigation.partnerMembers'),
          pathId: 'partnerMembers',
          testId: 'members-management-sub-tab',
          intercomTarget: 'members-management-button',
        },
      ],
    },
  ];
};

export default usePortalTabs;
