import { useCallback } from 'react';
import { useOnboardingQuery } from './useOnboardingQuery';
import useRequest from './useRequest';

export const useTanstackQuery = () => {
  const request = useRequest();
  const memoizedRequest = useCallback(request, [request]);

  const onboardingQueries = useOnboardingQuery(memoizedRequest);

  return {
    ...onboardingQueries,
  };
};
