import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { ReceiptInboxStatusBadge } from 'domains/organization/components';
import { ReceiptInboxThumbnail } from 'domains/transaction/components';
import {
  DataGridCell,
  DataGridSecondaryHeader,
  GridColDef,
  StatusBadge,
  Typography,
} from 'elements';
import { ReceiptAutoMatching } from 'services/constants';

const useColumns = (
  thumbnails: { [key: string]: string | null },
  isThumbnailLoading: boolean
) => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<ReceiptAutoMatching>[] = [
      {
        headerName: '',
        field: 'thumbnail',
        sortable: false,
        width: 72,
        renderCell: ({ row }) => (
          <ReceiptInboxThumbnail
            status={row.status}
            thumbnail={thumbnails[row.id] || ''}
            isLoading={isThumbnailLoading && thumbnails[row.id] === undefined}
          />
        ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('receiptInbox.table.date')}
            <DataGridSecondaryHeader>
              {t('receiptInbox.table.time')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'createdAt',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <DataGridCell>
            {moment(row.createdAt).format('D MMM YYYY')}
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              noWrap
            >
              {moment(row.createdAt).format('HH:mm')}
            </Typography>
          </DataGridCell>
        ),
      },
      {
        renderHeader: () => (
          <DataGridCell>
            {t('receiptInbox.table.sender')}
            <DataGridSecondaryHeader>
              {t('receiptInbox.table.subject')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'sender',
        sortable: false,
        flex: 1.5,
        renderCell: ({ row }) => (
          <DataGridCell>
            {row.emailFrom}
            <Typography
              component="div"
              variant="caption"
              color="textSecondary"
              noWrap
            >
              {row.emailSubject || '-'}
            </Typography>
          </DataGridCell>
        ),
      },
      {
        headerName: t('receiptInbox.table.merchant'),
        field: 'merchantName',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <DataGridCell>
            {row.ocr.merchantName || (
              <Typography variant="body2" color="textSecondary" noWrap>
                {t('receiptInbox.table.NA')}
              </Typography>
            )}
          </DataGridCell>
        ),
      },
      {
        renderHeader: () => (
          <DataGridCell sx={{ textAlign: 'right' }}>
            {t('receiptInbox.table.amount')}
            <DataGridSecondaryHeader>
              {t('receiptInbox.table.receiptDate')}
            </DataGridSecondaryHeader>
          </DataGridCell>
        ),
        field: 'amount',
        sortable: false,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ row }) => (
          <DataGridCell sx={{ textAlign: 'right' }}>
            {row.ocr.amount || row.ocr.receiptDate ? (
              <>
                {row.ocr.amount ? (
                  <FormatMoney value={row.ocr.amount} fractionalPart />
                ) : (
                  '-'
                )}
                <Typography
                  component="div"
                  variant="caption"
                  color="textSecondary"
                  noWrap
                >
                  {row.ocr.receiptDate
                    ? moment(row.ocr.receiptDate).format('D MMM YYYY')
                    : '-'}
                </Typography>
              </>
            ) : (
              <Typography variant="body2" color="textSecondary" noWrap>
                {t('receiptInbox.table.NA')}
              </Typography>
            )}
          </DataGridCell>
        ),
      },
      {
        headerName: t('receiptInbox.table.type'),
        field: 'ereceiptType',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) =>
          row.ereceiptType ? (
            <StatusBadge
              label={row.ereceiptType}
              variant="filled"
              color="primary"
            />
          ) : null,
      },
      {
        headerName: t('receiptInbox.table.status'),
        field: 'status',
        sortable: false,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ row }) => (
          <ReceiptInboxStatusBadge status={row.status} />
        ),
      },
    ];

    return columns;
  }, [i18n.language, thumbnails, isThumbnailLoading]);
};

export default useColumns;
