import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useGlobalState } from 'context/GlobalState';
import { IssueCardDialog } from 'domains/card/dialogs';
import { useAvailableRoles } from 'domains/member/utils';
import { Dialog, DialogProps, withDialogWrapper } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Member,
  MemberDetails,
  MemberStatus,
  SubscriptionPlan,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { Role, useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import InviteMemberStep from './InviteMemberStep';
import MemberPermissionsStep from './MemberPermissionsStep';

export interface FormikHandlers {
  onSubmit: (
    values: AllFormikValues,
    helpers: FormikHelpers<AllFormikValues>
  ) => Promise<any> | void;
  validate: (values: AllFormikValues) => void;
}

export interface AllFormikValues {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
  teamIds: string[];
  shouldIssueCard: boolean;
  editGeneralSettings: boolean;
  editModules: boolean;
  editIntegrations: boolean;
  maxSpendLimitPerCard: string;
  canCreateCardForSelf: boolean;
  accountingSettings: boolean;
  accountingExport: boolean;
}

interface State {
  isLoading: boolean;
  members: null | Member[];
  memberToIssueCardFor: null | MemberDetails;
}

interface Props extends DialogProps {
  subscriptionPlan: SubscriptionPlan;
  onClose: () => void;
  onSuccess: (member: MemberDetails) => void;
}

const InviteMemberDialog = ({
  subscriptionPlan,
  onSuccess,
  ...props
}: Props) => {
  const {
    state: { organization },
  } = useGlobalState();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const [step, setStep] = useState<'inviteMember' | 'setMemberPermissions'>(
    'inviteMember'
  );
  const [state, setState] = useState<State>({
    isLoading: false,
    members: null,
    memberToIssueCardFor: null,
  });
  const [formikHandlers, setFormikHandlers] = useState<FormikHandlers>({
    onSubmit: () => {},
    validate: () => {},
  });
  const { allowedRoles } = useAvailableRoles({
    members: state.members,
    isInvitingMember: true,
  });

  useEffect(() => {
    (async () => {
      if (canUser('member-first:create')) {
        try {
          setState((prevState) => ({ ...prevState, isLoading: true }));
          const { members } = await api.getMembers({
            organizationId: organization!.id,
            status: [MemberStatus.invited, MemberStatus.active].join(),
            limit: 1,
          });
          if (!mounted.current) return;
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            members,
          }));
        } catch (error) {
          if (!mounted.current) return;
          setState((prevState) => ({ ...prevState, isLoading: false }));
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    })();
  }, []);

  const onInviteSuccess = (member: MemberDetails, shouldIssueCard: boolean) => {
    if (shouldIssueCard) {
      setState((prevState) => ({ ...prevState, memberToIssueCardFor: member }));
    } else {
      onSuccess(member);
    }
  };

  if (state.memberToIssueCardFor) {
    return (
      <IssueCardDialog
        {...props}
        onClose={() => onSuccess(state.memberToIssueCardFor!)}
        onSuccess={() => onSuccess(state.memberToIssueCardFor!)}
        member={state.memberToIssueCardFor}
      />
    );
  }

  return (
    <Dialog {...props} maxWidth={step === 'inviteMember' ? 'sm' : 'xs'}>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={
          {
            title: '',
            firstName: '',
            lastName: '',
            email: '',
            role: allowedRoles[0].role,
            teamIds: [],
            shouldIssueCard: false,
            editGeneralSettings: true,
            editModules: true,
            editIntegrations: true,
            maxSpendLimitPerCard: '',
            canCreateCardForSelf: false,
            accountingSettings: true,
            accountingExport: true,
          } as AllFormikValues
        }
        onSubmit={formikHandlers.onSubmit}
        validate={formikHandlers.validate}
      >
        {step === 'inviteMember' ? (
          <InviteMemberStep
            allowedRoles={allowedRoles}
            isFirstMember={state.members?.length === 0}
            isLoading={state.isLoading}
            nextStep={() => setStep('setMemberPermissions')}
            subscriptionPlan={subscriptionPlan}
            onClose={props.onClose}
            onInviteSuccess={onInviteSuccess}
            setFormikHandlers={setFormikHandlers}
          />
        ) : (
          <MemberPermissionsStep
            setFormikHandlers={setFormikHandlers}
            previousStep={() => setStep('inviteMember')}
            onInviteSuccess={onInviteSuccess}
            onClose={props.onClose}
          />
        )}
      </Formik>
    </Dialog>
  );
};

export default withDialogWrapper<Props>(InviteMemberDialog);
