import { camelCase, startCase } from 'lodash';
import moment from 'moment';
import printJS from 'print-js';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { CrefoReport, ExternalReport } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { downloadFileUsingAnchorTag, getGenericErrorMsg } from 'services/utils';

function b64DecodeUnicode(str: string) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}

const useDownloadReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    state: { creditProfile },
  } = useCreditAndComplianceContext();

  return async (report: CrefoReport | ExternalReport) => {
    try {
      const printableOrgName = startCase(camelCase(organization!.name)).replace(
        / /g,
        ''
      );
      const reportDate = moment(report.downloadedAt).format('YYYYMMDD');

      if ('crefoReportRawResponseId' in report) {
        const { data } = await api.getCrefoReportContents(
          report.crefoReportRawResponseId
        );
        if (!mounted.current) return;
        downloadFileUsingAnchorTag(
          `${reportDate}_${printableOrgName}_CR_Crefo.pdf`,
          'data:application/pdf;base64,' + data
        );
      } else {
        const { data } = await api.getExternalReportContents(
          creditProfile!.agencyProfile.duns.value,
          report.reportId
        );
        if (!mounted.current) return;
        const initialPageTitle = document.title;
        // printJs takes the page title as default file name when saving the pdf via prrint dialog
        document.title = `${reportDate}_${printableOrgName}_CR_DnB.pdf`;
        printJS({
          printable: b64DecodeUnicode(data),
          type: 'raw-html',
          onPrintDialogClose: () => (document.title = initialPageTitle),
        });
      }
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };
};

export default useDownloadReport;
