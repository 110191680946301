import { MobileStepper, MobileStepperProps } from '@mui/material';
import { CSS_PROPS_BY_SIZE, Size } from 'elements/LinearProgress';

export interface MobileStepperProgressProps extends MobileStepperProps {
  /**
   * The size of the component.
   * @default 'medium'
   */
  size?: Size;
}

export const MobileStepperProgress = ({
  size = 'medium',
  sx = [],
  ...props
}: MobileStepperProgressProps) => {
  const { height, borderRadius } = CSS_PROPS_BY_SIZE[size];

  return (
    <MobileStepper
      variant="progress"
      position="static"
      sx={[
        {
          '.MuiMobileStepper-progress': {
            height,
            borderRadius,
            background: (theme) => theme.palette.grey[300],
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  );
};
