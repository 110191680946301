import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormatPercent from 'components/FormatPercent';
import { useGlobalState } from 'context/GlobalState';
import { UpdateTransferFxFeeDialog } from 'domains/terms/dialogs';
import { CircularProgress, EditButton, Paper, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

const TransferFxFeeSection = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const [fxTransferFeePercentage, setFxTransferFeePercentage] = useState<
    number | null
  >(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const getData = async () => {
    try {
      const { fxTransferFeePercentage } = await api.getFxTransferFeePercentage(
        organization!.id
      );
      if (!mounted.current) return;
      setFxTransferFeePercentage(fxTransferFeePercentage);
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    if (!canUser('transfer-fx-fee:view')) return;
    getData();
  }, []);

  if (!canUser('transfer-fx-fee:view')) return null;

  const renderPercentage = () => {
    if (isLoading) return <CircularProgress size="small" />;

    if (fxTransferFeePercentage === null) return '-';
    return <FormatPercent value={fxTransferFeePercentage} />;
  };

  return (
    <>
      <Paper
        variant="outlined"
        sx={{ display: 'flex', alignItems: 'center', p: 2 }}
      >
        <Typography sx={{ width: '33%', py: '10px' }}>
          {t('int.subscriptionSettingsPage.transferFxFeeSection.title')}
        </Typography>
        <Typography sx={{ flexGrow: 1 }}>{renderPercentage()}</Typography>
        {canUser('transfer-fx-fee:change') && (
          <EditButton
            disabled={isLoading}
            onClick={() => setIsDialogOpen(true)}
          />
        )}
      </Paper>

      <UpdateTransferFxFeeDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSuccess={(fxTransferFeePercentage) => {
          setFxTransferFeePercentage(fxTransferFeePercentage);
          setIsDialogOpen(false);
        }}
        fxFeePercentage={fxTransferFeePercentage}
      />
    </>
  );
};

export default TransferFxFeeSection;
