import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import {
  portalOrgPaths,
  PortalOrgPathsKeys,
} from 'components/App/PortalApp/paths';
import { Container, ContainerInner, MainContent } from 'components/App/style';
import { PortalMainHeader } from 'components/MainHeader';
import Sidebar from 'components/Sidebar';
import { useGlobalState } from 'context/GlobalState';
import { Box } from 'elements';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { OrganizationStatus } from 'services/constants';
import MirrorViewAlert from './MirrorViewAlert';

// TODO: how to show global error?

const SIDEBAR_TABS: PortalOrgPathsKeys[] = [
  'dashboard',
  // todo: uncomment tabs when the page is implemented
  // 'cardAccounts',
  // 'cards',
  // 'transactions',
  // 'transactionsReview',
  // 'transactionsFlagged',
  // 'accounting',
  // 'billingPayments',
  // 'billingStatements',
  // 'creditAndCompliance',
  // 'members',
  // 'teams',
  // 'rewards',
  // 'merchants',
  // 'pliantEarth',
  // 'export',
  // 'exportQueue',
  // 'exported',
  // 'settlements',
  // 'processingAccount',
  // 'partner',
];

const PortalOrgLayout = ({ children }: { children: React.ReactNode }) => {
  const {
    state: { organization },
  } = useGlobalState();
  const { isOrgInOnboarding, isOrgInOnboardingNew } = useIsOrgInOnboarding();

  const sidebarPaths = useMemo(() => {
    if (organization) {
      const sidebarPaths: { [pathId: string]: string } = {};

      if (
        isOrgInOnboarding ||
        organization.status === OrganizationStatus.cancelled
      ) {
        sidebarPaths.onboarding = generatePath(
          isOrgInOnboardingNew
            ? portalOrgPaths.orgOnboarding
            : portalOrgPaths.onboarding,
          {
            orgId: organization.id,
          }
        );
      }
      SIDEBAR_TABS.forEach((key) => {
        sidebarPaths[key] = generatePath(portalOrgPaths[key], {
          orgId: organization.id,
        });
      });
      return sidebarPaths;
    } else {
      return {};
    }
  }, [organization, isOrgInOnboardingNew]);

  return (
    <Container style={{ flexDirection: 'column' }}>
      <MirrorViewAlert />
      <Box sx={{ display: 'flex', flex: 1, position: 'relative' }}>
        <Sidebar
          paths={sidebarPaths}
          showOnboarding
          PaperProps={{ sx: { top: '48px', height: 'calc(100% - 48px)' } }}
        />
        <ContainerInner>
          <PortalMainHeader />
          <MainContent>{children}</MainContent>
        </ContainerInner>
      </Box>
    </Container>
  );
};

export default PortalOrgLayout;
