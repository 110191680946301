import React, { MouseEvent, useMemo, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  adminPaths,
  cardholderPaths,
  commonPaths,
  portalPaths,
} from 'components/App';
import { StyledIconButton } from 'components/MainHeader/style';
import { useGlobalState } from 'context/GlobalState';
import {
  BuildingsIcon,
  CaretRightIcon,
  Divider,
  Grow,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Popper,
  SignOutIcon,
  UserCircleIcon,
  UserIcon,
  UsersIcon,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import { Membership, MembershipStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';

const UserMenu = () => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const { isAdminApp, isExternalApp, isPortalApp } = useCurrentApp();
  const {
    dispatch,
    state: { isComplianceRiskWhiteLabelApp, member, memberships, organization },
  } = useGlobalState();
  const { user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const subMenuItemRef = useRef() as React.MutableRefObject<HTMLLIElement>;
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const shouldAppendCountryCode = useMemo(() => {
    const diffCountries = new Set(memberships.map((ms) => ms.country));
    return diffCountries.size > 1;
  }, [memberships]);
  const profilePath = isPortalApp
    ? portalPaths.profile
    : isAdminApp
    ? adminPaths.profile
    : cardholderPaths.profile;
  const isUserOnProfilePage = location.pathname === profilePath;

  const openSubMenu = () => setSubMenuAnchorEl(subMenuItemRef.current);

  const closeSubMenu = () => setSubMenuAnchorEl(null);

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
  };

  const signOut = () => {
    logout({
      returnTo: window.location.origin,
    });
    closeMenu();
  };

  const switchOrganization = async (membership: Membership) => {
    try {
      const { organizationId } = membership;

      if (organizationId === organization!.id) return;

      if (membership.status === MembershipStatus.invited) {
        history.push(
          generatePath(commonPaths.membershipActivation, {
            orgId: organizationId,
          })
        );
        return;
      }

      dispatch({ type: 'SET_IS_LOADING', payload: true });
      await api.switchOrganization(organizationId);
      // Reload the app to get an updated token, member, organization, etc.
      window.location.replace('/');
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error });
      logError(error);
    }
  };

  const renderSelectOrgMenuItem = () => {
    return memberships.length > 1 ? (
      <div onMouseEnter={openSubMenu} onMouseLeave={closeSubMenu}>
        <MenuItem
          ref={subMenuItemRef}
          selected={!!subMenuAnchorEl}
          data-intercom-target="select-organization-menu-item"
        >
          <ListItemIcon>
            <BuildingsIcon />
          </ListItemIcon>
          <ListItemText primary={t('userMenu.switchOrganization')} />
          <CaretRightIcon
            fontSize="small"
            sx={{ color: 'text.secondary', ml: 3 }}
          />
        </MenuItem>
        <Popper
          anchorEl={subMenuAnchorEl}
          open={!!subMenuAnchorEl}
          placement="left-start"
          disablePortal
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper
                elevation={8}
                sx={{ overflow: 'hidden auto', maxWidth: 300, maxHeight: 400 }}
              >
                <MenuList dense>
                  {memberships.map((item) => (
                    <MenuItem
                      key={item.id}
                      onClick={() => switchOrganization(item)}
                      selected={item.organizationId === organization!.id}
                    >
                      <ListItemText
                        primary={
                          item.organizationName +
                          (shouldAppendCountryCode ? ` (${item.country})` : '')
                        }
                        primaryTypographyProps={{ noWrap: true }}
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    ) : null;
  };

  const renderProfileMenuItem = () => {
    return canUser('profile-page:visit') || canUser('ch-profile-page:visit') ? (
      <MenuItem
        onClick={() => {
          history.push(profilePath);
          closeMenu();
        }}
        selected={isUserOnProfilePage}
        data-intercom-target="select-profile-details-menu-item"
      >
        <ListItemIcon>
          <UserIcon fontSize="small" />
        </ListItemIcon>
        {t('userMenu.profileDetails')}
      </MenuItem>
    ) : null;
  };

  return (
    <>
      <StyledIconButton
        onClick={openMenu}
        className={isUserOnProfilePage || !!anchorEl ? 'selected' : ''}
        data-intercom-target="user-menu-button"
      >
        <UserCircleIcon />
      </StyledIconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        PaperProps={{ sx: { overflow: 'visible', maxWidth: 300 } }}
      >
        <ListItem dense>
          <ListItemText
            {...(isExternalApp || isPortalApp
              ? {
                  primary: `${member.firstName} ${member.lastName}`,
                  secondary:
                    !isPortalApp &&
                    organization!.name +
                      (shouldAppendCountryCode
                        ? ` (${organization!.billingAddress.country})`
                        : ''),
                }
              : { primary: user!.email })}
            primaryTypographyProps={{ noWrap: true }}
            secondaryTypographyProps={{ noWrap: true }}
          />
        </ListItem>

        <Divider />

        <MenuList dense>
          {renderSelectOrgMenuItem()}

          {renderProfileMenuItem()}

          {canUser(
            'white-label-members-page:visit',
            isComplianceRiskWhiteLabelApp
          ) && (
            <MenuItem
              onClick={() => {
                history.push(adminPaths.members);
                closeMenu();
              }}
            >
              <ListItemIcon>
                <UsersIcon />
              </ListItemIcon>
              {t('userMenu.members')}
            </MenuItem>
          )}

          <MenuItem onClick={signOut}>
            <ListItemIcon>
              <SignOutIcon />
            </ListItemIcon>
            {t('userMenu.signOut')}
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};

export default UserMenu;
