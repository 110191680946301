import { TFunction } from 'i18next';
import { Address, SupportedCountry } from 'services/constants';
import { validateZipCodeByCountry } from './zipCodeByCountry';

export const DEFAULT_ADDRESS_FIELD_LENGTHS = {
  city: 64,
  country: 2,
  postalCode: 16,
  streetAdditional: 128,
  streetName: 128,
  streetNumber: 128,
};

export const CARD_DELIEVERY_FIELD_LENGTHS = {
  city: 64,
  country: 2,
  postalCode: 16,
  streetAdditional: 50,
  streetName: 50,
  streetNumber: 50,
};

/** Address validation  */
function validateAddress(
  address: Address,
  t: TFunction,
  isCardDeliveryAddress?: boolean
): null | Partial<{ [key in keyof typeof address]: string }>;

function validateAddress(
  address: Partial<Address>,
  t: TFunction,
  isCardDeliveryAddress: boolean,
  isPartial: true
): null | Partial<{ [key in keyof typeof address]: string }>;

/**
 * Should be either full Address or Partial<Address>, based on isPartial flag.
 * @param address
 * Use isPartial and pass only the fields from Address object that should be validated.
 * It's type is explicetly set to true, when address = Partial<Address>
 * @param isPartial
 */
function validateAddress(
  address: Partial<Address> | Address,
  t: TFunction,
  isCardDeliveryAddress: boolean = false,
  isPartial: boolean = false
) {
  const fieldMaxLengths = isCardDeliveryAddress
    ? CARD_DELIEVERY_FIELD_LENGTHS
    : DEFAULT_ADDRESS_FIELD_LENGTHS;

  const errors: Partial<{ [key in keyof typeof address]: string }> = {};

  const isFieldRequired = (key: keyof typeof address) =>
    !isPartial || key in address;

  if (isFieldRequired('city')) {
    if (!address.city) {
      errors.city = t('errors.fieldRequired');
    } else {
      if (address.city.length > fieldMaxLengths.city) {
        errors.city = t('errors.maxLength', {
          maxLength: fieldMaxLengths.city,
        });
      }
    }
  }

  if (isFieldRequired('country')) {
    if (!address.country) {
      errors.country = t('errors.fieldRequired');
    } else {
      if (address.country.length > fieldMaxLengths.country) {
        errors.country = t('errors.maxLength', {
          maxLength: fieldMaxLengths.country,
        });
      }
    }
  }

  if (isFieldRequired('postalCode')) {
    if (!address.postalCode) {
      errors.postalCode = t('errors.fieldRequired');
    } else {
      if (address.postalCode.length > fieldMaxLengths.postalCode) {
        errors.postalCode = t('errors.maxLength', {
          maxLength: fieldMaxLengths.postalCode,
        });
      } else {
        const postalCodeError = isFieldRequired('country')
          ? validateZipCodeByCountry(
              address.country as SupportedCountry,
              address.postalCode,
              t
            )
          : false;
        if (postalCodeError) {
          errors.postalCode = postalCodeError;
        }
      }
    }
  }

  if (isFieldRequired('streetAdditional')) {
    if (
      address.streetAdditional &&
      address.streetAdditional.length > fieldMaxLengths.streetAdditional
    ) {
      errors.streetAdditional = t('errors.maxLength', {
        maxLength: fieldMaxLengths.streetAdditional,
      });
    }
  }

  if (isFieldRequired('streetName')) {
    if (!address.streetName) {
      errors.streetName = t('errors.fieldRequired');
    } else {
      if (address.streetName.length > fieldMaxLengths.streetName) {
        errors.streetName = t('errors.maxLength', {
          maxLength: fieldMaxLengths.streetName,
        });
      }
    }
  }

  if (isFieldRequired('streetNumber')) {
    if (!address.streetNumber) {
      errors.streetNumber = t('errors.fieldRequired');
    } else {
      if (address.streetNumber.length > fieldMaxLengths.streetNumber) {
        errors.streetNumber = t('errors.maxLength', {
          maxLength: fieldMaxLengths.streetNumber,
        });
      }
    }
  }

  if (Object.keys(errors).length === 0) {
    return null;
  }

  return errors;
}

export { validateAddress };
