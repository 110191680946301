import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSortedPartnersForPartnerSelect } from 'domains/partner/utils';
import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  InputLabelTooltipIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import { Partner } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';

export type PartnerSelectType =
  | Partner
  | Pick<Partner, 'partnerId' | 'status' | 'name'>;

interface Props
  extends Pick<
    AutocompleteProps<Partner, false, true, false>,
    'onBlur' | 'disabled'
  > {
  name: string;
  value: PartnerSelectType;
  label?: string;
  tooltipLabel?: string;
  isPartnerConfig?: boolean;
  onChange: (value: PartnerSelectType) => void;
}

interface State {
  partners: Partner[];
  isLoading: boolean;
  error: boolean;
}

const PartnerSelect = ({
  label,
  value,
  tooltipLabel,
  isPartnerConfig = false,
  onChange,
  disabled,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const mounted = useMounted();
  const api = useImperativeApi();
  const [state, setState] = useState<State>({
    partners: [],
    isLoading: false,
    error: false,
  });

  const onOpen = async () => {
    if (state.partners.length || state.isLoading) return;

    setState((prevState) => ({ ...prevState, isLoading: true, error: false }));
    try {
      const { partners } = await api.getPartners();
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        partners,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      logError(error);
      setState((prevState) => ({
        ...prevState,
        error: true,
        isLoading: false,
      }));
    }
  };

  const sortedPartners = useMemo(
    () => getSortedPartnersForPartnerSelect(state.partners, isPartnerConfig),
    [state.partners]
  );

  return (
    <FormControl fullWidth disabled={disabled || !canUser('partners:view')}>
      <Autocomplete<PartnerSelectType, false, true, false>
        disableClearable
        onOpen={onOpen}
        value={value}
        onChange={(_, option) => {
          if (!option) return;
          onChange(option);
        }}
        options={sortedPartners}
        isOptionEqualToValue={(option, value) =>
          option.partnerId === value.partnerId
        }
        getOptionLabel={(option) => option.name.toUpperCase()}
        label={
          <>
            {label || t('partnerSelect.label')}
            {tooltipLabel && <InputLabelTooltipIcon title={tooltipLabel} />}
          </>
        }
        noOptionsText={t('common.nothingFound')}
        loadingText={t('common.loading')}
        {...props}
        loading={state.isLoading}
        error={!!state.error}
      />
    </FormControl>
  );
};

export default PartnerSelect;
