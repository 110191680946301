import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { TaskTitle } from 'domains/onboarding/components';
import { useGetGroupFromGroupId } from 'domains/onboarding/hooks';
import {
  getNavigationPath,
  getOnboardingKeyTypeFromUrl,
} from 'domains/onboarding/utils';
import { Breadcrumbs, Link, Typography } from 'elements';
import { OnboardingSectionType, OnboardingTask } from 'services/constants';
import { getPath } from 'services/utils';

interface ElementProps {
  translation: ReactNode;
  link?: string;
  isLastElement?: boolean;
}

const BreadcrumbElement = ({
  translation,
  link,
  isLastElement,
}: ElementProps) => {
  if (!isLastElement && link)
    return (
      <Link
        to={link}
        component={RouterLink}
        sx={{ textDecoration: 'none' }}
        color="textSecondary"
      >
        {translation}
      </Link>
    );

  return (
    <Typography variant="inherit" component="span" color="textPrimary">
      {translation}
    </Typography>
  );
};

const OnboardingBreadcrumbs = ({ task }: { task?: OnboardingTask | null }) => {
  const { t } = useTranslation();
  const { sectionKey, taskId, groupId } = useParams<{
    sectionKey: string;
    taskId?: string;
    groupId?: string;
  }>();
  const {
    state: { organization },
  } = useGlobalState();
  const currentGroup = useGetGroupFromGroupId();

  const currentSectionType = useMemo(
    () => getOnboardingKeyTypeFromUrl<OnboardingSectionType>(sectionKey),
    [sectionKey]
  );

  return (
    <Breadcrumbs>
      <Link
        to={generatePath(getPath('orgOnboarding'), {
          orgId: organization!.id,
        })}
        component={RouterLink}
        sx={{ textDecoration: 'none' }}
        color="textSecondary"
      >
        {t('orgOnboardingSectionPage.titleOverview')}
      </Link>

      <BreadcrumbElement
        translation={t(`orgOnboardingSectionTitle.${currentSectionType}`)}
        link={getNavigationPath(organization!.id, sectionKey)}
        isLastElement={!taskId && !groupId}
      />

      {currentGroup && (
        <BreadcrumbElement
          translation={currentGroup.name}
          link={getNavigationPath(organization!.id, sectionKey, { groupId })}
          isLastElement={!taskId}
        />
      )}

      {task && (
        <BreadcrumbElement
          translation={<TaskTitle task={task} isBreadcrumb />}
        />
      )}
    </Breadcrumbs>
  );
};

export default OnboardingBreadcrumbs;
