import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  PercentField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  fxFeePercentage: number;
}

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (fxFeePercentage: number) => void;
  fxFeePercentage: number | null;
}

const UpdateTransferFxFeeDialog = ({
  onSuccess,
  fxFeePercentage,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      fxFeePercentage: fxFeePercentage || 0,
    },
    onSubmit: async ({ fxFeePercentage }) => {
      try {
        const {
          fxTransferFeePercentage,
        } = await api.updateFxTransferFeePercentage(
          organization!.id,
          fxFeePercentage
        );
        if (!mounted.current) return;
        onSuccess(fxTransferFeePercentage);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('int.updateTransferFxFeeDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="update-fx-fee-percentage-form"
          noValidate
        >
          <PercentField
            label={t('int.updateTransferFxFeeDialog.label')}
            autoFocus
            disabled={formik.isSubmitting}
            {...omit(formik.getFieldProps('fxFeePercentage'), 'onChange')}
            onValueChange={({ floatValue }) =>
              formik.setFieldValue('fxFeePercentage', floatValue)
            }
            decimalScale={1}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.close')}
        </Button>
        <Button
          disabled={formik.isSubmitting || !formik.values.fxFeePercentage}
          form="update-fx-fee-percentage-form"
          type="submit"
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(UpdateTransferFxFeeDialog);
