import AppSwitchButton from './AppSwitchButton';
import NotificationsMenu from './NotificationsMenu';
import ReceiptInboxButton from './ReceiptInboxButton';
import SupportMenu from './SupportMenu';
import UserMenu from './UserMenu';
import { MenuSection, StyledMainHeader } from './style';
import withLightNavigationModeTheme from './withLightNavigationModeTheme';

const AdminMainHeader = () => {
  return (
    <StyledMainHeader>
      <MenuSection>
        <ReceiptInboxButton />
        <SupportMenu />
        <NotificationsMenu />

        <UserMenu />
        <AppSwitchButton />
      </MenuSection>
    </StyledMainHeader>
  );
};

export default withLightNavigationModeTheme(AdminMainHeader);
