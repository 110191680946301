import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { internalRootPaths } from 'components/App';
import ConfirmDialog from 'components/ConfirmDialogV2';
import EditGroupDialog from 'domains/organization/dialogs/EditGroupDialog';
import {
  FadersHorizontalIcon,
  IconButton,
  ListItemIcon,
  MenuContainer,
  MenuItem,
  PencilSimpleIcon,
  XCircleIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OrganizationGroup } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  orgGroup: OrganizationGroup;
  refetchOrgGroup: () => void;
  refetchOrgGroups: () => void;
}

interface State {
  isEditDialogOpen: boolean;
  isLoading: boolean;
  isDeleteDialogOpen: boolean;
}

const GroupDetailsMenu = ({ orgGroup, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [state, setState] = useState<State>({
    isEditDialogOpen: false,
    isDeleteDialogOpen: false,
    isLoading: false,
  });

  const handleDelete = async () => {
    try {
      await api.deleteOrganizationGroup(orgGroup.id);
      if (!mounted.current) return;
      history.push(internalRootPaths.organizationGroups);
      props.refetchOrgGroups();
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <MenuContainer
        button={
          <IconButton>
            <FadersHorizontalIcon />
          </IconButton>
        }
      >
        <MenuItem
          onClick={() =>
            setState((prevState) => ({
              ...prevState,
              isEditDialogOpen: true,
            }))
          }
        >
          <ListItemIcon>
            <PencilSimpleIcon />
          </ListItemIcon>
          {t('int.organizationGroupDetailsMenu.editOrgGroup')}
        </MenuItem>

        <MenuItem
          onClick={() =>
            setState((prevState) => ({
              ...prevState,
              isDeleteDialogOpen: true,
            }))
          }
        >
          <ListItemIcon>
            <XCircleIcon />
          </ListItemIcon>
          {t('int.organizationGroupDetailsMenu.deleteOrgGroup')}
        </MenuItem>
      </MenuContainer>

      <EditGroupDialog
        orgGroup={orgGroup}
        open={state.isEditDialogOpen}
        onClose={() =>
          setState((prevState) => ({ ...prevState, isEditDialogOpen: false }))
        }
        onSuccess={() => {
          props.refetchOrgGroup();
          props.refetchOrgGroups();
          setState((prevState) => ({ ...prevState, isEditDialogOpen: false }));
        }}
      />

      <ConfirmDialog
        open={state.isDeleteDialogOpen}
        onClose={() =>
          setState((prevState) => ({ ...prevState, isDeleteDialogOpen: false }))
        }
        onSuccess={handleDelete}
        title={t('int.organizationGroupDetailsMenu.deleteConfirmDialog.title')}
        loading={state.isLoading}
        description={
          <Trans
            i18nKey="int.organizationGroupDetailsMenu.deleteConfirmDialog.description"
            values={{
              orgGroupName: orgGroup.name,
            }}
            components={{
              b: <b />,
            }}
          />
        }
      />
    </>
  );
};

export default GroupDetailsMenu;
