import React, { useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import flowRight from 'lodash/flowRight';
import withLoader from 'components/App/withLoader';
import { useGlobalState } from 'context/GlobalState';
import useIdTokenCustomData from 'hooks/useIdTokenCustomData';
import { MemberStatus } from 'services/constants';
import { withFeatureFlagsProvider } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import withInitialLogic from './withInitialLogic';
import withTheme from './withTheme';

const PortalUserData = ({ children }: { children: React.ReactNode }) => {
  const { memberId } = useIdTokenCustomData();
  const api = useImperativeApi();
  const {
    dispatch,
    state: { isLoading, error },
  } = useGlobalState();

  const initialize = async () => {
    try {
      const member = await api.getPartnerMember(memberId);

      if (member.status === MemberStatus.invited) {
        await api.activatePartnerMember(member.partnerCompanyId, memberId);
        window.location.reload();
        return;
      }

      dispatch({
        type: 'SET_USER_DATA',
        payload: { member },
      });
      dispatch({ type: 'SET_IS_LOADING', payload: false });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error });
      logError(error);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  if (isLoading) return null;
  if (error) return null;

  return children;
};

export default flowRight([
  withLoader,
  withAuthenticationRequired,
  withInitialLogic,
  withFeatureFlagsProvider,
  // TODO: check if we need it
  // withJwtPayload,
  withTheme,
])(PortalUserData);
