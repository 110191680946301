import { ComponentType, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { ConfirmPaymentWith3dsBiometricAuthDialog } from 'domains/card/dialogs';
import useSnackbar from 'hooks/useSnackbar';
import { ThreeDsAuthRequest } from 'services/constants';
import {
  BiometricAuthRequestEventData,
  BiometricAuthResponseEventData,
  useMemberSse,
} from 'services/memberSse';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const with3dsBiometricAuthentication = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element | null => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const { memberSse } = useMemberSse();
  const {
    state: { jwtPayload },
  } = useGlobalState();
  const [
    authRequest,
    setAuthRequest,
  ] = useState<BiometricAuthRequestEventData | null>(null);
  const timeoutIdByGpsInitiateActionIdMap = useRef<Record<string, number>>({});

  const pass3dsAuthentication = async (authRequest: ThreeDsAuthRequest) => {
    try {
      await api.pass3dsAuthentication(authRequest);
      enqueueSnackbar(
        t(`3dsBiometricAuthSuccessMessages.${authRequest.status}`),
        { variant: authRequest.status === 'APPROVED' ? 'default' : 'error' }
      );
    } catch (error) {
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  // "BIOMETRIC_AUTH_REQUEST" is sent when a user is trying to make a payment.
  // "BIOMETRIC_AUTH_RESPONSE" is sent when the payment is approved by the user.
  // Both events are "sticky", meaning that they will always be sent every time
  // when a new SSE connection opens until their TTLs expire (5 minutes).
  // This is done to support approvals when users are not logged in or
  // re-initiate the approval flow in case if a user closes a tab accidentally.
  // For new connections, the sequence of events for approved payments is guaranteed:
  // 1. "BIOMETRIC_AUTH_REQUEST" => 2. "BIOMETRIC_AUTH_RESPONSE"
  // That's why the first one delays the execution, and the second one cancels it.
  useEffect(() => {
    const onBiometricAuthRequest = (
      e: CustomEvent<BiometricAuthRequestEventData>
    ) => {
      timeoutIdByGpsInitiateActionIdMap.current[
        e.detail.gpsInitiateActionId
      ] = window.setTimeout(() => {
        if (
          jwtPayload['3dsAuthRequest']?.gpsInitiateActionId ===
          e.detail.gpsInitiateActionId
        ) {
          // The security key authentication is passed by a user,
          // notify BE to pass the 3ds authentication
          pass3dsAuthentication(jwtPayload['3dsAuthRequest']);
        } else {
          // Show the confirm payment dialog, which will require to pass
          // the security key authentication in order to confirm / decline a payment.
          // Show it only if there are no pending 3ds auth requests.
          setAuthRequest((prevAuthRequest) => prevAuthRequest ?? e.detail);
        }
      }, 500);
    };

    const onBiometricAuthResponse = (
      e: CustomEvent<BiometricAuthResponseEventData>
    ) => {
      const timeoutId =
        timeoutIdByGpsInitiateActionIdMap.current[e.detail.gpsInitiateActionId];

      if (timeoutId) clearTimeout(timeoutId);

      // Close the confirm payment dialog in all inactive tabs
      setAuthRequest((prevAuthRequest) =>
        prevAuthRequest?.gpsInitiateActionId === e.detail.gpsInitiateActionId
          ? null
          : prevAuthRequest
      );
    };

    memberSse.addEventListener(
      'BIOMETRIC_AUTH_REQUEST',
      onBiometricAuthRequest
    );
    memberSse.addEventListener(
      'BIOMETRIC_AUTH_RESPONSE',
      onBiometricAuthResponse
    );

    return () => {
      memberSse.removeEventListener(
        'BIOMETRIC_AUTH_REQUEST',
        onBiometricAuthRequest
      );
      memberSse.removeEventListener(
        'BIOMETRIC_AUTH_RESPONSE',
        onBiometricAuthResponse
      );
    };
  }, []);

  return (
    <>
      <Component {...props} />

      <ConfirmPaymentWith3dsBiometricAuthDialog
        open={!!authRequest}
        authRequest={authRequest}
        onClose={() => setAuthRequest(null)}
      />
    </>
  );
};

export default with3dsBiometricAuthentication;
