import '@mui/lab/themeAugmentation';
import { ComponentType, CSSProperties, SVGProps } from 'react';
import {
  alpha,
  createTheme as createMuiTheme,
  PaletteMode,
  Shadows,
} from '@mui/material';
import { ReactComponent as BbvaLogoDarkMode } from 'assets/svg/integrations/bbvaLogoDarkMode.svg';
import { ReactComponent as BbvaLogoLightMode } from 'assets/svg/integrations/bbvaLogoLightMode.svg';
import { ReactComponent as BezalaLogo } from 'assets/svg/integrations/bezalaLogoBlack.svg';
import { ReactComponent as BizawayLogoDarkMode } from 'assets/svg/integrations/bizawayLogoDarkMode.svg';
import { ReactComponent as BizawayLogoLightMode } from 'assets/svg/integrations/bizawayLogoLightMode.svg';
import { ReactComponent as BonagoLogo } from 'assets/svg/integrations/bonagoLogo.svg';
import { ReactComponent as CandisLogo } from 'assets/svg/integrations/candisLogo.svg';
import { ReactComponent as CirculaSapphireLogo } from 'assets/svg/integrations/circulaSapphireLogo.svg';
import { ReactComponent as Co2MoveLogoDarkMode } from 'assets/svg/integrations/co2moveLogoDarkMode.svg';
import { ReactComponent as Co2MoveLogoLightMode } from 'assets/svg/integrations/co2moveLogoLightMode.svg';
import { ReactComponent as CommerzbankLogoDarkMode } from 'assets/svg/integrations/commerzbankLogoDarkMode.svg';
import { ReactComponent as CommerzbankLogoLightMode } from 'assets/svg/integrations/commerzbankLogoLightMode.svg';
import { ReactComponent as IntertoursLogoDarkMode } from 'assets/svg/integrations/intertoursLogoDarkMode.svg';
import { ReactComponent as IntertoursLogoLightMode } from 'assets/svg/integrations/intertoursLogoLightMode.svg';
import { ReactComponent as MobilexpenseLogo } from 'assets/svg/integrations/mobilexpenseLogoWL.svg';
import { ReactComponent as NellyLogo } from 'assets/svg/integrations/nellyLogo.svg';
import { ReactComponent as PliantLogo } from 'assets/svg/pliantLogo.svg';
import { PartnerIds } from 'services/constants';
import MuiAccordionOverrides from './MuiAccordionOverrides';
import MuiAlertOverrides from './MuiAlertOverrides';
import MuiAutocompleteOverrides from './MuiAutocompleteOverrides';
import MuiButtonOverrides from './MuiButtonOverrides';
import MuiChipOverrides from './MuiChipOverrides';
import MuiCssBaselineOverrides from './MuiCssBaselineOverrides';
import MuiDataGridOverrides from './MuiDataGridOverrides';
import MuiDateRangePickerOverrides from './MuiDateRangePickerOverrides';
import MuiDialogOverrides from './MuiDialogOverrides';
import MuiFormControlLabelOverrides from './MuiFormControlLabelOverrides';
import MuiFormHelperTextOverrides from './MuiFormHelperTextOverrides';
import MuiLinkOverrides from './MuiLinkOverrides';
import MuiMenuOverrides from './MuiMenuOverrides';
import MuiPaperOverrides from './MuiPaperOverrides';
import MuiPopoverOverrides from './MuiPopoverOverrides';
import MuiSelectOverrides from './MuiSelectOverrides';
import MuiStepperOverrides from './MuiStepperOverrides';
import MuiTableOverrides from './MuiTableOverrides';
import MuiTabsOverrides from './MuiTabsOverrides';
import MuiTextFieldOverrides from './MuiTextFieldOverrides';
import MuiTooltipOverrides from './MuiTooltipOverrides';
import MuiTypographyOverrides from './MuiTypographyOverrides';
import variablesJson from './variables.json';

type Variables = typeof variablesJson;

type Logo = {
  Component: ComponentType<SVGProps<SVGSVGElement>>;
  style: CSSProperties;
};

declare module '@mui/material/styles' {
  interface Theme {
    variables: Variables;
    navigationMode: PaletteMode;
    logoLightMode: Logo;
    logoDarkMode: Logo;
  }
  interface ThemeOptions {
    variables: Variables;
    navigationMode: PaletteMode;
    logoLightMode: Logo;
    logoDarkMode: Logo;
  }
}

const createTheme = ({
  variables,
  ...options
}: {
  variables: Variables;
  navigationMode: PaletteMode;
  logoLightMode: Logo;
  logoDarkMode: Logo;
}) =>
  createMuiTheme({
    variables,
    ...options,
    palette: {
      error: variables.error,
      info: variables.info,
      primary: variables.primary,
      secondary: variables.secondary,
      success: variables.success,
      text: variables.text,
      warning: variables.warning,
    },
    typography: {
      fontFamily: variables.typography.body1.fontFamily,
      ...variables.typography,
    },
    shadows: variables.shadows as Shadows,
    shape: variables.shape,
    components: {
      ...MuiAccordionOverrides,
      ...MuiAlertOverrides,
      ...MuiAutocompleteOverrides,
      ...MuiButtonOverrides,
      ...MuiCssBaselineOverrides,
      ...MuiDialogOverrides,
      ...MuiFormControlLabelOverrides,
      ...MuiFormHelperTextOverrides,
      ...MuiLinkOverrides,
      ...MuiMenuOverrides,
      ...MuiPaperOverrides,
      ...MuiPopoverOverrides,
      ...MuiSelectOverrides,
      ...MuiStepperOverrides,
      ...MuiTableOverrides,
      ...MuiTabsOverrides,
      ...MuiTextFieldOverrides,
      ...MuiTooltipOverrides,
      ...MuiDataGridOverrides,
      ...MuiChipOverrides,
      ...MuiTypographyOverrides,
      ...MuiDateRangePickerOverrides,
    },
  });

export const createPartnerTheme = (themeId: string) => {
  let variables: Variables = variablesJson;
  let navigationMode: PaletteMode = 'light';
  let logoLightMode;
  let logoDarkMode;

  switch (themeId) {
    case PartnerIds.nelly: {
      logoDarkMode = {
        Component: NellyLogo,
        style: { width: 140 },
      };
      logoLightMode = logoDarkMode;
      break;
    }

    case PartnerIds.bezalaWallet: {
      logoDarkMode = {
        Component: BezalaLogo,
        style: { height: 30 },
      };
      logoLightMode = logoDarkMode;
      break;
    }

    case PartnerIds.mobilexpenseDeclaree: {
      variables = {
        ...variablesJson,
        navigation: {
          ...variablesJson.navigation,
          light: {
            ...variablesJson.navigation.light,
            background: '#eef2f5',
          },
        },
      };
      logoLightMode = {
        Component: MobilexpenseLogo,
        style: { height: 39 },
      };
      logoDarkMode = logoLightMode;
      break;
    }

    case PartnerIds.circula:
    case PartnerIds.circulaDemo:
    case PartnerIds.circulaEmbedded:
    case PartnerIds.circulaFullyEmbedded: {
      variables = {
        ...variablesJson,
        navigation: {
          ...variablesJson.navigation,
          light: {
            ...variablesJson.navigation.light,
            background: '#eef3f7',
          },
        },
      };
      logoLightMode = {
        Component: CirculaSapphireLogo,
        style: { height: 25 },
      };
      logoDarkMode = logoLightMode;
      break;
    }

    case PartnerIds.candis: {
      variables = {
        ...variablesJson,
        navigation: {
          ...variablesJson.navigation,
          light: {
            ...variablesJson.navigation.light,
            background: '#f3efee',
          },
        },
      };
      logoLightMode = {
        Component: CandisLogo,
        style: { height: 25, width: 'auto' },
      };
      logoDarkMode = logoLightMode;
      break;
    }

    case PartnerIds.intertours: {
      variables = {
        ...variablesJson,
        navigation: {
          ...variablesJson.navigation,
          dark: {
            background: '#0a1f2f',
            selected: '#00C9D9',
            hover: 'rgba(247, 246, 242, 0.12)',
            text: {
              primary: '#ffffff',
              selectedPrimary: '#ffffff',
              secondary: 'rgba(247, 246, 242, 0.7)',
              disabled: 'rgba(255, 255, 255, 0.36)',
            },
          },
        },
      };
      navigationMode = 'dark';
      logoLightMode = {
        Component: IntertoursLogoLightMode,
        style: { width: 73 },
      };
      logoDarkMode = {
        Component: IntertoursLogoDarkMode,
        style: { width: 73 },
      };
      break;
    }

    case PartnerIds.bbva: {
      navigationMode = 'light';
      logoLightMode = {
        Component: BbvaLogoLightMode,
        style: { width: 'auto' },
      };
      logoDarkMode = {
        Component: BbvaLogoDarkMode,
        style: { width: 'auto' },
      };
      break;
    }

    case PartnerIds.bizaway: {
      variables = {
        ...variablesJson,
        navigation: {
          ...variablesJson.navigation,
          dark: {
            background: '#00BCD6',
            selected: '#2B275C',
            hover: 'rgba(247, 246, 242, 0.12)',
            text: {
              primary: '#ffffff',
              selectedPrimary: '#ffffff',
              secondary: 'rgba(247, 246, 242, 0.7)',
              disabled: 'rgba(255, 255, 255, 0.36)',
            },
          },
        },
        primary: {
          ...variablesJson.primary,
          main: '#2B275C',
          dark: '#2B275C',
        },
        button: {
          filled: {
            default: '#2B275C',
            hover: '#2B275C',
            text: '#ffffff',
          },
          shape: {
            borderRadius: 8,
          },
          textOutlined: {
            hover: 'rgba(43, 39, 92, 0.04)',
            text: '#2B275C',
            border: '#2B275C',
          },
        },
      };
      navigationMode = 'dark';
      logoLightMode = {
        Component: BizawayLogoLightMode,
        style: { height: 40 },
      };
      logoDarkMode = {
        Component: BizawayLogoDarkMode,
        style: { height: 40 },
      };
      break;
    }

    case PartnerIds.commerzbank: {
      variables = {
        ...variablesJson,
        navigation: {
          ...variablesJson.navigation,
          dark: {
            background: '#002E3C',
            selected: 'rgba(247, 246, 242, 0.12)',
            hover: 'rgba(247, 246, 242, 0.12)',
            text: {
              primary: '#ffffff',
              selectedPrimary: '#ffffff',
              secondary: 'rgba(247, 246, 242, 0.7)',
              disabled: 'rgba(255, 255, 255, 0.36)',
            },
          },
        },
        primary: {
          ...variablesJson.primary,
          main: '#002E3C',
          dark: '#002E3C',
        },
        button: {
          filled: {
            default: '#FFD700',
            hover: '#FFC700',
            text: '#002E3C',
          },
          shape: {
            borderRadius: 30,
          },
          textOutlined: {
            hover: alpha('#002E3C', 0.04),
            text: '#002E3C',
            border: '#002E3C',
          },
        },
      };
      navigationMode = 'dark';
      logoDarkMode = {
        Component: CommerzbankLogoDarkMode,
        style: { height: 40 },
      };
      logoLightMode = {
        Component: CommerzbankLogoLightMode,
        style: { height: 40 },
      };
      break;
    }

    case PartnerIds.co2move: {
      navigationMode = 'light';
      logoLightMode = {
        Component: Co2MoveLogoLightMode,
        style: { height: 24, maxWidth: '100%' },
      };
      logoDarkMode = {
        Component: Co2MoveLogoDarkMode,
        style: { height: 24, maxWidth: '100%' },
      };
      break;
    }

    case PartnerIds.bonago: {
      variables = {
        ...variablesJson,
        navigation: {
          ...variablesJson.navigation,
          light: {
            background: '#E6E6E6',
            selected: '#D5D5D5',
            hover: '#D5D5D5',
            text: {
              primary: '#939393',
              selectedPrimary: '#5B5B5B',
              secondary: '#5B5B5B',
              disabled: '#939393',
            },
          },
        },
        primary: {
          ...variablesJson.primary,
          main: '#E67B00',
        },
        button: {
          ...variablesJson.button,
          filled: {
            default: '#E67B00',
            hover: '#E67B00',
            text: '#FFFFFF',
          },
        },
      };
      navigationMode = 'light';
      logoLightMode = {
        Component: BonagoLogo,
        style: { height: 60 },
      };
      logoDarkMode = logoLightMode;
      break;
    }

    default: {
      navigationMode = themeId === 'PLIANT_INTERNAL_APP' ? 'dark' : 'light';
      logoLightMode = {
        Component: PliantLogo,
        style: {
          width: 64,
          color: variablesJson.navigation.light.text.primary,
        },
      };
      logoDarkMode = {
        Component: PliantLogo,
        style: { width: 64, color: variablesJson.navigation.dark.text.primary },
      };
    }
  }

  return createTheme({
    variables,
    navigationMode,
    logoLightMode,
    logoDarkMode,
  });
};

export default createPartnerTheme('PLIANT_INTERNAL_APP');
