import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { internalRootPaths } from 'components/App';
import { Button, PlusIcon } from 'elements';
import { PageHeader, PageTitle, PageTitleActions, Tab, Tabs } from 'layout';
import { useCanUser } from 'services/rbac';
import GroupsSubPage from './GroupsSubPage';
import OrganizationsAndNcoSubPage from './OrganizationsAndNcoSubPage';

enum OrganizationsSubPageType {
  customers = 'customers',
  groups = 'groups',
  nonCustomers = 'nonCustomers',
}

const OrganizationsPage = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const location = useLocation();
  const { path } = useRouteMatch();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const currentOrganizationsSubPage = (() => {
    switch (path) {
      case internalRootPaths.organizationGroups:
        return OrganizationsSubPageType.groups;
      case internalRootPaths.nonCustomerOrganizations:
        return OrganizationsSubPageType.nonCustomers;
      default:
        return OrganizationsSubPageType.customers;
    }
  })();

  const getActiveTabPathname = () => {
    if (location.pathname.startsWith(internalRootPaths.organizationGroups)) {
      return internalRootPaths.organizationGroups;
    }

    return location.pathname;
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('orgsPage.orgs')}>
          <PageTitleActions>
            {currentOrganizationsSubPage ===
              OrganizationsSubPageType.customers &&
              canUser('org:create') && (
                <Button
                  onClick={() => setIsCreateDialogOpen(true)}
                  startIcon={<PlusIcon />}
                  data-test-id="add-org-btn"
                >
                  {t('int.orgsPage.addOrg')}
                </Button>
              )}

            {currentOrganizationsSubPage ===
              OrganizationsSubPageType.nonCustomers &&
              canUser('non-customer-org:create') && (
                <Button
                  onClick={() => setIsCreateDialogOpen(true)}
                  startIcon={<PlusIcon />}
                  data-test-id="add-nco-btn"
                >
                  {t('int.orgsPage.addNco')}
                </Button>
              )}

            {currentOrganizationsSubPage === OrganizationsSubPageType.groups &&
              canUser('organization-group:create') && (
                <Button
                  onClick={() => setIsCreateDialogOpen(true)}
                  startIcon={<PlusIcon />}
                  data-test-id="add-group-btn"
                >
                  {t('int.orgsPage.addGroup')}
                </Button>
              )}
          </PageTitleActions>
        </PageTitle>

        <Tabs
          aria-label="Organization group page tabs"
          value={getActiveTabPathname()}
        >
          <Tab
            component={Link}
            value={internalRootPaths.organizations}
            to={internalRootPaths.organizations}
            label={t('int.orgsPage.customers')}
          />
          <Tab
            component={Link}
            value={internalRootPaths.nonCustomerOrganizations}
            to={internalRootPaths.nonCustomerOrganizations}
            label={t('int.orgsPage.nonCustomers')}
          />
          <Tab
            component={Link}
            value={internalRootPaths.organizationGroups}
            to={internalRootPaths.organizationGroups}
            label={t('int.orgsPage.groups')}
          />
        </Tabs>
      </PageHeader>

      <Switch>
        <Route path={internalRootPaths.organizations}>
          <OrganizationsAndNcoSubPage
            isCreateOrgDialogOpen={isCreateDialogOpen}
            setIsCreateOrgDialogOpen={setIsCreateDialogOpen}
          />
        </Route>
        <Route path={internalRootPaths.nonCustomerOrganizations}>
          <OrganizationsAndNcoSubPage
            isCreateOrgDialogOpen={isCreateDialogOpen}
            setIsCreateOrgDialogOpen={setIsCreateDialogOpen}
          />
        </Route>
        <Route path={internalRootPaths.organizationGroups}>
          <GroupsSubPage
            isCreateOrgDialogOpen={isCreateDialogOpen}
            setIsCreateOrgDialogOpen={setIsCreateDialogOpen}
          />
        </Route>
      </Switch>
    </>
  );
};

export default OrganizationsPage;
