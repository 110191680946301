import React, { useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { ReceiptDropzone } from 'domains/transaction/components';
import {
  Box,
  CaretDownIcon,
  CaretUpIcon,
  Chip,
  Divider,
  DownloadSimpleIcon,
  FileCodeIcon,
  FilePdfIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  MenuContainer,
  MenuItem,
  ReceiptAttentionIcon,
  Tooltip,
  TrashIcon,
  Typography,
} from 'elements';
import useSnackbar from 'hooks/useSnackbar';
import {
  EReceiptType,
  NetworkErrorCode,
  Receipt,
  ReceiptStatus,
  Transaction,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import { DEFAULT_SCALE } from './index';

export const SCALE_STEP = 0.15;

interface State {
  isUploadInProgress: boolean;
  isReceiptNotNeededChanging: boolean;
  receiptNeeded: boolean;
  receiptNumber: string;
}

interface Props {
  isLoading: boolean;
  transaction: Transaction;
  selectedReceipt: Receipt;
  page: number;
  numPages: number;
  scale: number;
  onChange: (newPage?: number, newScale?: number) => void;
  downloadOriginal: () => Promise<void>;
  downloadEReceiptOriginal: () => void;
  onDelete: () => void;
  onReject: () => void;
  canEditTransaction: boolean;
  onReceiptReplaced?: (
    transaction: Transaction,
    oldReceipt: Receipt,
    replacedReceipt: Receipt
  ) => void;
}

const PreviewDialogHeader = ({
  isLoading,
  transaction,
  selectedReceipt,
  page,
  numPages,
  scale,
  onChange,
  downloadOriginal,
  downloadEReceiptOriginal,
  onDelete,
  onReject,
  canEditTransaction,
  onReceiptReplaced,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const [state, setState] = useState<State>({
    isUploadInProgress: false,
    isReceiptNotNeededChanging: false,
    receiptNeeded: transaction.receiptNeeded,
    receiptNumber: transaction.receiptNumber,
  });

  const scalePercent = Math.round((scale - DEFAULT_SCALE + 1) * 100);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="60px"
      >
        <Box flex="1 1 auto" minWidth="100px" maxWidth="40%" pr={3} pl={8}>
          <Tooltip title={selectedReceipt?.fileName}>
            <Typography noWrap>{selectedReceipt?.fileName}</Typography>
          </Tooltip>
          <Tooltip
            title={
              <Trans
                i18nKey="previewDialog.uploadedBy"
                values={{
                  memberFullName: selectedReceipt?.memberFullName,
                  date: selectedReceipt?.createdAt
                    ? moment(selectedReceipt.createdAt).format(
                        'D MMM YYYY, HH:mm'
                      )
                    : '',
                }}
              />
            }
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
              noWrap
            >
              <Trans
                i18nKey="previewDialog.uploadedBy"
                values={{
                  memberFullName: selectedReceipt?.memberFullName,
                  date: selectedReceipt?.createdAt
                    ? moment(selectedReceipt.createdAt).format(
                        'D MMM YYYY, HH:mm'
                      )
                    : '',
                }}
              />
            </Typography>
          </Tooltip>
        </Box>
        {selectedReceipt?.ereceipt && (
          <Chip
            sx={{ mr: 3 }}
            label={`${t('common.badge.einvoice')} (${
              selectedReceipt.ereceiptType
            })`}
          />
        )}
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            sx={{ mr: 0.5 }}
            disabled={page <= 1 || numPages < 2 || isLoading}
            onClick={() => onChange(page - 1)}
            data-test-id="preview-prev-page"
          >
            <CaretUpIcon />
          </IconButton>
          <Typography
            variant="body2"
            noWrap
            sx={{ fontVariantNumeric: 'tabular-nums' }}
          >
            <Trans
              i18nKey="previewDialog.page"
              values={{ pagination: `${page} / ${numPages}` }}
            />
          </Typography>
          <IconButton
            size="small"
            sx={{ ml: 0.5 }}
            disabled={page >= numPages || numPages < 2 || isLoading}
            onClick={() => onChange(page + 1)}
            data-test-id="preview-next-page"
          >
            <CaretDownIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          textAlign="center"
          px={4}
        >
          <IconButton
            size="small"
            disabled={scalePercent <= 40 || isLoading}
            onClick={() =>
              onChange(undefined, parseFloat((scale - SCALE_STEP).toFixed(2)))
            }
            data-test-id="preview-zoom-out"
          >
            <MagnifyingGlassMinusIcon />
          </IconButton>
          <Typography mx={0.5} data-test-id="preview-zoom-percent" noWrap>
            <Box component="span" sx={{ fontVariantNumeric: 'tabular-nums' }}>
              {scalePercent}
            </Box>
            %
          </Typography>
          <IconButton
            size="small"
            disabled={scalePercent >= 190 || isLoading}
            onClick={() =>
              onChange(undefined, parseFloat((scale + SCALE_STEP).toFixed(2)))
            }
            data-test-id="preview-zoom-in"
          >
            <MagnifyingGlassPlusIcon />
          </IconButton>
        </Box>
        <Box display="flex" whiteSpace="nowrap" pr={1.5}>
          {selectedReceipt?.ereceiptType === EReceiptType.xrechnung ? (
            <MenuContainer
              button={
                <IconButton size="small">
                  <DownloadSimpleIcon />
                </IconButton>
              }
            >
              <MenuItem onClick={downloadEReceiptOriginal}>
                <ListItemIcon>
                  <FileCodeIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('previewDialog.downloadOriginalFile')}
                />
              </MenuItem>
              <MenuItem onClick={downloadOriginal}>
                <ListItemIcon>
                  <FilePdfIcon />
                </ListItemIcon>
                <ListItemText primary={t('previewDialog.downloadPdf')} />
              </MenuItem>
            </MenuContainer>
          ) : (
            <IconButton
              onClick={downloadOriginal}
              size="small"
              data-test-id="preview-download"
            >
              <DownloadSimpleIcon />
            </IconButton>
          )}
          {canEditTransaction && canUser('receipt:delete') && (
            <IconButton
              onClick={onDelete}
              size="small"
              sx={{ ml: 2 }}
              data-test-id="preview-delete"
            >
              <TrashIcon />
            </IconButton>
          )}
          {canEditTransaction &&
            selectedReceipt &&
            canUser('receipt:reject', selectedReceipt) && (
              <IconButton
                onClick={onReject}
                size="small"
                sx={{ ml: 2 }}
                data-test-id="reject-receipt"
              >
                <ReceiptAttentionIcon color="error" />
              </IconButton>
            )}
        </Box>
      </Box>
      <Divider />
      {canEditTransaction &&
        selectedReceipt?.status === ReceiptStatus.rejected && (
          <Box width="100%" p={2} bgcolor={(theme) => theme.palette.error.main}>
            <ReceiptDropzone
              isInPreviewDialog
              transaction={transaction}
              showMissingReceiptMessage
              receiptId={selectedReceipt.receiptId}
              onSuccess={(receipt: Receipt) => {
                setState((prevState) => ({
                  ...prevState,
                  isUploadInProgress: false,
                }));
                onReceiptReplaced &&
                  onReceiptReplaced(transaction, selectedReceipt, receipt);
              }}
              onUploadStart={() => {
                setState((prevState) => ({
                  ...prevState,
                  isUploadInProgress: true,
                }));
              }}
              onFail={(error) => {
                const errorCode = getNetworkErrorCode(error);
                if (
                  errorCode === NetworkErrorCode.receiptsNotNeeded ||
                  errorCode === NetworkErrorCode.receiptUploadNotEnabled
                ) {
                  enqueueSnackbar(getGenericErrorMsg(error), {
                    variant: 'error',
                  });
                  setState((prevState) => ({
                    ...prevState,
                    isUploadInProgress: false,
                    receiptNeeded:
                      errorCode === NetworkErrorCode.receiptsNotNeeded
                        ? false
                        : prevState.receiptNeeded,
                  }));
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    isUploadInProgress: false,
                  }));
                }
              }}
              disabled={
                !canUser('receipt:upload') || state.isReceiptNotNeededChanging
              }
            />
          </Box>
        )}
    </>
  );
};

export default PreviewDialogHeader;
