import { Link } from 'react-router-dom';
import AppLogo from 'components/AppLogo';
import { Box } from 'elements';
import AppSwitchButton from './AppSwitchButton';
import NotificationsMenu from './NotificationsMenu';
import SupportMenu from './SupportMenu';
import UserMenu from './UserMenu';
import { MenuSection, StyledMainHeader } from './style';
import withLightNavigationModeTheme from './withLightNavigationModeTheme';

const OnboardingMainHeader = () => {
  return (
    <StyledMainHeader>
      <Box
        component={Link}
        to="/"
        sx={{ mr: 'auto', lineHeight: 1, textDecoration: 'none' }}
      >
        {/* TODO: do we need to change color as in CH app? */}
        <AppLogo />
      </Box>

      <MenuSection>
        <SupportMenu />
        <NotificationsMenu />
        <UserMenu />
        <AppSwitchButton />
      </MenuSection>
    </StyledMainHeader>
  );
};

export default withLightNavigationModeTheme(OnboardingMainHeader);
