import React, { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';
import useCountdownTimer, {
  getReadableRemainingTime,
} from 'hooks/useCountdownTimer';
import usePrevious from 'hooks/usePrevious';
import { BiometricAuthRequestEventData } from 'services/memberSse';

const AUTH_REQUEST_TTL_IN_SECONDS = 5 * 60;

interface Props extends DialogProps {
  authRequest: BiometricAuthRequestEventData | null;
  onClose: () => void;
}

const ConfirmPaymentWith3dsBiometricAuthDialog = ({
  authRequest: authRequestNullable,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  const { remainingTime, startTimer } = useCountdownTimer();
  const prevRemainingTime = usePrevious(remainingTime);
  const authRequest = useMemo(() => authRequestNullable!, []);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    startTimer(authRequest.expiresAt);
  }, []);

  useEffect(() => {
    if (typeof prevRemainingTime === 'number' && remainingTime === null)
      props.onClose();
  }, [remainingTime]);

  const requireSecurityKeyAuth = async (status: 'APPROVED' | 'DECLINED') => {
    setIsLoading(true);
    loginWithRedirect({
      scope: 'mfa:required',
      '3dsAuthRequest': encodeURIComponent(
        JSON.stringify({
          gpsInitiateActionId: authRequest.gpsInitiateActionId,
          pmReferenceId: authRequest.pmReferenceId,
          pubToken: authRequest.publicToken,
          status,
        })
      ),
    });
  };

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>
        {t('confirmPaymentWith3dsBiometricAuthDialog.title')}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" mt={1} mb={3}>
          <Box position="relative">
            <CircularProgress
              size="large"
              color="secondary"
              variant="determinate"
              value={
                remainingTime !== null
                  ? (remainingTime / AUTH_REQUEST_TTL_IN_SECONDS) * 100
                  : 0
              }
              sx={{ display: 'block' }}
            />
            {remainingTime !== null && (
              <Typography
                variant="subtitle2"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  left: 0,
                  transform: 'translateY(-50%)',
                  textAlign: 'center',
                  fontVariantNumeric: 'tabular-nums',
                  color: 'text.secondary',
                }}
              >
                {getReadableRemainingTime(remainingTime)}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mx={2}
        >
          <Box minWidth={0} mr={2}>
            <Typography variant="subtitle1" noWrap>
              {authRequest.merchant}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {moment(authRequest.timestamp).format('D MMM YYYY, HH:mm')}
            </Typography>
          </Box>
          <Typography variant="subtitle1">
            <FormatMoney
              value={authRequest.amountInMinorUnits}
              fractionalPart
            />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="error"
          disabled={isLoading}
          onClick={() => requireSecurityKeyAuth('DECLINED')}
        >
          {t('common.button.decline')}
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => requireSecurityKeyAuth('APPROVED')}
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(
  ConfirmPaymentWith3dsBiometricAuthDialog
);
