import { sortBy } from 'lodash';
import {
  Partner,
  PartnerIds,
  PartnerIdType,
  PartnerScope,
  PartnerStatus,
} from 'services/constants';

export const isPartnerBasedSource = (partnerId: PartnerIdType) =>
  partnerId !== PartnerIds.pliant;

const sourcePartnerScopes = [
  PartnerScope.embeddedWallet,
  PartnerScope.fullyEmbedded,
  PartnerScope.pliantInternal,
  PartnerScope.whitelabel,
  PartnerScope.logoCard,
  PartnerScope.sync,
];

const configPartnerScopes = [
  PartnerScope.embeddedWallet,
  PartnerScope.fullyEmbedded,
  PartnerScope.pliantInternal,
  PartnerScope.whitelabel,
  PartnerScope.logoCard,
];

export const getSortedPartnersForPartnerSelect = (
  partners: Partner[] | null,
  isForConfigSelect: boolean
) => {
  if (!partners?.length) return [];

  const shownPartners = partners.filter(
    (item) =>
      (isForConfigSelect ? configPartnerScopes : sourcePartnerScopes).includes(
        item.partnerScope
      ) && item.status !== PartnerStatus.inactive
  );

  return sortBy(shownPartners!, (v) => v.name.toLowerCase());
};
