import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppWrapper } from 'components/App/AppWrapper';
import { queryClientOptions } from 'components/App/ExternalApp/layouts/OnboardingLayout';
import { env } from 'env';
import { onAuth0RedirectCallback } from 'services/utils';
import Routes from './Routes';

const queryClient = new QueryClient(queryClientOptions);

export const PortalApp = () => {
  return (
    <AppWrapper>
      {/* this is a temporary place for query client provider. It should be moved to
       *   AppWrapper for internal/external apps
       */}
      <QueryClientProvider client={queryClient}>
        <Auth0Provider
          domain={env.REACT_APP_AUTH0_DOMAIN}
          clientId={env.REACT_APP_AUTH0_CLIENT_ID}
          audience={env.REACT_APP_AUTH0_AUDIENCE}
          redirectUri={window.location.origin + '/callback'}
          onRedirectCallback={onAuth0RedirectCallback}
        >
          <Routes />
        </Auth0Provider>
      </QueryClientProvider>
    </AppWrapper>
  );
};
