import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import WidgetError from 'components/WidgetError';
import {
  MemberDetailsContactBlock,
  MemberDetailsHeader,
  PartnerMemberDetailsMenu,
} from 'domains/member/components';
import { LoaderWithOverlay } from 'elements';
import useMounted from 'hooks/useMounted';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import { PartnerMember } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

interface State {
  isLoading: boolean;
  member: PartnerMember | null;
  error: unknown;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (member: PartnerMember) => void;
}

const PartnerMemberDetailsPage = ({ onUpdate, ...props }: Props) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { memberId } = useParams<{ memberId: string }>();
  const idRef = useRef(memberId);
  const [state, setState] = useState<State>({
    isLoading: true,
    member: null,
    error: null,
  });

  const getData = async () => {
    try {
      setState((state) => ({
        ...state,
        isLoading: true,
      }));

      const member = await api.getPartnerMember(memberId);

      if (!mounted.current || memberId !== idRef.current) return;
      setState((state) => ({
        ...state,
        member,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || memberId !== idRef.current) return;
      setState((state) => ({
        ...state,
        error,
        member: null,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!memberId) return;
    idRef.current = memberId;
    getData();
  }, [memberId]);

  return (
    <DetailsDrawer
      {...props}
      actionsComponent={
        state.member && (
          <PartnerMemberDetailsMenu
            member={state.member}
            onUpdate={(member) => {
              setState((prevState) => ({
                ...prevState,
                member,
              }));
              onUpdate(member);
            }}
          />
        )
      }
    >
      {state.member && (
        <>
          <DetailsDrawerHeader>
            <MemberDetailsHeader member={state.member} />
          </DetailsDrawerHeader>

          <DetailsDrawerContent>
            <MemberDetailsContactBlock member={state.member} />
          </DetailsDrawerContent>
        </>
      )}

      {state.error && <WidgetError onReload={getData} />}
      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(PartnerMemberDetailsPage);
