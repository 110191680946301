import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'elements';
import { Card, CardManufacturer, CardStatus } from 'services/constants';

interface Props {
  card: Card;
}

const CardHint = ({ card }: Props) => {
  const { t } = useTranslation();
  let severity: 'info' | 'warning' = 'info';
  let hint: null | string = null;

  if (card.status === CardStatus.pendingRegistration)
    hint = t('cardDetails.pendingRegistrationHint');

  if (card.status === CardStatus.pendingValidity)
    hint = t('cardDetails.pendingValidityHint');

  if (card.status === CardStatus.pendingIssuance)
    hint = t('cardDetails.cardIsBeingIssuedHint');

  if (card.status === CardStatus.pendingActivation)
    if (card.manufacturer === CardManufacturer.exceet) {
      hint = t('cardDetails.needsActivationHint');
    } else {
      hint = t('cardDetails.needsActivationLastDigitsHint');
    }

  if (card.status === CardStatus.lockedPin) {
    severity = 'warning';
    hint = t('cardDetails.needsToBeUnlockedHint');
  }

  return hint ? (
    <Alert severity={severity} sx={{ mt: -1, mb: 3 }}>
      {hint}
    </Alert>
  ) : null;
};

export default CardHint;
