import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Container, ContainerInner, MainContent } from 'components/App/style';
import { OnboardingMainHeader } from 'components/MainHeader';

// move upper, when we use tanstack in other places
export const queryClientOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
};

const queryClient = new QueryClient(queryClientOptions);

const OnboardingLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Container>
        <ContainerInner>
          <OnboardingMainHeader />
          <MainContent data-intercom-target="page-content">
            {children}
          </MainContent>
        </ContainerInner>
      </Container>

      {/*  included in bundles only when process.env.NODE_ENV === 'development' */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default OnboardingLayout;
