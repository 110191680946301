import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  LoaderWithOverlay,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import useSnackbar from 'hooks/useSnackbar';
import { BankAccount, BankConnectionImportStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  bankAccounts: BankAccount[];
  accountIds: string[];
}

interface Props extends DialogProps {
  bankConnectionImportStatus: BankConnectionImportStatus;
  onClose: () => void;
  onSuccess: () => void;
}

const AuthorizeBankConnectionDialog = ({
  bankConnectionImportStatus,
  onClose,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const partnerName = usePartnerName();
  const [state, setState] = useState<State>({
    isLoading: true,
    bankAccounts: [],
    accountIds: [],
  });

  const getData = async () => {
    try {
      const { bankAccounts } = await api.getUnauthorizedBankAccounts(
        bankConnectionImportStatus.bankConnectionId
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        bankAccounts,
        accountIds: bankAccounts.map((item) => item.id),
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const authorizeBankConnection = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      await api.authorizeBankConnection(
        bankConnectionImportStatus.bankConnectionId,
        state.accountIds
      );
      if (!mounted.current) return;
      onSuccess();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{t('authorizeBankConnectionDialog.title')}</DialogTitle>
      <DialogContent>
        <Box mb={4}>
          <Typography variant="body2">
            {t('authorizeBankConnectionDialog.description', {
              partnerName,
            })}
          </Typography>
        </Box>
        <FormControl variant="standard">
          {state.bankAccounts.length > 0 && (
            <FormLabel>{t('authorizeBankConnectionDialog.iban')}</FormLabel>
          )}
          <FormGroup>
            {state.bankAccounts.map((bankAccount) => (
              <FormControlLabel
                key={bankAccount.id}
                control={
                  <Checkbox
                    checked={state.accountIds.includes(bankAccount.id)}
                    disabled={
                      state.accountIds.includes(bankAccount.id) &&
                      state.accountIds.length === 1
                    }
                    onChange={() => {
                      setState(({ accountIds, ...prevState }) => ({
                        ...prevState,
                        accountIds: accountIds.includes(bankAccount.id)
                          ? accountIds.filter((id) => id !== bankAccount.id)
                          : [...accountIds, bankAccount.id],
                      }));
                    }}
                  />
                }
                label={bankAccount.accountNumber.number}
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={authorizeBankConnection} disabled={state.isLoading}>
          {t('authorizeBankConnectionDialog.authorize')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper(AuthorizeBankConnectionDialog);
