import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { BillPaymentBatchStatesCell } from 'domains/billing/components';
import { Chip, drawerPlaceholderColumn, GridColDef, gridUtils } from 'elements';
import { BillPaymentBatch } from 'services/constants';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<BillPaymentBatch>[] = [
      {
        headerName: t('int.payoutBatchesPage.submissionDate'),
        field: 'submissionDate',
        flex: 140,
        minWidth: 161,
      },
      {
        headerName: t('int.payoutBatchesPage.paymentDate'),
        field: 'plannedPaymentDate',
        flex: 140,
        minWidth: 161,
      },
      {
        headerName: t('int.cashbackPaymentBatchesPage.accountGroup'),
        field: 'accountGroup',
        flex: 200,
        minWidth: 230,
        renderCell: ({ row }) => (
          <Chip label={t(`accountGroup.${row.accountGroup}`)} size="small" />
        ),
        sortable: false,
      },
      {
        headerName: t('int.payoutBatchesPage.currency'),
        field: 'currency',
        flex: 100,
        minWidth: 115,
        sortable: false,
      },
      {
        headerName: t('int.payoutBatchesPage.amount'),
        field: 'amount',
        flex: 140,
        minWidth: 162,
        renderCell: ({ row }) => (
          <FormatMoney value={row.amount} fractionalPart />
        ),
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.payoutBatchesPage.count'),
        field: 'count',
        flex: 120,
        minWidth: 138,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.payoutBatchesPage.state'),
        field: 'states',
        flex: 180,
        minWidth: 208,
        renderCell: ({ row }) => (
          <BillPaymentBatchStatesCell states={row.states} />
        ),
        sortable: false,
        headerAlign: 'right',
        align: 'right',
      },
      drawerPlaceholderColumn,
    ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language]);
};

export default useColumns;
