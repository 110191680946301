import {
  CalendarBlankIcon,
  CoinsIcon,
  CurrencyCircleDollarIcon,
  FileIcon,
  FilesIcon,
  GavelIcon,
  IdentificationCardIcon,
  MapPinIcon,
  NumberCircleOneIcon,
  PlugsConnectedIcon,
  PlusCircleIcon,
  QuestionIcon,
  ReadCvLogoIcon,
  TrademarkRegisteredIcon,
  UserIcon,
  UserListIcon,
  UsersIcon,
} from 'elements';
import {
  OnboardingSectionType,
  OnboardingTask,
  OnboardingTaskType,
} from 'services/constants';

export const SectionIconByType = {
  [OnboardingSectionType.companyOverview]: ReadCvLogoIcon,
  [OnboardingSectionType.companyDetails]: FilesIcon,
  [OnboardingSectionType.questionsAndAnswers]: PlusCircleIcon,
  [OnboardingSectionType.directorsAndShareholders]: UserListIcon,
  [OnboardingSectionType.termsAndConditions]: GavelIcon,
};

export const getTaskIconByType = (task: OnboardingTask) => {
  switch (task.type) {
    case OnboardingTaskType.organizationName:
      return TrademarkRegisteredIcon;
    case OnboardingTaskType.registeredAddress:
    case OnboardingTaskType.proofOfAddress:
      return MapPinIcon;
    case OnboardingTaskType.registeredNumber:
      return NumberCircleOneIcon;
    case OnboardingTaskType.foundationDate:
      return CalendarBlankIcon;
    case OnboardingTaskType.idCopy:
    case OnboardingTaskType.vatId:
      return IdentificationCardIcon;
    case OnboardingTaskType.annualRevenue:
      return CurrencyCircleDollarIcon;
    case OnboardingTaskType.balanceSheetTotal:
      return CoinsIcon;
    case OnboardingTaskType.staffHeadcount:
      return UsersIcon;
    case OnboardingTaskType.companyType:
      return QuestionIcon;
    case OnboardingTaskType.businessEstablishmentDocs:
      return FilesIcon;
    case OnboardingTaskType.shareholderList:
      return FileIcon;
    case OnboardingTaskType.question:
      return task.data.onboardingDocument.value?.type.question
        ? QuestionIcon
        : FileIcon;
    case OnboardingTaskType.identification:
      return PlugsConnectedIcon;
    case OnboardingTaskType.terms:
      return GavelIcon;
    default:
      return QuestionIcon;
  }
};

// extend when needed
export const getGroupIconByType = () => {
  return UserIcon;
};
