import { styled } from '@mui/material';
import { Grid, IconButton, Paper } from 'elements';

export const GridWrapper = styled(Grid)`
  height: 100vh;
  overflow: hidden;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  z-index: 20;
  top: 11px;
  left: 10px;
`;

export const ConfirmationOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 64px;
  height: 60px;
  right: 0;
  bottom: 0;
  padding-right: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  overflow: hidden;
`;

export const StyledGrid = styled(Grid)`
  padding-top: 8px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 8px);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ReceiptContent = styled(Grid)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ViewerWrap = styled('div')`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 0;
`;

export const FakeImage = styled((props) => (
  <Paper {...props} variant="tinted" />
))`
  max-width: 300px;
  width: 100%;
  max-height: 280px;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const NavButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
