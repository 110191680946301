import { CardConfigSettingList } from 'services/constants';

export const transformCardConfigs = (
  data: CardConfigSettingList,
  requestCardForAnotherMemberEnabled: boolean
): CardConfigSettingList => {
  return requestCardForAnotherMemberEnabled
    ? {
        ...data,
        cardConfigs: data.cardConfigs.map((config) => ({
          ...config,
          ...(config.maxUsage === 1 && {
            validityPeriodEnabled: true,
            validityPeriodDefaultInDays: 14,
          }),
        })),
      }
    : data;
};
