import { generatePath } from 'react-router-dom';
import {
  OnboardingItemStatus,
  OnboardingSectionType,
  OnboardingTask,
} from 'services/constants';
import { getPath } from 'services/utils';

// All navigation related utils for the new onboarding.

export const getUrlFromOnboardingKeyType = (itemType: OnboardingSectionType) =>
  itemType.toLowerCase().split('_').join('-');

// revert type back from URL
export const getOnboardingKeyTypeFromUrl = <T extends OnboardingSectionType>(
  itemKey: string
) => itemKey.toUpperCase().split('-').join('_') as T;

export const getTaskNavigationLinks = (
  tasks: OnboardingTask[],
  currentTaskId?: string
) => {
  if (!currentTaskId)
    return { prevTaskId: '', nextTaskId: '', isTaskNavigationEnabled: false };

  // allow navigation only for tasks that require action
  const tasksToNavigate = tasks.filter(
    (task) => task.status === OnboardingItemStatus.requiresAction
  );
  const currentPositionIndex = tasksToNavigate.findIndex(
    (t) => t.id === currentTaskId
  );
  if (currentPositionIndex === -1)
    return {
      prevTaskId: '',
      nextTaskId: '',
      isTaskNavigationEnabled: false,
    };
  return {
    prevTaskId:
      currentPositionIndex > 0
        ? tasksToNavigate[currentPositionIndex - 1].id
        : '',
    nextTaskId:
      currentPositionIndex < tasksToNavigate.length - 1
        ? tasksToNavigate[currentPositionIndex + 1].id
        : '',
    isTaskNavigationEnabled: true,
  };
};

// use one function for all cases in order to minimize numberof missed places,
// when url structure changes
export const getNavigationPath = (
  orgId: string,
  sectionKey: string,
  { groupId, taskId }: { groupId?: string; taskId?: string } = {}
) => {
  let completePath = `${getPath('orgOnboarding')}/${sectionKey}`;

  if (groupId) completePath = completePath + `/group/${groupId}`;
  if (taskId) completePath = completePath + `/task/${taskId}`;

  return generatePath(completePath, {
    orgId: orgId,
  });
};
