import { useEffect, useState } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { useCodatStepperContext } from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/useCodatStepperContext';
import {
  CodatTrackingCategoryNestedItem,
  createCategoriesHashMap,
  createNestedTrackingCategoriesArray,
  getShownTrackingCategoriesList,
} from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/utils';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CodatDataItemStatus,
  CodatMappingTarget,
  CodatTrackingCategoryItem,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  isInnerLoading: boolean;
  isError: boolean;
  fetchedTrackingCategories: CodatTrackingCategoryItem[] | null; // initial tracking categories
  filterTrackingCategories: CodatTrackingCategoryNestedItem[] | null; // used for filter dropdown
  shownCategoriesList: CodatTrackingCategoryNestedItem[] | null;
  shownCategoriesIds: string[];
  selectedCategoryId: 'all_top_level' | Omit<string, 'all_top_level'>;
  categoryProjectHashMap: { [id: string]: 'new' | string };
  selectedIds: string[];
}

const useCostUnitsSync = () => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization, projects },
  } = useGlobalState();
  const {
    actions: { onNext },
  } = useCodatStepperContext();
  const [state, setState] = useState<State>({
    isLoading: true,
    isInnerLoading: false,
    isError: false,
    fetchedTrackingCategories: null,
    filterTrackingCategories: null,
    shownCategoriesList: null,
    shownCategoriesIds: [],
    selectedCategoryId: '',
    categoryProjectHashMap: {},
    selectedIds: [],
  });

  const getCodatData = async () => {
    const fetchedTrackingCategories = await api.getCodatTrackingCategories(
      organization!.id,
      CodatMappingTarget.costUnit
    );

    // for filters
    const filterTrackingCategories = createNestedTrackingCategoriesArray(
      fetchedTrackingCategories
    );

    // Find preselected category for filter if exists
    const preselectedFilterCategory = fetchedTrackingCategories.find(
      (item) => item.filteredProjectCategoryId
    );

    // define which categoryId is selected in the filter
    const preselectedFilterCategoryId =
      preselectedFilterCategory?.filteredProjectCategoryId ||
      filterTrackingCategories[0]?.id;

    const flattenedShownCategoriesList = getShownTrackingCategoriesList(
      fetchedTrackingCategories,
      preselectedFilterCategoryId || 'all_top_level'
    );

    // for list
    const nestedShownCategoriesList = createNestedTrackingCategoriesArray(
      flattenedShownCategoriesList,
      preselectedFilterCategoryId || null,
      true
    );

    // set initial & refetched data
    return {
      fetchedTrackingCategories,
      filterTrackingCategories,
      selectedCategoryId: preselectedFilterCategoryId || 'all_top_level',
      shownCategoriesList: nestedShownCategoriesList,
      shownCategoriesIds: flattenedShownCategoriesList.map((item) => item.id),
      categoryProjectHashMap: fetchedTrackingCategories.length
        ? createCategoriesHashMap(fetchedTrackingCategories, 'mappedProjectId')
        : {},
    };
  };

  const getData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isError: false,
        isLoading: true,
      }));

      const { projects } = await api.getProjects({
        organizationId: organization!.id,
      });
      dispatch({ type: 'SET_ORGANIZATION_DATA', payload: { projects } });
      const mappedPartialState = await getCodatData();

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        ...mappedPartialState,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isError: true,
        isLoading: false,
      }));
      logError(error);
    }
  };

  const onCategoryChange = (categoryId: State['selectedCategoryId']) => {
    const flattenedShownCategoriesList = getShownTrackingCategoriesList(
      state.fetchedTrackingCategories!,
      categoryId === 'all_top_level' || categoryId === 'all'
        ? categoryId
        : state.fetchedTrackingCategories!.find(
            (item) => item.id === categoryId
          )!.id
    );
    const nestedShownCategoriesList = createNestedTrackingCategoriesArray(
      flattenedShownCategoriesList,
      categoryId,
      true
    );

    setState((prevState) => ({
      ...prevState,
      selectedCategoryId: categoryId,
      shownCategoriesList: nestedShownCategoriesList,
      shownCategoriesIds: flattenedShownCategoriesList.map((item) => item.id),
      selectedIds: [],
    }));
  };

  const onProjectChange = (
    categoryId: string,
    selectedProjectId: 'new' | string
  ) => {
    setState((prevState) => ({
      ...prevState,
      categoryProjectHashMap: {
        ...prevState.categoryProjectHashMap,
        [categoryId]: selectedProjectId,
      },
    }));
  };

  const onSelect = (categoryIds: string | string[], checked?: boolean) => {
    if (typeof categoryIds === 'string') {
      // on select 1 item
      setState((prevState) => ({
        ...prevState,
        selectedIds: checked
          ? [...prevState.selectedIds, categoryIds]
          : prevState.selectedIds.filter((id) => id !== categoryIds),
      }));
    } else {
      // on select all
      setState((prevState) => ({
        ...prevState,
        selectedIds: categoryIds,
      }));
    }
  };

  const onCostUnitsSave = async (onSaveCallback?: () => void) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isInnerLoading: true,
      }));

      const flattenedSelectedCategoriesList = state.fetchedTrackingCategories!.filter(
        (item) => state.selectedIds.includes(item.id)
      );

      const mappedTrackingCategories = state.selectedIds.map((id) => ({
        codatTrackingCategoryId: id,
        status: CodatDataItemStatus.selected,
      }));

      await api.updateCodatTrackingCategories({
        organizationId: organization!.id,
        mappingTarget: CodatMappingTarget.costUnit,
        filteredProjectCategoryId: state.selectedCategoryId as string | null,
        selectedTrackingCategories: mappedTrackingCategories,
      });

      await api.mapCodatProjects({
        organizationId: organization!.id,
        codatTrackingCategories: flattenedSelectedCategoriesList.map(
          (category) => ({
            id: category.id,
            name: category.name,
            projectId:
              state.categoryProjectHashMap[category.id] === 'new'
                ? null
                : (state.categoryProjectHashMap[category.id] as string),
            costUnit:
              state.categoryProjectHashMap[category.id] === 'new'
                ? ''
                : projects?.find(
                    (project) =>
                      project.id === state.categoryProjectHashMap[category.id]
                  )?.costUnit || '',
          })
        ),
      });

      if (!mounted.current) return;
      onSaveCallback ? onSaveCallback() : onNext();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isInnerLoading: false,
      }));
      logError(error);
    }
  };

  // get the latest Codat data
  const fetchCodatData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isInnerLoading: true,
      }));

      await api.getSyncedCodatMappingOptionsSummary(organization!.id);
      const mappedPartialState = await getCodatData();

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        ...mappedPartialState,
        isInnerLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isInnerLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    ...state,
    getData,
    onCategoryChange,
    onProjectChange,
    onSelect,
    onCostUnitsSave,
    fetchCodatData,
  };
};

export default useCostUnitsSync;
