import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MemberRoleOverline } from 'domains/member/components';
import { ResetPhoneNumberDialog } from 'domains/member/dialogs';
import { Box, Grid, LabeledValue, Typography } from 'elements';
import { MemberDetails } from 'services/constants';
import { useCanUser } from 'services/rbac';
import EditMemberDialog from './EditMemberDialog';

const PersonalDataSection = () => {
  const { t } = useTranslation();
  const {
    state: { member },
    dispatch,
  } = useGlobalState();
  const canUser = useCanUser();
  const [isEditMemberDialogOpen, setIsEditMemberDialogOpen] = useState(false);
  const [
    isResetPhoneNumberDialogOpen,
    setIsResetPhoneNumberDialogOpen,
  ] = useState(false);

  return (
    <Box mb={8}>
      <Typography variant="h6" mb={1}>
        {t('profilePage.personalDataSection.title')}
      </Typography>

      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid item xs={6}>
          <LabeledValue
            label={t('profilePage.personalDataSection.fullName')}
            value={`${member.firstName} ${member.lastName}`}
          />
        </Grid>
        <Grid item xs={6}>
          <LabeledValue
            label={t('profilePage.personalDataSection.role')}
            value={
              <MemberRoleOverline
                roles={member.roles}
                variant="inherit"
                showCardholderRole
              />
            }
          />
        </Grid>

        <Grid item xs={6}>
          <LabeledValue
            label={t('profilePage.personalDataSection.email')}
            value={member.email}
          />
        </Grid>
        <Grid item xs={6}>
          <LabeledValue
            label={t('profilePage.personalDataSection.phoneNumber')}
            value={
              member.securityKeyPaired
                ? t('profilePage.personalDataSection.noPhoneNumber')
                : member.phoneNumber || '-'
            }
            onEdit={
              canUser('profile-page-phone-number:change')
                ? () => setIsResetPhoneNumberDialogOpen(true)
                : undefined
            }
            disabled={member.securityKeyPaired}
            disabledTooltip={t(
              'profilePage.personalDataSection.noPhoneNumberTooltip'
            )}
          />
        </Grid>
      </Grid>

      {/* todo: should be removed? setIsEditMemberDialogOpen is not triggered from anywhere */}
      <EditMemberDialog
        member={member as MemberDetails}
        open={isEditMemberDialogOpen}
        onClose={() => setIsEditMemberDialogOpen(false)}
        onSuccess={(data) => {
          dispatch({
            type: 'SET_USER_DATA',
            payload: { member: data },
          });
          setIsEditMemberDialogOpen(false);
        }}
      />

      <ResetPhoneNumberDialog
        open={isResetPhoneNumberDialogOpen}
        onClose={() => setIsResetPhoneNumberDialogOpen(false)}
        onSuccess={() => {}}
        member={member as MemberDetails}
      />
    </Box>
  );
};

export default PersonalDataSection;
