import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'components/Button';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { Checkbox } from 'components/Form';
import { LoaderWithOverlay } from 'components/Loader';
import OnboardingBlockWrapper from 'components/OnboardingPage/OnboardingBlockWrapper';
import { MailDescription } from 'components/OnboardingPage/style';
import { useOnboardingContext } from 'components/OnboardingPage/useOnboardingContext';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import { getDocumentUrl } from 'domains/document/utils';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountGroup,
  DocumentGenericType,
  DocumentsByGenericTypeMap,
  LegalRepresentativeType,
  OrganizationAccountType,
  OrganizationStatus,
  PartnerIds,
  PartnerIdType,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import { CheckboxLabel, CheckboxWrapper, StyledButtonLink } from './style';

const renderCheckboxLabel = (
  documents: DocumentsByGenericTypeMap,
  genericType: DocumentGenericType,
  partnerId: PartnerIdType,
  hasPliantPaymentsLtdTacs: boolean
) => {
  const doc = documents[genericType][0];

  const getTranslationKey = () => {
    if (
      hasPliantPaymentsLtdTacs &&
      genericType === DocumentGenericType.bankTermsAndConditions
    ) {
      return 'bankAndIssuerTermsBlock.labelsByDocumentGenericType.BANK_TERMS_AND_CONDITIONS_PLIANT_PAYMENTS_LTD';
    }

    if (partnerId === PartnerIds.commerzbank) {
      return `bankAndIssuerTermsBlock.labelsByDocumentGenericType.${genericType}_COMMERZBANK`;
    }

    return `bankAndIssuerTermsBlock.labelsByDocumentGenericType.${genericType}`;
  };

  return (
    <Trans
      i18nKey={getTranslationKey()}
      components={{
        a: (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            href={getDocumentUrl(doc.documentName)}
            target="_blank"
            rel="noopener noreferrer"
          />
        ),
      }}
      values={doc}
    />
  );
};

enum TacDocName {
  platform = 'platform',
  cardOpen = 'cardOpen',
  cardIssue = 'cardIssue',
}

const TermsAndConditionsBlock = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { onboardingPlatformTacsEnabled } = useFlags();
  const {
    actions: { confirmTermsAndConditions, updateOnboarding },
    state: {
      hasOpenQaRequests,
      isConfirmTermsAndConditionsLoading,
      onboarding,
    },
  } = useOnboardingContext();
  const {
    state: { organization, member, defaultCardAccount, documents },
  } = useGlobalState();
  const [checkboxes, setCheckboxes] = useState<{
    [TacDocName.platform]: boolean;
    [TacDocName.cardOpen]: boolean;
    [TacDocName.cardIssue]: boolean;
  }>({
    platform: false,
    cardOpen: false,
    cardIssue: false,
  });
  const canUser = useCanUser();

  const hasVgBankTacs = [AccountGroup.vgTpml, AccountGroup.vgPliantOy].includes(
    defaultCardAccount!.accountGroup.value
  );
  const hasPliantPaymentsLtdTacs = [AccountGroup.bcTpl].includes(
    defaultCardAccount!.accountGroup.value
  );

  const didUserConfirmSDDMandate = !!onboarding?.directDebitActivationConfirmations?.some(
    (confirmation) => confirmation.memberId === member.id
  );
  const didUserConfirmPartnerBankTacs = !!onboarding?.bankTermsConfirmations?.some(
    (confirmation) => confirmation.memberId === member.id
  );
  const didUserConfirmIssuerTacs = !!onboarding?.issuerTermsConfirmations?.some(
    (confirmation) => confirmation.memberId === member.id
  );
  const areCheckboxesDisabled =
    isConfirmTermsAndConditionsLoading ||
    !canUser('onboarding-step-tac:pass') ||
    onboarding?.termsAndConditionsCompleted ||
    // required for joint legal reps
    (hasVgBankTacs
      ? didUserConfirmPartnerBankTacs
      : didUserConfirmIssuerTacs) ||
    (hasPliantPaymentsLtdTacs
      ? didUserConfirmPartnerBankTacs
      : didUserConfirmIssuerTacs);

  const isSubmitDisabled =
    areCheckboxesDisabled ||
    (onboardingPlatformTacsEnabled && !checkboxes.platform) ||
    (hasVgBankTacs && !checkboxes.cardOpen) ||
    (hasPliantPaymentsLtdTacs && !checkboxes.cardOpen) ||
    !checkboxes.cardIssue;
  const [state, setState] = useState({
    isResetting: false,
    isResetDialogOpen: false,
  });

  useEffect(() => {
    if (
      canUser(
        'onboarding-step-prefund-wait-for-risk-review-completed-toast:view'
      ) &&
      onboarding!.status === OrganizationStatus.onboardingRiskReview &&
      !hasOpenQaRequests &&
      defaultCardAccount!.accountType.value ===
        OrganizationAccountType.prefunded
    ) {
      enqueueSnackbar(
        t('onboardingPage.prefundWaitingForRiskReviewCompletedToast'),
        {
          persist: true,
        }
      );

      return () => closeSnackbar();
    }

    if (
      onboarding!.riskAssessmentCompleted &&
      ((defaultCardAccount!.accountType.value ===
        OrganizationAccountType.credit &&
        didUserConfirmPartnerBankTacs &&
        didUserConfirmSDDMandate &&
        (!onboarding!.directDebitCompleted ||
          !onboarding!.termsAndConditionsCompleted)) ||
        (defaultCardAccount!.accountType.value ===
          OrganizationAccountType.prefunded &&
          (hasVgBankTacs
            ? didUserConfirmPartnerBankTacs
            : didUserConfirmIssuerTacs) &&
          !onboarding!.termsAndConditionsCompleted))
    ) {
      enqueueSnackbar(
        t(
          defaultCardAccount!.accountType.value ===
            OrganizationAccountType.credit
            ? 'onboardingPage.jointLegalRepToastCredit'
            : 'onboardingPage.jointLegalRepToastPrefund'
        ),
        {
          persist: true,
        }
      );

      return () => closeSnackbar();
    }

    if (
      OrganizationStatus.onboardingWait === onboarding!.status &&
      canUser('onboarding-step-tac-success-toast:view')
    ) {
      enqueueSnackbar(t('bankAndIssuerTermsBlock.successMessage'), {
        autoHideDuration: null,
      });

      return () => closeSnackbar();
    }

    if (
      [LegalRepresentativeType.none, null].includes(
        member.legalRepresentative
      ) &&
      onboarding!.riskAssessmentCompleted &&
      OrganizationStatus.onboardingWait !== onboarding!.status
    ) {
      if (
        defaultCardAccount!.accountType.value ===
          OrganizationAccountType.credit &&
        (!onboarding!.termsAndConditionsCompleted ||
          !onboarding!.directDebitCompleted)
      ) {
        enqueueSnackbar(t('onboardingPage.noneLegalRepToastCredit'), {
          autoHideDuration: null,
        });

        return () => closeSnackbar();
      }

      if (
        defaultCardAccount!.accountType.value ===
          OrganizationAccountType.prefunded &&
        !onboarding!.termsAndConditionsCompleted
      ) {
        enqueueSnackbar(t('onboardingPage.noneLegalRepToastPrefund'), {
          autoHideDuration: null,
        });

        return () => closeSnackbar();
      }
    }
  }, [
    onboarding,
    didUserConfirmPartnerBankTacs,
    didUserConfirmSDDMandate,
    hasVgBankTacs,
    didUserConfirmIssuerTacs,
    hasOpenQaRequests,
  ]);

  const onCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setCheckboxes({ ...checkboxes, [e.target.name]: checked });
  };

  const getTacBlockState = () => {
    if (isConfirmTermsAndConditionsLoading || !onboarding) return 'disabled';

    if (
      onboarding.termsAndConditionsCompleted ||
      didUserConfirmPartnerBankTacs
    ) {
      return 'completed';
    }

    if (onboarding.riskAssessmentCompleted) return 'active';

    return 'disabled';
  };

  const resetTermsAndConditions = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isResetting: true,
      }));
      const updatedOnboarding = await api.resetTermsAndConditions(
        organization!.id
      );

      if (!mounted.current) return;
      updateOnboarding(updatedOnboarding);
      setState((prevState) => ({
        ...prevState,
        isResetDialogOpen: false,
        isResetting: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isResetting: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <OnboardingBlockWrapper
      title={
        onboardingPlatformTacsEnabled
          ? t('bankAndIssuerTermsBlock.title_v2')
          : t('bankAndIssuerTermsBlock.title')
      }
      state={getTacBlockState()}
      testId="onboarding-terms-block"
      textButton={
        canUser('onboarding:revert') &&
        [
          OrganizationStatus.onboardingDirectDebit,
          OrganizationStatus.onboardingCompliance,
        ].includes(onboarding!.status) &&
        (!!onboarding!.bankTermsConfirmations?.length ||
          !!onboarding!.issuerTermsConfirmations?.length) && (
          <StyledButtonLink
            onClick={(e) => {
              e.stopPropagation();
              setState((prevState) => ({
                ...prevState,
                isResetDialogOpen: true,
              }));
            }}
          >
            {t('int.onboardingResetTermsAndConditionsStepDialog.openButton')}
          </StyledButtonLink>
        )
      }
    >
      {!!onboarding && (
        <Fragment>
          <MailDescription $mb={20}>
            {t('bankAndIssuerTermsBlock.description')}
          </MailDescription>

          {documents ? (
            <>
              {[
                ...(onboardingPlatformTacsEnabled
                  ? [
                      {
                        name: TacDocName.platform,
                        docGenericType:
                          DocumentGenericType.platformTermsAndConditions,
                      },
                    ]
                  : []),
                ...(hasVgBankTacs || hasPliantPaymentsLtdTacs
                  ? [
                      {
                        name: TacDocName.cardOpen,
                        docGenericType:
                          DocumentGenericType.bankTermsAndConditions,
                      },
                    ]
                  : []),
                {
                  name: TacDocName.cardIssue,
                  docGenericType: DocumentGenericType.issuerTermsAndConditions,
                },
              ].map((item) => (
                <CheckboxWrapper key={item.name}>
                  <Checkbox
                    id={`${item.name}-checkbox`}
                    name={item.name}
                    checked={
                      checkboxes[item.name] ||
                      (hasVgBankTacs
                        ? didUserConfirmPartnerBankTacs
                        : didUserConfirmIssuerTacs) ||
                      onboarding.termsAndConditionsCompleted
                    }
                    disabled={areCheckboxesDisabled}
                    onChange={onCheckboxChange}
                  />
                  <CheckboxLabel htmlFor={`${item.name}-checkbox`}>
                    {renderCheckboxLabel(
                      documents,
                      item.docGenericType,
                      organization!.partnerId,
                      hasPliantPaymentsLtdTacs
                    )}
                  </CheckboxLabel>
                </CheckboxWrapper>
              ))}
              <Box mt="34px" textAlign="right">
                <Button
                  onClick={confirmTermsAndConditions}
                  disabled={isSubmitDisabled}
                  data-test-id="complete-onboarding-btn"
                >
                  {t('common.button.confirm')}
                </Button>
              </Box>
            </>
          ) : (
            <WidgetError />
          )}
          {isConfirmTermsAndConditionsLoading && (
            <LoaderWithOverlay size={22} thickness={3} />
          )}
        </Fragment>
      )}

      <ConfirmDialog
        title={t('int.onboardingResetTermsAndConditionsStepDialog.title')}
        description={t(
          'int.onboardingResetTermsAndConditionsStepDialog.description'
        )}
        open={state.isResetDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isResetDialogOpen: false,
          }))
        }
        onSuccess={resetTermsAndConditions}
        loading={state.isResetting}
      />
    </OnboardingBlockWrapper>
  );
};

export default TermsAndConditionsBlock;
