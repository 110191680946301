import { useMemo } from 'react';
import {
  CardCategoryControlType,
  CardConfigSetting,
  CardControlConfig,
  CardDateControlType,
  CardLocationControlType,
  CardMerchantControlType,
  CardTimeControlType,
} from 'services/constants';

const useAvailableCardControlConfigs = (
  { cardControls }: CardConfigSetting,
  editable?: boolean
): {
  categories: { [K in CardCategoryControlType]?: CardControlConfig };
  merchants: { [K in CardMerchantControlType]?: CardControlConfig };
  dates: { [K in CardDateControlType]?: CardControlConfig };
  times: { [K in CardTimeControlType]?: CardControlConfig };
  locations: { [K in CardLocationControlType]?: CardControlConfig };
} => {
  return useMemo(() => {
    const filterConfigs = (configByTypeMap: {
      [key: string]: CardControlConfig;
    }) => {
      const filteredEntries = Object.entries(configByTypeMap).filter(
        ([, config]) =>
          config.enabled &&
          (typeof editable === 'undefined' || editable === config.editable)
      );

      return Object.fromEntries(filteredEntries);
    };

    return {
      categories: filterConfigs(cardControls.categories),
      merchants: filterConfigs(cardControls.merchants),
      dates: filterConfigs(cardControls.dates),
      times: filterConfigs(cardControls.times),
      locations: filterConfigs(cardControls.locations),
    };
  }, [cardControls, editable]);
};

export default useAvailableCardControlConfigs;
