import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { adminPaths, cardholderPaths } from 'components/App';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import {
  ActionBox,
  ActionBoxActions,
  Box,
  Button,
  FilesIcon,
  HourglassHighIcon,
  PlugsIcon,
  ReceiptAddIcon,
  ReceiptExternalLinkIcon,
  ReceiptXIcon,
  TrashIcon,
  Typography,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  ReceiptAutoMatchingDetails,
  ReceiptInboxMatchingFlow,
  ReceiptsAutoMatchingStatus,
  Transaction,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getPath } from 'services/utils';
import MatchedTransactionsList from './MatchedTransactionsList';

interface Props {
  receipt: ReceiptAutoMatchingDetails;
  thumbnail: string;
  transactions: Transaction[];
  // refetch list of transactions
  onRefetch: (receiptId?: string) => void;
  onDetailsRefetch: () => void;
}

const ReceiptActionBlock = ({
  receipt,
  thumbnail,
  transactions,
  onRefetch,
  onDetailsRefetch,
}: Props) => {
  const receiptIdRef = useRef(receipt.id);
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const { isCardholderApp } = useCurrentApp();
  const {
    dispatch,
    state: { organization, member, receiptInbox },
  } = useGlobalState();
  const [state, setState] = useState({
    isLoading: false,
    transactionIdToUnmatch: '',
    isDeleteConfirmDialogOpen: false,
  });
  const confirmDialogRef = useRef<{
    title: string;
    description: string;
    successBtn: string;
    action: () => void;
  }>({
    title: '',
    description: '',
    successBtn: '',
    action: () => {},
  });

  useEffect(() => {
    setState({
      isLoading: false,
      transactionIdToUnmatch: '',
      isDeleteConfirmDialogOpen: false,
    });
  }, [receipt.id]);

  const transactionPath = isCardholderApp
    ? cardholderPaths.transactions
    : generatePath(getPath('transactions'), {
        orgId: organization!.id,
      });
  const canUserViewTransactions = isCardholderApp
    ? canUser('ch-transactions-page:visit')
    : canUser('transactions-page:visit');

  const handleReceiptMatching = () => {
    dispatch({
      type: 'SET_RECEIPT_INBOX_DATA',
      payload: {
        isDialogOpen: false,
        flow: ReceiptInboxMatchingFlow.selectedReceiptMatching,
        selectedTransationHasReceipt: false,
        receipt,
        thumbnail,
      },
    });
    dispatch({ type: 'TOGGLE_SIDEBAR', payload: false });

    // redirect user to Tx page, only if it's not open yet
    if (
      !matchPath(location.pathname, {
        path: cardholderPaths.transactions,
      }) &&
      !matchPath(location.pathname, {
        path: adminPaths.transactions,
      })
    )
      history.push(transactionPath);
  };

  const handleReceiptUnmatching = async () => {
    const hasSeveralMatchedTxs = transactions.length > 1;

    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      await api.unmatchReceiptFromTransaction(
        receipt.id,
        state.transactionIdToUnmatch
      );

      // flow when there were several txs matched (rear case)
      if (hasSeveralMatchedTxs) {
        if (!mounted.current || receiptIdRef.current !== receipt.id) return;
        onDetailsRefetch();
        setState((prevState) => ({
          ...prevState,
          transactionIdToUnmatch: '',
          isLoading: false,
        }));
        return;
      }
      // general flow - refetch list and unselect receipt
      onRefetch(receipt.id);
    } catch (error) {
      if (!mounted.current || receiptIdRef.current !== receipt.id) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        transactionIdToUnmatch: '',
        isLoading: false,
      }));
      logError(error);
    }
  };

  const handleMovingToTrash = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      await api.deleteReceiptsAutoMatching({ taskId: receipt.id });
      onRefetch(receipt.id);
      if (!mounted.current || receiptIdRef.current !== receipt.id) return;
      setState((prevState) => ({
        ...prevState,
        isDeleteConfirmDialogOpen: false,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || receiptIdRef.current !== receipt.id) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isDeleteConfirmDialogOpen: false,
        isLoading: false,
      }));
      logError(error);
    }
  };

  const handlDelete = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      await api.deleteReceiptsAutoMatchingTrash(
        { taskIds: [receipt.id] },
        isCardholderApp ? member.id : undefined,
        organization!.id
      );

      onRefetch(receipt.id);
      if (!mounted.current || receiptIdRef.current !== receipt.id) return;
      setState((prevState) => ({
        ...prevState,
        isDeleteConfirmDialogOpen: false,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || receiptIdRef.current !== receipt.id) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({
        ...prevState,
        isDeleteConfirmDialogOpen: false,
        isLoading: false,
      }));
      logError(error);
    }
  };

  const onDeleteConfirmationOpen = (
    key: 'moveToTrash' | 'deletePermanently'
  ) => {
    if (key === 'moveToTrash') {
      confirmDialogRef.current = {
        title: t('receiptInboxDetails.confirmMoveToTrashDialog.title'),
        description: t(
          'receiptInboxDetails.confirmMoveToTrashDialog.description'
        ),
        successBtn: t(
          'receiptInboxDetails.confirmMoveToTrashDialog.discardBtn'
        ),
        action: handleMovingToTrash,
      };
    } else {
      confirmDialogRef.current = {
        title: t('receiptInboxDetails.confirmPermanentlyDeleteDialog.title'),
        description: t(
          'receiptInboxDetails.confirmPermanentlyDeleteDialog.description'
        ),
        successBtn: t(
          'receiptInboxDetails.confirmPermanentlyDeleteDialog.discardBtn'
        ),
        action: handlDelete,
      };
    }

    setState((prevState) => ({
      ...prevState,
      isDeleteConfirmDialogOpen: true,
    }));
  };

  const renderContent = () => {
    // receipt matching flow (starts from Tx details)
    if (receiptInbox.flow === ReceiptInboxMatchingFlow.selectedTxMathing)
      return (
        <ActionBox icon={<ReceiptAddIcon />}>
          {receiptInbox.selectedTransationHasReceipt
            ? t('receiptInboxDetails.actionBoxText.txAlreadyHasReceipt')
            : t('receiptInboxDetails.actionBoxText.noTransactionFound')}
          <ActionBoxActions>
            <Button
              onClick={() =>
                dispatch({
                  type: 'SET_RECEIPT_INBOX_DATA',
                  payload: {
                    receipt,
                    thumbnail,
                    isDialogOpen: false,
                  },
                })
              }
            >
              {t('receiptInboxDetails.addToTxBtn')}
            </Button>
          </ActionBoxActions>
        </ActionBox>
      );

    // regular flow
    switch (receipt.status) {
      case ReceiptsAutoMatchingStatus.matchedAuto:
      case ReceiptsAutoMatchingStatus.matchedManually:
        return !canUser('receipt-inbox-matched-tx:view') ? null : (
          <Box pt={2} px={3} pb={1}>
            <Typography variant="overline" component="div">
              {t('receiptInboxDetails.matchedTxBlockTitle')}
            </Typography>

            {!!transactions.length && (
              <MatchedTransactionsList
                transactions={transactions}
                onUnmatch={(transactionId) =>
                  setState((prevState) => ({
                    ...prevState,
                    transactionIdToUnmatch: transactionId,
                  }))
                }
              />
            )}
          </Box>
        );

      case ReceiptsAutoMatchingStatus.processing:
        return (
          <ActionBox icon={<HourglassHighIcon />}>
            {t('receiptInboxDetails.actionBoxText.processing')}
          </ActionBox>
        );

      case ReceiptsAutoMatchingStatus.waitingForRetry:
      case ReceiptsAutoMatchingStatus.noTransactionFound:
        return (
          <ActionBox icon={<PlugsIcon />}>
            {t('receiptInboxDetails.actionBoxText.noTransactionFound')}
            <ActionBoxActions>
              <Button
                variant="outlined"
                disabled={state.isLoading}
                onClick={() => onDeleteConfirmationOpen('moveToTrash')}
                startIcon={<TrashIcon />}
              >
                {t('receiptInboxDetails.moveToTrashBtn')}
              </Button>
              <Button
                onClick={handleReceiptMatching}
                disabled={
                  !canUser('receipt-inbox:change') ||
                  !canUserViewTransactions ||
                  state.isLoading
                }
                startIcon={<FilesIcon />}
              >
                {t('receiptInboxDetails.matchBtn')}
              </Button>
            </ActionBoxActions>
          </ActionBox>
        );

      case ReceiptsAutoMatchingStatus.notReadable:
        return (
          <ActionBox icon={<ReceiptXIcon color="error" />}>
            {t('receiptInboxDetails.actionBoxText.notReadable')}
            <ActionBoxActions>
              <Button
                variant="outlined"
                disabled={state.isLoading}
                onClick={() => onDeleteConfirmationOpen('moveToTrash')}
                startIcon={<TrashIcon />}
              >
                {t('receiptInboxDetails.moveToTrashBtn')}
              </Button>
              <Button
                onClick={handleReceiptMatching}
                disabled={
                  !canUser('receipt-inbox:change') ||
                  !canUserViewTransactions ||
                  state.isLoading
                }
                startIcon={<FilesIcon />}
              >
                {t('receiptInboxDetails.matchBtn')}
              </Button>
            </ActionBoxActions>
          </ActionBox>
        );

      case ReceiptsAutoMatchingStatus.externalLink:
        return (
          <ActionBox icon={<ReceiptExternalLinkIcon color="info" />}>
            {t('receiptInboxDetails.actionBoxText.externalLink')}
            <ActionBoxActions>
              <Button
                disabled={state.isLoading}
                onClick={() => onDeleteConfirmationOpen('moveToTrash')}
                startIcon={<TrashIcon />}
              >
                {t('receiptInboxDetails.moveToTrashBtn')}
              </Button>
            </ActionBoxActions>
          </ActionBox>
        );

      case ReceiptsAutoMatchingStatus.deletedByCustomer:
      case ReceiptsAutoMatchingStatus.notPaidWithPliant:
      case ReceiptsAutoMatchingStatus.duplicate:
      case ReceiptsAutoMatchingStatus.alreadyExported:
      case ReceiptsAutoMatchingStatus.invalidAttachment:
        return (
          <ActionBox icon={<TrashIcon />}>
            {t('receiptInboxDetails.actionBoxText.willBeDeleted')}
            <ActionBoxActions>
              <Button
                color="error"
                disabled={state.isLoading}
                onClick={() => onDeleteConfirmationOpen('deletePermanently')}
              >
                {t('receiptInboxDetails.deletePermanentlyBtn')}
              </Button>
              <Button
                onClick={handleReceiptMatching}
                disabled={
                  !canUser('receipt-inbox:change') ||
                  !canUserViewTransactions ||
                  state.isLoading
                }
                startIcon={<FilesIcon />}
              >
                {t('receiptInboxDetails.matchBtn')}
              </Button>
            </ActionBoxActions>
          </ActionBox>
        );

      default:
        return null;
    }
  };

  const ElementToRender = renderContent();

  if (!ElementToRender) return null;

  return (
    <>
      <Box borderTop={(theme) => `1px solid ${theme.palette.divider}`}>
        {ElementToRender}
      </Box>

      <ConfirmDialog
        title={t('receiptInboxDetails.unmatchDialog.title')}
        loading={state.isLoading}
        description={
          <Trans
            i18nKey="receiptInboxDetails.unmatchDialog.description"
            components={{ br: <br /> }}
          />
        }
        confirmButtonProps={{
          children: t('receiptInboxDetails.unmatchBtn'),
        }}
        open={!!state.transactionIdToUnmatch}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            transactionIdToUnmatch: '',
          }))
        }
        onSuccess={handleReceiptUnmatching}
      />

      <ConfirmDialog
        open={state.isDeleteConfirmDialogOpen}
        title={confirmDialogRef.current.title}
        loading={state.isLoading}
        description={confirmDialogRef.current.description}
        confirmButtonProps={{
          color: 'error',
          children: confirmDialogRef.current.successBtn,
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isDeleteConfirmDialogOpen: false,
          }))
        }
        onSuccess={confirmDialogRef.current.action}
      />
    </>
  );
};

export default ReceiptActionBlock;
