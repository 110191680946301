import React, { ComponentType, CSSProperties } from 'react';
import { TFunction } from 'i18next';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import {
  ArrowsClockwiseIcon,
  Box,
  CalendarBlankIcon,
  CircularProgress,
  GearIcon,
  LockIcon,
  LockOpenIcon,
  PaletteIcon,
  PasswordIcon,
  SvgIconProps,
  Typography,
  UsersIcon,
  VerticalCardCheckIcon,
  VerticalCardExpiredIcon,
  VerticalCardReplacedIcon,
  VerticalCardRequestIcon,
  VerticalCardShippedIcon,
  VerticalCardTerminatedIcon,
} from 'elements';
import useIsAdminApp from 'hooks/useIsAdminApp';
import { CardHistoryEntry, CardHistoryEntryType } from 'services/constants';
import { getPath } from 'services/utils';
import CardControlEventTitle from './CardControlEventTitle';
import CardLimitChangeRequestEventTitle from './CardLimitChangeRequestEventTitle';
import CreatedIssuedRequestedEventTitle from './CreatedIssuedRequestedEventTitle';
import LimitChangedEventTitle from './LimitChangedEventTitle';
import ValidityChangedEventTitle from './ValidityChangedEventTitle';

const getIconByType = (
  type: CardHistoryEntryType
): ComponentType<SvgIconProps> => {
  switch (type) {
    case CardHistoryEntryType.created:
    case CardHistoryEntryType.issued:
    case CardHistoryEntryType.activated:
    case CardHistoryEntryType.requestApproved:
      return VerticalCardCheckIcon;
    case CardHistoryEntryType.requested:
    case CardHistoryEntryType.requestCancelled:
    case CardHistoryEntryType.requestRejected:
      return VerticalCardRequestIcon;
    case CardHistoryEntryType.expired:
      return VerticalCardExpiredIcon;
    case CardHistoryEntryType.locked:
    case CardHistoryEntryType.lockedPin:
      return LockIcon;
    case CardHistoryEntryType.unlocked:
      return LockOpenIcon;
    case CardHistoryEntryType.limitChanged:
    case CardHistoryEntryType.cardLimitChangeRequestCanceled:
    case CardHistoryEntryType.cardLimitChangeRequestCreated:
    case CardHistoryEntryType.cardLimitChangeRequestRejected:
    case CardHistoryEntryType.transactionLimitChanged:
    case CardHistoryEntryType.cardTransactionLimitChangeRequestCanceled:
    case CardHistoryEntryType.cardTransactionLimitChangeRequestCreated:
    case CardHistoryEntryType.cardTransactionLimitChangeRequestRejected:
      return ArrowsClockwiseIcon;
    case CardHistoryEntryType.pinChanged:
      return PasswordIcon;
    case CardHistoryEntryType.teamUpdated:
      return UsersIcon;
    case CardHistoryEntryType.issueFailed:
    case CardHistoryEntryType.terminatedLost:
    case CardHistoryEntryType.terminatedStolen:
    case CardHistoryEntryType.terminatedNotNeeded:
      return VerticalCardTerminatedIcon;
    case CardHistoryEntryType.replaced:
    case CardHistoryEntryType.replacedByCard:
      return VerticalCardReplacedIcon;
    case CardHistoryEntryType.shipped:
      return VerticalCardShippedIcon;
    case CardHistoryEntryType.cardColorChanged:
      return PaletteIcon;
    case CardHistoryEntryType.cardControlCategories:
    case CardHistoryEntryType.cardControlDates:
    case CardHistoryEntryType.cardControlMerchants:
    case CardHistoryEntryType.cardControlTimes:
    case CardHistoryEntryType.cardControlLocations:
      return GearIcon;
    case CardHistoryEntryType.validityFromChanged:
    case CardHistoryEntryType.validityToChanged:
    case CardHistoryEntryType.validityTimezoneChanged:
      return CalendarBlankIcon;
  }
};

const getTitle = (
  entry: CardHistoryEntry,
  t: TFunction,
  isAdminApp: boolean
) => {
  switch (entry.type) {
    case CardHistoryEntryType.teamUpdated:
      let customType: string = entry.type;
      if (!entry.prevTeamName) customType += '__first_time';
      else if (!entry.teamName) customType += '__unassigned';

      return (
        <Trans
          i18nKey={`cardHistory.titleByType.${customType}`}
          components={{ b: <b /> }}
          values={entry}
        />
      );
    case CardHistoryEntryType.created:
    case CardHistoryEntryType.issued:
    case CardHistoryEntryType.requested:
    case CardHistoryEntryType.requestApproved:
    case CardHistoryEntryType.requestCancelled:
    case CardHistoryEntryType.requestRejected:
      return <CreatedIssuedRequestedEventTitle entry={entry} />;
    case CardHistoryEntryType.limitChanged:
      return <LimitChangedEventTitle entry={entry} />;
    case CardHistoryEntryType.cardLimitChangeRequestCanceled:
    case CardHistoryEntryType.cardLimitChangeRequestCreated:
    case CardHistoryEntryType.cardLimitChangeRequestRejected:
      return <CardLimitChangeRequestEventTitle entry={entry} />;
    case CardHistoryEntryType.cardTransactionLimitChangeRequestCanceled:
    case CardHistoryEntryType.cardTransactionLimitChangeRequestCreated:
    case CardHistoryEntryType.cardTransactionLimitChangeRequestRejected:
      return (
        <Trans
          i18nKey={`cardHistory.titleByType.${entry.type}`}
          components={{
            b: <b />,
            requestedTransactionLimit: (
              <FormatMoney value={entry.requestedTransactionLimit!} />
            ),
            transactionLimit: <FormatMoney value={entry.transactionLimit} />,
          }}
        />
      );
    case CardHistoryEntryType.replaced:
      return (
        <Trans
          i18nKey={`cardHistory.titleByType.${entry.type}`}
          components={{
            a: isAdminApp ? (
              <Link
                to={generatePath(getPath('cardDetails'), {
                  orgId: entry.organizationId,
                  cardId: entry.replacementCardId!,
                })}
              />
            ) : (
              <span />
            ),
          }}
          values={{
            refNum: entry.replacementCardRefNum,
          }}
        />
      );
    case CardHistoryEntryType.replacedByCard:
      return (
        <Trans
          i18nKey={`cardHistory.titleByType.${entry.type}`}
          components={{
            a: (
              <Link
                to={generatePath(getPath('cardDetails'), {
                  orgId: entry.organizationId,
                  cardId: entry.replacementCardId!,
                })}
              />
            ),
          }}
          values={{
            reason: t(
              `cardHistory.replaceCardReason.${entry.cardType}.${entry.replacementCardReason}`
            ),
            refNum: entry.replacementCardRefNum,
          }}
        />
      );
    case CardHistoryEntryType.shipped:
    case CardHistoryEntryType.cardColorChanged:
      return t(`cardHistory.titleByType.${entry.type}`);
    case CardHistoryEntryType.cardControlCategories:
    case CardHistoryEntryType.cardControlDates:
    case CardHistoryEntryType.cardControlMerchants:
    case CardHistoryEntryType.cardControlTimes:
    case CardHistoryEntryType.cardControlLocations:
      return <CardControlEventTitle entry={entry} />;
    case CardHistoryEntryType.validityFromChanged:
    case CardHistoryEntryType.validityToChanged:
    case CardHistoryEntryType.validityTimezoneChanged:
      return <ValidityChangedEventTitle entry={entry} />;
    case CardHistoryEntryType.issueFailed:
      return <Trans i18nKey={`cardHistory.titleByType.${entry.type}`} />;
    default:
      return (
        <Trans
          i18nKey={`cardHistory.titleByType.${entry.type}`}
          components={{
            b: <b />,
            prevTransactionLimit: (
              <FormatMoney value={entry.prevTransactionLimit!} />
            ),
            transactionLimit: <FormatMoney value={entry.transactionLimit} />,
          }}
        />
      );
  }
};

const getInfo = (entry: CardHistoryEntry) => {
  switch (entry.type) {
    case CardHistoryEntryType.shipped:
      return moment(entry.initiatedAt).format('D MMM YYYY');

    default:
      return (
        <>
          {moment(entry.initiatedAt).format('D MMM YYYY, HH:mm')}{' '}
          {entry.initiatorName}
        </>
      );
  }
};

interface Props {
  data: CardHistoryEntry[];
  index: number;
  style: CSSProperties;
}

const Entry = ({ data, index, style }: Props) => {
  const isAdminApp = useIsAdminApp();
  const { t } = useTranslation();
  const entry = data[index];

  if (!entry)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={style}
      >
        <CircularProgress size="small" />
      </Box>
    );

  const Icon = getIconByType(entry.type);

  return (
    <Box display="flex" alignItems="center" pr={4.5} pl={3} style={style}>
      <Icon sx={{ mr: 2, color: 'text.secondary' }} />
      <div>
        <Typography variant="body2">
          {getTitle(entry, t, isAdminApp)}
        </Typography>
        <Typography variant="caption" component="div" color="text.secondary">
          {getInfo(entry)}
        </Typography>
      </div>
    </Box>
  );
};

export default Entry;
