import { isAxiosError } from 'axios';
import { NetworkErrorCode } from 'services/constants';

export * from './getFileNameFromHeader';

const arrayBufferToBase64 = (arrayBuffer: ArrayBuffer) => {
  let binary = '';
  const bytes = new Uint8Array(arrayBuffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

export const transformArrayBufferToString = (
  arrayBuffer: any,
  headers: any
) => {
  const mimetype = headers['content-type'];
  const b64encoded = arrayBufferToBase64(arrayBuffer);
  return ('data:' + mimetype + ';base64,' + b64encoded) as string;
};

export const getNetworkErrorCode = (error: unknown) => {
  let errorCode = null;
  if (isAxiosError(error) && error.response?.data?.errorCode) {
    errorCode = error.response.data.errorCode as NetworkErrorCode;
  }
  return errorCode;
};
