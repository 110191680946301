import { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogProps, DialogTitle, withDialogWrapper } from 'elements';
import useSnackbar from 'hooks/useSnackbar';
import { PaymentMethod, STRIPE_PUBLIC_KEY } from 'services/constants';
import Step1 from './Step1';
import Step2 from './Step2';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (paymentMethod: PaymentMethod) => void;
}

interface State {
  step: 1 | 2;
  secret: string | null;
}

const AddCardDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    step: 1,
    secret: null,
  });

  const goToNextStep = async (secret: string) => {
    setState((prevState) => ({
      ...prevState,
      secret,
      step: 2,
    }));
  };

  const onAddCardSuccess = (paymentMethod: PaymentMethod) => {
    setState((prevState) => ({
      ...prevState,
      addCardDialogOpen: false,
    }));
    onSuccess(paymentMethod);
    enqueueSnackbar(
      t('profilePage.privatePaymentCard.messages.cardAddedSuccess'),
      {
        variant: 'success',
      }
    );
  };

  return (
    <Dialog {...props}>
      <DialogTitle>
        {t('profilePage.privatePaymentCard.addCardDialog.title')}
      </DialogTitle>
      {state.step === 1 && (
        <Step1 onClose={props.onClose} onSuccess={goToNextStep} />
      )}
      {state.step === 2 && state.secret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: state.secret,
            appearance: {
              variables: {
                fontFamily: 'MaisonNeue, sans-serif',
              },
            },
          }}
        >
          <Step2 onClose={props.onClose} onSuccess={onAddCardSuccess} />
        </Elements>
      )}
    </Dialog>
  );
};

export default withDialogWrapper<Props>(AddCardDialog);
