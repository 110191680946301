import React from 'react';
import { useTranslation } from 'react-i18next';
import RestrictionBadge from 'domains/card/components/CardControlIndicator/RestrictionBadge';
import { Tooltip, Typography } from 'elements';
import { CardTimeControl } from 'services/constants';
import { Header, StyledChip, Wrapper } from './style';

interface Props {
  control: CardTimeControl | null;
}

const CardTimeControlIndicator = ({ control }: Props) => {
  const { t } = useTranslation();

  if (!control || !control.values.length) return null;

  const { restriction, type, values, offset } = control;

  return (
    <Wrapper>
      <Header>
        <Typography variant="subtitle2">
          {t(`cardControlIndicator.time.titles.${type}`)}
        </Typography>
        <RestrictionBadge restriction={restriction} />
      </Header>

      <Tooltip title={`${values[0]} (${offset})`}>
        <StyledChip label={`${values[0]} (${offset})`} />
      </Tooltip>
    </Wrapper>
  );
};

export default CardTimeControlIndicator;
