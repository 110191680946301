import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import NothingFound from 'components/NothingFound';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import { getDocumentUrl } from 'domains/document/utils';
import {
  OnboardingTaskActions,
  TaskTitle,
} from 'domains/onboarding/components';
import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  AccountGroup,
  DocumentGenericType,
  DocumentsByGenericTypeMap,
  OnboardingItemStatus,
  OnboardingTaskTerms,
  PartnerIds,
  PartnerIdType,
  TaskNavigationPropsBase,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

const REFETCH_INTERVAL = 1000;

const renderCheckboxLabel = (
  documents: DocumentsByGenericTypeMap,
  genericType: DocumentGenericType,
  partnerId: PartnerIdType,
  hasPliantPaymentsLtdTacs: boolean
) => {
  const doc = documents[genericType][0];

  const getTranslationKey = () => {
    if (
      hasPliantPaymentsLtdTacs &&
      genericType === DocumentGenericType.bankTermsAndConditions
    ) {
      return 'orgOnboardingTermsTask.labels.BANK_TERMS_AND_CONDITIONS_PLIANT_PAYMENTS_LTD';
    }

    if (partnerId === PartnerIds.commerzbank) {
      return `orgOnboardingTermsTask.labels.${genericType}_COMMERZBANK`;
    }

    return `orgOnboardingTermsTask.labels.${genericType}`;
  };

  return (
    <Trans
      i18nKey={getTranslationKey()}
      components={{
        a: (
          <Link
            color="inherit"
            href={getDocumentUrl(doc.documentName)}
            target="_blank"
            rel="noopener noreferrer"
          />
        ),
      }}
      values={doc}
    />
  );
};

enum TacDocName {
  platform = 'platform',
  cardOpen = 'cardOpen',
  cardIssue = 'cardIssue',
}

export interface Props extends TaskNavigationPropsBase {
  task: OnboardingTaskTerms;
  onUpdate: (newTask: OnboardingTaskTerms) => void;
}

const TermsAndConditionsTask = ({
  isReadOnly,
  task: taskFromTasksResponse,
  taskNavigationItems,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const {
    state: { organization, defaultCardAccount, documents },
  } = useGlobalState();
  const [refetchInterval, setRefetchInterval] = useState(0);
  const { useUpdateTerms, useGetOnboardingTermsTask } = useTanstackQuery();
  const {
    data: { acceptedByCurrentUser, fullyAccepted },
  } = taskFromTasksResponse;

  // in this particular task we need to fire API calls more often and update
  // app state accordingly
  const { data: task, isFetching, error } = useGetOnboardingTermsTask(
    organization!.id,
    taskFromTasksResponse.id,
    {
      refetchInterval,
    }
  );

  const { mutate: orgTermsMutate } = useUpdateTerms({
    onSuccess: () => {
      if (!mounted.current) return;
      setRefetchInterval(REFETCH_INTERVAL);
    },
    onError: (error) => {
      if (!mounted.current) return;
      formik.setSubmitting(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    },
  });

  useEffect(() => {
    // update tasks and sections, when task is accepted by user (async on BE)
    if (refetchInterval && task && task.data.acceptedByCurrentUser.value)
      onUpdate(task);
  }, [task?.data.acceptedByCurrentUser.value]);

  useEffect(() => {
    if (error) {
      formik.setSubmitting(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  }, [error]);

  const hasVgBankTacs = [AccountGroup.vgTpml, AccountGroup.vgPliantOy].includes(
    defaultCardAccount!.accountGroup.value
  );
  const hasPliantPaymentsLtdTacs = [AccountGroup.bcTpl].includes(
    defaultCardAccount!.accountGroup.value
  );

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      platform: acceptedByCurrentUser.value || false,
      cardOpen:
        (hasVgBankTacs && acceptedByCurrentUser.value) ||
        (hasPliantPaymentsLtdTacs && acceptedByCurrentUser.value) ||
        false,
      cardIssue: acceptedByCurrentUser.value || false,
    },
    onSubmit: () => {
      orgTermsMutate({
        organizationId: organization!.id,
        taskId: task?.id || '',
      });
    },
  });

  const areInputsEmpty =
    !formik.values.platform ||
    !formik.values.cardIssue ||
    (hasVgBankTacs && !formik.values.cardOpen) ||
    (hasPliantPaymentsLtdTacs && !formik.values.cardOpen);

  return (
    <>
      <PageTitle pt={0} title={<TaskTitle task={taskFromTasksResponse} />} />
      <Typography variant="body2" mb={5}>
        {t('orgOnboardingTermsTask.description')}
      </Typography>

      {task?.data && (
        <>
          {task.status === OnboardingItemStatus.requiresAction &&
            !acceptedByCurrentUser.value &&
            (!canUser('onboarding-step-tac:pass') ||
              !acceptedByCurrentUser.editable) && (
              <Alert severity="warning" sx={{ mb: 3 }}>
                <AlertTitle>
                  {t('orgOnboardingTermsTask.legalAlert.title')}
                </AlertTitle>
                {t('orgOnboardingTermsTask.legalAlert.description')}
              </Alert>
            )}

          {task.status === OnboardingItemStatus.requiresAction &&
            acceptedByCurrentUser.value &&
            !acceptedByCurrentUser.editable &&
            !fullyAccepted.value && (
              <Alert severity="warning" sx={{ mb: 3 }}>
                <AlertTitle>
                  {t('orgOnboardingTermsTask.secondConfirmationAlert.title')}
                </AlertTitle>
                {t(
                  'orgOnboardingTermsTask.secondConfirmationAlert.description'
                )}
              </Alert>
            )}

          {documents ? (
            <>
              {[
                {
                  name: TacDocName.platform,
                  docGenericType:
                    DocumentGenericType.platformTermsAndConditions,
                },
                ...(hasVgBankTacs || hasPliantPaymentsLtdTacs
                  ? [
                      {
                        name: TacDocName.cardOpen,
                        docGenericType:
                          DocumentGenericType.bankTermsAndConditions,
                      },
                    ]
                  : []),
                {
                  name: TacDocName.cardIssue,
                  docGenericType: DocumentGenericType.issuerTermsAndConditions,
                },
              ].map((item) => (
                <FormControlLabel
                  key={item.name}
                  disabled={
                    !canUser('onboarding-step-tac:pass') ||
                    isReadOnly ||
                    !acceptedByCurrentUser.editable
                  }
                  checked={formik.values[item.name]}
                  control={<Checkbox />}
                  onChange={(_, checked) =>
                    formik.setFieldValue(item.name, checked)
                  }
                  label={renderCheckboxLabel(
                    documents,
                    item.docGenericType,
                    organization!.partnerId,
                    hasPliantPaymentsLtdTacs
                  )}
                />
              ))}
            </>
          ) : (
            <WidgetError />
          )}

          <Box mt={4}>
            <OnboardingTaskActions
              isReadOnly={
                !canUser('onboarding-step-tac:pass') ||
                isReadOnly ||
                !acceptedByCurrentUser.editable
              }
              taskNavigationItems={taskNavigationItems}
              disabled={
                formik.isSubmitting ||
                areInputsEmpty ||
                (task.status !== OnboardingItemStatus.requiresAction &&
                  !formik.dirty)
              }
              onSuccess={formik.handleSubmit}
            />
          </Box>
        </>
      )}

      <LoaderWithOverlay loading={formik.isSubmitting || isFetching} />

      {!task && !isFetching && <NothingFound />}
    </>
  );
};

export default TermsAndConditionsTask;
