import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { adminPaths } from './paths';

interface OnboardingRedirectProps {
  children: React.ReactNode;
}

const OnboardingRedirect = ({ children }: OnboardingRedirectProps) => {
  const { isOrgInOnboarding } = useIsOrgInOnboarding();

  return isOrgInOnboarding ? (
    <Redirect to={adminPaths.onboarding} />
  ) : (
    <Fragment>{children}</Fragment>
  );
};

export default OnboardingRedirect;
