import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { getDocumentName } from 'domains/document/utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  DownloadSimpleIcon,
  Link,
  LoaderWithOverlay,
  StatusBadge,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Document,
  DocumentGenericType,
  DocumentsByGenericTypeMap,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { downloadPdfFile, getGenericErrorMsg } from 'services/utils';

const getDocumentDetails = (
  document: Document,
  genericType: DocumentGenericType
) => {
  const date = moment(document.version).format('D MMMM YYYY');
  if (
    [
      DocumentGenericType.platformDataPrivacyPolicyMobile,
      DocumentGenericType.platformDataPrivacyPolicyWeb,
      DocumentGenericType.platformTermsAndConditions,
    ].includes(genericType)
  ) {
    return date;
  }
  return `${date} (${document.counterparty})`;
};

interface Props extends DialogProps {
  onClose: () => void;
}

const ArchivedDocumentsDialog = (props: Props) => {
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    state: { organization, defaultCardAccount },
  } = useGlobalState();
  const [
    documentsByGenericType,
    setDocumentsByGenericType,
  ] = useState<DocumentsByGenericTypeMap | null>(null);

  const getData = async () => {
    try {
      const data = await api.getPublicDocuments(organization!.id, false);
      if (!mounted.current) return;
      setDocumentsByGenericType(data.documents);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const openPdfDoc = (id: string, name: string) => async () => {
    try {
      const data = await api.getPublicDocumentContentOld(id);
      downloadPdfFile(name, data);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{t('archivedDocumentsDialog.title')}</DialogTitle>
      <DialogContent sx={{ minHeight: 400 }}>
        {documentsByGenericType &&
          [
            DocumentGenericType.platformTermsAndConditions,
            DocumentGenericType.platformDataPrivacyPolicyWeb,
            DocumentGenericType.platformDataPrivacyPolicyMobile,
            DocumentGenericType.bankTermsAndConditions,
            DocumentGenericType.bankDataPrivacyPolicy,
            DocumentGenericType.issuerTermsAndConditions,
            DocumentGenericType.issuerDataPrivacyPolicy,
            DocumentGenericType.insuranceTerms,
          ].map((genericType) => {
            return documentsByGenericType[genericType] ? (
              <Box key={genericType} mb={4}>
                <Typography variant="subtitle2" mb={1}>
                  {getDocumentName(
                    null,
                    genericType,
                    organization!.partnerId,
                    defaultCardAccount!.accountGroup.value
                  )}
                </Typography>

                {documentsByGenericType[genericType].map((document, index) => (
                  <Box key={index} display="flex" alignItems="center">
                    <DownloadSimpleIcon
                      fontSize="small"
                      sx={{ color: 'text.secondary' }}
                    />
                    <Link
                      component="button"
                      variant="body2"
                      onClick={openPdfDoc(
                        document.documentId,
                        document.documentName
                      )}
                      sx={{ mx: 1 }}
                    >
                      {getDocumentDetails(document, genericType)}
                    </Link>
                    {index === 0 && (
                      <StatusBadge
                        color="success"
                        label={t('archivedDocumentsDialog.currentVersion_v2')}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            ) : null;
          })}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{t('common.button.close')}</Button>
      </DialogActions>
      <LoaderWithOverlay loading={!documentsByGenericType} />
    </Dialog>
  );
};

export default withDialogWrapper(ArchivedDocumentsDialog);
