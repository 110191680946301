import {
  ComponentType,
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useGlobalState } from 'context/GlobalState';
import { env } from 'env';
import useIdTokenCustomData from 'hooks/useIdTokenCustomData';
import { logError } from 'services/monitoring';
import { parseJsonIfValid } from 'services/utils';
import { MemberSseEventTarget } from './types';

const MAX_RETRIES = 10;
const MAX_RETRY_INTERVAL = 1000 * 60 * 2;

const baseURL = env.NODE_ENV === 'development' ? '/api' : env.REACT_APP_API_URL;

const memberSse = new EventTarget() as MemberSseEventTarget;

const MemberSseContext = createContext<{
  memberSse: MemberSseEventTarget;
  error: boolean;
}>({
  memberSse,
  error: false,
});

export const useMemberSse = () => useContext(MemberSseContext);

// @ts-ignore
window.sse_errors = [];

export const withMemberSse = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element | null => {
  const { getAccessTokenSilently } = useAuth0();
  const { memberId } = useIdTokenCustomData();
  const {
    state: { featureModules },
  } = useGlobalState();
  const [error, setError] = useState(false);
  const retries = useRef(0);
  const contextValue = useMemo(() => ({ memberSse, error }), [error]);

  const connectToServer = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      await fetchEventSource(
        `${baseURL}/notifications/sse/member/${memberId}`,
        {
          openWhenHidden: true,

          headers: { Authorization: `Bearer ${accessToken}` },

          async onopen(response) {
            console.log('SSE open event:', response);
            if (response.ok) {
              retries.current = 0;
              setError(false);
            } else {
              setError(true);
              throw new Error(
                `Could not establish an SSE connection: status = ${response.status}`
              );
            }
          },

          onmessage(message) {
            memberSse.dispatchEvent(
              new CustomEvent(message.event, {
                detail: parseJsonIfValid(message.data),
              })
            );
          },

          onclose() {
            console.log('SSE close event from server:');
            throw new Error('SSE connection closed by a server');
          },

          onerror(error) {
            console.log('SSE onerror:', error);
            // The fetch-event-source automatic reconnection does not work
            // because of our access token TTL, which is set to 5 minutes.
            // Always rethrow here to handle a reconnection in the catch statement.
            throw error;
          },
        }
      );
    } catch (error) {
      console.log('SSE catch:', error);
      // @ts-ignore
      window.sse_errors.push(error);
      if (retries.current < MAX_RETRIES) {
        const retryInterval = 1000 * 2 ** retries.current;
        setTimeout(() => {
          retries.current++;
          connectToServer();
        }, Math.min(retryInterval, MAX_RETRY_INTERVAL));
      }
      logError(error);
    }
  };

  useEffect(() => {
    if (featureModules.SECURITY_KEY_AUTHENTICATION) {
      connectToServer();
    }
  }, []);

  return (
    <MemberSseContext.Provider value={contextValue}>
      <Component {...props} />
    </MemberSseContext.Provider>
  );
};

export * from './types';
