import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { Box, Button, Typography } from 'elements';
import { PageToast } from 'layout';

interface Props {
  count: number;
  totalCount: number;
  isDisabled: boolean;
  backLink: string;
  onSuccess?: () => void;
}

const OnboardingFloatingToast = ({
  count,
  totalCount,
  isDisabled,
  backLink,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  return (
    <>
      <PageToast width="lg" paperProps={{ elevation: 4 }}>
        <Typography component="div" variant="subtitle1" mr="auto" pr={1} my={1}>
          {t('orgOnboardingSectionPage.toast.completedCountText', {
            count,
            total: totalCount,
          })}
        </Typography>

        <Box flexShrink={0} display="flex">
          <Button variant="text" onClick={() => history.push(backLink)}>
            {t('orgOnboardingSectionPage.toast.backBtn')}
          </Button>

          {onSuccess && (
            <Button
              sx={{ ml: 1 }}
              disabled={isDisabled}
              onClick={() => setIsConfirmDialogOpen(true)}
            >
              {t('orgOnboardingSectionPage.toast.submitBtn')}
            </Button>
          )}
        </Box>
      </PageToast>

      {onSuccess && (
        <ConfirmDialog
          open={isConfirmDialogOpen}
          title={t('orgOnboardingSectionPage.confirmDialog.title')}
          description={t('orgOnboardingSectionPage.confirmDialog.description')}
          confirmButtonProps={{
            children: t('common.button.confirm'),
          }}
          onSuccess={() => {
            setIsConfirmDialogOpen(false);
            onSuccess();
          }}
          onClose={() => setIsConfirmDialogOpen(false)}
        />
      )}
    </>
  );
};

export default OnboardingFloatingToast;
