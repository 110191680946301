import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Co2EmissionBadge } from 'components/Badge';
import { CardIconWithDetails } from 'domains/card/components';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  IntegrationCell,
  MerchantCell,
  TransactionAmountCell,
  TransactionDisputeIcon,
  TransactionMissingReceiptCell,
  TransactionReviewStatusIcon,
} from 'domains/transaction/components';
import { Box, drawerPlaceholderColumn, GridColDef } from 'elements';
import { Transaction } from 'services/constants';
import { getFullName } from 'services/utils';

const useColumns = () => {
  const { t, i18n } = useTranslation();
  const getCardAccountName = useCardAccountNameGetter();

  return useMemo(() => {
    const columns: GridColDef<Transaction>[] = [
      {
        headerName: t('transactionsPage.merchant'),
        field: 'merchantName',
        sortable: false,
        flex: 1.5,
        minWidth: 60,
        renderCell: ({ row, api }) => (
          <Box
            overflow="hidden"
            data-intercom-target={
              api.getRowIdFromRowIndex(0) === row.transactionId
                ? 'transactions-table-first-row'
                : undefined
            }
          >
            <MerchantCell transaction={row} showReceiptIcon showCommentIcon />
          </Box>
        ),
      },
      {
        headerName: '',
        field: 'receiptNeeded',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        flex: 0.2,
        renderCell: ({ row }) => (
          <TransactionMissingReceiptCell transaction={row} />
        ),
      },
      {
        headerName: '',
        field: 'disputed',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        flex: 0.2,
        renderCell: ({ row }) =>
          row.disputed ? <TransactionDisputeIcon /> : null,
      },
      {
        headerName: t('transactionsPage.date'),
        field: 'createdAt',
        sortable: false,
        flex: 0.7,
        valueGetter: ({ row }) => moment(row.createdAt).format('D MMM YYYY'),
      },
      {
        headerName: t('transactionsPage.card'),
        field: 'cardType',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <CardIconWithDetails
            cardConfig={row.cardConfig}
            cardDesignId={row.cardDesignId}
            cardRefNum={row.cardRefNum}
            cardName={row.cardName}
            showCardNameTooltip
          />
        ),
      },
      {
        headerName: t('transactionsPage.member'),
        field: 'memberFirstName',
        flex: 0.8,
        sortable: false,
        valueGetter: ({ row }) =>
          getFullName(row.memberFirstName, row.memberLastName) || '-',
      },
      {
        headerName: t('transactionsPage.account'),
        field: 'cardAccountName',
        flex: 0.8,
        sortable: false,
        valueGetter: ({ row }) => getCardAccountName(row.cardAccountId),
      },
      {
        headerName: t('transactionsPage.emission'),
        field: 'emission',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        flex: 0.6,
        renderCell: ({ row }) =>
          row.emission ? (
            <Co2EmissionBadge unit="kg" value={row.emission} />
          ) : null,
      },
      {
        headerName: t('transactionsPage.amount'),
        field: 'transactionAmount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        flex: 0.8,
        renderCell: ({ row }) => (
          <TransactionAmountCell
            transaction={row}
            bookingDate={row.bookingDate}
          />
        ),
      },
      {
        headerName: t('transactionsPage.review'),
        field: 'review',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        flex: 0.4,
        minWidth: 80,
        renderCell: ({ row }) => (
          <TransactionReviewStatusIcon status={row.reviewStatus} />
        ),
      },
      {
        headerName: t('transactionsPage.export'),
        field: '_integration_',
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        flex: 0.4,
        minWidth: 80,
        renderCell: ({ row }) => (
          <IntegrationCell
            status={row.status}
            exportStatus={row.exportStatus}
            integrationType={row.integrationType}
          />
        ),
      },
      drawerPlaceholderColumn,
    ];

    return columns;
  }, [i18n.language, getCardAccountName]);
};

export default useColumns;
