import { useAuth0 } from '@auth0/auth0-react';
import { JWT_CLAIM_NAMESPACE } from 'services/constants';

const useIdTokenCustomData = () => {
  const user = useAuth0().user!;

  // These typings are not correct because for internal users only the roles claim is present
  const memberId: string = user[`${JWT_CLAIM_NAMESPACE}/memberId`];
  const organizationId: string = user[`${JWT_CLAIM_NAMESPACE}/organizationId`];
  const organizationName: string =
    user[`${JWT_CLAIM_NAMESPACE}/organizationName`];
  const partnerCompanyId: string =
    user[`${JWT_CLAIM_NAMESPACE}/partnerCompanyId`];
  const roles: string[] = user[`${JWT_CLAIM_NAMESPACE}/roles`];
  const lang: string = user[`${JWT_CLAIM_NAMESPACE}/lang`];

  return {
    memberId,
    organizationId,
    partnerCompanyId,
    organizationName,
    roles,
    lang,
  };
};

export default useIdTokenCustomData;
