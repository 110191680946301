import { ComponentType, FC, useMemo } from 'react';
import { ThemeProvider } from '@mui/material';
import { Helmet } from 'react-helmet';
import { createPartnerTheme } from 'components/App/style/themeMui5';
import { useGlobalState } from 'context/GlobalState';
import { env } from 'env';

const withTheme = <P extends object>(Component: ComponentType<P>): FC<P> => (
  props: P
): JSX.Element | null => {
  const assetsFolderPath = {
    development: `${env.REACT_APP_ASSETS_URL}/images/dev`,
    staging: `${env.REACT_APP_ASSETS_URL}/images/staging`,
    prod: `${env.REACT_APP_ASSETS_URL}/images/v2`,
  }[env.REACT_APP_TARGET_ENV];

  const {
    state: { themeId },
  } = useGlobalState();
  const theme = useMemo(() => createPartnerTheme(themeId), [themeId]);

  return (
    <ThemeProvider theme={theme}>
      {/* TODO: update favicons and name*/}
      <Helmet>
        <link
          type="image/x-icon"
          rel="shortcut icon"
          href={`${assetsFolderPath}/favicon.ico`}
        />
        <link
          type="image/png"
          sizes="16x16"
          rel="icon"
          href={`${assetsFolderPath}/favicon-16x16.png`}
        />
        <link
          type="image/png"
          sizes="32x32"
          rel="icon"
          href={`${assetsFolderPath}/favicon-32x32.png`}
        />
        <link
          type="image/png"
          sizes="96x96"
          rel="icon"
          href={`${assetsFolderPath}/favicon-96x96.png`}
        />
        <title>Portal</title>
      </Helmet>
      <Component {...props} />
    </ThemeProvider>
  );
};
export default withTheme;
