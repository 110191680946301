import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import {
  SettlementStatusBadge,
  VssTypeBadge,
} from 'domains/billing/components';
import {
  Chip,
  drawerPlaceholderColumn,
  GridColDef,
  gridUtils,
  VssMissingIcon,
} from 'elements';
import { ProgramSettlement } from 'services/constants';
import CountCell from './CountCell';
import CustomMoneyCell from './CustomMoneyCell';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<ProgramSettlement>[] = [
      {
        headerName: t('int.programSettlementsPage.reportDate'),
        field: 'createdAt',
        flex: 120,
        minWidth: 120,
        valueGetter: ({ row }) => row.reportDate,
      },
      {
        headerName: t('int.programSettlementsPage.accountGroup'),
        field: 'accountGroup',
        flex: 115,
        minWidth: 128,
        renderCell: ({ row }) => (
          <Chip label={t(`accountGroup.${row.accountGroup}`)} size="small" />
        ),
        sortable: false,
      },
      {
        headerName: t('int.programSettlementsPage.service'),
        field: 'vssType',
        flex: 80,
        minWidth: 84,
        renderCell: ({ value }) => <VssTypeBadge value={value} />,
        sortable: false,
      },
      {
        headerName: t('int.programSettlementsPage.currency'),
        field: 'currency',
        flex: 80,
        minWidth: 84,
        sortable: false,
      },
      {
        headerName: t('int.programSettlementsPage.sre'),
        field: 'sreId',
        flex: 100,
        minWidth: 105,
        sortable: false,
      },
      {
        headerName: t('int.programSettlementsPage.count'),
        field: 'transactionsCount',
        flex: 60,
        minWidth: 62,
        renderCell: ({ row }) => (
          <CountCell
            status={row.status}
            transactionsCount={row.transactionsCount}
            matchedTransactionCount={row.matchedTransactionCount}
          />
        ),
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.programSettlementsPage.amount'),
        field: 'settlementAmount',
        flex: 140,
        minWidth: 146,
        renderCell: ({ row }) => (
          <CustomMoneyCell
            status={row.status}
            primary={row.settlementAmount}
            secondary={row.matchedTotalTransactionAmount}
          />
        ),
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.programSettlementsPage.fees'),
        field: 'feesAmount',
        flex: 140,
        minWidth: 146,
        renderCell: ({ row }) => (
          <CustomMoneyCell
            status={row.status}
            primary={row.feesAmount}
            secondary={row.matchedTotalFeeAmount}
          />
        ),
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.programSettlementsPage.charges'),
        field: 'chargesAmount',
        flex: 130,
        minWidth: 136,
        renderCell: ({ row }) => (
          <FormatMoney value={row.chargesAmount} fractionalPart />
        ),
        headerAlign: 'right',
        align: 'right',
      },
      {
        headerName: t('int.programSettlementsPage.state'),
        field: 'status',
        headerAlign: 'right',
        align: 'right',
        flex: 110,
        minWidth: 115,
        renderCell: ({ row }) => <SettlementStatusBadge status={row.status} />,
        sortable: false,
      },
      {
        headerName: '',
        field: 'vssFileName',
        sortable: false,
        width: 44,
        minWidth: 40,
        renderCell: ({ row }) =>
          row.vssFileName ? null : <VssMissingIcon color="error" />,
      },
      drawerPlaceholderColumn,
    ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language]);
};

export default useColumns;
