import { useGlobalState } from 'context/GlobalState';
import CustomFields from 'domains/transaction/pages/TransactionDetailsPage/CustomFields';
import ProjectSelect from 'domains/transaction/pages/TransactionDetailsPage/ProjectSelect';
import SubCategoriesAutocomplete from 'domains/transaction/pages/TransactionDetailsPage/SubCategoriesAutocomplete';
import VatRatesSelect from 'domains/transaction/pages/TransactionDetailsPage/VatRatesSelect';
import { Box } from 'elements';
import {
  AccountingTransaction,
  MerchantCategory,
  Project,
  ProjectTransactionInfo,
  Transaction,
  TransactionStatus,
  UpdateAccountingTransactionsPayload,
  UpdateAccTxnCustomFieldPayload,
} from 'services/constants';
import AccountingTransactionItem from './AccountingTransactionItem';
import SplitAccountingTransactions from './SplitAccountingTransactions';

interface Props {
  isExportPage: boolean;
  isEditing: boolean;
  isEditDisabled: boolean;
  transaction: Transaction;
  accountingTransactions: AccountingTransaction[];
  transactionFieldsHidden: boolean;
  onToggleEdit: (isEditing: boolean) => void;
  onUpdateAccountingTransactions: (
    accountingTransactions: UpdateAccountingTransactionsPayload
  ) => void;
  onUpdateSubcategory: (
    category: MerchantCategory,
    subcategoryId: string | null,
    accountingTransaction: AccountingTransaction
  ) => void;
  onUpdateVatRate: (id: string, data: { vatRateId: string | null }) => void;
  onUpdateProject: (
    id: string,
    data: {
      projectId: string | null;
      projectName: string | null;
      costUnit: string | null;
    },
    transaction: { project: ProjectTransactionInfo }
  ) => void;
  onUpdateCustomField: (
    id: string,
    data: UpdateAccTxnCustomFieldPayload,
    createNewField: boolean
  ) => void;
}

const AccountingTransactions = ({
  isExportPage,
  isEditing,
  isEditDisabled,
  transaction,
  accountingTransactions,
  transactionFieldsHidden,
  onToggleEdit,
  onUpdateAccountingTransactions,
  onUpdateSubcategory,
  onUpdateVatRate,
  onUpdateProject,
  onUpdateCustomField,
}: Props) => {
  const {
    state: { featureModules },
  } = useGlobalState();

  const accountingFeaturesEnabled = featureModules.ACCOUNTING_FEATURES;

  const onProjectChange = (
    accountingTransaction: AccountingTransaction,
    project: Project | null
  ) =>
    onUpdateProject(
      accountingTransaction.id,
      {
        projectId: project?.id || null,
        projectName: project?.name || null,
        costUnit: project?.costUnit || null,
      },
      {
        project: project
          ? {
              id: project.id,
              name: project.name,
              costUnit: project.costUnit,
            }
          : {
              id: null,
              name: null,
              costUnit: null,
            },
      }
    );

  if (isEditing) {
    return (
      <Box mb={4}>
        <SplitAccountingTransactions
          transaction={transaction}
          accountingTransactions={accountingTransactions}
          onUpdate={onUpdateAccountingTransactions}
          onCancel={() => onToggleEdit(false)}
        />
      </Box>
    );
  }

  if (accountingTransactions.length === 1) {
    return (
      <>
        <SubCategoriesAutocomplete
          isAutoMatched={accountingTransactions[0].subcategoryAutomatched}
          organizationId={transaction.organizationId}
          merchantId={transaction.merchant?.id}
          hideSubcategoryNotSelected={
            transaction.status === TransactionStatus.declined
          }
          isExportPage={isExportPage}
          selectedSubcategoryIdProp={accountingTransactions[0].subcategoryId}
          selectedCategoryProp={accountingTransactions[0].category}
          onChange={(category, subcategoryId) =>
            onUpdateSubcategory(
              category!,
              subcategoryId,
              accountingTransactions[0]
            )
          }
          disabled={isEditDisabled || !accountingFeaturesEnabled}
        />

        {!transactionFieldsHidden && accountingFeaturesEnabled && (
          <VatRatesSelect
            isAutoMatched={accountingTransactions[0].vatRateAutomatched}
            organizationId={transaction.organizationId}
            merchantId={transaction.merchant?.id}
            isExportPage={isExportPage}
            accountingTransaction={accountingTransactions[0]}
            onChange={(vatRate) =>
              onUpdateVatRate(accountingTransactions[0].id, {
                vatRateId: vatRate?.id || null,
              })
            }
            disabled={isEditDisabled}
          />
        )}
        {!transactionFieldsHidden && accountingFeaturesEnabled && (
          <ProjectSelect
            isExportPage={isExportPage}
            transaction={transaction}
            accountingTransaction={accountingTransactions[0]}
            onChange={(project) =>
              onProjectChange(accountingTransactions[0], project)
            }
            disabled={isEditDisabled}
          />
        )}

        <CustomFields
          isExportPage={isExportPage}
          fields={accountingTransactions[0].txnCustomFieldIdToField}
          disabled={isEditDisabled}
          organizationId={transaction.organizationId}
          cardId={transaction.cardId}
          onUpdate={(data, createNewField) =>
            onUpdateCustomField(
              accountingTransactions[0].id,
              data,
              createNewField
            )
          }
        />
      </>
    );
  }

  return (
    <Box mb={4}>
      {accountingTransactions.map((item) => (
        <AccountingTransactionItem key={item.id} accountingTransaction={item}>
          <SubCategoriesAutocomplete
            isAutoMatched={item.subcategoryAutomatched}
            organizationId={transaction.organizationId}
            merchantId={transaction.merchant?.id}
            hideSubcategoryNotSelected={
              transaction.status === TransactionStatus.declined
            }
            isExportPage={isExportPage}
            selectedSubcategoryIdProp={item.subcategoryId}
            selectedCategoryProp={item.category}
            onChange={(category, subcategoryId) =>
              onUpdateSubcategory(category!, subcategoryId, item)
            }
            disabled={isEditDisabled || !accountingFeaturesEnabled}
          />

          {!transactionFieldsHidden && accountingFeaturesEnabled && (
            <VatRatesSelect
              isAutoMatched={item.vatRateAutomatched}
              organizationId={transaction.organizationId}
              merchantId={transaction.merchant?.id}
              isExportPage={isExportPage}
              accountingTransaction={item}
              onChange={(vatRate) =>
                onUpdateVatRate(item.id, { vatRateId: vatRate?.id || null })
              }
              disabled={isEditDisabled}
            />
          )}
          {!transactionFieldsHidden && accountingFeaturesEnabled && (
            <ProjectSelect
              isExportPage={isExportPage}
              transaction={transaction}
              accountingTransaction={item}
              onChange={(project) => onProjectChange(item, project)}
              disabled={isEditDisabled}
            />
          )}

          <CustomFields
            isExportPage={isExportPage}
            fields={item.txnCustomFieldIdToField}
            disabled={isEditDisabled}
            organizationId={transaction.organizationId}
            cardId={transaction.cardId}
            onUpdate={(data, createNewField) =>
              onUpdateCustomField(item.id, data, createNewField)
            }
          />
        </AccountingTransactionItem>
      ))}
    </Box>
  );
};

export default AccountingTransactions;
