import { useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  PencilSimpleIcon,
  PlusIcon,
  Select,
} from 'elements';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { AccountGroup, CardAccountStatus } from 'services/constants';
import { useCanUser } from 'services/rbac';
import AssignAccountDialog from './AssignAccountDialog';

interface Props {
  cardAccountId: string;
  onCardAccountIdChange: (cardAccountId: string) => void;
}

const ProcessingAccountInfo = ({
  cardAccountId,
  onCardAccountIdChange,
}: Props) => {
  const canUser = useCanUser();
  const { t } = useTranslation();
  const {
    state: { cardAccounts },
  } = useGlobalState();
  const getCardAccountName = useCardAccountNameGetter();
  const [
    isAssignAccountNumberDialogOpen,
    setIsAssignAccountNumberDialogOpen,
  ] = useState(false);
  const selectedCardAccount = cardAccounts.find(
    (ca) => ca.id === cardAccountId
  )!;

  const openCardAccounts = cardAccounts.filter((ca) =>
    [CardAccountStatus.active, CardAccountStatus.pending].includes(
      ca.status.value
    )
  );

  const { isOrgInOnboarding } = useIsOrgInOnboarding();

  const showAssignAccountNumberDialog = () =>
    setIsAssignAccountNumberDialogOpen(true);

  const closeAccountNumberDialog = () =>
    setIsAssignAccountNumberDialogOpen(false);

  const canSelectedCardAccountBeEdited =
    canUser('processing-account:change') &&
    [AccountGroup.vgPliantOy, AccountGroup.vgTpml].includes(
      selectedCardAccount.accountGroup.value
    );

  return (
    <>
      <Box marginLeft="auto" width="250px">
        <FormControl fullWidth>
          <InputLabel>{t('int.processingAccountPage.cardAccount')}</InputLabel>
          <Select
            disabled={openCardAccounts.length === 1}
            value={cardAccountId}
            onChange={(e) => onCardAccountIdChange(e.target.value)}
          >
            {openCardAccounts.map((account) => (
              <MenuItem value={account.id} key={account.id}>
                {getCardAccountName(account)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box
          alignItems="center"
          display="flex"
          minHeight="36px"
          ml={0.5}
          mt={0.5}
        >
          {!selectedCardAccount.iban.value && canSelectedCardAccountBeEdited && (
            <Button
              data-test-id="assign-processing-account-btn"
              onClick={showAssignAccountNumberDialog}
              startIcon={<PlusIcon />}
              variant="text"
            >
              {t('int.processingAccountPage.assignAccountBtn')}
            </Button>
          )}

          {selectedCardAccount.iban.value && (
            <>
              <Typography
                color="textSecondary"
                component="span"
                variant="subtitle2"
              >
                {selectedCardAccount.iban.value}
              </Typography>
              {canSelectedCardAccountBeEdited && isOrgInOnboarding && (
                <IconButton
                  size="small"
                  onClick={showAssignAccountNumberDialog}
                  sx={{ ml: 1 }}
                >
                  <PencilSimpleIcon />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Box>

      <AssignAccountDialog
        open={isAssignAccountNumberDialogOpen}
        onClose={closeAccountNumberDialog}
        onSuccess={closeAccountNumberDialog}
        cardAccount={selectedCardAccount}
      />
    </>
  );
};

export default ProcessingAccountInfo;
