import React, { useEffect } from 'react';
import { styled } from '@mui/material';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, NavLink } from 'react-router-dom';
import { internalOrgPaths } from 'components/App';
import { PageHeader, PageHeadingLine } from 'components/Page';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Box, Tooltip, Typography } from 'elements';
import useHeaderBorderOnScroll from 'hooks/useBorderOnScroll';
import useSnackbar from 'hooks/useSnackbar';
import {
  BillPaymentType,
  CardAccountCurrency,
  OrganizationAccountType,
  organizationOnboardingStatuses,
  OrganizationStatus,
} from 'services/constants';
import AccountTypeSection from './AccountTypeSection';
import AutomaticActivationToggle from './AutomaticActivationToggle';

const ButtonGroup = styled('div')`
  background-color: ${({ theme }) => theme.palette.grey[900]};
  border-radius: 8px;
  display: flex;
  padding: 1px;
  width: fit-content;
`;

const StyledNavLink = styled(NavLink)`
  border-radius: 7px;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 9px 24px;
  text-decoration: none;

  &.active {
    background-color: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.grey[900]};
    cursor: default;
    pointer-events: none;
  }
`;

interface Props {
  children: React.ReactNode;
}

const CreditAndCompliancePageHeader = ({ children }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { getHeaderStyle } = useHeaderBorderOnScroll();
  const {
    state: { organization, defaultCardAccount },
  } = useGlobalState();
  const {
    state: { isNonCustomer },
  } = useCreditAndComplianceContext();

  useEffect(() => {
    if (
      defaultCardAccount!.accountType.value ===
        OrganizationAccountType.credit &&
      defaultCardAccount!.currency.value !== CardAccountCurrency.EUR &&
      defaultCardAccount!.billPaymentTransferType.value ===
        BillPaymentType.directDebit
    ) {
      const snackbarKey = enqueueSnackbar(
        <Trans
          i18nKey="int.creditAndCompliancePageHeader.invalidBillPaymentTransferTypeWarning"
          components={{ b: <b />, br: <br /> }}
        />,
        { persist: true, variant: 'error' }
      );

      return () => closeSnackbar(snackbarKey);
    }
  }, [defaultCardAccount]);

  return (
    <PageHeader style={getHeaderStyle()}>
      <PageHeadingLine heading={t('int.creditAndCompliancePageHeader.title')}>
        {!isNonCustomer &&
          organizationOnboardingStatuses.includes(organization!.status) && (
            <AutomaticActivationToggle />
          )}
        {!isNonCustomer &&
          [OrganizationStatus.active].includes(organization!.status) && (
            <Box display="flex" alignItems="center" height="100%">
              <Typography variant="subtitle2" mr={0.5} color="textSecondary">
                {t('int.creditAndCompliancePageHeader.activatedAt')}:
              </Typography>
              <Tooltip
                title={moment(organization!.activatedAt).format(
                  'DD.MM.YYYY HH:mm'
                )}
              >
                <Typography variant="subtitle2">
                  {moment(organization!.activatedAt).format('DD.MM.YYYY')}
                </Typography>
              </Tooltip>
            </Box>
          )}
      </PageHeadingLine>

      <Box
        alignItems="flex-start"
        display="flex"
        justifyContent="space-between"
        marginBottom="20px"
      >
        <ButtonGroup>
          <StyledNavLink
            to={generatePath(internalOrgPaths.credit, {
              orgId: organization!.id,
            })}
          >
            {t('int.creditAndCompliancePageHeader.credit')}
          </StyledNavLink>
          {!isNonCustomer && (
            <StyledNavLink
              to={generatePath(internalOrgPaths.compliance, {
                orgId: organization!.id,
              })}
            >
              {t('int.creditAndCompliancePageHeader.compliance')}
            </StyledNavLink>
          )}
        </ButtonGroup>

        {!isNonCustomer && <AccountTypeSection />}
      </Box>

      <Box display="flex" marginLeft="-20px">
        {children}
      </Box>
    </PageHeader>
  );
};

export default CreditAndCompliancePageHeader;
