import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Typography } from 'elements';
import usePartnerName from 'hooks/usePartnerName';

const PartnerLegalDisclaimer = () => {
  const { t } = useTranslation();
  const partnerName = usePartnerName();
  const {
    state: { isComplianceRiskWhiteLabelApp },
  } = useGlobalState();

  return isComplianceRiskWhiteLabelApp ? (
    <Typography
      variant="caption2"
      component="div"
      maxWidth={840}
      m="auto"
      pt={3}
      mb={3}
      align="center"
      color="textSecondary"
    >
      {t('page.partnerLegalDisclaimer', { partnerName })}
    </Typography>
  ) : null;
};
export default PartnerLegalDisclaimer;
