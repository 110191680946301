import React, { useMemo, useState } from 'react';
import { Collapse, FormControlLabel, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import TimezoneSelect from 'domains/card/components/CardControlFormElement/TimezoneSelect';
import {
  StyledBox,
  StyledRadioGroup,
  SummaryFormControlLabel,
  Wrapper,
} from 'domains/card/components/CardControlFormElement/style';
import {
  decodeValuesToDates,
  encodeMonthlyValue,
  FIXED_DATE_FORMAT,
} from 'domains/card/utils';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Switch,
} from 'elements';
import {
  CardControlConfig,
  CardControlRestriction,
  cardControlRestrictions,
  CardDateControl,
  CardDateControlType,
  cardDateControlTypes,
  DEFAULT_TIMEZONE,
} from 'services/constants';
import { DayAndMonthRangePicker } from './DayAndMonthRangePicker';
import { DayRangePicker } from './DayRangePicker';
import { SplittedDateRangePicker } from './SplittedDateRangePicker';
import { WeekButton } from './style';

interface Dates {
  startDate: string | null;
  endDate: string | null;
}

export interface Props {
  configs: { [K in CardDateControlType]?: CardControlConfig };
  control: CardDateControl | null;
  onChange: (control: CardDateControl | null) => void;
  disabled?: boolean;
}

export const WEEK_DAYS = moment.weekdays().map((day) => day.toUpperCase());

const CardDateControlFormElement = ({
  configs,
  control,
  onChange,
  disabled = false,
}: Props) => {
  const { t } = useTranslation();
  const availableTypes = useMemo(
    () =>
      cardDateControlTypes.filter((item) =>
        Object.keys(configs).includes(item)
      ),
    [configs]
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(
    !!control?.values.length
  );
  const defaultControl = {
    restriction: CardControlRestriction.allowed,
    type: availableTypes.includes(CardDateControlType.monthly)
      ? CardDateControlType.monthly
      : availableTypes[0],
    values: [],
    timezone: DEFAULT_TIMEZONE,
  };

  const { restriction, type, values, timezone } = control || defaultControl;
  const formattedDates = decodeValuesToDates(type, values);

  /**
   *
   * Preselect all weekdays if restriction is changed to allowed
   * Unselect all weekdays if restriction is changed to blocked
   */
  const handleRestrictionChange = (restriction: CardControlRestriction) => {
    const weeklyValues =
      restriction === CardControlRestriction.allowed ? WEEK_DAYS : [];

    onChange({
      restriction,
      type,
      values: type === CardDateControlType.weekly ? weeklyValues : values,
      timezone,
    });
  };

  /**
   *
   * Preselect all weekdays if type is changed to weekly and restriction is allowed
   * Unselect all weekdays otherwise
   */
  const handleTypeChange = (type: CardDateControlType) => {
    onChange({
      restriction,
      type,
      values:
        type === CardDateControlType.weekly &&
        restriction === CardControlRestriction.allowed
          ? WEEK_DAYS
          : [],
      timezone,
    });
  };

  const handleDayAndMonthRangeChange = (from: string, to: string | null) => {
    onChange({
      restriction,
      type,
      values: to ? [`${from}-${to}`] : [`${from}-`],
      timezone,
    });
  };

  const handleDayRangeChange = (from: string, to: string | null) => {
    onChange({
      restriction,
      type,
      values: to
        ? [`${encodeMonthlyValue(from)}-${encodeMonthlyValue(to)}`]
        : [`${encodeMonthlyValue(from)}-`],
      timezone,
    });
  };

  const handleWeekDayClick = (index: string) => {
    const newWeekly = values.includes(index)
      ? values.filter((item) => item !== index)
      : [...values, index];

    onChange({
      restriction,
      type,
      values: newWeekly,
      timezone,
    });
  };

  if (!availableTypes.length) return null;

  return (
    <Wrapper>
      <SummaryFormControlLabel
        checked={isExpanded}
        onChange={(e, checked) => {
          onChange(checked ? defaultControl : null);
          setIsExpanded(checked);
        }}
        disabled={disabled}
        control={<Switch size="medium" />}
        label={
          <Typography variant="subtitle1">
            {t('cardControlFormElement.date.title')}
          </Typography>
        }
        labelPlacement="start"
      />
      <Typography variant="body2" color="textSecondary">
        {t('cardControlFormElement.date.description')}
      </Typography>
      <Collapse in={isExpanded}>
        <StyledRadioGroup
          row
          value={restriction}
          onChange={(e) =>
            handleRestrictionChange(e.target.value as CardControlRestriction)
          }
        >
          {cardControlRestrictions.map((item) => (
            <FormControlLabel
              key={item}
              value={item}
              disabled={disabled}
              control={<Radio />}
              label={
                <Typography variant="body1">
                  {t(`cardControlFormElement.restrictions.${item}`)}
                </Typography>
              }
            />
          ))}
        </StyledRadioGroup>
        <StyledBox>
          <FormControl fullWidth>
            <InputLabel>{t('cardControlFormElement.date.dateType')}</InputLabel>
            <Select
              value={type}
              onChange={(e) =>
                handleTypeChange(e.target.value as CardDateControlType)
              }
              renderValue={(value) =>
                t(`cardControlFormElement.date.types.${value}`)
              }
              disabled={availableTypes.length === 1 || disabled}
            >
              {availableTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  {t(`cardControlFormElement.date.types.${item}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box mt={1} mb={1}>
            {type === CardDateControlType.fixed && (
              <SplittedDateRangePicker
                testId="card-date-control-fixed-type-select"
                startDate={(formattedDates as Dates).startDate as Moment | null}
                endDate={(formattedDates as Dates).endDate as Moment | null}
                onDatesChange={({ startDate, endDate }) => {
                  onChange({
                    restriction,
                    type,
                    values: [
                      `${startDate?.format(
                        FIXED_DATE_FORMAT
                      )}-${endDate?.format(FIXED_DATE_FORMAT)}`,
                    ],
                    timezone,
                  });
                }}
              />
            )}

            {type === CardDateControlType.yearly && (
              <DayAndMonthRangePicker
                testId="card-date-control-yearly-type-select"
                startDate={(formattedDates as Dates).startDate as string | null}
                endDate={(formattedDates as Dates).endDate as string | null}
                onDatesChange={handleDayAndMonthRangeChange}
              />
            )}

            {type === CardDateControlType.monthly && (
              <DayRangePicker
                testId="card-date-control-monthly-type-select"
                startDay={(formattedDates as Dates).startDate as string | null}
                endDay={(formattedDates as Dates).endDate as string | null}
                onDatesChange={handleDayRangeChange}
              />
            )}

            {type === CardDateControlType.weekly && (
              <Box mt={1}>
                <Typography variant="body2" color="textSecondary">
                  {t(
                    `cardControlFormElement.date.${
                      restriction === CardControlRestriction.allowed
                        ? 'allowOn'
                        : 'blockOn'
                    }`
                  )}
                </Typography>
                <Box display="flex" mt={0.5}>
                  {WEEK_DAYS.map((item) => (
                    <WeekButton
                      key={item}
                      className={values.includes(item) ? 'active' : ''}
                      onClick={() => handleWeekDayClick(item)}
                    >
                      <Typography variant="body2">
                        {item.substring(0, 1)}
                      </Typography>
                    </WeekButton>
                  ))}
                </Box>
              </Box>
            )}
          </Box>

          <TimezoneSelect
            shouldFetchData={isExpanded}
            timezone={timezone}
            onChange={(timezone) =>
              onChange({
                restriction,
                type,
                values,
                timezone,
              })
            }
          />
        </StyledBox>
      </Collapse>
    </Wrapper>
  );
};

export default CardDateControlFormElement;
