import { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  OnboardingBreadcrumbs,
  OnboardingFloatingToast,
  TaskListItem,
} from 'domains/onboarding/components';
import { useGetGroupFromGroupId, useGetTasks } from 'domains/onboarding/hooks';
import {
  getNavigationPath,
  getOnboardingKeyTypeFromUrl,
} from 'domains/onboarding/utils';
import { PartnerLegalDisclaimer } from 'domains/partner/components';
import { List, LoaderWithOverlay } from 'elements';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, PageContent, PageHeader, PageTitle } from 'layout';
import {
  OnboardingItemStatus,
  OnboardingSectionType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { onboardingKeys } from 'services/network/queryKeys';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

// SECTION->GROUP->TASKS page
const OrganizationOnboardingGroupPage = () => {
  const history = useHistory();
  const canUser = useCanUser();
  const { sectionKey, groupId } = useParams<{
    sectionKey: string;
    groupId: string;
  }>();
  const currentGroup = useGetGroupFromGroupId();
  const currentSectionType = useMemo(
    () => getOnboardingKeyTypeFromUrl<OnboardingSectionType>(sectionKey),
    [sectionKey]
  );
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const {
    state: { organization },
  } = useGlobalState();
  const showPageError = useShowPageError();
  const {
    useGetOnboardingSections,
    useSubmitOnboardingTaskGroup,
  } = useTanstackQuery();

  const {
    data: sectionsData,
    isFetching: areSectionsLoading,
  } = useGetOnboardingSections(organization!.id);
  const sections = sectionsData?.sections || [];
  const currentSectionObject = sections.find(
    (item) => item.type === currentSectionType
  );

  const { tasks, areTasksLoading, tasksError } = useGetTasks(
    !!currentSectionObject?.tasks?.length
  );

  const {
    mutate: orgTaskGroupSubmitMutate,
    isLoading: isGroupSubmitting,
  } = useSubmitOnboardingTaskGroup({
    onSuccess: () => {
      if (!mounted.current) return;
      history.push(getNavigationPath(organization!.id, sectionKey));
      queryClient.invalidateQueries(
        onboardingKeys.groupTasks(organization!.id, groupId)
      );
      queryClient.invalidateQueries(
        onboardingKeys.tasks(organization!.id, currentSectionType)
      );
      queryClient.invalidateQueries(onboardingKeys.sections(organization!.id));
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    },
  });

  useEffect(() => {
    if (tasksError) {
      logError(tasksError);
      showPageError();
    }
  }, [tasksError]);

  const numberCompletedTasks = useMemo(
    () =>
      tasks.filter(
        (task) =>
          task.status === OnboardingItemStatus.completed ||
          task.status === OnboardingItemStatus.submitted
      ).length,
    [tasks]
  );

  const isFloatingToastVisible =
    !areTasksLoading &&
    !areSectionsLoading &&
    !!tasks.length &&
    !!currentGroup?.status &&
    [
      OnboardingItemStatus.completed,
      OnboardingItemStatus.requiresAction,
    ].includes(currentGroup.status);

  const nothingFound = !areTasksLoading && tasks.length === 0;

  return (
    <>
      <PageHeader>
        <ContentContainer mx="auto" size="sm">
          <OnboardingBreadcrumbs />

          <PageTitle title={currentGroup?.name || ''} />
        </ContentContainer>
      </PageHeader>

      <PageContent
        display="flex"
        flexDirection="column"
        pb={isFloatingToastVisible ? 20 : 0}
        position="static"
      >
        <ContentContainer
          mx="auto"
          size="sm"
          width="100%"
          flex={1}
          position={nothingFound ? 'relative' : 'static'}
        >
          {!!tasks.length && (
            <List>
              {tasks.map((task) => (
                <TaskListItem
                  key={task.id}
                  task={task}
                  groupId={groupId}
                  sectionKey={sectionKey}
                />
              ))}
            </List>
          )}

          {nothingFound && <NothingFound />}
        </ContentContainer>

        <PartnerLegalDisclaimer />

        {isFloatingToastVisible && (
          <OnboardingFloatingToast
            count={numberCompletedTasks}
            totalCount={tasks.length}
            onSuccess={() =>
              orgTaskGroupSubmitMutate({
                organizationId: organization!.id,
                taskGroupId: groupId,
              })
            }
            backLink={getNavigationPath(organization!.id, sectionKey)}
            isDisabled={
              !canUser('org-onboarding-section:submit') ||
              tasks.length !== numberCompletedTasks ||
              isGroupSubmitting
            }
          />
        )}

        <LoaderWithOverlay
          loading={areTasksLoading || areSectionsLoading || isGroupSubmitting}
        />
      </PageContent>
    </>
  );
};

export default OrganizationOnboardingGroupPage;
