import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { IssueCardDialog } from 'domains/card/dialogs';
import { MemberCards } from 'domains/member/components';
import { Alert, Box, Button, Link, Typography } from 'elements';
import { Card, MemberDetails, MemberStatus, Team } from 'services/constants';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';

interface Props {
  member: MemberDetails;
  cards: Card[] | null;
  managerTeam?: Team | undefined;
  onCardIssue: () => void;
  onRetry: () => Promise<void>;
}

const MemberDetailsCardsBlock = ({
  member,
  cards,
  managerTeam,
  onCardIssue,
  onRetry,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { requestCardForAnotherMemberEnabled } = useFlags();
  const {
    state: { isComplianceRiskWhiteLabelApp },
  } = useGlobalState();
  const [isIssueCardDialogOpen, setIsIssueCardDialogOpen] = useState(false);

  const canIssueCard =
    !isComplianceRiskWhiteLabelApp &&
    canUser('card:create', requestCardForAnotherMemberEnabled) &&
    canUser('card:create-for-member', member, managerTeam);

  return (
    <Box p={3} borderTop={(theme) => `1px solid ${theme.palette.divider}`}>
      <Typography variant="overline" component="div" py={0.5}>
        {t('memberDetailsPage.cards')}
      </Typography>

      {!!cards?.length && (
        <MemberCards
          cards={cards}
          orgId={member.organizationId}
          teamId={managerTeam?.id}
        />
      )}

      {cards?.length === 0 && (
        <Typography my={1} color="textSecondary">
          {member.status === MemberStatus.deactivated
            ? t('memberDetailsPage.noActiveCards')
            : t('memberDetailsPage.noCardsIssuedYet')}{' '}
          {canIssueCard && (
            <Link
              component="button"
              onClick={() => setIsIssueCardDialogOpen(true)}
            >
              {t('memberDetailsPage.issueCardBtn')}
            </Link>
          )}
        </Typography>
      )}

      {!cards && (
        <Alert
          sx={{ my: (theme) => theme.spacing(1) }}
          severity="error"
          action={
            <Button variant="text" color="error" size="small" onClick={onRetry}>
              {t('common.retry')}
            </Button>
          }
        >
          {t('errors.loadData')}
        </Alert>
      )}

      <IssueCardDialog
        open={isIssueCardDialogOpen}
        onClose={() => setIsIssueCardDialogOpen(false)}
        onSuccess={() => {
          setIsIssueCardDialogOpen(false);
          onCardIssue();
        }}
        member={member}
        managerTeam={managerTeam}
      />
    </Box>
  );
};

export default MemberDetailsCardsBlock;
