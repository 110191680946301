import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  OnboardingTaskActions,
  TaskTitle,
} from 'domains/onboarding/components';
import {
  Box,
  FormControlLabel,
  LoaderWithOverlay,
  Radio,
  RadioGroup,
  TextField,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  OnboardingItemStatus,
  OnboardingTaskVatId,
  TaskNavigationPropsBase,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg, validateVatIdByCountry } from 'services/utils';

interface FormValues {
  vatId: string;
  vatIdRequired: boolean;
}

export interface Props extends TaskNavigationPropsBase {
  task: OnboardingTaskVatId;
  onUpdate: (newTask: OnboardingTaskVatId) => void;
}

const VatIdTask = ({
  isReadOnly,
  task,
  taskNavigationItems,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const {
    data: { vatId, vatIdRequired },
  } = task;
  const { useUpdateVatId } = useTanstackQuery();
  const { mutate: orgVatIdMutate } = useUpdateVatId({
    onSuccess: (response) => {
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          organization: {
            ...organization!,
            vatId: response.data.vatId.value ?? '',
          },
        },
      });
      if (!mounted.current) return;
      onUpdate(response);
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      formik.setSubmitting(false);
    },
  });

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      vatId: vatId.value ?? '',
      vatIdRequired: vatIdRequired.value ?? false,
    },
    validate: (values) => {
      const errors: { [k in keyof FormValues]?: string } = {};
      if (values.vatIdRequired) {
        const vatIdError = validateVatIdByCountry(
          organization!.billingAddress.country,
          values.vatId.trim(),
          t
        );
        if (vatIdError) errors.vatId = vatIdError;
      }
      return errors;
    },
    onSubmit: (values) => {
      orgVatIdMutate({
        organizationId: organization!.id,
        taskId: task.id,
        data: {
          vatId: values.vatIdRequired ? values.vatId.trim() : '',
          vatIdRequired: values.vatIdRequired,
        },
      });
    },
  });

  const hasEmptyValues = formik.values.vatIdRequired && !formik.values.vatId;

  return (
    <>
      <PageTitle pt={0} pb={3} title={<TaskTitle task={task} />} />

      <RadioGroup
        value={formik.values.vatIdRequired}
        onChange={(_, value) =>
          formik.setFieldValue('vatIdRequired', value === 'true')
        }
      >
        <FormControlLabel
          disabled={
            isReadOnly || formik.isSubmitting || !vatIdRequired.editable
          }
          value={true}
          control={<Radio />}
          label={t('orgOnboardingVatIdTask.orgIsSubjectToVatLabel')}
        />

        <Box ml={3.5} mb={2}>
          {formik.values.vatIdRequired && (
            <TextField
              disabled={isReadOnly || formik.isSubmitting || !vatId.editable}
              label={t('orgOnboardingVatIdTask.vatIdLabel')}
              {...formik.getFieldProps('vatId')}
              error={!!formik.errors.vatId}
              helperText={formik.errors.vatId}
            />
          )}
        </Box>

        <FormControlLabel
          disabled={
            isReadOnly || formik.isSubmitting || !vatIdRequired.editable
          }
          value={false}
          control={<Radio />}
          label={t('orgOnboardingVatIdTask.orgIsNotSubjectToVatLabel')}
        />
      </RadioGroup>

      <Box mt={4}>
        <OnboardingTaskActions
          isReadOnly={isReadOnly || !vatId.editable}
          taskNavigationItems={taskNavigationItems}
          disabled={
            formik.isSubmitting ||
            (task.status !== OnboardingItemStatus.requiresAction &&
              !formik.dirty) ||
            hasEmptyValues
          }
          onSuccess={formik.handleSubmit}
        />
      </Box>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default VatIdTask;
