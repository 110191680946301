import { OnboardingSectionType } from 'services/constants';

// Query keys used for tanstack
export const onboardingKeys = {
  sections: (orgId: string) => ['getOnboardingSections', orgId] as const,
  tasks: (orgId: string, sectionType: OnboardingSectionType) =>
    ['getOnboardingTasks', orgId, sectionType] as const,
  task: (orgId: string, taskId: string) =>
    ['getOnboardingTask', orgId, taskId] as const,
  groupTasks: (orgId: string, groupId: string) =>
    ['getOnboardingGroupTasks', orgId, groupId] as const,
};
