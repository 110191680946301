import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  CaretDownIcon,
  MenuContainer,
  MenuItem,
} from 'elements';

interface Props extends ButtonGroupProps {
  title: string;
  onAdd?: () => void;
  onUpload?: () => void;
  onSync?: () => void;
  format?: string;
}

// Used for custom cases when user is allowed to add and/or upload
// new items manually or using CSV
export const AddNewItemMenu = ({
  title,
  onAdd,
  onUpload,
  onSync,
  size = 'medium',
  format = 'CSV',
  ...otherProps
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box ml="auto">
      <MenuContainer
        button={
          <ButtonGroup variant="contained" size={size} {...otherProps}>
            <Button>{title}</Button>
            <Button>
              <CaretDownIcon />
            </Button>
          </ButtonGroup>
        }
      >
        {onSync && (
          <MenuItem onClick={onSync}>
            {t('addSettingItemMenu.importSyncValuesTitle')}
          </MenuItem>
        )}
        {onAdd && (
          <MenuItem onClick={onAdd}>
            {t('addSettingItemMenu.addManualButtonTitle')}
          </MenuItem>
        )}
        {onUpload && (
          <MenuItem onClick={onUpload}>
            {t('addSettingItemMenu.uploadButtonTitle', { format })}
          </MenuItem>
        )}
      </MenuContainer>
    </Box>
  );
};
