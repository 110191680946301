import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  FormControl,
  ListItemIcon,
  MenuItem,
  ProjectorScreenIcon,
  Select,
  UserPlusIcon,
  UsersIcon,
} from 'elements';
import {
  AccountingItemStatus,
  Project,
  Team,
  TeamStatus,
} from 'services/constants';
import { ComponentType } from './NestedTrackingCategoryItems';

type SelectedType = 'new' | string;

interface Props {
  type: ComponentType;
  selected: SelectedType;
  disabledIds: string[];
  onChange: (selectedId: SelectedType) => void;
}

const getSelectedInstance = (
  type: ComponentType,
  items: Project[] | Team[],
  selected: SelectedType
) => {
  if (type === 'project')
    return (items as Project[]).find((item) => item.id === selected);
  return (items as Team[]).find((item) => item.id === selected);
};

// Select used for similar functionality - teams and projects dropdowns
const MappingInstanceSelect = ({
  type,
  selected,
  disabledIds,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { teams, projects },
  } = useGlobalState();

  const instance =
    type === 'project'
      ? projects?.filter(
          (item) =>
            item.status === AccountingItemStatus.active || item.id === selected
        ) || []
      : teams.filter(
          (item) =>
            item.teamStatus === TeamStatus.active || item.id === selected
        );

  const addNewLabel =
    type === 'project'
      ? t('codatSyncSetupDialog.costUnitsStep.createAsNewProject')
      : t('codatSyncSetupDialog.costCentersStep.createAsNewTeam');

  return (
    <FormControl sx={{ width: 200 }}>
      <Select
        value={selected}
        renderValue={(selectedValue) => {
          if (!selectedValue) return '';
          if (selectedValue === 'new') return addNewLabel;

          return (
            getSelectedInstance(type, instance, selectedValue as SelectedType)
              ?.name || ''
          );
        }}
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem value={'new'}>
          <ListItemIcon>
            {type === 'project' ? <ProjectorScreenIcon /> : <UserPlusIcon />}
          </ListItemIcon>
          {addNewLabel}
        </MenuItem>

        {instance.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            disabled={selected !== item.id && disabledIds.includes(item.id)}
          >
            <ListItemIcon>
              {type === 'project' ? <ProjectorScreenIcon /> : <UsersIcon />}
            </ListItemIcon>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MappingInstanceSelect;
