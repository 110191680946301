import { useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import useMounted from 'hooks/useMounted';

export const getReadableRemainingTime = (remainingTime: number) => {
  let minutes = Math.floor(remainingTime / 60);
  let seconds = remainingTime % 60;

  return `${minutes < 10 ? '0' + minutes : minutes}:${
    seconds < 10 ? '0' + seconds : seconds
  }`;
};

const useCountdownTimer = () => {
  const mounted = useMounted();
  const expiresAtMoment = useRef<Moment | null>(null);
  const [remainingTime, setRemainingTime] = useState<number | null>(null);

  const startTimer = (expiresAt: string) => {
    expiresAtMoment.current = moment(expiresAt);
    setRemainingTime(expiresAtMoment.current.diff(moment(), 'seconds'));

    let intervalId = setInterval(() => {
      if (!mounted.current) {
        clearInterval(intervalId);
        return;
      }

      const newRemainingTime = expiresAtMoment.current!.diff(
        moment(),
        'seconds'
      );

      if (newRemainingTime <= 0) {
        setRemainingTime(null);
        clearInterval(intervalId);
        expiresAtMoment.current = null;
      } else {
        setRemainingTime(newRemainingTime);
      }
    }, 1000);
  };

  return {
    remainingTime,
    startTimer,
  };
};

export default useCountdownTimer;
