import React from 'react';
import { adminPaths } from 'components/App/ExternalApp/paths';
import { Container, ContainerInner, MainContent } from 'components/App/style';
import { AdminMainHeader } from 'components/MainHeader';
import Sidebar from 'components/Sidebar';
import { useGlobalState } from 'context/GlobalState';
import { isPartnerBasedSource } from 'domains/partner/utils';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { PartnerWhitelabelLevel } from 'services/constants';
import { useCanUser } from 'services/rbac';

const AdminLayout = ({ children }: { children: React.ReactNode }) => {
  const canUser = useCanUser();
  const { isOrgInOnboarding } = useIsOrgInOnboarding();
  const {
    state: { organization, featureModules, partnerConfig },
  } = useGlobalState();
  const orgHasSource =
    !!organization && isPartnerBasedSource(organization.partnerId);

  const isEmbeddedPartnerPageUsed = !featureModules.INTEGRATIONS_PAGE;
  const showIntegrationsPage =
    canUser('partners:view') && orgHasSource && !isEmbeddedPartnerPageUsed;

  const onboardingPaths = {
    onboarding: adminPaths.onboarding,
    members: adminPaths.members,
    partner:
      canUser('partners:view') && orgHasSource && isEmbeddedPartnerPageUsed
        ? adminPaths.partner
        : undefined,
    settingsIntegrations: showIntegrationsPage
      ? adminPaths.settingsIntegrations
      : undefined,
    settings:
      showIntegrationsPage &&
      partnerConfig?.whitelabelLevel !== PartnerWhitelabelLevel.FULLY_WHITELABEL
        ? adminPaths.settings
        : undefined,
  };

  return (
    <Container>
      <Sidebar
        showOnboarding={isOrgInOnboarding}
        paths={isOrgInOnboarding ? onboardingPaths : adminPaths}
      />
      <ContainerInner>
        <AdminMainHeader />
        <MainContent data-intercom-target="page-content">
          {children}
        </MainContent>
      </ContainerInner>
    </Container>
  );
};

export default AdminLayout;
