import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { whiteLabeledPaths } from 'components/App/ExternalApp/paths';
import AppLogo from 'components/AppLogo';
import { useGlobalState } from 'context/GlobalState';
import {
  BankIcon,
  Box,
  BuildingsIcon,
  Button,
  ListItemIcon,
  ListItemText,
} from 'elements';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { useCanUser } from 'services/rbac';
import UserMenu from './UserMenu';
import { MenuSection, StyledListItemButton, StyledMainHeader } from './style';

const WhiteLabeledMainHeader = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { organization, partnerConfig },
  } = useGlobalState();
  const { isOrgInOnboarding } = useIsOrgInOnboarding();

  return (
    <StyledMainHeader navigation>
      <Box mr={2}>
        <AppLogo mode={theme.navigationMode} />
      </Box>
      <Box display="flex" alignItems="center" flexGrow={1}>
        {!isOrgInOnboarding && (
          <>
            <StyledListItemButton
              component={NavLink}
              to={whiteLabeledPaths.settingsOrganization}
              exact
            >
              <ListItemIcon>
                <BuildingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('header.nav.companyDetails')} />
            </StyledListItemButton>
            {canUser('bank-accounts-subpage:visit') && (
              <StyledListItemButton
                component={NavLink}
                to={`${whiteLabeledPaths.settingsOrganization}/bank-accounts`}
              >
                <ListItemIcon>
                  <BankIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t('header.nav.bankAccounts')} />
              </StyledListItemButton>
            )}
          </>
        )}
      </Box>
      <MenuSection>
        <UserMenu />
        {partnerConfig!.applicationLink && (
          <Button
            component="a"
            href={partnerConfig!.applicationLink}
            sx={{ ml: 3 }}
          >
            {t('header.backToPartnerAppButton', {
              name: organization!.partnerName,
            })}
          </Button>
        )}
      </MenuSection>
    </StyledMainHeader>
  );
};

export default WhiteLabeledMainHeader;
