import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { adminPaths } from 'components/App';
import {
  orgOnboardingPaths,
  partnerPaths,
} from 'components/App/ExternalApp/paths';
import AppLogo from 'components/AppLogo';
import { Button } from 'components/Button';
import { Radio } from 'components/Form';
import { LoaderWithOverlay } from 'components/Loader';
import {
  Paper,
  PaperDescription,
  PaperHeading,
  PaperHolder,
} from 'components/Paper';
import Tooltip, { CircleQuestionMarkIcon } from 'components/Tooltip';
import { useGlobalState } from 'context/GlobalState';
import LanguageSelect from 'domains/member/components/LanguageSelect';
import withPageConfig from 'hoc/withPageConfig';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  LegalRepresentativeType,
  MemberDetails,
  PartnerScope,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getJwtPayload, useCanUser } from 'services/rbac';
import { getGenericErrorMsg, history } from 'services/utils';
import { StyledFormControlLabel } from './style';

enum RadioValue {
  director = 'DIRECTOR',
  employee = 'EMPLOYEE',
  advisor = 'ADVISOR',
  sole = 'SOLE',
  joint = 'JOINT',
}

const OnboardingRolePage = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const canUser = useCanUser();
  const {
    dispatch,
    state: { member, partnerConfig },
  } = useGlobalState();
  const { isOrgInOnboarding, isOrgInOnboardingNew } = useIsOrgInOnboarding();
  const [screen, setScreen] = useState<'role' | 'legal-rep-type'>('role');
  const [value, setValue] = useState<null | RadioValue>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const updateLegalRepType = async (
    legalRepresentative: LegalRepresentativeType
  ) => {
    try {
      setIsLoading(true);
      await api.updateLegalRepType(member.id, {
        legalRepresentative,
        organizationId: (member as MemberDetails).organizationId,
      });
      const accessToken = await getAccessTokenSilently({
        ignoreCache: true,
      });
      const jwtPayload = getJwtPayload(accessToken);
      dispatch({ type: 'SET_JWT_PAYLOAD', payload: { jwtPayload } });
      dispatch({
        type: 'SET_USER_DATA',
        payload: { member: { ...member, legalRepresentative } },
      });

      if (
        canUser('partners:change') &&
        partnerConfig &&
        [PartnerScope.embeddedWallet, PartnerScope.fullyEmbedded].includes(
          partnerConfig.partnerScope
        )
      )
        history.replace(partnerPaths.partners);
      else
        history.replace(
          isOrgInOnboardingNew
            ? orgOnboardingPaths.orgOnboarding
            : adminPaths.onboarding
        );
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const selectRole = () => {
    if (value === RadioValue.director) setScreen('legal-rep-type');
    else updateLegalRepType(LegalRepresentativeType.none);
  };

  const selectLegalRepType = () => {
    const type =
      value === RadioValue.sole
        ? LegalRepresentativeType.sole
        : LegalRepresentativeType.joint;
    updateLegalRepType(type);
  };

  if (!isOrgInOnboarding) return <Redirect to={adminPaths.dashboard} />;

  if (member.legalRepresentative)
    return (
      <Redirect
        to={
          isOrgInOnboardingNew
            ? orgOnboardingPaths.orgOnboarding
            : adminPaths.onboarding
        }
      />
    );

  return (
    <PaperHolder>
      <Paper>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <AppLogo />
          <LanguageSelect />
        </Box>

        {screen === 'role' && (
          <>
            <PaperHeading>{t('onboardingRolePage.roleHeading')}</PaperHeading>
            <PaperDescription>
              {t('onboardingRolePage.roleDescription')}
            </PaperDescription>
            <RadioGroup
              value={value}
              onChange={(e) =>
                setValue((e.target as HTMLInputElement).value as RadioValue)
              }
            >
              <StyledFormControlLabel
                value={RadioValue.director}
                control={<Radio />}
                label={t('onboardingRolePage.director')}
              />
              <StyledFormControlLabel
                value={RadioValue.employee}
                control={<Radio />}
                label={t('onboardingRolePage.employee')}
              />
              <StyledFormControlLabel
                value={RadioValue.advisor}
                control={<Radio />}
                label={t('onboardingRolePage.advisor')}
              />
            </RadioGroup>
            <Box position="absolute" right="48px" bottom="48px">
              <Button
                disabled={!value}
                onClick={selectRole}
                data-test-id="select-role"
              >
                {t('common.button.continue')}
              </Button>
            </Box>
          </>
        )}

        {screen === 'legal-rep-type' && (
          <>
            <PaperHeading>
              {t('onboardingRolePage.legalRepHeading')}
            </PaperHeading>
            <RadioGroup
              value={value}
              onChange={(e) =>
                setValue((e.target as HTMLInputElement).value as RadioValue)
              }
            >
              <StyledFormControlLabel
                value={RadioValue.sole}
                control={<Radio />}
                label={
                  <Box component="span" display="flex" alignItems="center">
                    <span>{t('onboardingRolePage.sole')}</span>
                    <Tooltip
                      title={t('onboardingRolePage.soleTooltip') as string}
                    >
                      <CircleQuestionMarkIcon />
                    </Tooltip>
                  </Box>
                }
              />
              <StyledFormControlLabel
                value={RadioValue.joint}
                control={<Radio />}
                label={
                  <Box component="span" display="flex" alignItems="center">
                    <span>{t('onboardingRolePage.joint')}</span>
                    <Tooltip
                      title={t('onboardingRolePage.jointTooltip') as string}
                    >
                      <CircleQuestionMarkIcon />
                    </Tooltip>
                  </Box>
                }
              />
            </RadioGroup>
            <Box position="absolute" right="48px" bottom="48px">
              <Button
                variant="outlined"
                onClick={() => setScreen('role')}
                $m="0 16px"
              >
                {t('onboardingRolePage.back')}
              </Button>
              <Button
                disabled={value === RadioValue.director}
                onClick={selectLegalRepType}
                data-test-id="select-legal-rep-type"
              >
                {t('common.button.continue')}
              </Button>
            </Box>
          </>
        )}
        {isLoading && <LoaderWithOverlay size={32} thickness={3} />}
      </Paper>
    </PaperHolder>
  );
};

export default withPageConfig(OnboardingRolePage, {
  permission: 'onboarding-role-page:visit',
});
