import { useTranslation } from 'react-i18next';
import { isPartnerBasedSource } from 'domains/partner/utils';
import { Box, DataGridCell, StatusBadge } from 'elements';
import { PartnerIdType, PartnerName, PartnerStatus } from 'services/constants';

const PartnerCell = ({
  partnerName,
  partnerId,
  status,
}: {
  partnerName: PartnerName | null;
  partnerId: PartnerIdType;
  status: PartnerStatus;
}) => {
  const { t } = useTranslation();

  if (!isPartnerBasedSource(partnerId)) return null;

  return (
    <Box display="flex" alignItems="center" minWidth={0}>
      <DataGridCell>{partnerName || ''}</DataGridCell>
      {status === PartnerStatus.inactive && (
        <StatusBadge
          label={t('common.badge.archived')}
          color="default"
          sx={{ ml: 1 }}
        />
      )}
    </Box>
  );
};

export default PartnerCell;
