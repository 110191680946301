import { useEffect, useRef, useState } from 'react';
import lodashIntersection from 'lodash/intersection';
import { generatePath, useHistory } from 'react-router';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { internalOrgPaths, internalRootPaths } from 'components/App';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  CreateOrgDialog,
  CreateOrgManuallyDialog,
} from 'domains/organization/dialogs';
import {
  Box,
  DataGrid,
  GridSortModel,
  gridUtils,
  LoaderWithOverlay,
  useGridApiRef,
} from 'elements';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageTableContent } from 'layout';
import {
  AccountGroup,
  accountGroups,
  CardAccountCurrency,
  DEFAULT_PAGE_LIMIT,
  Organization,
  OrganizationStatus,
  organizationStatuses,
  PartialOrganization,
  Partner,
  PartnerIds,
  PartnerName,
  PartnerStatus,
  supportedCountries,
  SupportedCountry,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { isSortValid } from 'services/utils';
import Filters from './Filters';
import useColumns from './useColumns';

const defaultPartner = {
  partnerId: PartnerIds.pliant,
  status: PartnerStatus.active,
  name: PartnerName.pliant,
};

const getStatusApiParam = (status: string[]) => {
  if (!!status.length)
    return status.includes(OrganizationStatus.onboardingRiskReview)
      ? [...status, OrganizationStatus.onboardingRiskData].join()
      : status.join();
  return undefined;
};

const getQueryParams = (
  qs: string,
  allowedSortKeys: string[],
  existingPartners: Partner[] | null,
  cardAccountCurrencies: CardAccountCurrency[]
) => {
  const {
    accountGroup,
    country,
    currency,
    q,
    sort,
    partnerConfigId,
    status,
  } = Object.fromEntries(new URLSearchParams(qs).entries());

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '+name',
    q: q ? q.trim() : '',
    status: status
      ? lodashIntersection(status.split(','), organizationStatuses)
      : [],
    country:
      country && supportedCountries.includes(country as SupportedCountry)
        ? country
        : '',
    partnerConfigId:
      partnerConfigId &&
      existingPartners?.find((partner) => partner.partnerId === partnerConfigId)
        ? partnerConfigId
        : '',
    accountGroup:
      accountGroup && accountGroups.includes(accountGroup as AccountGroup)
        ? accountGroup
        : '',
    currency:
      currency &&
      cardAccountCurrencies.includes(currency as CardAccountCurrency)
        ? currency
        : '',
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

interface State {
  isLoading: boolean;
  organizations: PartialOrganization[];
  hasNextPage: boolean;
  selectedPartner: Partner | Pick<Partner, 'partnerId' | 'status' | 'name'>;
  selectedPartnerRevenueShare:
    | Partner
    | Pick<Partner, 'partnerId' | 'status' | 'name'>;
}

interface Props {
  isCreateOrgDialogOpen?: boolean;
  setIsCreateOrgDialogOpen?: (isOpen: boolean) => void;
}

const OrganizationsAndNcoSubPage = ({
  isCreateOrgDialogOpen = false,
  ...props
}: Props) => {
  const dataGridRef = useGridApiRef();
  const location = useLocation();
  const { path } = useRouteMatch();
  const isNonCustomerOrganizationsPage =
    path === internalRootPaths.nonCustomerOrganizations;
  const { columns, allowedSortKeys } = useColumns(
    isNonCustomerOrganizationsPage
  );
  const api = useImperativeApi();
  const mounted = useMounted();
  const history = useHistory();
  const {
    state: { partners, cardAccountCurrencies },
  } = useGlobalState();
  const { isPortalApp } = useCurrentApp();
  const showPageError = useShowPageError();
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(
    getQueryParams(
      location.search,
      allowedSortKeys,
      partners,
      cardAccountCurrencies
    )
  );
  const pageRef = useRef(0);

  const [state, setState] = useState<State>({
    isLoading: true,
    organizations: [],
    hasNextPage: false,
    selectedPartner: defaultPartner,
    selectedPartnerRevenueShare: defaultPartner,
  });
  const [
    isCreateOrgManuallyDialogOpen,
    setIsCreateOrgManuallyDialogOpen,
  ] = useState(false);
  const selectedFiltersCount =
    +!!paramsRef.current.country +
    (isNonCustomerOrganizationsPage
      ? 0
      : +!!paramsRef.current.status.length +
        +!!paramsRef.current.partnerConfigId +
        +!!paramsRef.current.accountGroup +
        +!!paramsRef.current.currency);

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      if (!isLoadMore) {
        setState((state) => ({
          ...state,
          isLoading: true,
        }));
      }
      const orgEndpoint = isPortalApp
        ? api.getPartnerOrganizations
        : api.getOrganizations;

      const {
        accountGroup,
        country,
        currency,
        q,
        sort,
        partnerConfigId,
        status,
      } = paramsRef.current;
      const { organizations, hasNextPage } = await orgEndpoint({
        page,
        limit,
        sort,
        q: q.length ? q : undefined,
        status: isNonCustomerOrganizationsPage
          ? OrganizationStatus.nonCustomer
          : (getStatusApiParam(status) as OrganizationStatus),
        country: country || undefined,
        currency: currency || undefined,
        accountGroup: (accountGroup as AccountGroup) || undefined,
        ...(!isNonCustomerOrganizationsPage && {
          partnerId: partnerConfigId || undefined,
        }),
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        organizations: isLoadMore
          ? [...prevState.organizations, ...organizations]
          : organizations,
        hasNextPage,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });

    paramsRef.current = getQueryParams(
      location.search,
      allowedSortKeys,
      partners,
      cardAccountCurrencies
    );
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const onOrgCreated = (organization: Organization) => {
    if (!props.setIsCreateOrgDialogOpen) return;
    props.setIsCreateOrgDialogOpen(false);
    history.push(
      generatePath(
        organization.status === OrganizationStatus.nonCustomer
          ? internalOrgPaths.creditAndCompliance
          : internalOrgPaths.dashboard,
        {
          orgId: organization.id,
        }
      )
    );
  };

  const handleSortModelChange = (sort: GridSortModel) => {
    if (state.isLoading || !state.organizations.length) return;
    setQueryParam('sort', gridUtils.getNewSortParam(sort));
  };

  return (
    <>
      <Box px={5} pb={2}>
        <Filters
          isNCOPage={isNonCustomerOrganizationsPage}
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
        />
      </Box>

      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid<PartialOrganization>
          apiRef={dataGridRef}
          getRowId={(row) => row.organizationId}
          disableMultipleRowSelection
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(paramsRef.current.sort),
            },
          }}
          onSortModelChange={handleSortModelChange}
          loading={state.isLoading}
          rows={state.organizations}
          columns={columns}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          onRowClick={({ row }) => {
            history.push(
              generatePath(
                row?.status === OrganizationStatus.nonCustomer
                  ? internalOrgPaths.creditAndCompliance
                  : internalOrgPaths.dashboard,
                {
                  orgId: row.organizationId,
                }
              )
            );
          }}
          slots={{
            noRowsOverlay: () => <NothingFound $top={0} $bottom={0} />,
            loadingOverlay: () => null,
          }}
        />
      </PageTableContent>

      <CreateOrgDialog
        open={isCreateOrgDialogOpen}
        isNonCustomerOrganizationsPage={isNonCustomerOrganizationsPage}
        isSourcePartnerSelected={
          state.selectedPartner.partnerId !== defaultPartner.partnerId ||
          state.selectedPartnerRevenueShare.partnerId !==
            defaultPartner.partnerId
        }
        selectedPartner={state.selectedPartner}
        selectedPartnerRevenueShare={state.selectedPartnerRevenueShare}
        onPartnerChange={(selectedPartner, selectedPartnerRevenueShare) =>
          setState((prevState) => ({
            ...prevState,
            selectedPartner,
            selectedPartnerRevenueShare,
          }))
        }
        onClose={() => {
          if (!props.setIsCreateOrgDialogOpen) return;
          props.setIsCreateOrgDialogOpen(false);
          setState((prevState) => ({
            ...prevState,
            selectedPartner: defaultPartner,
            selectedPartnerRevenueShare: defaultPartner,
          }));
        }}
        onSuccess={onOrgCreated}
        openManualOrgCreationDialog={() => {
          if (!props.setIsCreateOrgDialogOpen) return;
          props.setIsCreateOrgDialogOpen(false);
          setIsCreateOrgManuallyDialogOpen(true);
        }}
      />

      <CreateOrgManuallyDialog
        open={isCreateOrgManuallyDialogOpen}
        isNonCustomerOrganizationsPage={isNonCustomerOrganizationsPage}
        pliantSource={defaultPartner}
        selectedPartner={state.selectedPartner}
        selectedPartnerRevenueShare={state.selectedPartnerRevenueShare}
        onClose={() => {
          setIsCreateOrgManuallyDialogOpen(false);
          setState((prevState) => ({
            ...prevState,
            selectedPartnerId: defaultPartner,
            selectedPartnerRevenueShareId: defaultPartner,
          }));
        }}
        onSuccess={onOrgCreated}
      />
    </>
  );
};

export default OrganizationsAndNcoSubPage;
