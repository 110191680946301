import React, { useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import flowRight from 'lodash/flowRight';
import withFeatureModules from 'components/App/withFeatureModules';
import withLoader from 'components/App/withLoader';
import { useGlobalState } from 'context/GlobalState';
import { transformCardConfigs } from 'domains/card/utils';
import useIdTokenCustomData from 'hooks/useIdTokenCustomData';
import { DEFAULT_PAGE_LIMIT } from 'services/constants';
import { useFlags, withFeatureFlagsProvider } from 'services/featureflags';
import { withIntercom } from 'services/intercom';
import { withMemberSse } from 'services/memberSse';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser, withJwtPayload } from 'services/rbac';
import useSessionManager from './useSessionManager';
import with3dsBiometricAuthentication from './with3dsBiometricAuthentication';
import withInitialLogic from './withInitialLogic';
import withPartnerLogic from './withPartnerLogic';
import withTermsAndConditions from './withTermsAndConditions';

interface Props {
  children: React.ReactNode;
}

const UserData = ({ children }: Props) => {
  useSessionManager();
  const { requestCardForAnotherMemberEnabled } = useFlags();
  const {
    dispatch,
    state: { isLoading, error, isComplianceRiskWhiteLabelApp, featureModules },
  } = useGlobalState();
  const canUser = useCanUser();
  const { memberId, organizationId } = useIdTokenCustomData();
  const api = useImperativeApi();

  const getTeams = () => {
    if (!featureModules.TEAMS) return { teams: [], teamCount: 0 };

    if (canUser('team:get-all'))
      return api
        .getTeams({ organizationId })
        .then(({ teams }) => ({ teams, teamCount: teams.length }))
        .catch((error) => {
          logError(error);
          return { teams: [], teamCount: 0 };
        });

    if (canUser('team:manage'))
      return Promise.all([
        api.getTeams({ organizationId, memberId }).catch((error) => {
          logError(error);
          return { teams: [] };
        }),
        api.getTeamCount(organizationId).catch((error) => {
          logError(error);
          return { count: 0 };
        }),
      ]).then(([{ teams }, { count }]) => ({ teams, teamCount: count }));

    return api
      .getTeamCount(organizationId)
      .then(({ count }) => ({ teams: [], teamCount: count }))
      .catch((error) => {
        logError(error);
        return { teams: [], teamCount: 0 };
      });
  };

  const getAccountingData = async () => {
    const accountingSettings = await api.getAccountingSettings(organizationId);
    const [
      subcategories,
      vatRates,
      projects,
      receiptsSettings,
      { transactionCustomFields },
    ] = await Promise.all([
      accountingSettings.subcategoryEnabled
        ? api
            .getSubcategories(organizationId)
            .then((data) => data.subcategories)
            .catch((error) => {
              logError(error);
              return null;
            })
        : null,
      accountingSettings.vatRateEnabled
        ? api
            .getVatRates(organizationId)
            .then((data) => data.vatRates)
            .catch((error) => {
              logError(error);
              return null;
            })
        : null,
      accountingSettings.projectEnabled
        ? api
            .getProjects({ organizationId })
            .then((data) => data.projects)
            .catch((error) => {
              logError(error);
              return null;
            })
        : null,
      canUser('receipts-settings:view')
        ? api.getReceiptsSettings(organizationId).catch((error) => {
            logError(error);
            return null;
          })
        : null,
      featureModules.TRANSACTION_CUSTOM_FIELDS && canUser('custom-fields:view')
        ? api
            .getCustomFields({
              organizationId: organizationId,
              page: 0,
              limit: DEFAULT_PAGE_LIMIT,
            })
            .catch((error) => {
              logError(error);
              return { transactionCustomFields: [] };
            })
        : { transactionCustomFields: [] },
    ]);
    return {
      accountingSettings,
      subcategories,
      vatRates,
      projects,
      receiptsSettings,
      transactionCustomFields,
    };
  };

  const getPliantSpecificData = async () => {
    const [
      organizationIntegrations,
      { teams, teamCount },
      {
        accountingSettings,
        subcategories,
        vatRates,
        projects,
        receiptsSettings,
        transactionCustomFields,
      },
    ] = await Promise.all([
      canUser('org-integrations:view')
        ? api.getOrganizationIntegrations(organizationId).catch((error) => {
            logError(error);
            return null;
          })
        : null,
      getTeams(),
      getAccountingData(),
    ]);

    return {
      organizationIntegrations,
      teams,
      teamCount,
      accountingSettings,
      subcategories,
      vatRates,
      projects,
      receiptsSettings,
      transactionCustomFields,
    };
  };

  const getCardsData = async () => {
    const [
      { cardConfigs: cardConfigSettings },
      { cardDesigns },
    ] = await Promise.all([
      api
        .getCardConfigSettings(organizationId)
        .then((data) =>
          transformCardConfigs(data, requestCardForAnotherMemberEnabled)
        )
        .catch((error) => {
          logError(error);
          return { cardConfigs: [] };
        }),
      api.getCardDesigns(organizationId).catch((error) => {
        logError(error);
        return { cardDesigns: [] };
      }),
    ]);

    return { cardConfigSettings, cardDesigns };
  };

  const initialize = async () => {
    try {
      // basic api calls
      const [
        generalInfo,
        member,
        subscriptionPlan,
        { documents },
        unmatchedReceiptsCounts,
      ] = await Promise.all([
        api.getGeneralInfo(organizationId).catch((error) => {
          logError(error);
          return null;
        }),
        api.syncMfa(memberId),
        api.getSubscriptionPlanParams(organizationId).catch((error) => {
          logError(error);
          return null;
        }),
        api.getPublicDocuments(organizationId).catch((error) => {
          logError(error);
          return { documents: null };
        }),
        canUser('receipt-inbox:view')
          ? api.getReceiptAutoMatchingCount(organizationId).catch((error) => {
              logError(error);
              return { unmatchedTaskCount: 0, unmatchedTaskCountSelf: 0 };
            })
          : { unmatchedTaskCount: 0, unmatchedTaskCountSelf: 0 },
      ]);

      // pliant user specific api calls
      const [pliantSpecificData, cardData] = !isComplianceRiskWhiteLabelApp
        ? await Promise.all([getPliantSpecificData(), getCardsData()])
        : [undefined, undefined];

      if (generalInfo) {
        dispatch({
          type: 'SET_GENERAL_INFO',
          payload: { generalInfo },
        });
      }

      if (cardData) {
        dispatch({
          type: 'SET_CARD_CONFIG_SETTINGS',
          payload: { cardConfigSettings: cardData.cardConfigSettings },
        });
        dispatch({
          type: 'SET_CARD_DESIGNS',
          payload: { cardDesigns: cardData.cardDesigns },
        });
      }

      dispatch({
        type: 'SET_SUBSCRIPTION_PLAN_DATA',
        payload: { subscriptionPlan },
      });
      dispatch({ type: 'SET_DOCUMENTS', payload: { documents } });
      dispatch({
        type: 'SET_UNMATCHED_RECEIPTS_COUNT',
        payload: unmatchedReceiptsCounts,
      });

      dispatch({
        type: 'SET_USER_DATA',
        payload: {
          member,
          ...(pliantSpecificData
            ? {
                organizationIntegrations:
                  pliantSpecificData.organizationIntegrations,
                teams: pliantSpecificData.teams,
                teamCount: pliantSpecificData.teamCount,
                accountingSettings: pliantSpecificData.accountingSettings,
                subcategories: pliantSpecificData.subcategories,
                vatRates: pliantSpecificData.vatRates,
                projects: pliantSpecificData.projects,
                receiptsSettings: pliantSpecificData.receiptsSettings,
                transactionCustomFields:
                  pliantSpecificData.transactionCustomFields,
              }
            : {}),
        },
      });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: error });
      logError(error);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  if (isLoading) return null;
  if (error) return null;

  return <>{children}</>;
};

export default flowRight([
  withLoader,
  withAuthenticationRequired,
  withInitialLogic,
  withFeatureFlagsProvider,
  withJwtPayload,
  withTermsAndConditions,
  withFeatureModules,
  withPartnerLogic,
  withIntercom,
  withMemberSse,
  with3dsBiometricAuthentication,
])(UserData);
