import { camelCase } from 'lodash';
import { FileError, FileWithPath } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  FilesIcon,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TrashIcon,
  Typography,
  XIcon,
} from 'elements';
import { OnboardingDocumentFile } from 'services/constants';
import formatBytes from 'services/utils/formatBytes';

type SelectedFileProps = {
  version: 'selected';
  file: FileWithPath;
  isLoading: boolean;
  uploadingProgress?: number;
  disabled: boolean;
  onDelete: () => void;
};

interface UploadedFileProps {
  version: 'uploaded';
  file: OnboardingDocumentFile;
  isLoading: boolean;
  disabled: boolean;
  onDelete: () => void;
}

interface RejectedFileProps {
  version: 'error';
  errors: FileError[];
  file: FileWithPath;
  isLoading: boolean;
  disabled: boolean;
  maxFileSizeInBytes: number;
}

const FileRow = ({
  version,
  file,
  isLoading,
  disabled,
  ...props
}: UploadedFileProps | SelectedFileProps | RejectedFileProps) => {
  const { t } = useTranslation();

  return (
    <ListItem
      component={Paper}
      variant="outlined"
      sx={{ mt: 1 }}
      secondaryAction={
        !disabled &&
        'onDelete' in props && (
          <IconButton
            edge="end"
            aria-label="delete"
            disabled={isLoading}
            onClick={props.onDelete}
          >
            {version === 'uploaded' ? (
              <TrashIcon fontSize="small" />
            ) : (
              <XIcon fontSize="small" />
            )}
          </IconButton>
        )
      }
    >
      <ListItemAvatar>
        <Avatar variant="rounded">
          <FilesIcon />
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={file.name}
        secondary={formatBytes(
          version === 'uploaded' ? file.sizeInBytes : file.size
        )}
      />

      {isLoading &&
        version === 'selected' &&
        'uploadingProgress' in props &&
        typeof props.uploadingProgress === 'number' && (
          <Typography
            component="span"
            variant="body2"
            color="textSecondary"
          >{` (${props.uploadingProgress}%)`}</Typography>
        )}

      {version === 'error' && 'errors' in props && (
        <Typography color="error.main" variant="caption">
          {props.errors.map((e) => (
            <span key={e.code}>
              {t(`errors.${camelCase(e.code)}`, {
                maxFileSize: formatBytes(props.maxFileSizeInBytes),
              })}
            </span>
          ))}
        </Typography>
      )}
    </ListItem>
  );
};

export default FileRow;
