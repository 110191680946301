import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from 'elements';
import {
  CardLocationControl,
  CardLocationControlType,
} from 'services/constants';
import RestrictionBadge from './RestrictionBadge';
import { Header, StyledChip, Wrapper } from './style';

interface Props {
  control: CardLocationControl | null;
}

const CardLocationControlIndicator = ({ control }: Props) => {
  const { t } = useTranslation();

  if (!control || !control.values.length) return null;

  const { restriction, type, values, displayValues } = control;

  return (
    <Wrapper>
      <Header>
        <Typography variant="subtitle2">
          {t(`cardControlIndicator.location.titles.${type}`)}
        </Typography>
        <RestrictionBadge restriction={restriction} />
      </Header>

      {type === CardLocationControlType.postalCode &&
        values.map((item) => {
          return (
            <Tooltip key={item} title={item}>
              <StyledChip label={item} />
            </Tooltip>
          );
        })}

      {type === CardLocationControlType.region &&
        displayValues!.map((item) => (
          <Tooltip key={item.id} title={item.uiName}>
            <StyledChip label={item.uiName} />
          </Tooltip>
        ))}
    </Wrapper>
  );
};

export default memo(CardLocationControlIndicator);
