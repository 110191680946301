import { ComponentType, FC } from 'react';
import { Redirect } from 'react-router-dom';
import { adminPaths, partnerPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import {
  PartnerAuthStatus,
  PartnerScope,
  SKIP_PARTNER_AUTH,
} from 'services/constants';
import { useCanUser } from 'services/rbac';

const withOnboardingRedirection = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element => {
  const canUser = useCanUser();
  const {
    state: { member, partnerOrgAuthDetails, partnerConfig },
  } = useGlobalState();

  if (canUser('onboarding-role-page:visit') && !member.legalRepresentative)
    return <Redirect to={adminPaths.onboardingRole} />;

  if (
    canUser('partners:change') &&
    partnerConfig &&
    [PartnerScope.embeddedWallet, PartnerScope.fullyEmbedded].includes(
      partnerConfig.partnerScope
    ) &&
    partnerOrgAuthDetails?.status === PartnerAuthStatus.inactive &&
    sessionStorage.getItem(SKIP_PARTNER_AUTH.key) !== SKIP_PARTNER_AUTH.value
  )
    return <Redirect to={partnerPaths.partners} />;

  return <Component {...props} />;
};

export default withOnboardingRedirection;
