import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';
import {
  organizationOnboardingStatuses,
  OrganizationStatus,
} from 'services/constants';

const useIsOrgInOnboarding = () => {
  const {
    state: { organization },
  } = useGlobalState();

  return useMemo(
    () => ({
      isOrgInOnboarding:
        !!organization &&
        organizationOnboardingStatuses.includes(organization.status),
      isOrgInOnboardingNew:
        !!organization && organization.status === OrganizationStatus.onboarding,
    }),
    [organization?.status]
  );
};

export default useIsOrgInOnboarding;
