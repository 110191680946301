import React from 'react';
import { useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import {
  DialogStepper,
  IssueRequestCardSummaryContent,
} from 'domains/card/components';
import { FormValues, Step } from 'domains/card/dialogs/CardRequestDialog';
import useStepManager from 'domains/card/dialogs/CardRequestDialog/useStepManager';
import { getCardName } from 'domains/card/utils';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoaderWithOverlay,
  TextField,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';

interface Props {
  onClose: () => void;
}

const SummaryStep = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { isAdminApp } = useCurrentApp();
  const stepManager = useStepManager();
  const formik = useFormikContext<FormValues>();

  const isCommentTextareaVisible =
    formik.values.cardConfigSetting!.maxUsage !== 1;
  const isSubmitDisabled =
    (isCommentTextareaVisible && !formik.values.comment.trim()) ||
    formik.isSubmitting;

  return (
    <>
      <DialogStepper<Step>
        currentStep={stepManager.currentStep}
        steps={stepManager.steps}
        goTo={stepManager.goTo}
        getStepLabel={stepManager.getStepLabel}
      />
      <DialogTitle>
        <Trans
          i18nKey="cardRequestDialog_v2.summaryStep.title"
          values={{
            cardName: getCardName(
              formik.values.cardConfigSetting!.cardConfig,
              true
            ),
          }}
        />
      </DialogTitle>
      <DialogContent>
        <IssueRequestCardSummaryContent values={formik.values} />
      </DialogContent>
      <DialogActions sx={{ flexWrap: 'wrap' }}>
        {isCommentTextareaVisible && (
          <Box width="100%" mb={3}>
            <TextField
              {...formik.getFieldProps('comment')}
              label={
                isAdminApp
                  ? t(
                      'cardRequestDialog_v2.summaryStep.commentTitleAnotherMember'
                    )
                  : t('cardRequestDialog_v2.summaryStep.commentTitle')
              }
              placeholder={
                isAdminApp
                  ? t(
                      'cardRequestDialog_v2.summaryStep.commentPlaceholderAnotherMember'
                    )
                  : t('cardRequestDialog_v2.summaryStep.commentPlaceholder')
              }
              autoFocus
              multiline
              minRows={3}
              maxRows={5}
              inputProps={{ maxLength: 200 }}
            />
          </Box>
        )}
        <Box flexGrow="1">
          <Button variant="text" onClick={stepManager.goPrev}>
            {t('common.button.back')}
          </Button>
        </Box>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          onClick={() => formik.handleSubmit()}
          disabled={isSubmitDisabled}
        >
          {t('cardRequestDialog_v2.summaryStep.requestCard')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default SummaryStep;
