import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CloudXIcon, Typography } from 'elements';

interface Props {
  title?: ReactNode;
  description?: ReactNode;
  ctaText?: ReactNode;
  onReload?: () => void;
}

const WidgetError = ({ title, description, ctaText, onReload }: Props) => {
  const { t } = useTranslation();

  return (
    <Box px={3} py={10}>
      <Box maxWidth={418} mx="auto" textAlign="center">
        <CloudXIcon
          fontSize="large"
          sx={{ verticalAlign: 'top', mb: 1, color: 'text.secondary' }}
        />
        <Typography variant="h6" mb={1}>
          {title ?? t('errors.unexpectedError.title')}
        </Typography>
        <Typography color="text.secondary" mb={2}>
          {description ?? t('errors.unexpectedError.description')}
        </Typography>
        <Button
          onClick={() => (onReload ? onReload() : window.location.reload())}
          size="large"
        >
          {ctaText ?? t('errors.unexpectedError.reload')}
        </Button>
      </Box>
    </Box>
  );
};

export default WidgetError;
