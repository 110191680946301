import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  CardCategoryControlFormElement,
  CardDateControlFormElement,
  CardLocationControlFormElement,
  CardMerchantControlFormElement,
  CardTimeControlFormElement,
} from 'domains/card/components';
import { useAvailableCardControlConfigs } from 'domains/card/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Card,
  CardCategoryControl,
  CardConfigSetting,
  CardDateControl,
  CardLocationControl,
  CardMerchantControl,
  CardTimeControl,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  categoryControl: CardCategoryControl | null;
  merchantControl: CardMerchantControl | null;
  dateControl: CardDateControl | null;
  timeControl: CardTimeControl | null;
  locationControl: CardLocationControl | null;
}

interface Props extends DialogProps {
  card: Card;
  cardConfigSetting: CardConfigSetting;
  onClose: () => void;
  onSuccess: (card: Card) => void;
}

const EditCardControlsDialog = ({
  card,
  cardConfigSetting,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const configs = useAvailableCardControlConfigs(cardConfigSetting, true);
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      categoryControl: card.cardControls.categories,
      merchantControl: card.cardControls.merchants,
      dateControl: card.cardControls.dates,
      timeControl: card.cardControls.times,
      locationControl: card.cardControls.locations,
    },
    onSubmit: async (
      {
        categoryControl,
        merchantControl,
        dateControl,
        timeControl,
        locationControl,
      },
      { setSubmitting }
    ) => {
      try {
        const cardControls = await api.updateCardControls(card.cardId, {
          ...(!!categoryControl?.values.length && {
            categories: categoryControl,
          }),
          ...(!!merchantControl?.values.length && {
            merchants: merchantControl,
          }),
          ...(!!dateControl?.values.length && {
            dates: dateControl,
          }),
          ...(!!timeControl?.values.length && {
            times: timeControl,
          }),
          ...(!!locationControl?.values.length && {
            locations: locationControl,
          }),
        });
        if (!mounted.current) return;
        onSuccess({ ...card, cardControls });
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('editCardControlsDialog.title')}</DialogTitle>
      <DialogContent>
        <CardCategoryControlFormElement
          mccGroup={cardConfigSetting.mccGroup}
          configs={configs.categories}
          control={formik.values.categoryControl}
          onChange={(categoryControl) =>
            formik.setValues((prevValues) => ({
              ...prevValues,
              categoryControl,
            }))
          }
          disabled={formik.isSubmitting}
        />
        <CardMerchantControlFormElement
          configs={configs.merchants}
          control={formik.values.merchantControl}
          onChange={(merchantControl) =>
            formik.setValues((prevValues) => ({
              ...prevValues,
              merchantControl,
            }))
          }
        />
        <CardDateControlFormElement
          configs={configs.dates}
          control={formik.values.dateControl}
          onChange={(dateControl) =>
            formik.setValues((prevValues) => ({
              ...prevValues,
              dateControl,
            }))
          }
        />
        <CardTimeControlFormElement
          configs={configs.times}
          control={formik.values.timeControl}
          onChange={(timeControl) =>
            formik.setValues((prevValues) => ({
              ...prevValues,
              timeControl,
            }))
          }
        />
        <CardLocationControlFormElement
          configs={configs.locations}
          control={formik.values.locationControl}
          onChange={(locationControl) =>
            formik.setValues((prevValues) => ({
              ...prevValues,
              locationControl,
            }))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditCardControlsDialog);
