import { omit } from 'lodash';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { List, ListItem, Typography } from 'elements';
import { OnboardingTask, OnboardingTaskQA } from 'services/constants';

interface Props {
  task: OnboardingTask;
  isBreadcrumb?: boolean;
}

// for QA tasks old titles are used
const TaskTitle = ({ task, isBreadcrumb }: Props) => {
  const { t } = useTranslation();

  const isQATask = 'onboardingDocument' in task.data;
  if (!isQATask) return <>{t(`orgOnboardingTaskTitle.${task.type}`)}</>;

  const {
    data: {
      onboardingDocument: { value },
    },
  } = task as OnboardingTaskQA;
  const docValue = value!;

  return (
    <>
      {docValue.customLabelLocal || (
        <Trans
          i18nKey={`onboardingDocumentType.${docValue.type.name}`}
          values={{
            ...omit(docValue.attributes, ['fromMonthYear', 'toMonthYear']),
            ...(docValue.attributes['fromMonthYear'] && {
              fromMonthYear: moment(docValue.attributes.fromMonthYear).format(
                'MMM yyyy'
              ),
            }),
            ...(docValue.attributes['toMonthYear'] && {
              toMonthYear: moment(docValue.attributes.toMonthYear).format(
                'MMM yyyy'
              ),
            }),
          }}
          components={{
            b: <b />,
            li: isBreadcrumb ? (
              <span style={{ paddingLeft: '4px' }} />
            ) : (
              <ListItem component={Typography} variant="body2" />
            ),
            ul: isBreadcrumb ? <></> : <List />,
          }}
        />
      )}
    </>
  );
};

export default TaskTitle;
