import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import NothingFound from 'components/NothingFound';
import WidgetError from 'components/WidgetError';
import NestedTrackingCategoryItems from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/NestedTrackingCategoryItems';
import { useCodatStepperContext } from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/useCodatStepperContext';
import { getTrackingCategoriesFilterItems } from 'domains/settings/pages/AccountingPage/CodatSubPage/CodatSyncSetupDialog/utils';
import {
  ArrowsClockwiseIcon,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import useCostUnitsSync from './useCostUnitsSync';

interface Props {
  isPartialFlow?: boolean;
  onPartialFlowSuccess?: () => void;
  onPartialFlowCancel?: () => void;
}

const CostUnitsStep = ({
  isPartialFlow,
  onPartialFlowSuccess,
  onPartialFlowCancel,
}: Props) => {
  const { t } = useTranslation();
  const partnerName = usePartnerName();
  const {
    actions: { onNext },
  } = useCodatStepperContext();
  const {
    fetchedTrackingCategories,
    filterTrackingCategories,
    shownCategoriesList,
    shownCategoriesIds,
    selectedCategoryId,
    categoryProjectHashMap,
    selectedIds,
    isLoading,
    isInnerLoading,
    isError,
    getData,
    onCategoryChange,
    onProjectChange,
    onSelect,
    onCostUnitsSave,
    fetchCodatData,
  } = useCostUnitsSync();

  const nameLabel = useMemo(() => {
    if (selectedCategoryId === 'all')
      return t('codatSyncSetupDialog.costUnitsStep.allEntries');
    if (selectedCategoryId === 'all_top_level')
      return t('codatSyncSetupDialog.costUnitsStep.allEntriesTopLevelOption');

    return (
      fetchedTrackingCategories?.find((item) => item.id === selectedCategoryId)
        ?.name || ''
    );
  }, [selectedCategoryId, fetchedTrackingCategories]);

  const filterCategoriesArray = useMemo(
    () =>
      filterTrackingCategories?.length
        ? getTrackingCategoriesFilterItems(filterTrackingCategories)
        : [],
    [filterTrackingCategories]
  );

  if (isLoading) return <LoaderWithOverlay loading />;

  if (isError)
    return (
      <Box margin="auto">
        <WidgetError onReload={getData} />
      </Box>
    );

  return (
    <>
      <DialogTitle>{t('codatSyncSetupDialog.costUnitsStep.title')}</DialogTitle>

      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      >
        <Typography color="text.secondary">
          <Trans
            i18nKey="codatSyncSetupDialog.costUnitsStep.description"
            values={{
              partnerName,
            }}
          />
        </Typography>

        {fetchedTrackingCategories?.length ? (
          <>
            <Box my={3} maxWidth={240}>
              <FormControl fullWidth>
                <Select
                  value={selectedCategoryId}
                  renderValue={(selected) => {
                    if (!selected) return '';
                    if (selected === 'all')
                      return t('codatSyncSetupDialog.costUnitsStep.allEntries');
                    if (selected === 'all_top_level')
                      return t(
                        'codatSyncSetupDialog.costUnitsStep.allEntriesTopLevelOption'
                      );
                    return (
                      fetchedTrackingCategories!.find(
                        (item) => item.id === selected
                      )?.name || ''
                    );
                  }}
                  onChange={(e) => onCategoryChange(e.target.value)}
                >
                  <MenuItem value={'all'}>
                    {t('codatSyncSetupDialog.costUnitsStep.allEntries')}
                  </MenuItem>
                  <MenuItem
                    value={'all_top_level'}
                    sx={{
                      borderBottom: (theme) =>
                        !!filterCategoriesArray.length
                          ? `1px solid ${theme.palette.divider}}`
                          : 0,
                    }}
                  >
                    {t(
                      'codatSyncSetupDialog.costUnitsStep.allEntriesTopLevelOption'
                    )}
                  </MenuItem>
                  {filterCategoriesArray}
                </Select>
              </FormControl>
            </Box>

            <Box flex={1} overflow="hidden" position="relative">
              <TableContainer sx={{ height: '100%', overflowY: 'auto' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selectedIds.length > 0 &&
                            selectedIds.length < shownCategoriesIds.length
                          }
                          checked={
                            shownCategoriesIds.length > 0 &&
                            selectedIds.length === shownCategoriesIds.length
                          }
                          onChange={() =>
                            selectedIds.length
                              ? onSelect([])
                              : onSelect(shownCategoriesIds)
                          }
                          inputProps={{
                            'aria-label': 'select all',
                          }}
                          disabled={!shownCategoriesIds.length}
                        />
                      </TableCell>
                      <TableCell sx={{ pl: 0 }}>
                        {t('codatSyncSetupDialog.costUnitsStep.nameLabel', {
                          name: nameLabel,
                        })}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        {t('codatSyncSetupDialog.costUnitsStep.projectsLabel')}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {shownCategoriesList?.length ? (
                      <NestedTrackingCategoryItems
                        type="project"
                        selectedIds={selectedIds}
                        isPartialFlow={isPartialFlow}
                        trackingCategories={shownCategoriesList}
                        categoryHashMap={categoryProjectHashMap}
                        onChange={onProjectChange}
                        onSelect={onSelect}
                      />
                    ) : (
                      <NothingFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        ) : (
          <NothingFound />
        )}

        <LoaderWithOverlay loading={isInnerLoading} />
      </DialogContent>

      <DialogActions>
        {isPartialFlow ? (
          <Box mr="auto">
            <Button
              variant="text"
              onClick={onPartialFlowCancel}
              disabled={isInnerLoading}
            >
              {t('common.button.close')}
            </Button>

            <Button
              variant="text"
              startIcon={<ArrowsClockwiseIcon />}
              onClick={fetchCodatData}
              disabled={isInnerLoading}
            >
              {t('codatSyncSetupDialog.syncNowButton')}
            </Button>
          </Box>
        ) : (
          <Button
            onClick={onNext}
            variant={!!shownCategoriesList?.length ? 'outlined' : 'contained'}
            disabled={isInnerLoading}
          >
            {t('codatSyncSetupDialog.costUnitsStep.skipProjectMappingButton')}
          </Button>
        )}

        {!!shownCategoriesList?.length && (
          <Button
            onClick={() => onCostUnitsSave(onPartialFlowSuccess)}
            disabled={isInnerLoading || selectedIds.length === 0}
          >
            {t('codatSyncSetupDialog.costUnitsStep.saveMappingButton')}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default CostUnitsStep;
