import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GridRowSpacing, GridRowSpacingParams } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import ConfirmDialog from 'components/ConfirmDialogV2';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { useActiveTeams, useGlobalState } from 'context/GlobalState';
import { isCodatAccSystemConnected } from 'domains/settings/utils';
import { ExportTransactionsDialog } from 'domains/transaction/dialogs';
import { useWaitForExportFlowTransactionsProcessing } from 'domains/transaction/hooks';
import { TransactionDetailsPage } from 'domains/transaction/pages';
import {
  Alert,
  AlertTitle,
  Box,
  DataGrid,
  FileXIcon,
  InfoIcon,
  LoaderWithOverlay,
  ReceiptAttentionIcon,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import useSnackbar from 'hooks/useSnackbar';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  AccountingSystem,
  ApiExportTransactionStatus,
  ApiIntegrationStatus,
  ExportFlowSummary,
  ExportFlowTransaction,
  ExportFormat,
  FeatureModuleKey,
  Supplier,
  TransactionExportStatus,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  getGenericErrorMsg,
  getPath,
  getReceiptFilterApiParams,
} from 'services/utils';
import AccSystemLogoComponent from './AccSystemLogoComponent';
import Filters from './Filters';
import FilterChips from './Filters/FilterChips';
import TransactionExportToast from './TransactionExportToast';
import useColumns from './useColumns';
import useEventSource from './useEventSource';
import {
  getProjectFilterApiParams,
  getQueryParams,
  getSelectedFiltersCount,
  getSubcategoryFilterApiParams,
  getVatRateFilterApiParams,
  mergeTransactionDetailsFieldsToExportFlowTransaction,
  MISSING_SUPPLIER_OPTION,
  MISSING_TEAM_OPTION,
  RequestParams,
} from './utils';
import withCirculaSyncCheck from './withCirculaSyncCheck';
import withInitialData from './withInitialData';

const PAGE_LIMIT = 200;
const PAGE_LIMIT_EXPORT_QUEUE = 100000;

interface State {
  isLoading: boolean;
  transactions: ExportFlowTransaction[];
  hasNextPage: boolean;
  totalCount: number;
  allIdsCount: number;
  selectedIds: string[];
  selectedId: string | null;
  isCodatMissingFieldsDialogOpen: boolean;
  isMissingInfoDialogOpen: boolean;
  isExportTransactionsDialogOpen: boolean;
  isMandatoryFieldsMissedDialogOpen: boolean;
  exportFlowSummary: ExportFlowSummary | null;
}

interface Props {
  suppliers: Supplier[];
  exportStatus: TransactionExportStatus;
}

const ExportPage = ({ suppliers, exportStatus }: Props) => {
  const dataGridRef = useGridApiRef();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { datevExportEnabled } = useFlags();
  const canUser = useCanUser();
  const showPageError = useShowPageError();
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:transactionId',
    true
  );
  const {
    state: {
      organization,
      accountingSettings,
      subcategories,
      vatRates,
      projects,
      featureModules,
      cardAccounts,
    },
  } = useGlobalState();
  const activeTeams = useActiveTeams();
  const cardAccountIds = useMemo(
    () => cardAccounts.map((account) => account.id),
    [cardAccounts]
  );
  const waitForExportFlowTransactionsProcessing = useWaitForExportFlowTransactionsProcessing();
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(
    getQueryParams(
      location.search,
      accountingSettings,
      suppliers,
      subcategories,
      vatRates,
      projects,
      activeTeams,
      cardAccountIds,
      featureModules
    )
  );
  const pageRef = useRef(0);
  const columns = useColumns();
  const [state, setState] = useState<State>({
    isLoading: true,
    transactions: [],
    hasNextPage: false,
    totalCount: 0,
    allIdsCount: 0,
    selectedIds: [],
    selectedId: null,
    isCodatMissingFieldsDialogOpen: false,
    isMissingInfoDialogOpen: false,
    isExportTransactionsDialogOpen: false,
    isMandatoryFieldsMissedDialogOpen: false,
    exportFlowSummary: null,
  });
  // we need this indicator, as we don't receive notification from BE asap
  const [isAsyncExportLoading, setIsAsyncExportLoading] = useState(false);
  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;
  const isEmptyState =
    !state.isLoading && !state.transactions.length && !areFiltersApplied;
  const confirmDialogRef = useRef<{
    confirm: () => void;
    cancel: () => void;
  } | null>(null);

  const lexOfficeApiConnected =
    accountingSettings?.accountingSystem === AccountingSystem.lexOffice &&
    accountingSettings?.useAccountingExport &&
    accountingSettings?.exportFormats.includes(ExportFormat.api);

  const datevApiConnected =
    datevExportEnabled &&
    accountingSettings?.accountingSystem === AccountingSystem.datev &&
    accountingSettings?.useAccountingExport &&
    accountingSettings?.exportFormats.includes(ExportFormat.api);

  const codatApiConnected = isCodatAccSystemConnected(accountingSettings);
  const isDatevApiErrorShown =
    datevExportEnabled &&
    canUser('datev-sub-page:visit', accountingSettings) &&
    accountingSettings!.exportFormats.includes(ExportFormat.api);

  const serverEventsAllowed =
    (lexOfficeApiConnected || datevApiConnected || codatApiConnected) &&
    accountingSettings?.apiIntegrationStatus === ApiIntegrationStatus.connected;

  const { eventSourceData, isEventSourceError } = useEventSource(
    serverEventsAllowed
  );

  useEffect(() => {
    if (isAsyncExportLoading) {
      setIsAsyncExportLoading(false);
    }
  }, [eventSourceData, isEventSourceError]);

  const getRequestParams = (): RequestParams => {
    const {
      q,
      type,
      receipt,
      supplier,
      subcategory,
      vatRate,
      project,
      fromDate,
      toDate,
      team,
      reviewStatus,
      flagReason,
      cardAccountId,
    } = paramsRef.current;

    return {
      q: q.length ? q : undefined,
      organizationId: organization!.id,
      type: type.length ? type.join() : undefined,
      fromDate: fromDate?.format(),
      toDate: toDate?.format(),
      teamId: !!team && team !== MISSING_TEAM_OPTION ? team : undefined,
      isTeamMissing: team === MISSING_TEAM_OPTION ? true : undefined,
      supplierId:
        !!supplier && supplier !== MISSING_SUPPLIER_OPTION
          ? supplier
          : undefined,
      isSupplierMissing: supplier === MISSING_SUPPLIER_OPTION || undefined,
      ...getSubcategoryFilterApiParams(subcategory),
      ...getVatRateFilterApiParams(vatRate),
      ...getProjectFilterApiParams(project),
      ...getReceiptFilterApiParams(receipt),
      reviewStatus: reviewStatus || undefined,
      flagReason: flagReason || undefined,
      cardAccountId: cardAccountId || undefined,
    };
  };

  const getData = async (
    page: number,
    limit = PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const params = getRequestParams();
      const {
        transactions,
        hasNextPage,
        totalCount,
      } = await api.getExportFlowTransactions({
        ...params,
        exportStatus,
        page,
        limit:
          exportStatus === TransactionExportStatus.inExportQueue
            ? PAGE_LIMIT_EXPORT_QUEUE
            : limit,
      });
      if (!mounted.current) return;
      setState((prevState) => {
        return {
          ...prevState,
          isLoading: false,
          transactions: isLoadMore
            ? [...prevState.transactions, ...transactions]
            : transactions,
          hasNextPage,
          totalCount,
          allIdsCount: totalCount,
          selectedIds: isLoadMore ? prevState.selectedIds : [],
        };
      });
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const getRowSpacing = useCallback(
    (params: GridRowSpacingParams): GridRowSpacing => {
      if (!state.selectedIds.length) return {};
      const marginBottom = isDetailsPageOpen ? 192 : 152;
      return {
        bottom: params.isLastVisible ? marginBottom : 0,
      };
    },
    [isDetailsPageOpen, state.selectedIds.length]
  );

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });

    paramsRef.current = getQueryParams(
      location.search,
      accountingSettings,
      suppliers,
      subcategories,
      vatRates,
      projects,
      activeTeams,
      cardAccountIds,
      featureModules
    );
    pageRef.current = 0;
    getData(pageRef.current);
  }, [exportStatus, location.search]);

  const refreshListState = () => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });

    pageRef.current = 0;
    getData(pageRef.current).then(() => {
      if (isDetailsPageOpen) {
        const pathname = generatePath(getPath('export'), {
          orgId: organization!.id,
        });
        history.replace({ pathname, search: location.search });
      }
    });
  };

  const showMoveToExportQueueConfirm = () =>
    new Promise<boolean>((resolve) => {
      confirmDialogRef.current = {
        confirm() {
          resolve(true);
          confirmDialogRef.current = null;
          setState((prevState) => ({
            ...prevState,
            isMissingInfoDialogOpen: false,
            isLoading: true,
            exportFlowSummary: null,
          }));
        },
        cancel() {
          resolve(false);
          confirmDialogRef.current = null;
          setState((prevState) => ({
            ...prevState,
            isMissingInfoDialogOpen: false,
            exportFlowSummary: null,
          }));
        },
      };
      setState((prevState) => ({
        ...prevState,
        isMissingInfoDialogOpen: true,
        isLoading: false,
      }));
    });

  const handleMoveToExportQueue = async (transactionId?: string) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const selectedIds = transactionId ? [transactionId] : state.selectedIds;
      const exportFlowSummary = await api.getExportFlowSummary(
        organization!.id,
        selectedIds
      );

      if (!mounted.current) return;

      if (exportFlowSummary.mandatoryFieldsMissed) {
        setState((prevState) => ({
          ...prevState,
          isMandatoryFieldsMissedDialogOpen: true,
          isLoading: false,
        }));
        return;
      }

      if (
        (featureModules.RECEIPT_MANAGEMENT &&
          exportFlowSummary.missingReceipts) ||
        exportFlowSummary.missingVatRate ||
        exportFlowSummary.missingSubcategory ||
        (featureModules.MANAGER_TX_REVIEWS && exportFlowSummary.missingReview)
      ) {
        setState((prevState) => ({ ...prevState, exportFlowSummary }));
        const isConfirmed = await showMoveToExportQueueConfirm();
        if (!isConfirmed) return;
        if (!mounted.current) return;
      }
      const { accountingExportId } = await api.updateTransactionsExport({
        organizationId: organization!.id,
        transactionIds: selectedIds,
        newExportStatus: TransactionExportStatus.inExportQueue,
      });
      if (!mounted.current) return;
      await waitForExportFlowTransactionsProcessing({
        organizationId: organization!.id,
        exportStatus: TransactionExportStatus.inExportQueue,
        accountingExportId,
        totalCount: selectedIds.length,
      });
      if (!mounted.current) return;
      refreshListState();
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const handleMoveToNotExported = async (transactionId?: string) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const selectedIds = transactionId ? [transactionId] : state.selectedIds;
      const { accountingExportId } = await api.updateTransactionsExport({
        organizationId: organization!.id,
        transactionIds: selectedIds,
        newExportStatus: TransactionExportStatus.notExported,
      });
      if (!mounted.current) return;
      await waitForExportFlowTransactionsProcessing({
        organizationId: organization!.id,
        exportStatus: TransactionExportStatus.notExported,
        accountingExportId,
        totalCount: selectedIds.length,
      });
      if (!mounted.current) return;
      refreshListState();
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const handleMoveToExported = async (
    isExportedPage?: boolean,
    transactionId?: string
  ) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const selectedIds = transactionId ? [transactionId] : state.selectedIds;
      if (isCodatAccSystemConnected(accountingSettings) && !isExportedPage) {
        const codatCompanyConfig = await api.getCodatCompanyConfig(
          organization!.id
        );
        if (!mounted.current) return;
        if (
          !codatCompanyConfig.bankAccount.id ||
          !codatCompanyConfig.customer.id ||
          !codatCompanyConfig.supplier.id
        ) {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            isCodatMissingFieldsDialogOpen: true,
          }));
          return;
        }
      }

      const exportFlowSummary = await api.getExportFlowSummary(
        organization!.id,
        selectedIds
      );

      if (!mounted.current) return;
      if (!isExportedPage && exportFlowSummary.mandatoryFieldsMissed) {
        setState((prevState) => ({
          ...prevState,
          isMandatoryFieldsMissedDialogOpen: true,
          isLoading: false,
        }));
        return;
      }
      setState((prevState) => ({
        ...prevState,
        exportFlowSummary,
        isExportTransactionsDialogOpen: true,
        selectedId: transactionId || null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const renderExportStatusMsg = () => {
    const renderUpdatedAt = () =>
      eventSourceData?.updatedAt && (
        <>
          {'. '}
          {t('exportPage.sseMsg.updatedAt', {
            date: moment(eventSourceData?.updatedAt).format('DD.MM.YYYY HH:mm'),
          })}
        </>
      );

    if (isEventSourceError)
      return (
        <Alert severity="error">
          {t('exportPage.sseMsg.connectionFailed')}
        </Alert>
      );
    if (
      eventSourceData?.status === ApiExportTransactionStatus.inProgress ||
      isAsyncExportLoading
    )
      return (
        <Alert severity="info">
          {t('exportPage.sseMsg.inProgress')}
          {renderUpdatedAt()}
        </Alert>
      );
    if (
      eventSourceData?.status === ApiExportTransactionStatus.done &&
      eventSourceData.totalTransactions !== eventSourceData.uploadedTransactions
    )
      return (
        <Alert severity="error">
          {t('exportPage.sseMsg.partiallyDone')}
          {renderUpdatedAt()}
        </Alert>
      );
    if (eventSourceData?.status === ApiExportTransactionStatus.done)
      return (
        <Alert severity="success">
          {t('exportPage.sseMsg.done', {
            count: eventSourceData.uploadedTransactions,
          })}
          {renderUpdatedAt()}
        </Alert>
      );
    if (
      eventSourceData?.status === ApiExportTransactionStatus.canceled ||
      eventSourceData?.status === ApiExportTransactionStatus.failed
    )
      return (
        <Alert severity="error">
          {t('exportPage.sseMsg.failed')}
          {renderUpdatedAt()}
        </Alert>
      );
    return <Alert severity="info">{t('exportPage.sseMsg.loading')}</Alert>;
  };

  return (
    <>
      <PageHeader>
        <PageTitle
          title={
            exportStatus === TransactionExportStatus.exported
              ? t('exportPage.titleExported')
              : exportStatus === TransactionExportStatus.inExportQueue
              ? t('exportPage.titleInExportQueue')
              : t('exportPage.titleNotExported')
          }
          suptitle={t('exportPage.title')}
        >
          <AccSystemLogoComponent />
        </PageTitle>

        {serverEventsAllowed &&
          exportStatus !== TransactionExportStatus.notExported && (
            <Box mb={3} mt={1}>
              {renderExportStatusMsg()}
            </Box>
          )}

        <Filters
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          disabled={isEmptyState}
          suppliers={suppliers}
          transactionsCount={state.totalCount}
        />

        <FilterChips
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          suppliers={suppliers}
        />
      </PageHeader>

      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid<ExportFlowTransaction>
          apiRef={dataGridRef}
          rowHeight={72}
          rowCount={state.totalCount}
          getRowId={(row) => row.transactionId}
          getRowSpacing={getRowSpacing}
          checkboxSelection={canUser('transaction:export')}
          getRowClassName={(params) =>
            params.id === detailsParams?.transactionId
              ? 'row-details-visible'
              : ''
          }
          onRowSelectionModelChange={(newRowSelectionModel) =>
            setState((prevState) => ({
              ...prevState,
              selectedIds: newRowSelectionModel as string[],
            }))
          }
          keepNonExistentRowsSelected
          loading={state.isLoading}
          rows={state.transactions}
          columns={columns}
          columnVisibilityModel={{
            receiptNeeded: !!(
              featureModules.RECEIPT_MANAGEMENT &&
              organization?.missingReceiptNotificationEnabled &&
              exportStatus !== TransactionExportStatus.exported
            ),
            exportError:
              (isDatevApiErrorShown &&
                exportStatus !== TransactionExportStatus.notExported) ||
              (codatApiConnected &&
                exportStatus !== TransactionExportStatus.notExported),
            exportDateAndCounter:
              !isDetailsPageOpen &&
              exportStatus === TransactionExportStatus.exported,
            subcategoryStatus:
              !isDetailsPageOpen && !!accountingSettings?.subcategoryEnabled,
            vatRateStatus:
              !isDetailsPageOpen && !!accountingSettings?.vatRateEnabled,
            projectStatus:
              !isDetailsPageOpen && !!accountingSettings?.projectEnabled,
            teamId: !isDetailsPageOpen && featureModules.TEAMS,
            cardAccountName: !isDetailsPageOpen && cardAccounts.length > 1,
            review: !isDetailsPageOpen && featureModules.MANAGER_TX_REVIEWS,
            drawerPlaceholder: isDetailsPageOpen,
          }}
          disableRowSelectionOnClick
          rowSelectionModel={state.selectedIds}
          onRowClick={({ id, row }) => {
            if (id === detailsParams?.transactionId) {
              history.push(`${url}${location.search}`);
            } else
              history.push(`${url}/${row.transactionId}${location.search}`);
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.transactions.length && areFiltersApplied)
                return <NothingFound />;

              return (
                <NoData
                  isNewDesign
                  Icon={FileXIcon}
                  label={t('exportPage.noTransactions')}
                  $top={90}
                />
              );
            },
            loadingOverlay: () => null,
          }}
        />

        <TransactionExportToast
          exportStatus={exportStatus}
          isDetailsPageOpen={isDetailsPageOpen}
          count={state.selectedIds.length}
          onSuccess={() => {
            if (exportStatus === TransactionExportStatus.notExported)
              handleMoveToExportQueue();
            if (exportStatus === TransactionExportStatus.inExportQueue)
              handleMoveToExported();
            if (exportStatus === TransactionExportStatus.exported)
              handleMoveToExported(true);
          }}
          onCancel={() => handleMoveToNotExported()}
        />

        <Route
          path={`${path}/:transactionId`}
          children={({ match }) => (
            <TransactionDetailsPage
              open={!!match}
              isAdminApp
              isExportPage
              onChangeExportStatus={(id, newExportStatus) => {
                if (newExportStatus === TransactionExportStatus.notExported) {
                  handleMoveToNotExported(id);
                } else if (
                  newExportStatus === TransactionExportStatus.inExportQueue
                ) {
                  handleMoveToExportQueue(id);
                } else if (
                  newExportStatus === TransactionExportStatus.exported
                ) {
                  handleMoveToExported(
                    exportStatus === TransactionExportStatus.exported,
                    id
                  );
                }
              }}
              onUpdate={(transaction, accountingTransactions) => {
                setState((prevState) => ({
                  ...prevState,
                  transactions: state.transactions.map(
                    (exportFlowTransaction) =>
                      exportFlowTransaction.transactionId ===
                      transaction.transactionId
                        ? mergeTransactionDetailsFieldsToExportFlowTransaction(
                            exportFlowTransaction,
                            transaction,
                            accountingTransactions
                          )
                        : exportFlowTransaction
                  ),
                }));
              }}
            />
          )}
        />
      </PageTableContent>

      <ConfirmDialog
        open={state.isCodatMissingFieldsDialogOpen}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            isCodatMissingFieldsDialogOpen: false,
          }));
        }}
        onSuccess={() => {
          history.push(
            generatePath(getPath('settingsAccounting'), {
              orgId: organization!.id,
            })
          );
        }}
        confirmButtonProps={{
          children: t('exportTransactionsDialog.codatMissingFieldsYesBtnTitle'),
        }}
        cancelButtonProps={{ children: t('common.button.close') }}
        description={t(
          'exportTransactionsDialog.codatMissingFieldsDescription'
        )}
        title={t('exportTransactionsDialog.codatMissingFieldsTitle')}
      />

      <ConfirmDialog
        open={state.isMissingInfoDialogOpen}
        onClose={() => confirmDialogRef.current?.cancel()}
        onSuccess={() => confirmDialogRef.current?.confirm()}
        title={t('exportPage.missingInfoConfirmDialogTitle_v2')}
        description={
          <>
            {featureModules.RECEIPT_MANAGEMENT &&
              !!state.exportFlowSummary?.missingReceipts && (
                <Alert
                  severity="error"
                  icon={<ReceiptAttentionIcon />}
                  sx={{ mb: 2 }}
                >
                  <AlertTitle>
                    {t('exportTransactionsDialog.missingReceiptTitle')}
                  </AlertTitle>
                  {t('exportTransactionsDialog.missingReceiptDescription')}
                </Alert>
              )}

            {!!(
              state.exportFlowSummary?.missingSubcategory ||
              state.exportFlowSummary?.missingVatRate
            ) && (
              <Alert severity="warning" icon={<InfoIcon />} sx={{ mb: 2 }}>
                <AlertTitle>
                  {t('exportTransactionsDialog.missingInfoTitle')}
                </AlertTitle>
                {t('exportTransactionsDialog.missingInfoDescription')}
              </Alert>
            )}

            {featureModules.MANAGER_TX_REVIEWS &&
              !!state.exportFlowSummary?.missingReview && (
                <Alert severity="error">
                  <AlertTitle>
                    {t('exportTransactionsDialog.missingReviewTitle')}
                  </AlertTitle>
                  {t('exportTransactionsDialog.missingReviewDescription')}
                </Alert>
              )}
          </>
        }
      />

      <ConfirmDialog
        open={state.isMandatoryFieldsMissedDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isMandatoryFieldsMissedDialogOpen: false,
            exportFlowSummary: null,
          }))
        }
        title={t('exportPage.mandatoryFieldsMissedDialog.title')}
        description={t('exportPage.mandatoryFieldsMissedDialog.description')}
        confirmButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ children: t('common.button.close') }}
        onSuccess={() => {}}
      />

      {state.isExportTransactionsDialogOpen && (
        <ExportTransactionsDialog
          transactionIds={
            state.selectedId ? [state.selectedId] : state.selectedIds
          }
          exportFlowSummary={state.exportFlowSummary!}
          isExported={exportStatus === TransactionExportStatus.exported}
          open={state.isExportTransactionsDialogOpen}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              isExportTransactionsDialogOpen: false,
              exportFlowSummary: null,
              selectedId: null,
            }))
          }
          onSuccess={(isAsyncExport) => {
            if (isAsyncExport) setIsAsyncExportLoading(true);
            setState((prevState) => ({
              ...prevState,
              isExportTransactionsDialogOpen: false,
              exportFlowSummary: null,
            }));
            if (exportStatus === TransactionExportStatus.exported) {
              setState((prevState) => ({
                ...prevState,
                selectedIds: [],
                selectedId: null,
              }));
            } else {
              refreshListState();
            }
          }}
        />
      )}
    </>
  );
};

export default withPageConfig(
  withCirculaSyncCheck(withInitialData(ExportPage)),
  {
    permission: 'export-page:visit',
    featureFlag: 'exportFlowEnabled',
    featureModule: FeatureModuleKey.accountingFeatures,
  }
);
