import { FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  TrashIcon,
} from 'elements';
import { countries, TaxResidency } from 'services/constants';

export interface TaxIdInputGroupProps {
  disabled: boolean;
  addFieldDisabled: boolean;
  errors: string | string[] | FormikErrors<TaxResidency>[];
  isLoading: boolean;
  onFieldsNumberChange: (taxResidences: TaxResidency[]) => void;
  onChange: (
    index: number,
    key: 'taxResidence' | 'taxId',
    value: string
  ) => void;
  onErrorChange: (errors: FormikErrors<TaxResidency>[]) => void;
  taxResidences: TaxResidency[];
}

const TaxIdInputGroup = ({
  disabled,
  addFieldDisabled,
  isLoading,
  taxResidences,
  onFieldsNumberChange,
  onChange,
  ...props
}: TaxIdInputGroupProps) => {
  const { t } = useTranslation();
  const errors = props.errors as FormikErrors<TaxResidency>[];

  return (
    <Grid container spacing={4}>
      {taxResidences.map((_, index) => (
        <Grid key={index} item xs={12}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Autocomplete<
                  { countryName: string; countryCode: string },
                  false,
                  false,
                  false
                >
                  searchIcon={false}
                  disabled={isLoading || disabled}
                  onChange={(_, option) =>
                    onChange(index, 'taxResidence', option?.countryCode ?? '')
                  }
                  value={
                    taxResidences[index].taxResidence
                      ? countries.find(
                          (c) =>
                            c.countryCode === taxResidences[index].taxResidence
                        )
                      : null
                  }
                  options={countries}
                  getOptionLabel={(option) =>
                    `${option.countryName} (${option.countryCode})`
                  }
                  id={`countryCode-${index}`}
                  label={t('orgOnboardingTaxIdTask.taxResidenceLabel')}
                  noOptionsText={t('common.nothingFound')}
                />
              </Grid>
              <Grid item xs={6} display="flex">
                <TextField
                  disabled={isLoading || disabled}
                  label={t('orgOnboardingTaxIdTask.taxIdNumberLabel')}
                  name={`taxResidences[${index}].taxId`}
                  value={taxResidences[index].taxId}
                  onChange={(e) => onChange(index, 'taxId', e.target.value)}
                  error={!!errors[index]?.taxId}
                  helperText={errors[index]?.taxId}
                />

                {!!index && (
                  <Box ml={1} flex="1" my="auto">
                    <IconButton
                      disabled={isLoading || disabled}
                      onClick={() =>
                        onFieldsNumberChange([
                          ...taxResidences.slice(0, index),
                          ...taxResidences.slice(index + 1),
                        ])
                      }
                      aria-label="Delete"
                    >
                      <TrashIcon />
                    </IconButton>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}

      {!disabled && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            disabled={isLoading || addFieldDisabled}
            onClick={() =>
              onFieldsNumberChange([
                ...taxResidences,
                { taxId: '', taxResidence: '', primary: false },
              ])
            }
          >
            {t('orgOnboardingTaxIdTask.addTaxIdBtn')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default TaxIdInputGroup;
