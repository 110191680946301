import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import OnboardingResetTaskDialog from 'domains/onboarding/components/OnboardingResetTaskDialog';
import { useGetTasks } from 'domains/onboarding/hooks';
import { getNavigationPath } from 'domains/onboarding/utils';
import { Box, Button } from 'elements';
import {
  OnboardingItemStatus,
  TaskNavigationPropsBase,
} from 'services/constants';
import { useCanUser } from 'services/rbac';

interface Props extends TaskNavigationPropsBase {
  disabled: boolean;
  onSuccess: () => void;
  successBtnText?: string;
}

const OnboardingTaskActions = ({
  isReadOnly,
  taskNavigationItems,
  disabled,
  successBtnText,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { sectionKey, taskId, groupId } = useParams<{
    sectionKey: string;
    taskId: string;
    groupId?: string;
  }>();
  const {
    state: { organization },
  } = useGlobalState();
  const { tasks } = useGetTasks();
  const taskStatus = tasks.find((t) => t.id === taskId)!.status;
  const [isRestTaskDialogOpen, setIsRestTaskDialogOpen] = useState(false);
  const {
    nextTaskId,
    prevTaskId,
    isTaskNavigationEnabled,
  } = taskNavigationItems;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={4}
    >
      <Button
        variant="text"
        component={RouterLink}
        to={{
          pathname: getNavigationPath(organization!.id, sectionKey, {
            taskId: prevTaskId,
            groupId,
          }),
        }}
      >
        {t('common.button.back')}
      </Button>

      <Box sx={{ 'a + button': { ml: 2 } }}>
        {isTaskNavigationEnabled && nextTaskId && (
          <Button
            component={RouterLink}
            variant="text"
            to={{
              pathname: getNavigationPath(organization!.id, sectionKey, {
                taskId: nextTaskId,
                groupId,
              }),
            }}
          >
            {t('common.button.skip')}
          </Button>
        )}

        {!isReadOnly && (
          <Button disabled={disabled} onClick={onSuccess}>
            {successBtnText || t('common.button.continue')}
          </Button>
        )}

        {canUser('org-onboarding-task:reset') &&
          [
            OnboardingItemStatus.submitted,
            OnboardingItemStatus.verified,
          ].includes(taskStatus) && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => setIsRestTaskDialogOpen(true)}
            >
              {t('common.button.reset')}
            </Button>
          )}
      </Box>

      <OnboardingResetTaskDialog
        open={isRestTaskDialogOpen}
        onClose={() => setIsRestTaskDialogOpen(false)}
      />
    </Box>
  );
};

export default OnboardingTaskActions;
