import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import Auth0Callback from 'components/App/Auth0Callback';
import AppErrorOverlay from 'components/AppErrorOverlay';
import OrganizationData from 'components/OrganizationData';
import {
  AdminProfilePage,
  PartnerMembersPage,
  UsersPage,
} from 'domains/member/pages';
import { DashboardPage, OrganizationsPage } from 'domains/organization/pages';
import PortalUserData from './PortalUserData';
import PortalLayout from './layouts/PortalLayout';
import PortalOrgLayout from './layouts/PortalOrgLayout';
import {
  portalOrgPaths,
  portalOrgPathsArray,
  portalPathsArray,
  portalRootPaths,
  portalRootPathsArray,
} from './paths';

function Routes() {
  return (
    <Switch>
      <Route path="/unauthorized">
        <AppErrorOverlay errorCode="UNAUTHORIZED" />
      </Route>
      <Route path="/callback">
        <Auth0Callback />
      </Route>
      <Route exact path={portalPathsArray}>
        <PortalUserData>
          <Route exact path={portalRootPathsArray}>
            <PortalLayout>
              <Route
                exact
                path={portalRootPaths.root}
                render={() => <Redirect to={portalRootPaths.organizations} />}
              />
              <Route
                exact
                path={portalRootPaths.organizations}
                component={OrganizationsPage}
              />
              <Route
                path={portalRootPaths.profile}
                component={AdminProfilePage}
              />
              <Route path={portalRootPaths.users} component={UsersPage} />
              <Route
                exact
                path={portalRootPaths.userDetailsPartial}
                render={(params) => (
                  <Redirect
                    to={`${portalRootPaths.users}${params.location.search}`}
                  />
                )}
              />
              <Route
                path={portalRootPaths.partnerMembers}
                component={PartnerMembersPage}
              />
            </PortalLayout>
          </Route>

          <Route path={portalOrgPathsArray}>
            <PortalOrgLayout>
              <OrganizationData>
                <Route
                  exact
                  path={portalOrgPaths.root}
                  render={({ match }) => (
                    <Redirect
                      to={generatePath(portalOrgPaths.dashboard, match.params)}
                    />
                  )}
                />
                <Route
                  exact
                  path={portalOrgPaths.dashboard}
                  component={DashboardPage}
                />
              </OrganizationData>
            </PortalOrgLayout>
          </Route>
        </PortalUserData>
      </Route>

      <Route path="*">
        <AppErrorOverlay errorCode="PAGE_NOT_FOUND" />
      </Route>
    </Switch>
  );
}

export default Routes;
