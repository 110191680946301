import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  CaretUpDownIcon,
  CheckIcon,
  Chip,
  Divider,
  Link,
  List,
  ListItemButton,
  ListSubheader,
  MenuContainer,
  Typography,
} from 'elements';
import { env, EnvType } from 'env';
import { PartnerMemberMappedDetails } from 'services/constants';
import { appDomainNames } from 'services/constants/appDomainNames';
import { drawerWidth, SidebarMenuButton } from './style';

// todo: styles of this component should be updated/adjusted during the next redesign.
// The component is used only for Partner portal and should be refactored
// when adding to other apps
const SidebarMenu = () => {
  const { t } = useTranslation();
  const {
    state: { member, organization },
  } = useGlobalState();
  const currentMember = member as PartnerMemberMappedDetails;

  const shownEnvs: EnvType['REACT_APP_TARGET_ENV'][] =
    env.REACT_APP_PARTNER_SCOPE === 'pliant-internal'
      ? ['prod', 'staging', 'development']
      : ['prod', 'staging'];

  return (
    <Box pt={3}>
      <MenuContainer
        MenuProps={{
          PaperProps: {
            sx: {
              width: (theme) => `calc(${drawerWidth}px - ${theme.spacing(4)})`,
            },
          },
        }}
        button={
          <SidebarMenuButton
            endIcon={
              !!organization?.name ? null : <CaretUpDownIcon fontSize="small" />
            }
            fullWidth
            variant="outlined"
            disabled={!!organization?.name}
          >
            <Box>
              <Typography
                variant="body2"
                sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
              >
                {organization?.name || currentMember.partnerCompanyName}
              </Typography>
              {!organization?.name && (
                <Chip
                  sx={{ mt: 1 }}
                  label={t(`navigation.menu.${env.REACT_APP_TARGET_ENV}`)}
                  color={
                    env.REACT_APP_TARGET_ENV === 'prod' ? 'info' : 'default'
                  }
                  size="small"
                />
              )}
            </Box>
          </SidebarMenuButton>
        }
        buttonSelectedClass="active"
      >
        <List
          sx={{ width: '100%' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <Box mb={0.5}>
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                sx={{ lineHeight: 2 }}
              >
                {t('navigation.menu.environment')}
              </ListSubheader>
              <Divider />
            </Box>
          }
        >
          {shownEnvs.map((target) => {
            const domainLink =
              appDomainNames[env.REACT_APP_PARTNER_SCOPE]?.[target];
            const isActive = env.REACT_APP_TARGET_ENV === target;

            if (!domainLink) return null;
            return (
              <ListItemButton
                key={target}
                {...(isActive
                  ? { selected: true }
                  : { component: Link, href: `https://${domainLink}` })}
              >
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Chip
                    label={t(`navigation.menu.${target}`)}
                    color={target === 'prod' ? 'info' : 'default'}
                    size="small"
                  />
                  {isActive && <CheckIcon fontSize="small" />}
                </Box>
              </ListItemButton>
            );
          })}
        </List>
      </MenuContainer>
    </Box>
  );
};

export default SidebarMenu;
