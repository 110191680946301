import { Box, MenuItem } from 'elements';
import {
  CodatTrackingCategoryItem,
  CustomFieldOption,
} from 'services/constants';

export interface CodatTrackingCategoryNestedItem
  extends CodatTrackingCategoryItem {
  children: CodatTrackingCategoryNestedItem[];
}

export const createCategoriesHashMap = <
  TKey extends 'mappedProjectId' | 'mappedTeamId' | 'mappedOption'
>(
  array: Array<any>,
  key: TKey
): TKey extends 'mappedOption'
  ? { [id: string]: 'new' | CustomFieldOption }
  : { [id: string]: 'new' | string } =>
  array.reduce(
    (result, item) => ({ ...result, [item.id]: item[key] || 'new' }),
    {}
  );

export const createNestedTrackingCategoriesArray = (
  items: CodatTrackingCategoryItem[],
  parentId:
    | 'all_top_level'
    | 'all'
    | Omit<string, 'all_top_level' | 'all'>
    | null = null, // if null - it means some root tracking category is (pre)selected
  hasChildrenIgnored?: boolean // if true - we should ignore hasChildren property and show all items
) => {
  // in case when we show root items -> we don't need to nest children
  if (parentId === 'all_top_level') {
    return items.map((item) => ({
      ...item,
      children: [],
    }));
  }

  // case when we show all items
  parentId = parentId === 'all' ? null : parentId;

  // nested children case
  const addChildItems = (parentId: string | null) => {
    const children: any = [];
    for (const item of items) {
      if (
        item.parentId === parentId &&
        (hasChildrenIgnored || item.hasChildren)
      ) {
        const newItem: any = {
          ...item,
          children: item.hasChildren ? addChildItems(item.id) : [],
        };

        children.push(newItem);
      }
    }
    return children;
  };

  const nestedArray: CodatTrackingCategoryNestedItem[] = [];
  nestedArray.push(...addChildItems(parentId as string | null));
  return nestedArray;
};

export const getShownTrackingCategoriesList = (
  categories: CodatTrackingCategoryItem[],
  selectedItemId: 'all_top_level' | Omit<string, 'all_top_level'>
) => {
  // return all items for 'all' option
  if (selectedItemId === 'all') return categories;

  // return root items for 'all_top_level' option
  if (selectedItemId === 'all_top_level')
    return categories
      .filter((item) => !item.parentId)
      .map((item) => ({
        ...item,
        children: [],
      }));

  const children: CodatTrackingCategoryItem[] = [];

  const getChildren = (id: string) => {
    for (const item of categories) {
      if (item.parentId === id) {
        children.push(item);
        if (item.hasChildren) getChildren(item.id);
      }
    }
  };
  getChildren(selectedItemId as string);
  return children;
};

export const getTrackingCategoriesFilterItems = (
  trackingCategories: CodatTrackingCategoryNestedItem[]
) => {
  const resultCategories: JSX.Element[] = [];

  const renderNestedFilterItems = (
    categories: CodatTrackingCategoryNestedItem[],
    indentLevel: number
  ) => {
    // indent size is set per level
    const indentSize = indentLevel * 2;

    categories.forEach((category) => {
      resultCategories.push(
        <MenuItem key={category.id} value={category.id}>
          <Box
            sx={{
              ml: (theme) => theme.spacing(indentSize),
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {category.name}
          </Box>
        </MenuItem>
      );
      if (category.children.length > 0)
        renderNestedFilterItems(category.children, indentLevel + 1);
    });
  };

  renderNestedFilterItems(trackingCategories, 0);

  return resultCategories;
};
