import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { internalOrgPaths } from 'components/App';
import CountryFlag from 'components/CountryFlag';
import { OrganizationStatusBadge } from 'domains/organization/components';
import {
  Chip,
  DataGridCell,
  GridColDef,
  gridUtils,
  Link,
  Tooltip,
} from 'elements';
import { PartialOrganization } from 'services/constants';
import OrgBalanceLimitCell from './OrgBalanceLimitCell';
import PartnerCell from './PartnerCell';

const useColumns = (isNonCustomerOrganizationsPage: boolean) => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<
      PartialOrganization
    >[] = isNonCustomerOrganizationsPage
      ? [
          {
            headerName: t('orgsPage.org'),
            field: 'name',
            flex: 200,
            renderCell: ({ row }) => (
              <Tooltip title={row.name} enterDelay={666} enterNextDelay={666}>
                <DataGridCell>
                  <Link
                    component={RouterLink}
                    to={generatePath(internalOrgPaths.creditAndCompliance, {
                      orgId: row.organizationId,
                    })}
                    onClick={(e) => e.stopPropagation()}
                    underline="none"
                    color="inherit"
                  >
                    {row.name}
                  </Link>
                </DataGridCell>
              </Tooltip>
            ),
          },
          {
            headerName: t('orgsPage.country'),
            field: 'country',
            sortable: false,
            flex: 600,
            renderCell: ({ row }) => (
              <DataGridCell>
                <CountryFlag code={row.country} mr={1} />
                {row.country}
              </DataGridCell>
            ),
          },
        ]
      : [
          {
            headerName: t('orgsPage.org'),
            field: 'name',
            flex: 200,
            renderCell: ({ row }) => (
              <Tooltip title={row.name} enterDelay={666} enterNextDelay={666}>
                <DataGridCell>
                  <Link
                    component={RouterLink}
                    to={generatePath(internalOrgPaths.dashboard, {
                      orgId: row.organizationId,
                    })}
                    onClick={(e) => e.stopPropagation()}
                    underline="none"
                    color="inherit"
                  >
                    {row.name}
                  </Link>
                </DataGridCell>
              </Tooltip>
            ),
          },
          {
            headerName: t('orgsPage.country'),
            field: 'country',
            sortable: false,
            flex: 100,
            renderCell: ({ row }) => (
              <DataGridCell>
                <CountryFlag code={row.country} mr={1} />
                {row.country}
              </DataGridCell>
            ),
          },
          {
            headerName: t('orgsPage.config'),
            field: 'config',
            flex: 100,
            sortable: false,
            renderCell: ({ row }) => (
              <PartnerCell
                partnerName={row.partnerName}
                partnerId={row.partnerId}
                status={row.partnerStatus}
              />
            ),
          },
          {
            headerName: t('orgsPage.cardAccountCurrencies'),
            field: 'cardAccountCurrencies',
            flex: 100,
            sortable: false,
            renderCell: ({ row }) => (
              <Tooltip
                disableHoverListener={row.cardAccountCurrencies.length === 1}
                title={row.cardAccountCurrencies.join(', ')}
              >
                <DataGridCell>
                  {
                    <Chip
                      key={row.mainCardAccountCurrency}
                      label={row.mainCardAccountCurrency}
                      size="small"
                      sx={{ mr: 0.5 }}
                    />
                  }
                  {row.cardAccountCurrencies
                    .filter((item) => item !== row.mainCardAccountCurrency)
                    .map((item) => (
                      <Chip
                        key={item}
                        label={item}
                        size="small"
                        sx={{ mr: 0.5 }}
                      />
                    ))}
                </DataGridCell>
              </Tooltip>
            ),
          },
          {
            headerName: t('orgsPage.accountGroups'),
            field: 'accountGroups',
            flex: 150,
            sortable: false,
            renderCell: ({ row }) => (
              <DataGridCell>
                {
                  <Chip
                    key={row.mainCardAccountAccountGroup}
                    label={t(`accountGroup.${row.mainCardAccountAccountGroup}`)}
                    size="small"
                    sx={{ mr: 0.5 }}
                  />
                }
                {row.accountGroups
                  .filter((item) => item !== row.mainCardAccountAccountGroup)
                  .map((item) => (
                    <Chip
                      key={item}
                      label={t(`accountGroup.${item}`)}
                      size="small"
                      sx={{ mr: 0.5 }}
                    />
                  ))}
              </DataGridCell>
            ),
          },
          {
            headerName: t('orgsPage.status'),
            field: 'status',
            flex: 200,
            renderCell: ({ row }) => (
              <OrganizationStatusBadge status={row.status} />
            ),
          },
          {
            headerName: t('orgsPage.members'),
            field: 'membersCount',
            flex: 110,
            sortable: false,
          },
          {
            headerName: t('orgsPage.activeCards'),
            field: 'activeCardsCount',
            flex: 80,
            renderCell: ({ row }) => row.activeCardsCount || 0,
          },
          {
            headerName: t('orgsPage.balanceLimit'),
            field: 'limit',
            flex: 150,
            align: 'right',
            headerAlign: 'right',
            renderCell: ({ row }) => <OrgBalanceLimitCell organization={row} />,
          },
        ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language, isNonCustomerOrganizationsPage]);
};

export default useColumns;
