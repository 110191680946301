import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Box, EditButton, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { RelatedPerson } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getYesNoFromBoolean } from 'services/utils';

interface Props {
  relatedPerson: RelatedPerson;
}

interface State {
  isConfirmationDialiogOpen: boolean;
  isLoading: boolean;
}

const ExcludedFromBankSubmissionButton = ({ relatedPerson }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    state: { organization },
  } = useGlobalState();
  const {
    actions: { fetchRelatedPersons },
  } = useCreditAndComplianceContext();

  const [state, setState] = useState<State>({
    isConfirmationDialiogOpen: false,
    isLoading: false,
  });

  const onSubmit = async () => {
    try {
      await api.updateOrganizationRelatedPerson(
        organization!.id,
        relatedPerson.id.value,
        {
          isExcludedFromSubmission: !relatedPerson.isExcludedFromSubmission
            .value,

          citizenships: relatedPerson.citizenships.value,
          city: relatedPerson.city.value,
          companyShare: relatedPerson.companyShare.value,
          countryCode: relatedPerson.countryCode.value,
          countryOfBirth: relatedPerson.countryOfBirth.value,
          dateOfBirth: relatedPerson.dateOfBirth.value,
          email: relatedPerson.email.value,
          givenName: relatedPerson.givenName.value,
          isFatca: relatedPerson.isFatca.value,
          isPep: relatedPerson.isPep.value,
          kyc: relatedPerson.kyc.value,
          legalRepType: relatedPerson.legalRepType.value,
          legitimation: relatedPerson.legitimation.value,
          mobileNumber: relatedPerson.mobileNumber.value,
          placeOfBirth: relatedPerson.placeOfBirth.value,
          postalCode: relatedPerson.postalCode.value,
          salutation: relatedPerson.salutation.value,
          street: relatedPerson.street.value,
          streetNumber: relatedPerson.streetNumber.value,
          surName: relatedPerson.surName.value,
          taxId: relatedPerson.taxId.value,
          taxResidences: relatedPerson.taxResidences.map((taxResidence) => ({
            primary: taxResidence.primary.value,
            taxId: taxResidence.taxId.value,
            taxResidence: taxResidence.taxResidence.value,
          })),
          uboType: relatedPerson.uboType.value,
        }
      );

      await fetchRelatedPersons();

      setState((prevState) => ({
        ...prevState,
        isConfirmationDialiogOpen: false,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={(theme) => ({
          backgroundColor: theme.palette.grey[100],
          borderRadius: theme.shape.borderRadius + 'px',
          marginRight: theme.spacing(3),
          minHeight: theme.spacing(4.5),
          paddingX: theme.spacing(1),
        })}
      >
        <Typography variant="body2" color="textSecondary" mr={0.5}>
          {t('int.excludedFromBankSubmissionButton.label')}
        </Typography>

        <Typography variant="body2">
          {getYesNoFromBoolean(
            !relatedPerson.isExcludedFromSubmission.value,
            t
          )}
        </Typography>

        <Box ml={0.5}>
          <EditButton
            onClick={(e) => {
              e.stopPropagation();
              setState((prevState) => ({
                ...prevState,
                isConfirmationDialiogOpen: true,
              }));
            }}
            disabled={state.isLoading}
            size="small"
          />
        </Box>
      </Box>

      <ConfirmDialog
        loading={state.isLoading}
        onSuccess={(e) => {
          e.stopPropagation();
          onSubmit();
        }}
        open={state.isConfirmationDialiogOpen}
        onClose={(e) => {
          e.stopPropagation();
          setState((prevState) => ({
            ...prevState,
            isConfirmationDialiogOpen: false,
          }));
        }}
        description={
          <Trans
            i18nKey={`int.excludedFromBankSubmissionButton.confirmationDialogDescription.${
              relatedPerson.isExcludedFromSubmission.value
                ? 'include'
                : 'exclude'
            }`}
            components={{ b: <b /> }}
            values={{
              name: `${relatedPerson.givenName.value} ${relatedPerson.surName.value}`,
            }}
          />
        }
        title={t(
          `int.excludedFromBankSubmissionButton.confirmationDialogTitle.${
            relatedPerson.isExcludedFromSubmission.value ? 'include' : 'exclude'
          }`
        )}
      />
    </>
  );
};

export default ExcludedFromBankSubmissionButton;
