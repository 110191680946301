import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCreditAndComplianceContext from 'domains/creditAndCompliance/context/useCreditAndComplianceContext';
import { Box, EditButton, Typography } from 'elements';
import {
  BillPaymentType,
  OrganizationAccountType,
  OrganizationProductType,
  OrganizationStatus,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import EditAccountTypeDialog from './EditAccountTypeDialog';
import EditBillPaymentTransferTypeDialog from './EditBillPaymentTransferTypeDialog';
import EditProductTypeDialog from './EditProductTypeDialog';

const AccountTypeSection = () => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const {
    state: { organization, defaultCardAccount },
  } = useGlobalState();
  const {
    state: { directDebitInfo, riskAndCompliance },
  } = useCreditAndComplianceContext();
  const [
    isEditAccountTypeDialogOpen,
    setIsEditAccountTypeDialogOpen,
  ] = useState(false);
  const [
    isEditProductTypeDialogOpen,
    setIsEditProductTypeDialogOpen,
  ] = useState(false);
  const [
    isEditBillPaymentTransferTypeDialog,
    setIsEditBillPaymentTransferTypeDialog,
  ] = useState(false);

  const canAccountTypeBeChanged =
    canUser('org-account-type:change') &&
    riskAndCompliance!.accountTypeChangeAllowed;

  const canBillPaymentTransfertTypeBeChanged =
    canUser('org-account-type:change') &&
    !(
      organization!.status === OrganizationStatus.active &&
      defaultCardAccount!.billPaymentTransferType.value ===
        BillPaymentType.wireTransfer &&
      !directDebitInfo
    );

  return (
    <Box display="flex" flexDirection="column" maxWidth="fit-content">
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Box
          display="flex"
          alignItems="center"
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[100],
            borderRadius: theme.shape.borderRadius + 'px',
            minHeight: theme.spacing(4.5),
            paddingX: theme.spacing(1),
          })}
        >
          <Typography variant="body2" color="textSecondary" mr={0.5}>
            {`${t(
              'int.creditAndCompliancePageHeader.accountTypeSection.title'
            )}:`}
          </Typography>

          <Typography variant="body2">
            {t(
              `int.creditAndCompliancePageHeader.accountTypeSection.${
                defaultCardAccount!.accountType.value
              }`
            )}
          </Typography>

          <Box ml={0.5}>
            <EditButton
              onClick={() => setIsEditAccountTypeDialogOpen(true)}
              disabled={!canAccountTypeBeChanged}
              size="small"
            />
          </Box>
        </Box>

        {defaultCardAccount!.accountType.value ===
          OrganizationAccountType.credit && (
          <Box
            display="flex"
            alignItems="center"
            sx={(theme) => ({
              backgroundColor: theme.palette.grey[100],
              borderRadius: theme.shape.borderRadius + 'px',
              marginLeft: theme.spacing(3),
              minHeight: theme.spacing(4.5),
              paddingX: theme.spacing(1),
            })}
          >
            <Typography variant="body2" color="textSecondary" mr={0.5}>
              {`${t(
                'int.creditAndCompliancePageHeader.billPaymentTransferTypeSection.title'
              )}:`}
            </Typography>

            <Typography variant="body2">
              {t(
                `int.creditAndCompliancePageHeader.billPaymentTransferTypeSection.${
                  defaultCardAccount!.billPaymentTransferType.value
                }`
              )}
            </Typography>

            <Box ml={0.5}>
              <EditButton
                onClick={() => setIsEditBillPaymentTransferTypeDialog(true)}
                disabled={!canBillPaymentTransfertTypeBeChanged}
                size="small"
              />
            </Box>
          </Box>
        )}
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={1}>
        <Box display="flex" alignItems="center" mr={2}>
          <Typography variant="subtitle2" mr={0.5} color="textSecondary">
            {`${t(
              'int.creditAndCompliancePageHeader.productTypeSection.mainCardAccount'
            )}:`}
          </Typography>
          <Typography variant="subtitle2">
            {`${t(
              `accountGroup.${defaultCardAccount!.accountGroup!.value}`
            )} (${defaultCardAccount!.currency!.value})`}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" mr={0.5} color="textSecondary">
            {`${t(
              'int.creditAndCompliancePageHeader.productTypeSection.bankAccountProductType'
            )}:`}
          </Typography>
          <Typography variant="subtitle2">
            {organization!.productType}
          </Typography>
          {organization!.productType === OrganizationProductType.CCREU &&
            canUser('org-account-type:change') && (
              <EditButton
                onClick={() => setIsEditProductTypeDialogOpen(true)}
              />
            )}
        </Box>
      </Box>

      <EditAccountTypeDialog
        open={isEditAccountTypeDialogOpen}
        onClose={() => setIsEditAccountTypeDialogOpen(false)}
      />

      <EditProductTypeDialog
        open={isEditProductTypeDialogOpen}
        onClose={() => setIsEditProductTypeDialogOpen(false)}
      />

      <EditBillPaymentTransferTypeDialog
        open={isEditBillPaymentTransferTypeDialog}
        onClose={() => setIsEditBillPaymentTransferTypeDialog(false)}
      />
    </Box>
  );
};

export default AccountTypeSection;
