import React, { useCallback, useEffect, useState } from 'react';
import { allocate, equal, greaterThan, toDecimal } from 'dinero.js';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { useCardAccountNameGetter } from 'domains/card/hooks';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  LoaderWithOverlay,
  MenuItem,
  MoneyField,
  ProhibitIcon,
  Select,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import useSnackbar from 'hooks/useSnackbar';
import {
  BankAccount,
  BankAccountTransfersAllowedStatus,
  CardAccount,
  Money,
  NetworkErrorCode,
  OrganizationAccountType,
  OrganizationBalance,
  Payment,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  getCurrencyByCode,
  getGenericErrorMsg,
  getNetworkErrorCode,
} from 'services/utils';

const dineroFromMoneyInPercent = (money: Money | null, percent: number) => {
  if (!money) return null;
  const dBalance = dineroFromMoney(money);
  const [dValue] = allocate(dBalance, [percent, 100 - percent]);
  return dValue;
};

const findBillingAccountId = (bankAccounts: BankAccount[]) => {
  return bankAccounts.find((item) => item.directDebit)?.id;
};

const findLastUpdatedBankAccountId = (bankAccounts: BankAccount[]): string => {
  const lastUpdatedBankAccount = bankAccounts.reduce((prev, current) =>
    new Date(prev.updatedAt) > new Date(current.updatedAt) ? prev : current
  );
  return lastUpdatedBankAccount.id;
};

const getBankAccountsWithoutBlockedStatus = (bankAccounts: BankAccount[]) => {
  return bankAccounts.filter(
    (item) =>
      item.transfersAllowedStatus !== BankAccountTransfersAllowedStatus.blocked
  );
};

const getSelectedCardAccount = (
  cardAccounts: CardAccount[],
  cardAccountId: string
) => cardAccounts.find((account) => account.id === cardAccountId)!;

interface FormValues {
  payoutAmount: string;
  bankAccountId: string;
  organizationId: string;
}

interface State {
  isBankAccountsListLoading: boolean;
  bankAccounts: BankAccount[];
  isOrgBalanceLoading: boolean;
  organizationBalance: OrganizationBalance | null;
  cardAccountId: string;
}

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (billPayment: Payment) => void;
  visibleCardAccounts?: CardAccount[];
  selectedCardAccount?: CardAccount;
}

const PayoutPaymentDialog = ({
  onSuccess,
  visibleCardAccounts,
  selectedCardAccount: initiallySelectedCardAccount,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const mounted = useMounted();
  const getCardAccountName = useCardAccountNameGetter();
  const partnerName = usePartnerName();
  const {
    state: { organization, defaultCardAccount, cardAccounts, featureModules },
    dispatch,
  } = useGlobalState();
  const getInitiallySelectedCardAccountId = () => {
    if (initiallySelectedCardAccount) return initiallySelectedCardAccount.id;
    const defaultCardAccountId = defaultCardAccount!.id;
    if (!!visibleCardAccounts) {
      if (
        visibleCardAccounts.some(
          (account) => account.id === defaultCardAccountId
        )
      ) {
        return defaultCardAccountId;
      }
      return visibleCardAccounts[0].id;
    }
    return defaultCardAccountId;
  };
  const [state, setState] = useState<State>({
    isBankAccountsListLoading: true,
    bankAccounts: [],
    isOrgBalanceLoading: true,
    organizationBalance: null,
    cardAccountId: getInitiallySelectedCardAccountId(),
  });
  const selectedCardAccount =
    initiallySelectedCardAccount ||
    getSelectedCardAccount(cardAccounts, state.cardAccountId);
  const balance =
    (selectedCardAccount.accountType.value === OrganizationAccountType.prefunded
      ? state.organizationBalance?.availableLimit
      : state.organizationBalance?.balance) || null;
  const currency = balance ? getCurrencyByCode(balance.currency) : null;
  const isLoading =
    state.isBankAccountsListLoading || state.isOrgBalanceLoading;

  const updateBalance = async () => {
    try {
      setState((prevState) => ({ ...prevState, isOrgBalanceLoading: true }));
      const { cardAccounts } = await api.getCardAccounts(organization!.id);
      const organizationBalance = await api.getOrganizationBalance(
        organization!.id,
        state.cardAccountId
      );
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          cardAccounts,
          defaultCardAccount: cardAccounts.find(
            (item) => item.defaultAccount.value
          )!,
        },
      });
      if (!mounted.current) return;
      if (
        (selectedCardAccount.accountType.value ===
          OrganizationAccountType.prefunded &&
          organizationBalance.availableLimit.value <= 0) ||
        (selectedCardAccount.accountType.value ===
          OrganizationAccountType.credit &&
          organizationBalance.balance.value <= 0)
      ) {
        props.onClose();
        enqueueSnackbar(t('payoutPaymentDialog.updatedOrgBalanceZeroError'), {
          variant: 'error',
        });
        return;
      }
      setState((prevState) => ({
        ...prevState,
        isOrgBalanceLoading: false,
        organizationBalance,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      payoutAmount: '',
      bankAccountId: '',
      organizationId: organization!.id,
    },
    validate: ({ payoutAmount }) => {
      const value = +payoutAmount;
      if (payoutAmount && !value) {
        return {
          payoutAmount: t('payoutPaymentDialog.payoutAmountZeroError'),
        };
      }
      if (
        currency &&
        balance &&
        greaterThan(dineroFromFloat(value, currency), dineroFromMoney(balance))
      ) {
        return {
          payoutAmount: t(
            'payoutPaymentDialog.payoutAmountHigherThanLimitError'
          ),
        };
      }
    },
    onSubmit: async (
      { payoutAmount, ...values },
      { setSubmitting, setFieldError }
    ) => {
      try {
        const billPayment = await api.createPayoutPayment({
          ...values,
          payoutAmount: convertDineroToMoney(
            dineroFromFloat(payoutAmount, currency!)
          ),
          cardAccountId: state.cardAccountId,
        });
        const { cardAccounts } = await api.getCardAccounts(organization!.id);
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: {
            cardAccounts,
            defaultCardAccount: cardAccounts.find(
              (item) => item.defaultAccount.value
            )!,
          },
        });
        if (!mounted.current) return;
        onSuccess(billPayment);
        enqueueSnackbar(t('payoutPaymentDialog.successMessage'), {
          variant: 'success',
        });
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (getNetworkErrorCode(error) === NetworkErrorCode.payoutImpossible) {
          setState((prevState) => ({ ...prevState, isLoading: true }));
          await updateBalance();
          if (!mounted.current) return;
          setFieldError(
            'payoutAmount',
            t('payoutPaymentDialog.payoutAmountToHighError')
          );
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    },
  });

  const getData = async () => {
    try {
      const data = await api.getBankAccounts(organization!.id);
      if (!mounted.current) return;
      const bankAccounts = canUser('bank-account-blocked:view')
        ? data.bankAccounts
        : getBankAccountsWithoutBlockedStatus(data.bankAccounts);
      if (!bankAccounts.length) {
        enqueueSnackbar(t('errors.general'), { variant: 'error' });
        return props.onClose();
      }
      setState((prevState) => ({
        ...prevState,
        isBankAccountsListLoading: false,
        bankAccounts,
      }));
      if (bankAccounts.length === 1) {
        await formik.setFieldValue('bankAccountId', bankAccounts[0].id, false);
      } else {
        const selectedBankAccountId =
          findBillingAccountId(bankAccounts) ||
          findLastUpdatedBankAccountId(bankAccounts);
        await formik.setFieldValue(
          'bankAccountId',
          selectedBankAccountId,
          false
        );
      }
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!state.cardAccountId) return;
    updateBalance();
  }, [state.cardAccountId]);

  const selectedBank = state.bankAccounts.find(
    (item) => item.id === formik.values.bankAccountId
  );

  const isSubmitDisabled =
    !formik.values.payoutAmount || !formik.values.bankAccountId;

  const renderPercentButton = (percent: number) => {
    const dineroInPercent = dineroFromMoneyInPercent(balance, percent);
    const isValueSelected =
      formik.values.payoutAmount &&
      isFinite(+formik.values.payoutAmount) &&
      !!currency &&
      !!dineroInPercent &&
      equal(
        dineroFromFloat(formik.values.payoutAmount, currency),
        dineroInPercent
      );

    return (
      <Button
        disabled={!balance}
        variant={isValueSelected ? 'contained' : undefined}
        onClick={() => {
          if (!dineroInPercent) return;
          formik.setFieldValue(
            'payoutAmount',
            toDecimal(dineroInPercent),
            false
          );
          formik.setFieldError('payoutAmount', undefined);
        }}
      >
        {percent}%
      </Button>
    );
  };

  const renderTitle = useCallback(() => {
    if (!!initiallySelectedCardAccount) {
      const name = getCardAccountName(initiallySelectedCardAccount);
      return t('payoutPaymentDialog.titleWithCardAccountName', { name });
    }
    return t('payoutPaymentDialog.title', { partnerName });
  }, [getCardAccountName]);

  const renderBalanceLabel = () => {
    return selectedCardAccount.accountType.value ===
      OrganizationAccountType.prefunded
      ? t('payoutPaymentDialog.availableBalance')
      : t('payoutPaymentDialog.availablePositiveBalance');
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{renderTitle()}</DialogTitle>
      <DialogContent>
        <form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          id="payout-form"
        >
          <Typography variant="body2" mb={2}>
            {t('payoutPaymentDialog.description', { partnerName })}
          </Typography>

          {cardAccounts.length > 1 &&
            !!visibleCardAccounts &&
            !initiallySelectedCardAccount && (
              <Box>
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <InputLabel>
                    {t('payoutPaymentDialog.cardAccountLabel')}
                  </InputLabel>
                  <Select<string>
                    value={state.cardAccountId}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        cardAccountId: e.target.value,
                      }))
                    }
                  >
                    {visibleCardAccounts.map((account) => (
                      <MenuItem value={account.id} key={account.id}>
                        {getCardAccountName(account)}
                        {featureModules.MULTI_CURRENCY_BILLING &&
                          ` (${account.currency.value})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

          <Box
            mb={2}
            px={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2">{renderBalanceLabel()}</Typography>
            <Typography variant="h6" component="div">
              {balance ? <FormatMoney value={balance} fractionalPart /> : '-'}
            </Typography>
          </Box>
          <Box
            mb={2}
            px={2}
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2" mt={1}>
              {t('payoutPaymentDialog.withdrawalAmount')}
            </Typography>
            <Box width="50%">
              <MoneyField
                {...omit(formik.getFieldProps('payoutAmount'), 'onChange')}
                onValueChange={({ value }) => {
                  formik.setFieldValue('payoutAmount', value, false);
                  formik.setFieldError('payoutAmount', undefined);
                }}
                error={!!formik.errors.payoutAmount}
                helperText={formik.errors.payoutAmount || ' '}
                disabled={formik.isSubmitting}
                currency={currency?.code}
                decimalScale={currency?.exponent}
                isNumericString
              />
            </Box>
          </Box>
          <Box mb={5} px={2}>
            <ButtonGroup color="primary" variant="outlined" fullWidth>
              {renderPercentButton(25)}
              {renderPercentButton(50)}
              {renderPercentButton(75)}
              {renderPercentButton(100)}
            </ButtonGroup>
          </Box>
          <Box px={2}>
            <FormControl fullWidth disabled={formik.isSubmitting}>
              <InputLabel id="bank-account-select-label">
                {t('payoutPaymentDialog.bankAccountLabel')}
              </InputLabel>
              <Select<string>
                labelId="bank-account-select-label"
                {...formik.getFieldProps('bankAccountId')}
                renderValue={(selected) => {
                  const selectedBank = state.bankAccounts.find(
                    (item) => item.id === selected
                  );
                  return selectedBank ? selectedBank.bankName : '';
                }}
              >
                {state.bankAccounts.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <ListItemText
                      primary={item.bankName}
                      secondary={item.accountNumber.number}
                    />
                    {item.transfersAllowedStatus ===
                      BankAccountTransfersAllowedStatus.blocked && (
                      <ListItemIcon>
                        <ProhibitIcon color="error" />
                      </ListItemIcon>
                    )}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ whiteSpace: 'pre' }}>
                <span>{selectedBank?.accountNumber.number}</span>
                <span> </span>
                {selectedBank?.transfersAllowedStatus ===
                  BankAccountTransfersAllowedStatus.blocked && (
                  <ProhibitIcon
                    fontSize="small"
                    color="error"
                    sx={{ position: 'absolute', bottom: 1 }}
                  />
                )}
              </FormHelperText>
            </FormControl>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button disabled={isSubmitDisabled} type="submit" form="payout-form">
          {t('payoutPaymentDialog.submit')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={isLoading || formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(PayoutPaymentDialog);
