import React, { useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  DownloadSimpleIcon,
  FileTextIcon,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CrefoReport,
  ExternalReport,
  ExternalReportProvider,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import ArchivedCreditReportsDialog from './ArchivedCreditReportsDialog';
import useDownloadReport from './useDownloadReport';

interface Props {
  allReports: (CrefoReport | ExternalReport)[];
  duns?: string;
}

const LatestReportWithHistory = ({ allReports, duns }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();

  const downloadReport = useDownloadReport();
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);

  const latestReport = allReports[0];
  const isDnbReport =
    'provider' in latestReport &&
    latestReport.provider === ExternalReportProvider.dnb;

  const downloadDnbRawData = async () => {
    try {
      const rawData = await api.getDnbReportRawData(duns!);
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(rawData, null, 2)
      )}`;

      const link = document.createElement('a');
      link.href = jsonString;

      link.download = `${duns}.json`;

      link.click();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(t('errors.featureModulesLoadingFailed'), {
        variant: 'error',
      });
      logError(error);
    }
  };

  return (
    <>
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Link
            component="button"
            onClick={() => downloadReport(latestReport)}
            variant="body2"
            sx={{ alignItems: 'center', display: 'flex' }}
          >
            <FileTextIcon fontSize="inherit" sx={{ marginRight: 1 }} />
            <span>{t('int.agencyProfileSection.creditReport.reportName')}</span>
          </Link>

          {isDnbReport && duns && (
            <Tooltip
              title={t(
                'int.agencyProfileSection.creditReport.downloadDnbRawData'
              )}
            >
              <IconButton size="small" onClick={downloadDnbRawData}>
                <DownloadSimpleIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Typography variant="caption" sx={{ color: 'grey.600' }}>
          <Trans
            i18nKey="int.agencyProfileSection.creditReport.reportMetadata"
            context={allReports.length > 1 ? 'history' : undefined}
            values={{
              downloadedAt: moment(latestReport.downloadedAt).format(
                'D MMM YYYY, HH:mm'
              ),
            }}
            components={{
              button: (
                <Link
                  component="button"
                  onClick={() => setIsArchiveDialogOpen(true)}
                />
              ),
            }}
          />
        </Typography>
      </Box>

      <ArchivedCreditReportsDialog
        reports={allReports}
        open={isArchiveDialogOpen}
        onClose={() => setIsArchiveDialogOpen(false)}
      />
    </>
  );
};

export default LatestReportWithHistory;
