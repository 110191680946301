import React from 'react';
import { portalPaths } from 'components/App/PortalApp/paths';
import { Container, ContainerInner, MainContent } from 'components/App/style';
import { PortalMainHeader } from 'components/MainHeader';
import Sidebar from 'components/Sidebar';

const paths = {
  organizations: portalPaths.organizations,
  users: portalPaths.users,
  partnerMembers: portalPaths.partnerMembers,
};

const PortalLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container>
      <Sidebar portalView paths={paths} />
      <ContainerInner>
        <PortalMainHeader />
        <MainContent>{children}</MainContent>
      </ContainerInner>
    </Container>
  );
};

export default PortalLayout;
