import { useState } from 'react';
import { equal, toDecimal } from 'dinero.js';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  MoneyField,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { OrganizationGroup, OrganizationGroupType } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  getGenericErrorMsg,
} from 'services/utils';

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
  orgGroup: OrganizationGroup;
}

interface State {
  isLoading: boolean;
  maxLimit: string | null;
  name: string;
}

const EditGroupDialog = ({ onSuccess, orgGroup, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState<State>({
    isLoading: false,
    name: orgGroup.name.value,

    maxLimit: orgGroup.maxLimit.value
      ? toDecimal(dineroFromMoney(orgGroup.maxLimit.value!))
      : null,
  });

  const handleSubmit = async () => {
    try {
      await api.editOrganizationGroup(orgGroup.id, {
        name: state.name,
        ...(orgGroup.type === OrganizationGroupType.ru &&
          orgGroup.maxLimit.value && {
            maxLimit: convertDineroToMoney(
              dineroFromFloat(state.maxLimit!, orgGroup.maxLimit.value.currency)
            ),
          }),
      });
      onSuccess();
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const canBeSubmitted =
    (state.name && orgGroup.name.value !== state.name) ||
    (orgGroup.maxLimit?.value?.value &&
      !equal(
        dineroFromMoney(orgGroup.maxLimit.value!),
        dineroFromFloat(state.maxLimit!, orgGroup.maxLimit.value.currency)
      ));

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('int.createGroupDialog.title')}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={t('int.createGroupDialog.name')}
              onChange={(event) =>
                setState((prevState) => ({
                  ...prevState,
                  name: event.target.value,
                }))
              }
              value={state.name}
            />
          </Grid>
          {orgGroup.type === OrganizationGroupType.ru && (
            <Grid item xs={12}>
              <MoneyField
                label={t('int.createGroupDialog.maxLimit')}
                onValueChange={(all) => {
                  console.log(all);
                  setState((prevState) => ({
                    ...prevState,
                    maxLimit: all.value,
                  }));
                }}
                value={state.maxLimit}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={state.isLoading || !canBeSubmitted}
          type="submit"
          onClick={handleSubmit}
        >
          {t('common.button.save')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditGroupDialog);
