import { useEffect } from 'react';
import { useGlobalState, useManagedTeams } from 'context/GlobalState';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import { TransactionReviewStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';

const useGetTransactionsNeedsReviewCounter = (initialFetch = false) => {
  const canUser = useCanUser();
  const { isAdminApp } = useCurrentApp();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    dispatch,
    state: {
      featureModules,
      transactionsNeedsReviewCount: count,
      organization,
    },
  } = useGlobalState();
  const managedTeams = useManagedTeams();
  const isNeedsReviewPageVisible =
    ((isAdminApp && canUser('transaction-review:view')) ||
      canUser('team-transaction-review:view')) &&
    featureModules.MANAGER_TX_REVIEWS;

  useEffect(() => {
    if (!isNeedsReviewPageVisible) return;
    if (!initialFetch) return;
    if (!organization?.id) return;
    (async () => {
      try {
        const { totalCount } = await api.getTransactions({
          organizationId: organization.id,
          page: 0,
          limit: 1,
          teamId: isAdminApp
            ? undefined
            : managedTeams?.map((team) => team.id).join(','),
          reviewStatus: TransactionReviewStatus.needsReview,
        });
        if (!mounted.current) return;
        dispatch({
          type: 'SET_TRANSACTIONS_COUNT',
          payload: { transactionsNeedsReviewCount: totalCount },
        });
      } catch (error) {
        logError(error);
      }
    })();
  }, [isNeedsReviewPageVisible, initialFetch, organization?.id]);

  if (!isNeedsReviewPageVisible) return 0;

  return count;
};

export default useGetTransactionsNeedsReviewCounter;
