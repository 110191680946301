import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Autocomplete, Link, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { CardControlRegion, CardLocationControl } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { Wrapper } from './style';

interface State {
  inputValue: string;
  regions: CardControlRegion[];
  isLoading: boolean;
}

interface Props {
  control: CardLocationControl;
  onChange: (control: CardLocationControl) => void;
}

const LocationRegions = ({ control, onChange }: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    inputValue: '',
    regions: [],
    isLoading: true,
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const regions = await api.getCardControlRegions(organization!.partnerId);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, regions, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    // Get initial set only once and then filter it on FE
    // since BE doesn't support filtering for now
    getData();
  }, []);

  return (
    <Wrapper sx={{ p: 3 }}>
      <Typography variant="subtitle1">
        {t('cardControlFormElement.location.REGION.title')}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={2}>
        <Trans
          i18nKey="cardControlFormElement.location.REGION.description"
          // TODO: link
          components={{
            a: (
              <Link
                href="https://www.getpliant.com"
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      </Typography>
      <Autocomplete<CardControlRegion, false, false, false>
        inputValue={state.inputValue}
        onInputChange={(e, inputValue) =>
          setState((prevState) => ({ ...prevState, inputValue }))
        }
        value={control.displayValues?.[0] || null}
        onChange={(e, value) =>
          onChange({
            ...control,
            values: value ? [value.id] : [],
            displayValues: value ? [value] : [],
          })
        }
        options={state.regions}
        loading={state.isLoading}
        filterOptions={(options, { inputValue }) =>
          options.filter((item) =>
            item.uiName.toLowerCase().includes(inputValue.trim().toLowerCase())
          )
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.uiName}
        searchIcon={false}
        placeholder={t('cardControlFormElement.location.REGION.placeholder')}
        noOptionsText={t('common.nothingFound')}
        loadingText={t('common.loading')}
      />
    </Wrapper>
  );
};

export default LocationRegions;
