import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { useTanstackQuery } from 'services/network/useTanstackQuery';

export const useGetGroupFromGroupId = () => {
  const { groupId } = useParams<{
    groupId?: string;
  }>();
  const {
    state: { organization },
  } = useGlobalState();
  const { useGetOnboardingSections } = useTanstackQuery();

  const { data: sectionsData } = useGetOnboardingSections(organization!.id);

  return useMemo(() => {
    if (!groupId) return null;
    const currentGroupSection = sectionsData?.sections.find((section) =>
      section.groups.find((group) => group.id === groupId)
    );
    return (
      currentGroupSection?.groups.find((group) => group.id === groupId) || null
    );
  }, [groupId, sectionsData]);
};
