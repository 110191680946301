import { useTranslation } from 'react-i18next';
import withPageErrorWrapper from 'hoc/withPageErrorWrapper';
import useCurrentApp from 'hooks/useCurrentApp';
import { PageHeader, PageTitle } from 'layout';
import OrganizationsAndNcoSubPage from './OrganizationsAndNcoSubPage';
import OrganizationsPageDefault from './OrganizationsPage';

const OrganizationsPage = () => {
  const { t } = useTranslation();
  const { isPortalApp } = useCurrentApp();

  return isPortalApp ? (
    <>
      <PageHeader sx={{ pb: 0 }}>
        <PageTitle title={t('orgsPage.orgs')} />
      </PageHeader>

      <OrganizationsAndNcoSubPage />
    </>
  ) : (
    <OrganizationsPageDefault />
  );
};

export default withPageErrorWrapper(OrganizationsPage);
