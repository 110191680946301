import { TFunction } from 'i18next';
import { FileWithPath } from 'react-dropzone';
import {
  NewOnboardingDocument,
  OnboardingDocumentFile,
  OnboardingItemStatus,
} from 'services/constants';
import { ACTION_MODE } from './index';

export const getActionDetails = (
  t: TFunction,
  taskCanBeCompleted: boolean | null,
  docValue: NewOnboardingDocument,
  acceptedFiles: FileWithPath[],
  uploadedFiles: OnboardingDocumentFile[],
  answerLocal: string,
  taskStatus: OnboardingItemStatus
) => {
  const isUploadDisabled =
    docValue.type.maxNumberOfFiles <
      acceptedFiles.length + uploadedFiles.length ||
    docValue.type.minNumberOfFiles >
      acceptedFiles.length + uploadedFiles.length ||
    acceptedFiles.length === 0;

  // case with question type item
  if (docValue.type.question) {
    const answerIsChanged =
      answerLocal.trim() !== (docValue.answerLocal || '').trim();

    if (acceptedFiles.length && answerIsChanged)
      return {
        mode: ACTION_MODE.saveAndUpload,
        isSubmitDisabled: isUploadDisabled || !answerLocal.trim(),
        successBtnText: t('orgOnboardingQATask.saveUploadBtn'),
      };
    if (answerIsChanged)
      return {
        mode: ACTION_MODE.save,
        isSubmitDisabled: !answerLocal.trim(),
        successBtnText: t('common.button.save'),
      };
    if (answerLocal.trim() && acceptedFiles.length) {
      return {
        mode: ACTION_MODE.upload,
        isSubmitDisabled: isUploadDisabled,
        successBtnText: t('common.button.upload'),
      };
    }
    return {
      mode: ACTION_MODE.completeTask,
      isSubmitDisabled:
        !taskCanBeCompleted ||
        taskStatus === OnboardingItemStatus.completed ||
        !!acceptedFiles.length,
      successBtnText: undefined,
    };
  }

  // case with document type item
  return acceptedFiles.length
    ? {
        mode: ACTION_MODE.upload,
        isSubmitDisabled: isUploadDisabled,
        successBtnText: t('common.button.upload'),
      }
    : {
        mode: ACTION_MODE.completeTask,
        isSubmitDisabled:
          !taskCanBeCompleted || taskStatus === OnboardingItemStatus.completed,
        successBtnText: undefined,
      };
};
