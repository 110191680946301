import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import {
  Box,
  DataGrid,
  GridSortModel,
  gridUtils,
  LoaderWithOverlay,
  SuitcaseSimpleIcon,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  DEFAULT_PAGE_LIMIT,
  SettlementGroup,
  SettlementTransaction,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getFilterNullableEntries } from 'services/utils';
import AccountIbanFilterSelect from './AccountIbanFilterSelect';
import Filters from './Filters';
import FiltersChips from './Filters/FiltersChips';
import useColumns from './useColumns';
import { getQueryParams, getSelectedFiltersCount } from './utils';
import withInitialData from './withInitialData';

interface State {
  isLoading: boolean;
  transactions: SettlementTransaction[];
  hasNextPage: boolean;
  totalCount: number;
}

interface Props {
  settlementGroups: SettlementGroup[];
}

const SettlementAccountPage = ({ settlementGroups }: Props) => {
  const { t } = useTranslation();
  const { allowedSortKeys, columns } = useColumns();
  const location = useLocation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const showPageError = useShowPageError();
  const setQueryParam = useSetQueryParam();
  const paramsRef = useRef(getQueryParams(location.search, allowedSortKeys));
  const pageRef = useRef(0);
  const dataGridRef = useGridApiRef();
  const [state, setState] = useState<State>({
    isLoading: true,
    transactions: [],
    hasNextPage: false,
    totalCount: -1,
  });
  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;
  const isEmptyState =
    !state.isLoading && !state.transactions.length && !areFiltersApplied;

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      if (!isLoadMore) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
      }
      const {
        sort,
        q,
        paymentType,
        transactionType,
        fromDate,
        toDate,
        settlementGroupId,
      } = paramsRef.current;
      const {
        transactions,
        hasNextPage,
        totalCount,
      } = await api.getProgramSettlementTransactions({
        page,
        limit,
        sort,
        q: q.length ? q : undefined,
        paymentTypes: paymentType.length ? paymentType.join() : undefined,
        transactionType: transactionType.length
          ? transactionType.join()
          : undefined,
        dateType: fromDate ? 'POSTING' : undefined,
        fromDate: fromDate?.format('YYYY-MM-DD'),
        toDate: toDate?.format('YYYY-MM-DD'),
        settlementGroupId,
      });
      if (!mounted.current) return;
      if (paramsRef.current.settlementGroupId !== settlementGroupId) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        transactions: isLoadMore
          ? [...prevState.transactions, ...transactions]
          : transactions,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });
    paramsRef.current = getQueryParams(location.search, allowedSortKeys);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const handleSortModelChange = (sort: GridSortModel) => {
    if (state.isLoading || !state.transactions.length) return;
    setQueryParam('sort', gridUtils.getNewSortParam(sort));
  };

  const onAccountIbanFilterChange = (value: string) => {
    setQueryParam([
      ...getFilterNullableEntries(paramsRef.current),
      ['settlementGroupId', value],
      ['q', null],
    ]);
  };

  return (
    <>
      <PageHeader sx={{ mb: selectedFiltersCount ? 0 : 2 }}>
        <PageTitle title={t('int.settlementAccountPage.title')} />
        <Box display="flex" alignItems="center">
          <Filters
            params={paramsRef.current}
            selectedFiltersCount={selectedFiltersCount}
            setParam={setQueryParam}
            disabled={isEmptyState}
            totalCount={state.totalCount}
          />
          <AccountIbanFilterSelect
            value={paramsRef.current.settlementGroupId}
            onChange={onAccountIbanFilterChange}
            options={settlementGroups}
          />
        </Box>
        <FiltersChips
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
        />
      </PageHeader>

      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />
        <DataGrid<SettlementTransaction>
          apiRef={dataGridRef}
          initialState={{
            sorting: {
              sortModel: gridUtils.getSortModel(paramsRef.current.sort),
            },
          }}
          getRowId={(row) => row.transactionId}
          loading={state.isLoading}
          rows={state.transactions}
          rowCount={state.totalCount !== -1 ? state.totalCount : undefined}
          columns={columns}
          onSortModelChange={handleSortModelChange}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.transactions.length && areFiltersApplied) {
                return <NothingFound />;
              }
              return (
                <NoData
                  isNewDesign
                  Icon={SuitcaseSimpleIcon}
                  label={t('int.settlementAccountPage.noData')}
                />
              );
            },
            loadingOverlay: () => null,
          }}
          rowHeight={72}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(withInitialData(SettlementAccountPage), {
  permission: 'settlement-account-page:visit',
});
