import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const contentPaddingY = 38;
const contentPaddingX = 42;

export const PageHeader = styled.div`
  position: relative;
  z-index: 10;
  flex: 0 0 auto;
  padding: ${contentPaddingY}px ${contentPaddingX}px 0;
  transition: box-shadow 0.2s;
`;

export const PageTableHeader = styled(PageHeader)`
  z-index: 0;
`;

export const PageContentBox = styled.div`
  overflow: auto;
  flex: 1 1 auto;
  position: relative;
  padding: 20px ${contentPaddingX}px ${contentPaddingY}px;
`;

export const SettingsPageContent = styled(PageContentBox)`
  display: flex;
  flex-direction: column;
`;

const paddedContentMixin = css`
  padding-left: ${contentPaddingX + 80}px;
  padding-right: ${contentPaddingX + 80}px;
`;

export const PageTableContent = styled(PageContentBox)<{ $padded?: boolean }>`
  overflow: hidden;
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  ${({ $padded }) => ($padded ? paddedContentMixin : '')};
`;

/* fix for table expansion */
export const PageTableBox = styled.div`
  min-width: 0;
  flex: 1;
  position: relative;
`;

export const PageFiltersScrollable = styled.div<{ $padded?: boolean }>`
  margin: 0 -42px;
  padding: 30px;
  padding-right: ${({ $padded }) => ($padded ? '122px' : '42px')};
  padding-left: ${({ $padded }) => ($padded ? '106px' : '26px')};
  background-color: ${({ theme }) => theme.colors.neutralVariant80};
`;

export const PageFilters = styled.div<{ $padded?: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin: 0 -42px;
  padding: 30px;
  padding-right: ${({ $padded }) => ($padded ? '122px' : '42px')};
  padding-left: ${({ $padded }) => ($padded ? '106px' : '26px')};
  background-color: ${({ theme }) => theme.colors.neutralVariant80};
`;

export const PageNav = styled.div`
  display: flex;
  margin: 0 -22px 12px;
`;

export const PageNavItem = styled(NavLink)`
  position: relative;
  height: 30px;
  padding: 0 3px;
  margin: 0 20px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey100};
  text-decoration: none;

  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.grey10};

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      border-radius: 1px;
      background: ${({ theme }) => theme.colors.grey10};
    }
  }
`;

/** @deprecated Use PartnerLegalDisclaimer instead */
export const PartnerLegalDisclaimer = styled.div`
  color: ${({ theme }) => theme.colors.grey100};
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.19278px;
  text-align: center;
  max-width: 700px;
  padding-top: 10vh;
  margin: auto auto 0;
`;
