import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import {
  OnboardingTaskActions,
  TaskTitle,
} from 'domains/onboarding/components';
import {
  Alert,
  AlertTitle,
  Box,
  FormControlLabel,
  Link,
  LoaderWithOverlay,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  IdentificationStatus,
  OnboardingItemStatus,
  OnboardingTaskIdentification,
  SUPPORT_EMAIL,
  TaskNavigationPropsBase,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg, validateEmail } from 'services/utils';

const REFETCH_INTERVAL = 1000;

export interface Props extends TaskNavigationPropsBase {
  task: OnboardingTaskIdentification;
  onTaskInvalidate: () => Promise<void>;
  onUpdate: (
    newTask: OnboardingTaskIdentification,
    disabledRedirection?: boolean
  ) => void;
}

const IdentificationTask = ({
  isReadOnly,
  task: taskFromTasksResponse,
  taskNavigationItems,
  onTaskInvalidate,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [refetchInterval, setRefetchInterval] = useState(0);
  const {
    useUpdateIdentification,
    useGetOnboardingIdentificationTask,
  } = useTanstackQuery();

  // in this particular task we need to fire API calls more often and update
  // app state accordingly
  const { data: task, isFetching, error } = useGetOnboardingIdentificationTask(
    organization!.id,
    taskFromTasksResponse.id,
    {
      refetchInterval,
    }
  );

  const { mutate: orgIdentificationMutate } = useUpdateIdentification({
    onSuccess: (response, variables) => {
      if (!mounted.current) return;
      if (variables.data.identificationRequired) {
        setRefetchInterval(REFETCH_INTERVAL);
        setIsConfirmDialogOpen(false);
        return;
      }
      onUpdate(response);
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      formik.setSubmitting(false);
    },
  });

  useEffect(() => {
    // remove polling, when task changes status
    if (
      refetchInterval &&
      task?.data.identificationStatus?.value !==
        IdentificationStatus.notRequested
    ) {
      setRefetchInterval(0);
      onTaskInvalidate();
      formik.setSubmitting(false);
    }
    // if task in Tasks response is outdated, need to update all tasks/sections
    else if (
      task?.data.identificationStatus?.value &&
      task.data.identificationStatus.value !==
        taskFromTasksResponse.data.identificationStatus?.value
    )
      onUpdate(task, true);
  }, [task?.data.identificationStatus?.value]);

  useEffect(() => {
    if (error) {
      formik.setSubmitting(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  }, [error]);

  const identificationStatus = task?.data.identificationStatus;
  const identificationRequired = task?.data.identificationRequired;
  const email = task?.data.email;
  const idNowUrl = task?.data.idNowUrl;

  const isIdentificationOngoing =
    identificationStatus?.value === IdentificationStatus.ongoing;

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      identificationRequired: identificationRequired?.value ?? null,
      email: email?.value || '',
    },
    onSubmit: (values) => {
      orgIdentificationMutate({
        organizationId: organization!.id,
        taskId: task?.id || '',
        data: {
          email: values.email,
          identificationRequired: !!values.identificationRequired,
          requestIdentification: !!values.identificationRequired,
        },
      });
    },
  });

  const { invalidEmailError, emailTooLongError } = validateEmail(
    formik.values.email
  );

  const areFieldsEmpty =
    typeof formik.values.identificationRequired !== 'boolean' ||
    !!(
      formik.values.identificationRequired &&
      (!formik.values.email.trim() || invalidEmailError || emailTooLongError)
    );

  return (
    <>
      <PageTitle pt={0} title={<TaskTitle task={taskFromTasksResponse} />} />
      <Box mb={5} display="flex" alignItems="center">
        <Typography variant="body2" color="textSecondary">
          {t('orgOnboardingIdentificationTask.description')}
        </Typography>
      </Box>
      {task?.data && (
        <>
          {isIdentificationOngoing && idNowUrl!.value && (
            <Alert severity="warning" sx={{ mb: 3 }}>
              <AlertTitle>
                {t('orgOnboardingIdentificationTask.ongoingAlert.title')}
              </AlertTitle>
              <Trans
                i18nKey="orgOnboardingIdentificationTask.ongoingAlert.description"
                values={{ sup: SUPPORT_EMAIL }}
                components={{
                  linkTo: (
                    <Link
                      href={idNowUrl!.value}
                      rel="noopener noreferrer"
                      target="_blank"
                      color="inherit"
                    />
                  ),
                  sup: (
                    <Link
                      href={'mailto:' + SUPPORT_EMAIL}
                      rel="noreferrer"
                      color="inherit"
                    />
                  ),
                  br: <br />,
                }}
              />
            </Alert>
          )}

          <RadioGroup
            value={formik.values.identificationRequired}
            onChange={(_, value) =>
              formik.setFieldValue('identificationRequired', value === 'true')
            }
          >
            <FormControlLabel
              disabled={
                isReadOnly ||
                isIdentificationOngoing ||
                formik.isSubmitting ||
                !identificationRequired?.editable
              }
              value={true}
              control={<Radio />}
              label={t('orgOnboardingIdentificationTask.yesLabel')}
            />

            {formik.values.identificationRequired && (
              <Box mb={2}>
                <TextField
                  disabled={
                    isReadOnly ||
                    isIdentificationOngoing ||
                    formik.isSubmitting ||
                    !email!.editable ||
                    !identificationRequired!.editable
                  }
                  label={t('orgOnboardingIdentificationTask.emailLabel')}
                  {...formik.getFieldProps('email')}
                  error={!!invalidEmailError || !!emailTooLongError}
                  helperText={invalidEmailError || emailTooLongError}
                />
              </Box>
            )}

            <FormControlLabel
              disabled={
                isReadOnly ||
                isIdentificationOngoing ||
                formik.isSubmitting ||
                !identificationRequired!.editable
              }
              value={false}
              control={<Radio />}
              label={t('orgOnboardingIdentificationTask.noLabel')}
            />
          </RadioGroup>

          <Box mt={4}>
            <OnboardingTaskActions
              isReadOnly={isReadOnly || !identificationRequired?.editable}
              taskNavigationItems={taskNavigationItems}
              disabled={
                isIdentificationOngoing ||
                formik.isSubmitting ||
                (task.status !== OnboardingItemStatus.requiresAction &&
                  !formik.dirty) ||
                areFieldsEmpty
              }
              onSuccess={() =>
                formik.values.identificationRequired
                  ? setIsConfirmDialogOpen(true)
                  : formik.handleSubmit()
              }
            />
          </Box>
        </>
      )}

      <LoaderWithOverlay loading={formik.isSubmitting || isFetching} />

      {!task && !isFetching && <NothingFound />}

      <ConfirmDialog
        title={t('orgOnboardingIdentificationTask.confirm.title')}
        description={
          <Trans
            i18nKey="orgOnboardingIdentificationTask.confirm.description"
            values={{ sup: SUPPORT_EMAIL }}
            components={{
              sup: (
                <Link
                  href={'mailto:' + SUPPORT_EMAIL}
                  rel="noreferrer"
                  color="inherit"
                />
              ),
              br: <br />,
            }}
          />
        }
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onSuccess={() => formik.handleSubmit()}
        loading={formik.isSubmitting}
      />
    </>
  );
};

export default IdentificationTask;
