import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FormatMoney from 'components/FormatMoney';
import WidgetError from 'components/WidgetError';
import {
  BatchPaymentsList,
  DirectDebitBadge,
  DownloadPainFileButton,
  PaymentStatusBadge,
  ResubmitPaymentFileButton,
} from 'domains/billing/components';
import { visibleBatchPaymentStatuses } from 'domains/billing/utils';
import {
  ActionBox,
  ActionBoxActions,
  ActionBoxTitle,
  Box,
  Button,
  Chip,
  LoaderWithOverlay,
  Paper,
  Stack,
  Tooltip,
  Typography,
  WarningCircleIcon,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  DetailsDrawer,
  DetailsDrawerContent,
  DetailsDrawerHeader,
  DetailsDrawerProps,
  withDetailsDrawerWrapper,
} from 'layout';
import { BatchPayment, BillPaymentBatch } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface State {
  isLoading: boolean;
  billPaymentBatch: BillPaymentBatch | null;
  batchPayments: BatchPayment[] | null;
  error: unknown;
}

interface Props extends DetailsDrawerProps {
  onUpdate: (billPaymentBatch: BillPaymentBatch) => void;
}

const BillPaymentBatchDetailsPage = ({ onUpdate, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const { batchId } = useParams<{ batchId: string }>();
  const mounted = useMounted();
  const idRef = useRef(batchId);
  const [state, setState] = useState<State>({
    isLoading: true,
    billPaymentBatch: null,
    batchPayments: null,
    error: null,
  });

  const getData = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const [billPaymentBatch, batchPayments] = await Promise.all([
        api.getBillPaymentBatch(batchId),
        api.getBillPaymentBatchPayments(batchId).then(
          (data) => data.payments,
          () => null
        ),
      ]);
      if (!mounted.current || batchId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        billPaymentBatch,
        batchPayments,
        error: null,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current || batchId !== idRef.current) return;
      setState((prevState) => ({
        ...prevState,
        error,
        billPaymentBatch: null,
        isLoading: false,
      }));
      logError(error);
    }
  };

  useEffect(() => {
    if (!batchId) return;
    idRef.current = batchId;
    getData();
  }, [batchId]);

  const hasDirectDebitSent = !!state.billPaymentBatch?.submittedAt;

  const onSubmitDirectDebit = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      await api.submitDirectDebitForBillPayments(
        state.billPaymentBatch!.paymentFileId
      );
      if (!mounted.current || batchId !== idRef.current) return;
      await getData();
      onUpdate(state.billPaymentBatch!);
    } catch (error) {
      if (!mounted.current || batchId !== idRef.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <DetailsDrawer {...props}>
      {state.billPaymentBatch && (
        <>
          <DetailsDrawerHeader>
            <Box display="flex" alignItems="center">
              <Typography variant="h5" mr="auto">
                {state.billPaymentBatch.submissionDate}
              </Typography>
              <DownloadPainFileButton
                painFileName={state.billPaymentBatch.paymentFileName}
              />
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              spacing={1}
              my={2}
              useFlexGap
            >
              <Chip
                label={t(`accountGroup.${state.billPaymentBatch.accountGroup}`)}
                size="small"
              />
              <Chip label={state.billPaymentBatch.currency} size="small" />
              {state.billPaymentBatch.directDebitType && (
                <DirectDebitBadge
                  value={state.billPaymentBatch.directDebitType}
                />
              )}
            </Stack>
            <Box display="flex" alignItems="center">
              {!!state.billPaymentBatch.states.PENDING &&
                moment().diff(
                  moment(state.billPaymentBatch.createdAt),
                  'days'
                ) <= 5 && (
                  <ResubmitPaymentFileButton
                    fileName={state.billPaymentBatch.paymentFileName}
                    onSuccess={getData}
                  />
                )}
              <Box ml="auto">
                <Typography align="right" variant="h5">
                  <FormatMoney
                    value={state.billPaymentBatch.amount}
                    fractionalPart
                  />
                </Typography>
                <Typography align="right" variant="caption" component="div">
                  {t('int.billPaymentsBatchDetailsPage.billPaymentBatchAmount')}
                </Typography>
              </Box>
            </Box>
          </DetailsDrawerHeader>
          <DetailsDrawerContent>
            {!hasDirectDebitSent && (
              <ActionBox
                icon={<WarningCircleIcon fontSize="inherit" />}
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
              >
                <ActionBoxTitle>
                  {t('int.billPaymentsBatchDetailsPage.submitButtonTitle')}
                </ActionBoxTitle>
                <ActionBoxActions>
                  <Button
                    disabled={state.isLoading}
                    onClick={onSubmitDirectDebit}
                  >
                    {t('int.billPaymentsBatchDetailsPage.submitButton')}
                  </Button>
                </ActionBoxActions>
              </ActionBox>
            )}

            <Box
              sx={{ pt: 4, px: 3, pb: 3 }}
              borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
            >
              <Paper variant="outlined" sx={{ py: 1.5, px: 2.5 }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1.5}
                >
                  <Typography variant="body1">
                    {t('int.billPaymentsBatchDetailsPage.count')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {state.billPaymentBatch.count}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1.5}
                >
                  <Typography variant="body1">
                    {t('int.billPaymentsBatchDetailsPage.createdAt')}
                  </Typography>
                  <Tooltip
                    title={t('int.billPaymentsBatchDetailsPage.berlinTime')}
                  >
                    <Typography variant="body2" color="text.secondary">
                      {moment(state.billPaymentBatch.createdAt).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )}
                    </Typography>
                  </Tooltip>
                </Box>
                {state.billPaymentBatch.submittedAt && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={1.5}
                  >
                    <Typography variant="body1">
                      {t('int.billPaymentsBatchDetailsPage.submittedAt')}
                    </Typography>
                    <Tooltip
                      title={t('int.billPaymentsBatchDetailsPage.berlinTime')}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {moment(state.billPaymentBatch.submittedAt).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )}
                      </Typography>
                    </Tooltip>
                  </Box>
                )}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1">
                    {t('int.billPaymentsBatchDetailsPage.plannedPaymentDate')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {state.billPaymentBatch.plannedPaymentDate}
                  </Typography>
                </Box>
              </Paper>
            </Box>

            <Box
              p={3}
              borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
            >
              <Typography variant="overline" component="div">
                {t('int.billPaymentsBatchDetailsPage.state')}
              </Typography>
              <Paper variant="outlined" sx={{ py: 1.5, px: 2.5 }}>
                <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                  {visibleBatchPaymentStatuses
                    .filter(
                      (paymentStatus) =>
                        !!state.billPaymentBatch?.states[paymentStatus]
                    )
                    .map((paymentStatus) => (
                      <PaymentStatusBadge
                        key={paymentStatus}
                        status={paymentStatus}
                        count={
                          state.billPaymentBatch!.states[paymentStatus] || 0
                        }
                      />
                    ))}
                </Stack>
              </Paper>
            </Box>

            <BatchPaymentsList
              onRetry={getData}
              batchPayments={state.batchPayments}
            />
          </DetailsDrawerContent>
        </>
      )}
      {state.error && <WidgetError onReload={getData} />}
      <LoaderWithOverlay loading={state.isLoading} />
    </DetailsDrawer>
  );
};

export default withDetailsDrawerWrapper(BillPaymentBatchDetailsPage);
