import { useGlobalState } from 'context/GlobalState';
import { isTxCustomFieldVisible } from 'domains/transaction/utils';
import { Box } from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import {
  AccountingTransaction,
  CustomField as CustomFieldI,
  CustomFieldStatus,
  CustomFieldType,
  TxCustomField,
  UpdateAccTxnCustomFieldPayload,
} from 'services/constants';
import CustomFieldSelect from './CustomFieldSelect';
import CustomFieldText from './CustomFieldText';

export type CustomFieldExtended = Omit<CustomFieldI, 'defaultValue'> & {
  defaultValue: string;
  createNewField: boolean;
  automatched: boolean;
  customFieldOptionId: string | null;
  customFieldOptionName: string | null;
  customFieldOptionValue: string | null;
};

interface TxCustomFieldObject {
  [id: string]: TxCustomField;
}

const getFieldsToShow = (
  transactionCustomFields: CustomFieldI[],
  fields: AccountingTransaction['txnCustomFieldIdToField'],
  disabled: boolean,
  isAdminApp: boolean
) => {
  if (!transactionCustomFields.length) return [];

  const transactionCustomFieldsByApp = transactionCustomFields.filter((item) =>
    isTxCustomFieldVisible(item, isAdminApp)
  );

  return transactionCustomFieldsByApp.reduce((result, field) => {
    const accTxCustomFieldObject = (fields as TxCustomFieldObject)?.[field.id];

    // Show only active fields or fields from acc tx response with values
    if (
      (field.status === CustomFieldStatus.active && !disabled) ||
      (accTxCustomFieldObject?.type === CustomFieldType.text &&
        accTxCustomFieldObject?.value) ||
      (accTxCustomFieldObject?.type === CustomFieldType.select &&
        accTxCustomFieldObject?.customFieldOptionId)
    ) {
      return [
        ...result,
        {
          ...field,
          label: accTxCustomFieldObject?.label || field.label,
          defaultValue: accTxCustomFieldObject?.value || '',
          automatched: !!accTxCustomFieldObject?.automatched,
          customFieldOptionId:
            accTxCustomFieldObject?.customFieldOptionId || null,
          customFieldOptionName:
            accTxCustomFieldObject?.customFieldOptionName || null,
          customFieldOptionValue:
            accTxCustomFieldObject?.customFieldOptionValue || null,
          // if field is not provided in acc tx, then we should use "create" endpoint
          createNewField: !accTxCustomFieldObject,
        },
      ];
    }

    return result;
  }, [] as CustomFieldExtended[]);
};

interface Props {
  isExportPage: boolean;
  fields: AccountingTransaction['txnCustomFieldIdToField'];
  disabled: boolean;
  organizationId: string;
  cardId: string;
  onUpdate: (
    data: UpdateAccTxnCustomFieldPayload,
    createNewField: boolean
  ) => void;
}

// no need to check permissions as we need the same check as for fetching "transactionCustomFields".
const CustomFields = ({
  isExportPage,
  fields,
  disabled,
  organizationId,
  cardId,
  onUpdate,
}: Props) => {
  const {
    state: { featureModules, transactionCustomFields },
  } = useGlobalState();
  const { isAdminApp } = useCurrentApp();

  const customFieldsArray = getFieldsToShow(
    transactionCustomFields,
    fields,
    disabled,
    isAdminApp
  );

  if (
    !featureModules.TRANSACTION_CUSTOM_FIELDS ||
    customFieldsArray.length === 0
  )
    return null;

  return (
    <>
      {customFieldsArray.map((field) => {
        if (field.type === CustomFieldType.text)
          return (
            <Box mb={1} key={field.id}>
              <CustomFieldText
                field={field}
                disabled={
                  disabled || field.status === CustomFieldStatus.inactive
                }
                organizationId={organizationId}
                cardId={cardId}
                onUpdate={onUpdate}
              />
            </Box>
          );

        if (
          field.type === CustomFieldType.select &&
          field.dropdownOptionsCount > 0
        )
          return (
            <Box mb={1} key={field.id}>
              <CustomFieldSelect
                isExportPage={isExportPage}
                field={field}
                organizationId={organizationId}
                disabled={
                  disabled || field.status === CustomFieldStatus.inactive
                }
                cardId={cardId}
                onUpdate={onUpdate}
              />
            </Box>
          );

        return null;
      })}
    </>
  );
};

export default CustomFields;
