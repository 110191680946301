import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import AppLogo from 'components/AppLogo';
import { Box } from 'elements';
import AppSwitchButton from './AppSwitchButton';
import CardholderNavigation from './CardholderNavigation';
import NotificationsMenu from './NotificationsMenu';
import ReceiptInboxButton from './ReceiptInboxButton';
import SupportMenu from './SupportMenu';
import UserMenu from './UserMenu';
import { MenuSection, StyledMainHeader } from './style';

const CardholderMainHeader = () => {
  const theme = useTheme();

  return (
    <StyledMainHeader navigation>
      <Box
        component={Link}
        to="/"
        sx={{ mr: 5, lineHeight: 1, textDecoration: 'none' }}
      >
        <AppLogo mode={theme.navigationMode} />
      </Box>
      <CardholderNavigation />
      <MenuSection>
        <ReceiptInboxButton />
        <SupportMenu />
        <NotificationsMenu />
        <UserMenu />
        <AppSwitchButton mode={theme.navigationMode} />
      </MenuSection>
    </StyledMainHeader>
  );
};

export default CardholderMainHeader;
