import { TFunction } from 'i18next';
import moment from 'moment';
import { OnboardingTask, OnboardingTaskType } from 'services/constants';

// Used as second line for tasks list in section.
export const getTaskPreselectedText = (task: OnboardingTask, t: TFunction) => {
  switch (task.type) {
    case OnboardingTaskType.organizationName:
      return task.data.tradeName.value || '';
    case OnboardingTaskType.registeredAddress: {
      const addressArray = [];
      if (task.data.streetName.value)
        addressArray.push(
          task.data.streetName.value +
            (task.data.streetNumber.value
              ? ` ${task.data.streetNumber.value}`
              : '')
        );
      if (task.data.postalCode.value)
        addressArray.push(task.data.postalCode.value);
      return addressArray.join(', ') || '';
    }
    case OnboardingTaskType.registeredNumber:
      return task.data.registrationNumber.value || '';
    case OnboardingTaskType.foundationDate:
      return task.data.foundationDate.value
        ? moment(task.data.foundationDate.value).format('DD.MM.YYYY')
        : '';
    case OnboardingTaskType.vatId:
      return task.data.vatId.value || '';
    case OnboardingTaskType.annualRevenue:
      return task.data.annualRevenue.value && t
        ? t(`annualRevenueLabels.${task.data.annualRevenue.value}`)
        : '';
    case OnboardingTaskType.balanceSheetTotal:
      return task.data.balanceSheetTotal.value && t
        ? t(`balanceSheetTotalLabels.${task.data.balanceSheetTotal.value}`)
        : '';
    case OnboardingTaskType.staffHeadcount:
      return task.data.staffHeadcount.value && t
        ? t(`staffHeadcountLabels.${task.data.staffHeadcount.value}`)
        : '';
    case OnboardingTaskType.shareholderList:
    case OnboardingTaskType.businessEstablishmentDocs:
    case OnboardingTaskType.proofOfAddress:
    case OnboardingTaskType.idCopy:
      return task.data.onboardingDocument.value?.files?.length
        ? task.data.onboardingDocument.value.files.map((i) => i.name).join(', ')
        : '';
    case OnboardingTaskType.question: {
      if (
        task.data.onboardingDocument.value?.type.question &&
        task.data.onboardingDocument.value.answerLocal
      )
        return task.data.onboardingDocument.value.answerLocal;
      return task.data.onboardingDocument.value?.files?.length
        ? task.data.onboardingDocument.value.files.map((i) => i.name).join(', ')
        : '';
    }
    case OnboardingTaskType.companyType:
      return task.data.companyType.value && t
        ? t(`orgOnboardingCompanyTypeTask.label.${task.data.companyType.value}`)
        : '';
    case OnboardingTaskType.taxId:
      return task.data.taxResidences.value?.length
        ? `${task.data.taxResidences.value[0].taxResidence} ${task.data.taxResidences.value[0].taxId}`
        : '';

    case OnboardingTaskType.pepFatca:
      const values = [];
      if (task.data.pep.value && t)
        values.push(t('orgOnboardingPepFatcaTask.PEP'));
      if (task.data.fatca.value && t)
        values.push(t('orgOnboardingPepFatcaTask.FATCA'));
      return values.join(', ');

    case OnboardingTaskType.identification:
      return task.data.email.value || '';

    default:
      return '';
  }
};

const getParsedFirstNumber = (range: string) => {
  // parse all numbers in a string
  let numbers = range.match(/\d+/g)?.map(Number);
  if (!numbers) return 0;
  return numbers[0];
};

export const sortLabelsByAmounts = <T,>(array: { value: T; label: string }[]) =>
  array.sort(
    (a, b) => getParsedFirstNumber(a.label) - getParsedFirstNumber(b.label)
  );

export * from './icons';
export * from './navigation';
