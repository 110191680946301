import {
  adminAllPaths,
  AdminAllPathsKeys,
  internalOrgPaths,
  InternalOrgPathsKeys,
} from 'components/App';
import { env } from 'env';

export type PathsKeys = AdminAllPathsKeys | InternalOrgPathsKeys;

// TODO: extend with partnel portal paths
export const getPath = (pathId: PathsKeys) =>
  env.REACT_APP_BUILD_TARGET === 'external'
    ? adminAllPaths[pathId as AdminAllPathsKeys]
    : internalOrgPaths[pathId as InternalOrgPathsKeys];
