import React, { useMemo, useRef, useState } from 'react';
import { isAxiosError } from 'axios';
import { FormikErrors, useFormik } from 'formik';
import { omit } from 'lodash';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import PartnerSelect, { PartnerSelectType } from 'components/PartnerSelect';
import { useGlobalState } from 'context/GlobalState';
import {
  AccountGroupSelect,
  OrganizationToHubspotLinkField,
} from 'domains/organization/components';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountGroup,
  CardAccountCurrency,
  NetworkErrorCode,
  Organization,
  PlatformLegalForm,
  supportedCountries,
  SupportedCountry,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  validateAddress,
} from 'services/utils';

export interface CreateOrgManuallyDialogProps extends DialogProps {
  isNonCustomerOrganizationsPage: boolean;
  pliantSource: PartnerSelectType;
  selectedPartner: PartnerSelectType;
  selectedPartnerRevenueShare: PartnerSelectType;
  onClose: () => void;
  onSuccess: (organization: Organization) => void;
}

interface FormValues {
  accountGroup: AccountGroup | null;
  city: string;
  country: SupportedCountry;
  currency: CardAccountCurrency;
  foundationDate: string;
  platformLegalForm: PlatformLegalForm | null;
  name: string;
  nationalRegisterCourt: string;
  streetName: string;
  streetAdditional: string;
  streetNumber: string;
  tradeRegisterNumber: string;
  postalCode: string;
  partner: PartnerSelectType;
  partnerRevenueShare: PartnerSelectType;
  hubspotId: string;
  partnersOrganizationId: string;
  newOnboardingFlow: boolean;
}

const CreateOrgManuallyDialogInternals = ({
  isNonCustomerOrganizationsPage,
  pliantSource,
  selectedPartner,
  selectedPartnerRevenueShare,
  onSuccess,
  ...props
}: CreateOrgManuallyDialogProps) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { newOnboardingEnabled } = useFlags();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    state: { cardAccountCurrencies, platformLegalForms },
  } = useGlobalState();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const didUserPassConfirmDialog = useRef(false);

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      accountGroup: null,
      city: '',
      country: SupportedCountry.de,
      currency: CardAccountCurrency.EUR,
      foundationDate: '',
      platformLegalForm: null,
      name: '',
      nationalRegisterCourt: '',
      streetName: '',
      streetAdditional: '',
      streetNumber: '',
      tradeRegisterNumber: '',
      postalCode: '',
      partner: selectedPartner,
      partnerRevenueShare: selectedPartnerRevenueShare,
      hubspotId: '',
      partnersOrganizationId: '',
      newOnboardingFlow: false,
    },
    validate: (values) => {
      const billingAddressErrors = validateAddress(
        {
          city: values.city,
          country: values.country,
          postalCode: values.postalCode,
          streetAdditional: values.streetAdditional,
          streetName: values.streetName,
          streetNumber: values.streetNumber,
        },
        t
      );
      const errors: FormikErrors<FormValues> = { ...billingAddressErrors };

      if (moment(values.foundationDate, 'YYYY-MM-DD', true).isValid()) {
        if (!moment(values.foundationDate).isBefore(moment.now(), 'days')) {
          errors['foundationDate'] = t(
            'int.createOrgManuallyDialog.errors.foundationDatePast'
          );
        }
      } else {
        errors['foundationDate'] = t(
          'int.createOrgManuallyDialog.errors.foundationDateFormatInvalid'
        );
      }

      return errors;
    },
    onSubmit: async (
      {
        accountGroup,
        currency,
        name,
        tradeRegisterNumber,
        nationalRegisterCourt,
        foundationDate,
        platformLegalForm,
        partner,
        partnerRevenueShare,
        hubspotId,
        partnersOrganizationId,
        newOnboardingFlow,
        ...rest
      },
      { setSubmitting }
    ) => {
      try {
        if (
          (isSourcePartnerSelected || newOnboardingFlow) &&
          !didUserPassConfirmDialog.current
        ) {
          setIsConfirmationModalOpen(true);
          return;
        }

        const partnerOrgIdTrimmed = partnersOrganizationId.trim();
        const payload = {
          accountGroup: accountGroup!,
          currency,
          name,
          tradeRegisterNumber,
          nationalRegisterCourt,
          foundationDate,
          legalForm: platformLegalForm!.abbreviation,
          billingAddress: {
            streetName: rest.streetName,
            streetNumber: rest.streetNumber,
            streetAdditional: rest.streetAdditional,
            postalCode: rest.postalCode,
            city: rest.city,
            country: rest.country,
          },
          partnerId: partner.partnerId,
          partnerRevenueShareId: partnerRevenueShare.partnerId,
          hubspotId,
          partnersOrganizationId:
            isSourcePartnerSelected && partnerOrgIdTrimmed
              ? partnerOrgIdTrimmed
              : null,
          newOnboardingFlow,
        };

        const organization = isNonCustomerOrganizationsPage
          ? await api.createNonCustomerOrganizationManually(payload)
          : await api.createOrganizationManually(payload);
        if (!mounted.current) return;
        onSuccess(organization);
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        if (
          getNetworkErrorCode(error) === NetworkErrorCode.alreadyExists &&
          isAxiosError(error) &&
          error.response?.data?.message
        ) {
          enqueueSnackbar(error.response.data.message, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
        setIsConfirmationModalOpen(false);
        setSubmitting(false);
      }
    },
  });

  const isSourcePartnerSelected =
    formik.values.partner.partnerId !== pliantSource.partnerId ||
    formik.values.partnerRevenueShare.partnerId !== pliantSource.partnerId;

  const platformLegalFormsByCountry = useMemo(
    () =>
      platformLegalForms.filter(
        (item) => item.country === formik.values.country
      ),
    [formik.values.country, platformLegalForms]
  );

  const isSubmitDisabled =
    formik.isSubmitting ||
    !formik.values.accountGroup ||
    !formik.values.country ||
    !formik.values.name ||
    !formik.values.platformLegalForm ||
    !formik.values.streetName ||
    !(
      formik.values.country === SupportedCountry.ie ||
      formik.values.streetNumber.trim()
    ) ||
    !formik.values.postalCode ||
    !formik.values.city ||
    (formik.values.country === SupportedCountry.de &&
      !formik.values.nationalRegisterCourt) ||
    !formik.values.tradeRegisterNumber ||
    !formik.values.foundationDate ||
    (!isNonCustomerOrganizationsPage && !formik.values.hubspotId);

  if (isConfirmationModalOpen)
    return (
      <ConfirmDialog
        {...props}
        loading={formik.isSubmitting}
        title={t(
          `int.createOrgConfirmationDialog.${
            isSourcePartnerSelected
              ? 'partnerRevenueShare'
              : 'newOnboardingFlow'
          }.title`
        )}
        description={
          <>
            {isSourcePartnerSelected && (
              <Trans
                i18nKey="int.createOrgConfirmationDialog.partnerRevenueShare.description"
                components={{
                  item: <Box mt={2} />,
                  partner: <Box style={{ fontWeight: 'bold' }} />,
                }}
                values={{
                  revenueSharePartner: formik.values.partnerRevenueShare.name,
                  configurationPartner: formik.values.partner.name,
                }}
              />
            )}

            {isSourcePartnerSelected && formik.values.newOnboardingFlow && (
              <Box py={2}>
                <Divider />
              </Box>
            )}

            {formik.values.newOnboardingFlow && (
              <Trans
                i18nKey="int.createOrgConfirmationDialog.newOnboardingFlow.description"
                components={{
                  b: <b />,
                  br: <br />,
                }}
              />
            )}
          </>
        }
        onClose={() => setIsConfirmationModalOpen(false)}
        onSuccess={() => {
          didUserPassConfirmDialog.current = true;
          formik.handleSubmit();
        }}
      />
    );

  return (
    <Dialog {...props}>
      <DialogTitle>
        {isNonCustomerOrganizationsPage
          ? t('int.createOrgManuallyDialog.addNewNco')
          : t('int.createOrgManuallyDialog.addNewOrg')}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="create-org-manually-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            {!isNonCustomerOrganizationsPage && (
              <>
                <Grid item xs={6}>
                  <PartnerSelect
                    disabled={formik.isSubmitting}
                    label={t('int.partnerSelect.revenueLabel')}
                    tooltipLabel={t('int.partnerSelect.revenueTooltip')}
                    {...omit(
                      formik.getFieldProps('partnerRevenueShare'),
                      'onChange'
                    )}
                    onChange={(value) => {
                      formik.setFieldValue('partnerRevenueShare', value, false);
                      formik.setFieldValue('partner', value, false);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PartnerSelect
                    disabled={formik.isSubmitting}
                    label={t('int.partnerSelect.configLabel')}
                    tooltipLabel={t('int.partnerSelect.configTooltip')}
                    isPartnerConfig
                    {...omit(formik.getFieldProps('partner'), 'onChange')}
                    onChange={(value) =>
                      formik.setFieldValue('partner', value, false)
                    }
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <TextField
                disabled={!formik.values.country || formik.isSubmitting}
                label={t('int.createOrgManuallyDialog.name')}
                placeholder={t('int.createOrgManuallyDialog.name')}
                {...formik.getFieldProps('name')}
                onChange={(e) => {
                  formik.setFieldValue('hubspotId', '', false);
                  formik.setFieldValue('name', e.target.value, false);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete<SupportedCountry, false, true, false>
                value={formik.values.country}
                onChange={(_, option) => {
                  formik.resetForm();
                  formik.setFieldValue('country', option);
                }}
                options={supportedCountries}
                disableClearable
                disabled={formik.isSubmitting}
                label={t('int.createOrgManuallyDialog.country')}
                placeholder={t('int.createOrgManuallyDialog.country')}
                getOptionLabel={(option) => t(`countries.${option}`)}
                noOptionsText={t('common.nothingFound')}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth disabled={formik.isSubmitting}>
                <InputLabel id="main-card-account-currency-select-label">
                  {t('int.createOrgDialog.currency')}
                </InputLabel>
                <Select
                  value={formik.values.currency}
                  onChange={(event) =>
                    formik.setFieldValue(
                      'currency',
                      event.target.value as CardAccountCurrency
                    )
                  }
                  labelId="main-card-account-currency-select-label"
                >
                  {cardAccountCurrencies.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <AccountGroupSelect
                country={formik.values.country}
                currency={formik.values.currency}
                partnerId={formik.values.partner.partnerId}
                value={formik.values.accountGroup}
                onChange={(accountGroup) =>
                  formik.setFieldValue('accountGroup', accountGroup, false)
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete<PlatformLegalForm, false, false, false>
                value={formik.values.platformLegalForm}
                onChange={(_, option) => {
                  formik.setFieldValue('hubspotId', '', false);
                  formik.setFieldValue('platformLegalForm', option, false);
                }}
                options={platformLegalFormsByCountry}
                disabled={formik.isSubmitting}
                label={t('int.createOrgManuallyDialog.legalForm')}
                placeholder={t('int.createOrgManuallyDialog.legalForm')}
                getOptionLabel={(option) => option.abbreviation}
                noOptionsText={t('common.nothingFound')}
              />
            </Grid>

            {!isNonCustomerOrganizationsPage && (
              <Grid item xs={12}>
                <OrganizationToHubspotLinkField
                  disabled={
                    !formik.values.name || !formik.values.platformLegalForm
                  }
                  validHsOrgNames={
                    formik.values.name && formik.values.platformLegalForm
                      ? [
                          `${formik.values.name} ${formik.values.platformLegalForm.abbreviation}`,
                          `${formik.values.name}`,
                        ]
                      : []
                  }
                  onSuccess={(hsId) => formik.setFieldValue('hubspotId', hsId)}
                  resetOnChange={() => {
                    if (formik.values.hubspotId) {
                      formik.setFieldValue('hubspotId', '');
                    }
                  }}
                />
              </Grid>
            )}
            {(pliantSource.partnerId !== formik.values.partner.partnerId ||
              pliantSource.partnerId !==
                formik.values.partnerRevenueShare.partnerId) && (
              <Grid item xs={6}>
                <TextField
                  label={t(
                    'int.createOrgManuallyDialog.partnersOrganizationId'
                  )}
                  placeholder={t(
                    'int.createOrgManuallyDialog.partnersOrganizationId'
                  )}
                  inputProps={{ maxLength: 100 }}
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('partnersOrganizationId')}
                />
              </Grid>
            )}

            {newOnboardingEnabled && (
              <Grid item xs={12}>
                <FormControlLabel
                  {...formik.getFieldProps('newOnboardingFlow')}
                  control={<Switch />}
                  label={t(
                    'int.createOrgManuallyDialog.newOnboardingFlowLabel'
                  )}
                />
              </Grid>
            )}
          </Grid>

          <Box mt={4} mb={1}>
            <Typography variant="overline">
              {t('int.createOrgManuallyDialog.billingAddress')}
            </Typography>
          </Box>

          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={6}>
              <TextField
                disabled={!formik.values.country || formik.isSubmitting}
                label={t('int.createOrgManuallyDialog.streetName')}
                placeholder={t('int.createOrgManuallyDialog.streetName')}
                {...formik.getFieldProps('streetName')}
                error={!!formik.errors.streetName}
                helperText={formik.errors.streetName}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled={!formik.values.country || formik.isSubmitting}
                label={t('int.createOrgManuallyDialog.streetNumber')}
                placeholder={t('int.createOrgManuallyDialog.streetNumber')}
                {...formik.getFieldProps('streetNumber')}
                error={!!formik.errors.streetNumber}
                helperText={formik.errors.streetNumber}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled={!formik.values.country || formik.isSubmitting}
                label={t('int.createOrgManuallyDialog.streetAdditional')}
                placeholder={t('int.createOrgManuallyDialog.streetAdditional')}
                {...formik.getFieldProps('streetAdditional')}
                error={!!formik.errors.streetAdditional}
                helperText={formik.errors.streetAdditional}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled={!formik.values.country || formik.isSubmitting}
                label={t('int.createOrgManuallyDialog.postalCode')}
                placeholder={t('int.createOrgManuallyDialog.postalCode')}
                error={!!formik.errors.postalCode}
                helperText={formik.errors.postalCode}
                {...formik.getFieldProps('postalCode')}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled={!formik.values.country || formik.isSubmitting}
                label={t('int.createOrgManuallyDialog.city')}
                placeholder={t('int.createOrgManuallyDialog.city')}
                {...formik.getFieldProps('city')}
                error={!!formik.errors.city}
                helperText={formik.errors.city}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled={true}
                label={t('int.createOrgManuallyDialog.country')}
                placeholder={t('int.createOrgManuallyDialog.country')}
                {...omit(formik.getFieldProps('country'), ['value'])}
                value={
                  formik.values.country
                    ? t(`countries.${formik.values.country}`)
                    : ''
                }
                error={!!formik.errors.country}
                helperText={formik.errors.country}
              />
            </Grid>
          </Grid>

          <Box mt={4} mb={1}>
            <Typography variant="overline">
              {t('int.createOrgManuallyDialog.company')}
            </Typography>
          </Box>

          <Grid container columnSpacing={3} rowSpacing={2}>
            {formik.values.country === SupportedCountry.de && (
              <Grid item xs={6}>
                <TextField
                  disabled={!formik.values.country || formik.isSubmitting}
                  label={t('int.createOrgManuallyDialog.nationalRegisterCourt')}
                  placeholder={t(
                    'int.createOrgManuallyDialog.nationalRegisterCourt'
                  )}
                  {...formik.getFieldProps('nationalRegisterCourt')}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <TextField
                disabled={!formik.values.country || formik.isSubmitting}
                label={t('int.createOrgManuallyDialog.tradeRegisterNumber')}
                placeholder={t(
                  'int.createOrgManuallyDialog.tradeRegisterNumber'
                )}
                {...formik.getFieldProps('tradeRegisterNumber')}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="date"
                disabled={!formik.values.country || formik.isSubmitting}
                label={t('int.createOrgManuallyDialog.foundationDate')}
                error={!!formik.errors.foundationDate}
                helperText={
                  !!formik.errors.foundationDate && formik.errors.foundationDate
                }
                {...formik.getFieldProps('foundationDate')}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="create-org-manually-form"
          type="submit"
        >
          {t('int.createOrgManuallyDialog.addOrg')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<CreateOrgManuallyDialogProps>(
  CreateOrgManuallyDialogInternals
);
