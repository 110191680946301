import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { Grid, LabeledValue } from 'elements';
import MainCardAccountAccountGroupGroupChangeDialog from './MainCardAccountAccountGroupGroupChangeDialog';

const MainCardAccountAccountGroupGroup = () => {
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();

  const {
    state: { defaultCardAccount },
  } = useGlobalState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Grid item xs={12} display="flex">
        <LabeledValue
          label={t(
            'int.generalTermsPage.onboardingSettingsSection.mainCurrency'
          )}
          value={defaultCardAccount!.currency!.value}
        />
        <LabeledValue
          label={t(
            'int.generalTermsPage.onboardingSettingsSection.mainCardAccountAccountGroup'
          )}
          value={t(`accountGroup.${defaultCardAccount!.accountGroup!.value}`)}
          {...(organization!.canAccountGroupBeChanged && {
            onEdit: () => setIsDialogOpen(true),
          })}
        />
      </Grid>

      <MainCardAccountAccountGroupGroupChangeDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default MainCardAccountAccountGroupGroup;
