import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { PaymentStatusBadge } from 'domains/billing/components';
import { visibleBatchPaymentStatuses } from 'domains/billing/utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import {
  AccountGroup,
  accountGroups,
  DirectDebitType,
  directDebitTypes,
  PaymentStatus,
} from 'services/constants';
import { QueryParams } from '.././index';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  fromDate: Moment | null;
  toDate: Moment | null;
  accountGroup: AccountGroup | '';
  type: DirectDebitType | '';
  status: PaymentStatus[];
}

const initialState: State = {
  fromDate: null,
  toDate: null,
  accountGroup: '',
  type: '',
  status: [],
};

const FiltersDrawer = ({ params, setParam, ...props }: Props) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>(initialState);

  const areFiltersSelected = !!(
    state.fromDate ||
    state.toDate ||
    state.accountGroup ||
    state.type ||
    state.status.length
  );

  useEffect(() => {
    if (props.open)
      setState({
        fromDate: params.fromDate,
        toDate: params.toDate,
        accountGroup: params.accountGroup,
        type: params.type,
        status: params.status,
      });
  }, [params, props.open]);

  const onFiltersApply = () => {
    setParam(
      Object.entries({
        ...state,
        fromDate: state.fromDate?.format('YYYY-MM-DD'),
        toDate: state.toDate?.format('YYYY-MM-DD'),
      })
    );
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.entries(initialState));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <DateFilter
        label={t('filters.period')}
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate,
            toDate,
          }))
        }
      />

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-account-group"
          id="panel-account-group-header"
        >
          <Typography>{t('int.filters.accountGroup')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth>
            <Select
              size="medium"
              displayEmpty
              value={state.accountGroup}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  accountGroup: e.target.value as State['accountGroup'],
                }))
              }
            >
              <MenuItem value="">
                <Typography color="text.disabled">
                  {t('int.filters.accountGroup')}
                </Typography>
              </MenuItem>
              {accountGroups.map((item) => (
                <MenuItem key={item} value={item}>
                  {t(`int.accountGroupSelect.items.${item}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-type"
          id="panel-type-header"
        >
          <Typography>{t('filters.type')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth>
            <Select<DirectDebitType>
              size="medium"
              displayEmpty
              value={state.type}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  type: e.target.value as State['type'],
                }))
              }
            >
              <MenuItem value="">
                <Typography color="text.disabled">
                  {t('filters.type')}
                </Typography>
              </MenuItem>
              {directDebitTypes.map((directDebitType) => (
                <MenuItem key={directDebitType} value={directDebitType}>
                  {t(`directDebitTypes.${directDebitType}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-status"
          id="panel-status-header"
        >
          <Typography>{t('filters.status')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <FormGroup>
              {visibleBatchPaymentStatuses.map((status) => (
                <FormControlLabel
                  key={status}
                  checked={state.status.includes(status)}
                  control={<Checkbox />}
                  onChange={() =>
                    setState((prevState) => ({
                      ...prevState,
                      status: xor(prevState.status, [status]),
                    }))
                  }
                  label={<PaymentStatusBadge status={status} />}
                />
              ))}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </FilterDrawer>
  );
};

export default FiltersDrawer;
