import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { cardholderPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import { useGetTransactionsNeedsReviewCounter } from 'domains/transaction/hooks';
import { Badge } from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import { Tab, Tabs } from 'layout';
import { useCanUser } from 'services/rbac';

const ChTransactionPagesTabs = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { isCardholderApp } = useCurrentApp();
  const canUser = useCanUser();
  const {
    state: { featureModules },
  } = useGlobalState();
  const transactionsNeedsReviewCount = useGetTransactionsNeedsReviewCounter();

  const isTeamTransactionsPageVisible =
    canUser('ch-team-transactions-page:visit') && featureModules.TEAMS;

  const isTransactionsReviewPageVisible =
    canUser('team-transaction-review:view') &&
    featureModules.MANAGER_TX_REVIEWS;

  if (
    !isCardholderApp ||
    (!isTeamTransactionsPageVisible && !isTransactionsReviewPageVisible)
  )
    return null;

  return (
    <Tabs aria-label="Cardholder transaction page tabs" value={path}>
      <Tab
        component={Link}
        value={cardholderPaths.transactions}
        to={cardholderPaths.transactions}
        data-intercom-target="ch-all-transactions-tab"
        label={t('cardholderTransactionsPageContainer.myTransactions')}
      />
      {isTeamTransactionsPageVisible && (
        <Tab
          component={Link}
          value={cardholderPaths.teamTransactions}
          to={cardholderPaths.teamTransactions}
          data-intercom-target="ch-team-transactions-tab"
          label={t('cardholderTransactionsPageContainer.teamTransactions')}
        />
      )}
      {isTransactionsReviewPageVisible && (
        <Tab
          component={Link}
          value={cardholderPaths.transactionsReview}
          to={cardholderPaths.transactionsReview}
          data-intercom-target="ch-review-transactions-tab"
          sx={{ overflow: 'visible' }}
          label={
            <>
              <Badge
                color="secondary"
                badgeContent={transactionsNeedsReviewCount}
                showZero={false}
                sx={{
                  '.MuiBadge-badge': {
                    m: 'auto',
                    ml: 1,
                    transform: 'none',
                    position: 'static',

                    '&.MuiBadge-invisible': { display: 'none' },
                  },
                }}
              >
                {t('cardholderTransactionsPageContainer.needsReview')}
              </Badge>
            </>
          }
        />
      )}
    </Tabs>
  );
};

export default ChTransactionPagesTabs;
