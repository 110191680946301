import { EnvType } from 'env';

// Actual links/domain names to different apps and environments.
// No need in placing them into env variables,
// as they are used for apps switch
export const appDomainNames: Partial<Record<
  EnvType['REACT_APP_PARTNER_SCOPE'],
  Record<EnvType['REACT_APP_TARGET_ENV'], string>
>> = {
  pliant: {
    development: 'app.dev.infinnitytest.com',
    staging: 'app.staging.infinnitytest.com',
    prod: 'app.getpliant.com',
  },
  'pliant-internal': {
    development: 'admin.dev.infinnitytest.com',
    staging: 'admin.staging.infinnitytest.com',
    prod: 'admin.getpliant.com',
  },
  portal: {
    development: 'portal.dev.infinnitytest.com',
    staging: 'portal.staging.infinnitytest.com',
    prod: 'portal.getpliant.com',
  },
  bbva: {
    development: '',
    staging: 'bbva.staging.infinnitytest.com',
    prod: 'bbva.paypliant.com',
  },
  bizaway: {
    development: '',
    staging: 'bizaway.staging.infinnitytest.com',
    prod: 'paywith.bizaway.com',
  },
  intertours: {
    development: 'intertours.dev.infinnitytest.com',
    staging: 'intertours.staging.infinnitytest.com',
    prod: 'pay.intertours.de',
  },
  commerzbank: {
    development: '',
    staging: 'commerzbank.staging.infinnitytest.com',
    prod: 'commerzbank.paypliant.com',
  },
};
