import { HTMLAttributes, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import usePartnerName from 'hooks/usePartnerName';
import { PageContentBox, PartnerLegalDisclaimer } from './style';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

/** @deprecated Use PageContent from layout instead */
export const PageContent = ({ children, ...props }: Props) => {
  const { t } = useTranslation();
  const partnerName = usePartnerName();
  const {
    state: { isComplianceRiskWhiteLabelApp },
  } = useGlobalState();

  return (
    <PageContentBox {...props}>
      {children}
      {isComplianceRiskWhiteLabelApp && (
        <PartnerLegalDisclaimer>
          {t('page.partnerLegalDisclaimer', { partnerName })}
        </PartnerLegalDisclaimer>
      )}
    </PageContentBox>
  );
};
