import { isNil, omitBy } from 'lodash';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  OnboardingDocsComponent,
  TaskTitle,
} from 'domains/onboarding/components';
import { QuestionIcon, Tooltip, Typography } from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { PageTitle } from 'layout';
import { OnboardingTaskQA, TaskNavigationPropsBase } from 'services/constants';

export interface Props extends TaskNavigationPropsBase {
  task: OnboardingTaskQA;
  onUpdate: (newTask: OnboardingTaskQA) => void;
  onInvalidate: () => Promise<void>;
}

const QATask = (props: Props) => {
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();
  const partnerName = usePartnerName();
  const {
    task: {
      data: {
        onboardingDocument: { value },
      },
    },
  } = props;
  const docValue = value!;

  const hasPredefinedDescription = !!t(
    `onboardingDocumentType.description.${docValue.type.name}`,
    {
      defaultValue: false,
    }
  );
  const hasTooltip = !!t(
    `onboardingDocumentType.fileTypeTooltip.${docValue.type.name}`,
    { defaultValue: false, partnerName }
  );

  return (
    <>
      <PageTitle
        pt={0}
        title={
          <>
            <TaskTitle task={props.task} />{' '}
            {hasTooltip && (
              <Tooltip
                title={
                  <Trans
                    i18nKey={`onboardingDocumentType.fileTypeTooltip.${docValue.type.name}`}
                    components={{ b: <b />, br: <br /> }}
                    values={{
                      orgName: docValue.attributes.orgName ?? '',
                      partnerName,
                    }}
                  />
                }
              >
                <QuestionIcon />
              </Tooltip>
            )}
          </>
        }
      />

      <Typography variant="body2" color="textSecondary" mb={4}>
        {hasPredefinedDescription ? (
          <Trans
            i18nKey={`onboardingDocumentType.description.${docValue?.type.name}`}
            components={{
              b: <b />,
              br: <br />,
              li: <li />,
              ul: <ul />,
            }}
            values={{
              ...omitBy(docValue?.attributes, isNil),
              ...(docValue?.attributes?.date && {
                date: moment(docValue.attributes.date, 'YYYY-MM-DD').format(
                  'DD.MM.YYYY'
                ),
              }),
              orgName: organization!.name,
            }}
          />
        ) : (
          docValue?.descriptionLocal || ''
        )}
      </Typography>

      <OnboardingDocsComponent {...props} />
    </>
  );
};

export default QATask;
