import { alpha, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  MagnifyingGlassPlusIcon,
  Paper,
  ReceiptXIcon,
  StatusBadge,
} from 'elements';
import { ReceiptsAutoMatchingStatus } from 'services/constants';

const getThumbnail = (
  status: ReceiptsAutoMatchingStatus,
  thumbnail: string | null,
  isLoading: boolean
) => {
  if (isLoading || status === ReceiptsAutoMatchingStatus.processing)
    return {
      styles: { borderStyle: 'dashed' },
      component: <CircularProgress size="small" />,
    };

  if (!thumbnail && status === ReceiptsAutoMatchingStatus.notReadable)
    return {
      styles: { borderColor: (theme: Theme) => theme.palette.error.main },
      component: <ReceiptXIcon color="error" />,
    };

  if (thumbnail) {
    return {
      styles: {
        cursor: 'pointer',
        backgroundImage: `url('data:image/jpeg;base64,${thumbnail}')`,
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        ...(status === ReceiptsAutoMatchingStatus.notReadable
          ? { borderColor: (theme: Theme) => theme.palette.error.main }
          : {}),
        svg: { opacity: 0, transition: '0.2s' },
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: (theme: Theme) =>
            status === ReceiptsAutoMatchingStatus.notReadable
              ? alpha(theme.palette.error.main, 0.1)
              : 'transparent',
          transition: '0.2s',
        },
        '&:hover': {
          svg: { opacity: 1 },
          '&:after': {
            backgroundColor: 'rgba(0, 0, 0, 0.10)',
          },
        },
      },
      component: (
        <MagnifyingGlassPlusIcon
          fontSize="large"
          sx={{
            position: 'relative',
            zIndex: 1,
            color: (theme) => theme.palette.primary.contrastText,
          }}
        />
      ),
    };
  }

  return { styles: {}, component: null };
};

interface Props {
  status: ReceiptsAutoMatchingStatus;
  thumbnail: string | null;
  ereceipt: boolean;
  isLoading: boolean;
  onPreviewOpen: () => void;
}

const ReceiptInboxGridThumbnail = ({
  status,
  thumbnail,
  ereceipt,
  isLoading,
  onPreviewOpen,
}: Props) => {
  const { t } = useTranslation();
  const { styles, component } = getThumbnail(status, thumbnail, isLoading);

  return (
    <Paper
      sx={{
        height: 192,
        my: 1,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        border: (theme) =>
          `1px solid ${theme.variables.states.primary.outlinedBorder}`,
        borderRadius: (theme) => theme.shape.borderRadius + 'px',
        ...styles,
      }}
      onClick={(e) => {
        e.stopPropagation();
        onPreviewOpen();
      }}
    >
      {component}
      {ereceipt && (
        <StatusBadge
          label={t('common.badge.einvoice')}
          variant="filled"
          color="primary"
          sx={(theme) => ({
            position: 'absolute',
            bottom: theme.spacing(1.5),
            right: theme.spacing(1.5),
          })}
        />
      )}
    </Paper>
  );
};

export default ReceiptInboxGridThumbnail;
