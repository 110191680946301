import { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import { useGlobalState } from 'context/GlobalState';
import { getDocumentUrl } from 'domains/document/utils';
import { ModuleTile } from 'domains/settings/pages/FeatureModulesPage/useModuleTiles';
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Link,
  StatusBadge,
  Tooltip,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import usePartnerName from 'hooks/usePartnerName';
import useSnackbar from 'hooks/useSnackbar';
import useUrls from 'hooks/useUrls';
import { FeatureModuleKey, SubscriptionPlan } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, mapFeatureModules } from 'services/utils';
import CardRequestsSettings from './CardRequestsSettings';
import Dependencies from './Dependencies';
import ManagerTxReviewsSettings from './ManagerTxReviewsSettings';
import PliantEarthActivationConfirmationDialog from './PliantEarthActivationConfirmationDialog';
import ReceiptManagementSettings from './ReceiptManagementSettings';

const HeroImage = styled('div')<{ $image: string }>`
  height: 224px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ $image }) => `url(${$image})`};
`;

interface Props extends DialogProps {
  moduleTile: ModuleTile | null;
  subscriptionPlan: SubscriptionPlan;
  onClose: () => void;
}

const ManageFeatureModuleDialog = ({
  moduleTile: moduleTileNullable,
  subscriptionPlan,
  ...props
}: Props) => {
  const { t, i18n } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const { HELP_CENTER_URL } = useUrls();
  const moduleTile = useMemo(() => moduleTileNullable!, []);
  const partnerName = usePartnerName();
  const {
    dispatch,
    state: { member, organization, documents, featureModulesWithData },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [canDeactivateSecurityKey, setCanDeactivateSecurityKey] = useState(
    false
  );
  const [
    companyReimbursementTermsAndConditions,
    setCompanyReimbursementTermsAndConditions,
  ] = useState({
    alreadyAccepted: true,
    accepted: false,
  });

  useEffect(() => {
    if (moduleTile.key === FeatureModuleKey.securityKeyAuthentication) {
      getSecurityKeyExclusiveCount();
    }

    if (moduleTile.key === FeatureModuleKey.companyReimbursement) {
      getCompanyReimbursementAlreadyAcceptedTermsAndConditions();
    }
  }, [moduleTile.key]);

  const getSecurityKeyExclusiveCount = async () => {
    try {
      const { count } = await api.getSecurityKeyExclusiveCount(
        organization!.id
      );
      if (!mounted.current) return;
      setCanDeactivateSecurityKey(count === 0);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const getCompanyReimbursementAlreadyAcceptedTermsAndConditions = async () => {
    try {
      const {
        isAccepted,
      } = await api.getCompanyReimbursementAlreadyAcceptedTermsAndConditions(
        organization!.id
      );
      if (!mounted.current) return;
      setCompanyReimbursementTermsAndConditions({
        alreadyAccepted: isAccepted,
        accepted: isAccepted,
      });
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const actionButtonVisible =
    canUser('feature-module:toggle') && !moduleTile.isActive;
  const settingsChangeAllowed =
    canUser('feature-module:toggle') ||
    canUser('internal-feature-module:toggle');

  const toggleFeatureModule = async () => {
    try {
      setIsLoading(true);
      if (
        moduleTile.key === FeatureModuleKey.companyReimbursement &&
        !companyReimbursementTermsAndConditions.alreadyAccepted
      ) {
        await api.acceptCompanyReimbursementTermsAndConditions(
          organization!.id,
          member.id
        );
      }
      const data = await api.toggleFeatureModule(organization!.id, {
        feature: moduleTile!.key,
        enabled: !moduleTile.isActive,
      });
      dispatch({
        type: 'SET_FEATURE_MODULES',
        payload: mapFeatureModules(data),
      });
      if (!mounted.current) return;
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setIsConfirming(false);
      setIsLoading(false);
      logError(error);
    }
  };

  const renderActionButton = (buttonProps?: Partial<ButtonProps>) => {
    const showWrongPlanDisabledReason =
      moduleTile.isPremium && !subscriptionPlan.premiumFeaturesEnabled;

    return (
      <Tooltip
        title={
          (showWrongPlanDisabledReason &&
            t(
              'manageFeatureModuleDialog.disabledReasons.featureNotAvailableOnCurrentPlanTooltip'
            )) ||
          (moduleTile.key === FeatureModuleKey.securityKeyAuthentication &&
            moduleTile.isActive &&
            !canDeactivateSecurityKey &&
            t(
              'manageFeatureModuleDialog.disabledReasons.someMembersHaveSecurityKey'
            )) ||
          (moduleTile.key === FeatureModuleKey.companyReimbursement &&
            !companyReimbursementTermsAndConditions.accepted &&
            t(
              'manageFeatureModuleDialog.disabledReasons.termsAndConditionsNotAccepted'
            )) ||
          moduleTile.disabledReason ||
          ''
        }
        placement="top"
      >
        <Box>
          <Button
            onClick={() => setIsConfirming(true)}
            disabled={
              showWrongPlanDisabledReason ||
              !!moduleTile.disabledReason ||
              (moduleTile.key === FeatureModuleKey.securityKeyAuthentication &&
                moduleTile.isActive &&
                !canDeactivateSecurityKey) ||
              (moduleTile.key === FeatureModuleKey.companyReimbursement &&
                !companyReimbursementTermsAndConditions.accepted)
            }
            {...buttonProps}
          >
            {moduleTile.isActive
              ? t('manageFeatureModuleDialog.deactivate')
              : t('manageFeatureModuleDialog.activate')}
          </Button>
        </Box>
      </Tooltip>
    );
  };

  if (isConfirming) {
    if (!moduleTile.isActive && moduleTile.key === FeatureModuleKey.pliantEarth)
      return <PliantEarthActivationConfirmationDialog {...props} />;

    const getConfirmationDescription = () => {
      if (moduleTile.isActive && moduleTile.deactivateConfirmationDescription) {
        // this is old way of handling translations and should be removed once all keys are translated
        return moduleTile.deactivateConfirmationDescription;
      }

      const baseKey = moduleTile.isActive
        ? 'manageFeatureModuleDialog.deactivateDescription'
        : 'manageFeatureModuleDialog.activateDescription';
      // this check will be removed with a new implementetion
      // and replaced with baseKey.default
      const defaultDescriptionKey = moduleTile.isActive
        ? 'manageFeatureModuleDialog.deactivateConfirmationDescription'
        : 'manageFeatureModuleDialog.activateConfirmationDescription';

      return i18n.exists(`${baseKey}.${moduleTile.key}`)
        ? t(`${baseKey}.${moduleTile.key}`)
        : t(defaultDescriptionKey);
    };

    return (
      <ConfirmDialog
        {...props}
        title={t(
          moduleTile.isActive
            ? 'manageFeatureModuleDialog.deactivateConfirmationTitle'
            : 'manageFeatureModuleDialog.activateConfirmationTitle',
          { featureModuleName: t(`featureModuleNames.${moduleTile.key}`) }
        )}
        description={
          <>
            {moduleTile.isActive &&
              moduleTile.key === FeatureModuleKey.privateExpense &&
              featureModulesWithData.COMPANY_REIMBURSEMENT.enabled && (
                <Trans
                  i18nKey={
                    'manageFeatureModuleDialog.deactivateDescription.PRIVATE_EXPENSE_WITH_COMPANY_REIMBURSEMENT'
                  }
                  components={{ br: <br /> }}
                />
              )}
            {getConfirmationDescription()}
          </>
        }
        onClose={() => setIsConfirming(false)}
        onSuccess={toggleFeatureModule}
        loading={isLoading}
      />
    );
  }

  return (
    <Dialog {...props}>
      <HeroImage $image={moduleTile.dialogHeroImage} />

      <DialogTitle display="flex" alignItems="center">
        {t(`featureModuleNames.${moduleTile.key}`)}
        {moduleTile.isActive && (
          <StatusBadge
            sx={{ ml: 2 }}
            label={t('manageFeatureModuleDialog.active')}
            color="success"
          />
        )}
        {moduleTile.isPremium && !subscriptionPlan.premiumFeaturesEnabled && (
          <Tooltip
            title={t(
              'manageFeatureModuleDialog.disabledReasons.featureNotAvailableOnCurrentPlanTooltip'
            )}
            placement="top"
          >
            <StatusBadge
              sx={{ ml: 1 }}
              label={t('common.badge.upgrade')}
              color="warning"
            />
          </Tooltip>
        )}
      </DialogTitle>

      <DialogContent>
        <Typography>
          <Trans
            i18nKey={`manageFeatureModuleDialog.moduleDescriptions.${moduleTile.key}`}
            components={{
              br: <br />,
            }}
            values={{ partnerName }}
          />
        </Typography>

        <Box mt={3}>
          <Link
            variant="body1"
            href={moduleTile.helpCenterUrl || HELP_CENTER_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('manageFeatureModuleDialog.goToHelpCenter')}
          </Link>
        </Box>

        {settingsChangeAllowed &&
          moduleTile.key === FeatureModuleKey.cardRequests && (
            <CardRequestsSettings />
          )}
        {settingsChangeAllowed &&
          moduleTile.key === FeatureModuleKey.managerTxReviews && (
            <ManagerTxReviewsSettings />
          )}
        {settingsChangeAllowed &&
          moduleTile.key === FeatureModuleKey.receiptManagement && (
            <ReceiptManagementSettings />
          )}

        <Dependencies data={moduleTile.dependsOn} />

        {moduleTile.key === FeatureModuleKey.companyReimbursement &&
          !companyReimbursementTermsAndConditions.alreadyAccepted &&
          !!documents && (
            <FormControlLabel
              sx={{ mt: 3 }}
              control={<Checkbox />}
              checked={companyReimbursementTermsAndConditions.accepted}
              label={
                <Trans
                  i18nKey={`manageFeatureModuleDialog.companyReimbursementTermsAndConditions`}
                  components={{
                    a: (
                      <Link
                        href={getDocumentUrl(
                          'COMPANY_REIMBURSEMENT_ORGANIZATION.pdf'
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                  }}
                />
              }
              onChange={(_, checked) =>
                setCompanyReimbursementTermsAndConditions({
                  ...companyReimbursementTermsAndConditions,
                  accepted: checked,
                })
              }
            />
          )}
      </DialogContent>

      <DialogActions>
        {canUser('feature-module:toggle') && moduleTile.isActive && (
          <Box mr="auto">
            {renderActionButton({ color: 'error', variant: 'text' })}
          </Box>
        )}

        <Button
          onClick={props.onClose}
          variant={actionButtonVisible ? 'text' : 'contained'}
        >
          {t('manageFeatureModuleDialog.close')}
        </Button>

        {actionButtonVisible && <Box ml={1}>{renderActionButton()}</Box>}
      </DialogActions>
    </Dialog>
  );
};

export default withDialogWrapper(ManageFeatureModuleDialog);
