import React, { PropsWithChildren, useEffect } from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { useGlobalState } from 'context/GlobalState';
import { env } from 'env';
import useIdTokenCustomData from 'hooks/useIdTokenCustomData';
import { SupportedWebAppLanguage } from 'services/constants';
import { getMemberRole } from 'services/rbac';

const IntercomWrapper = ({ children }: PropsWithChildren<{}>) => {
  const {
    state: {
      organization,
      member,
      memberships,
      featureModules,
      jwtPayload,
      defaultCardAccount,
    },
  } = useGlobalState();
  const { boot, shutdown } = useIntercom();
  const { lang } = useIdTokenCustomData();

  useEffect(() => {
    if ('organizationId' in member && member?.firstName && organization?.name) {
      boot({
        name: `${member.firstName} ${member.lastName}`,
        email: member.email,
        userId: member.id,
        company: {
          companyId: organization.id,
          name: organization.name,
        },
        userHash: member.hashedId,
        customAttributes: {
          environment: env.REACT_APP_TARGET_ENV,
          current_organization: organization.name,
          current_organization_status: organization.status,
          current_role: getMemberRole(member.roles),
          partner_name: organization.partnerName,
          account_type: defaultCardAccount!.accountType.value,
          bank_account_product_type: organization.productType,
          multi_org_user: memberships.length > 1,
          create_card_request_available:
            featureModules.CARD_REQUESTS &&
            jwtPayload.permissions.includes('CreateCardRequest'),
        },
        languageOverride:
          lang.slice(0, 2) === SupportedWebAppLanguage.de
            ? SupportedWebAppLanguage.de
            : SupportedWebAppLanguage.en,
      });

      return () => shutdown();
    }
  }, [member?.firstName, organization?.name]);

  return <>{children}</>;
};

const withIntercom = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => (props: P): JSX.Element | null => {
  return (
    <IntercomProvider appId={env.REACT_APP_INTERCOM_APP_ID!}>
      <IntercomWrapper>
        <Component {...props} />
      </IntercomWrapper>
    </IntercomProvider>
  );
};

export default withIntercom;
