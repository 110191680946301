import { JWT_CLAIM_NAMESPACE } from 'services/constants';
import getAttributes from './attributes';
import { Role } from './constants';
import { JwtPayload } from './types';

export const getMemberRole = (roles: string[]) => {
  if (roles.includes(Role.accountant)) return Role.accountant;
  if (roles.includes(Role.orgAdmin)) return Role.orgAdmin;
  if (roles.includes(Role.accountOwner)) return Role.accountOwner;
  if (roles.includes(Role.auditor)) return Role.auditor;
  return Role.cardholder;
};

export const getPartnerMemberRole = (roles: string[]) => {
  if (roles.includes(Role.partnerAdmin)) return Role.partnerAdmin;
  return Role.partnerOps;
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const stringifiedPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  const jsonPayload = JSON.parse(stringifiedPayload);

  return {
    ...jsonPayload,
    [`${JWT_CLAIM_NAMESPACE}/attr`]: getAttributes(
      jsonPayload[`${JWT_CLAIM_NAMESPACE}/attr`]
    ),
  };
};

export const getJwtPayload = (token: string): JwtPayload => {
  const payload = parseJwt(token);

  return {
    '3dsAuthRequest': payload[`${JWT_CLAIM_NAMESPACE}/3dsAuthRequest`],
    attr: payload[`${JWT_CLAIM_NAMESPACE}/attr`],
    roles: payload[`${JWT_CLAIM_NAMESPACE}/roles`],
    permissions: payload.permissions,
  };
};
