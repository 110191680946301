import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGlobalState } from 'context/GlobalState';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  BankAccount,
  BankSubmission,
  ComplianceCompany,
  ComplianceRating,
  ComplianceStaticData,
  CreditAssessment,
  CreditAssessmentLimits,
  CreditAssessmentUnderwritingCase,
  CreditProfile,
  CrefoReport,
  DirectDebitInfo,
  ExternalReport,
  ExternalReportProvider,
  InternalIndustryType,
  NetworkErrorCode,
  OnboardingDocument,
  OnboardingDocumentCategory,
  OnboardingDocumentName,
  OnboardingDocumentStatus,
  OnboardingDocumentType,
  OrganizationStatus,
  RelatedPerson,
  RiskAndCompliance,
  SpanishProvince,
  SupportedCountry,
  TacMetadata,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import { generatateOnboardingDocumentTypeGroups } from './onboardingDocumentTypesHelper';
import { sortOrgRepresentatives } from './representativeHelper';

type OnboardingDocumentTypeGroups = {
  complianceOnboardingDocumentTypeSubcategories: string[];
  onboardingDocumentTypeByName: null | {
    [key: string]: OnboardingDocumentType;
  };
  onboardingDocumentTypeNamesBySubcategory: null | {
    [key: string]: OnboardingDocumentName[];
  };
  riskOnboardingDocumentTypeSubcategories: string[];
};

type State = {
  bankAccounts: BankAccount[];
  bankSubmissions: BankSubmission[];
  canDocumentsSectionBeEdited: boolean;
  canDocumentsBeRequestedByCategory: {
    [OnboardingDocumentCategory.COMPLIANCE]: boolean;
    [OnboardingDocumentCategory.RISK]: boolean;
  };
  canRiskDocumentsBeRequested: boolean;
  complianceCompany: ComplianceCompany | null;
  complianceStaticData: ComplianceStaticData | null;
  creditAssessment: CreditAssessment | null;
  creditProfile: CreditProfile | null;
  internalIndustryTypes: InternalIndustryType[];
  crefoReportsMetaData: CrefoReport[];
  directDebitInfo: DirectDebitInfo | null;
  externalReportsMetaData: ExternalReport[];
  isInitialLoading: boolean;
  isLoading: boolean;
  isRequesting: boolean;
  monitoringStatuses: {
    crefoMonitoringActive: boolean;
    dnbMonitoringActive: boolean;
  } | null;
  onboardingDocumentTypeGroups: OnboardingDocumentTypeGroups | null;
  onboardingDocumentTypes: OnboardingDocumentType[];
  onboardingDocuments: OnboardingDocument[];
  representatives: RelatedPerson[];
  riskAndCompliance: RiskAndCompliance | null;
  spanishProvinces: SpanishProvince[];
  termsAndConditions: TacMetadata[] | null;
};

interface CreditAndComplianceContextType {
  actions: {
    addRepresentative: (createdRep: RelatedPerson) => void;
    archiveDocument: (
      documentId: string,
      setIsDocumentCardLoading: (isLoading: boolean) => void
    ) => Promise<void>;
    completeCompliance: (callback: () => void) => Promise<void>;
    completeCreditAssessment: (callback: () => void) => Promise<void>;
    completePreCheck: (callback: () => void) => Promise<void>;
    fetchBankSubmissions: () => Promise<void>;
    fetchCrefoReportsMetaData: () => Promise<void>;
    fetchExternalReportsMetaData: () => Promise<void>;
    fetchOnboardingDocuments: () => Promise<void>;
    fetchRelatedPersons: () => Promise<void>;
    refetchCompleteContext: () => Promise<void>;
    refetchComplianceCompany: () => Promise<void>;
    refetchMonitoringStatuses: () => Promise<void>;
    requestAlltDocumentsInDraft: (
      category: OnboardingDocumentCategory
    ) => Promise<void>;
    revertOrganizationStatus: (
      newOrgStatus: OrganizationStatus,
      callback: () => void
    ) => Promise<void>;
    setComplianceRating: (payload: ComplianceRating) => void;
    setCreditAssessmentLimits: (payload: CreditAssessmentLimits) => void;
    setOnboardingDocuments: (payload: OnboardingDocument[]) => void;
    setUnderwritingCase: (payload: CreditAssessmentUnderwritingCase) => void;
    updateOnboardingDocument: (payload: OnboardingDocument) => void;
    updateRepresentative: (updatedRep: RelatedPerson) => void;
  };
  state: State & { isNonCustomer: boolean };
}

const CreditAndComplianceContext = createContext<
  CreditAndComplianceContextType
>({
  actions: {} as CreditAndComplianceContextType['actions'],
  state: {
    bankAccounts: [],
    bankSubmissions: [],
    canDocumentsSectionBeEdited: false,
    canDocumentsBeRequestedByCategory: {
      [OnboardingDocumentCategory.COMPLIANCE]: false,
      [OnboardingDocumentCategory.RISK]: false,
    },
    canRiskDocumentsBeRequested: false,
    complianceCompany: null,
    complianceStaticData: null,
    creditAssessment: null,
    creditProfile: null,
    internalIndustryTypes: [],
    crefoReportsMetaData: [],
    directDebitInfo: null,
    externalReportsMetaData: [],
    isInitialLoading: true,
    isLoading: false,
    isNonCustomer: false,
    isRequesting: false,
    monitoringStatuses: null,
    onboardingDocumentTypeGroups: null,
    onboardingDocumentTypes: [],
    onboardingDocuments: [],
    representatives: [],
    riskAndCompliance: null,
    spanishProvinces: [],
    termsAndConditions: null,
  },
});
CreditAndComplianceContext.displayName = 'CreditAndComplianceContext';

interface CreditAndComplianceProviderProps {
  children: React.ReactNode;
}

const CreditAndComplianceProvider = ({
  children,
}: CreditAndComplianceProviderProps) => {
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
    dispatch,
  } = useGlobalState();
  const organizationId = organization!.id;

  const [creditAndComplianceState, setCreditAndComplianceState] = useState<
    State
  >({
    bankAccounts: [],
    bankSubmissions: [],
    canDocumentsSectionBeEdited: false,
    canDocumentsBeRequestedByCategory: {
      [OnboardingDocumentCategory.COMPLIANCE]: false,
      [OnboardingDocumentCategory.RISK]: false,
    },
    canRiskDocumentsBeRequested: false,
    complianceCompany: null,
    complianceStaticData: null,
    internalIndustryTypes: [],
    creditAssessment: null,
    creditProfile: null,
    crefoReportsMetaData: [],
    directDebitInfo: null,
    externalReportsMetaData: [],
    isInitialLoading: true,
    isLoading: false,
    isRequesting: false,
    monitoringStatuses: null,
    onboardingDocumentTypeGroups: null,
    onboardingDocumentTypes: [],
    onboardingDocuments: [],
    representatives: [],
    riskAndCompliance: null,
    spanishProvinces: [],
    termsAndConditions: null,
  });

  const synchMonitoringStatuses = useCallback(
    async (creditProfile: CreditProfile) => {
      try {
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));

        const [
          { active: crefoMonitoringActive },
          { active: dnbMonitoringActive },
        ] = await Promise.all([
          api.getMonitoringStatus(organizationId, ExternalReportProvider.crefo),
          creditProfile.agencyProfile.duns.value
            ? (async () => {
                try {
                  return await api.getMonitoringStatus(
                    creditProfile.agencyProfile.duns.value,
                    ExternalReportProvider.dnb
                  );
                } catch (e) {
                  if (
                    getNetworkErrorCode(e) ===
                    NetworkErrorCode.dnbTestDataNotFound
                  ) {
                    return Promise.resolve({ active: false });
                  }
                  throw e;
                }
              })()
            : Promise.resolve({ active: false }),
        ]);
        if (!mounted.current) return;
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          isLoadong: false,
          monitoringStatuses: {
            crefoMonitoringActive,
            dnbMonitoringActive,
          },
        }));
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        logError(error);
      }
    },
    [mounted.current]
  );

  const getOrgDirectDebitInfo = async () => {
    try {
      const directDebitInfo = await api.getSddMandate(organization!.id);
      return directDebitInfo;
    } catch (error) {
      if (getNetworkErrorCode(error) === NetworkErrorCode.notFound) {
        return null;
      } else {
        throw error;
      }
    }
  };

  const getData = useCallback(async () => {
    try {
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const [
        riskAndCompliance,
        creditProfile,
        creditAssessment,
        { bankAccounts },
        { onboardingDocumentTypes },
        { onboardingDocuments },
        { termsAndConditions },
        complianceCompany,
        complianceStaticData,
        { internalIndustryTypes },
      ] = await Promise.all([
        api.getRiskAndCompliance(organization!.id),
        api.getCreditProfile(organization!.id),
        api.getCreditAssessment(organization!.id),
        api.getBankAccounts(organization!.id),
        api.getOnboardingDocumentTypes(),
        api.getOnboardingDocuments(organization!.id),
        api.getTermsAndConditions(organization!.id),
        api.getComplianceCompany(organization!.id),
        api.getComplianceStaticData(),
        api.getInternalIndustries(),
      ]);

      const [
        { reportsPaths: crefoReportsMetaData },
        { reportsPaths: externalReportsMetaData },
        bankSubmissions,
        { relatedPersons: representatives },
        { spanishProvinces },
        directDebitInfo,
      ] = await Promise.all([
        api.getCrefoReports(organization!.id),
        creditProfile.agencyProfile.duns.value
          ? api.getExternalReports(creditProfile.agencyProfile.duns.value)
          : Promise.resolve({ reportsPaths: [] }),
        api.getBankSubmissions(organization!.id),
        api.getRelatedPersons(organization!.id),
        organization!.billingAddress.country === SupportedCountry.es
          ? api.getSpanishProvinces()
          : Promise.resolve({ spanishProvinces: [] }),
        getOrgDirectDebitInfo(),
        synchMonitoringStatuses(creditProfile),
      ]);

      if (!mounted.current) return;
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        isLoading: false,
        bankAccounts,
        bankSubmissions,
        onboardingDocumentTypes,
        onboardingDocumentTypeGroups: generatateOnboardingDocumentTypeGroups(
          onboardingDocumentTypes
        ),
        onboardingDocuments,
        creditAssessment,
        creditProfile,
        riskAndCompliance,
        termsAndConditions,
        complianceCompany,
        internalIndustryTypes,
        complianceStaticData,
        crefoReportsMetaData,
        externalReportsMetaData,
        representatives: sortOrgRepresentatives(representatives),
        spanishProvinces,
        directDebitInfo,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  }, [organizationId]);

  const getInitialData = async () => {
    await getData();
    setCreditAndComplianceState((prevState) => ({
      ...prevState,
      isInitialLoading: false,
    }));
  };

  useEffect(() => {
    getInitialData();
  }, [getData]);

  const addRepresentative = useCallback(
    (createdRepresentative: RelatedPerson) => {
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        representatives: sortOrgRepresentatives([
          ...prevState.representatives,
          createdRepresentative,
        ]),
      }));
    },
    [mounted.current]
  );

  const archiveDocument = useCallback(
    async (
      documentId: string,
      setIsDocumentCardLoading: (isLoading: boolean) => void
    ) => {
      try {
        setIsDocumentCardLoading(true);
        await api.archiveOnboardingDocument(organization!.id, documentId);
        if (!mounted.current) return;
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          onboardingDocuments: prevState.onboardingDocuments.map((doc) =>
            doc.id === documentId
              ? {
                  ...doc,
                  status: OnboardingDocumentStatus.ARCHIVED,
                }
              : doc
          ),
        }));
        setIsDocumentCardLoading(false);
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        setIsDocumentCardLoading(false);
        logError(error);
      }
    },
    [creditAndComplianceState.onboardingDocuments]
  );

  const completeCreditAssessment = useCallback(
    async (callback) => {
      try {
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        await api.completeRiskAssessment(organization!.id);
        const [
          updatedOrganization,
          updatedRiskAndCompliance,
        ] = await Promise.all([
          api.getOrganization(organization!.id),
          api.getRiskAndCompliance(organization!.id),
        ]);

        if (!mounted.current) return;
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          riskAndCompliance: updatedRiskAndCompliance,
        }));
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { organization: updatedOrganization },
        });
        callback();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
        callback();
      }
    },
    [mounted.current]
  );

  const completeCompliance = useCallback(
    async (callback) => {
      try {
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        await api.completeComplianceCheck(organization!.id);
        const [
          updatedOrganization,
          updatedRiskAndCompliance,
        ] = await Promise.all([
          api.getOrganization(organization!.id),
          api.getRiskAndCompliance(organization!.id),
        ]);

        if (!mounted.current) return;
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          riskAndCompliance: updatedRiskAndCompliance,
        }));
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { organization: updatedOrganization },
        });
        callback();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
        callback();
      }
    },
    [mounted.current]
  );

  const completePreCheck = useCallback(
    async (callback) => {
      try {
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        await api.completePreCheck(organization!.id);
        const updatedComplianceCompany = await api.getComplianceCompany(
          organization!.id
        );

        if (!mounted.current) return;
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          complianceCompany: updatedComplianceCompany,
        }));
        callback();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
        callback();
      }
    },
    [mounted.current]
  );

  const revertOrganizationStatus = useCallback(
    async (newOrgStatus, callback) => {
      try {
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        await api.revertOrganizationStatus(organization!.id, newOrgStatus);
        const [
          updatedOrganization,
          updatedRiskAndCompliance,
        ] = await Promise.all([
          api.getOrganization(organization!.id),
          api.getRiskAndCompliance(organization!.id),
        ]);

        if (!mounted.current) return;
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          riskAndCompliance: updatedRiskAndCompliance,
        }));
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: { organization: updatedOrganization },
        });
        callback();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
        callback();
      }
    },
    [mounted.current]
  );

  const fetchBankSubmissions = useCallback(async () => {
    try {
      const bankSubmissions = await api.getBankSubmissions(organization!.id);
      if (!mounted.current) return;
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        bankSubmissions,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  }, [mounted.current]);

  const fetchComlianceCompany = useCallback(async () => {
    try {
      const updatedComplianceCompany = await api.getComplianceCompany(
        organization!.id
      );
      if (!mounted.current) return;
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        complianceCompany: updatedComplianceCompany,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  }, [mounted.current]);

  const fetchCrefoReportsMetaData = useCallback(async () => {
    try {
      const { reportsPaths: crefoReportsMetaData } = await api.getCrefoReports(
        organization!.id
      );
      if (!mounted.current) return;
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        crefoReportsMetaData,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  }, [mounted.current]);

  const fetchExternalReportsMetaData = useCallback(async () => {
    try {
      const {
        reportsPaths: externalReportsMetaData,
      } = await api.getExternalReports(
        creditAndComplianceState.creditProfile!.agencyProfile.duns.value
      );
      if (!mounted.current) return;
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        externalReportsMetaData,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  }, [mounted.current, creditAndComplianceState.creditProfile]);

  const fetchOnboardingDocuments = useCallback(async () => {
    try {
      const { onboardingDocuments } = await api.getOnboardingDocuments(
        organization!.id
      );
      if (!mounted.current) return;
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        onboardingDocuments,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  }, []);

  const fetchRelatedPersons = useCallback(async () => {
    try {
      const { relatedPersons: representatives } = await api.getRelatedPersons(
        organization!.id
      );
      if (!mounted.current) return;
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        representatives: sortOrgRepresentatives(representatives),
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  }, []);

  const requestAlltDocumentsInDraft = useCallback(
    async (category: OnboardingDocumentCategory) => {
      try {
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          isRequesting: true,
        }));
        const { onboardingDocuments } = await api.requestOnboardingDocuments(
          organization!.id,
          creditAndComplianceState.onboardingDocuments
            .filter((doc) => doc.type.category === category)
            .filter((doc) => doc.status === OnboardingDocumentStatus.DRAFT)
            .map((doc) => ({ onboardingDocumentId: doc.id }))
        );
        if (!mounted.current) return;
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          isRequesting: false,
          onboardingDocuments,
        }));
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        setCreditAndComplianceState((prevState) => ({
          ...prevState,
          isRequesting: false,
        }));
        logError(error);
      }
    },
    [creditAndComplianceState.onboardingDocuments]
  );

  const setComplianceRating = useCallback(
    (updatedComplianceRating: ComplianceRating) => {
      if (!mounted.current) return;
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        complianceCompany: {
          ...prevState.complianceCompany!,
          complianceRating: updatedComplianceRating,
        },
      }));
    },
    [mounted.current]
  );

  const setCreditAssessmentLimits = useCallback(
    (updatedCreditAssessmentLimits: CreditAssessmentLimits) => {
      if (!mounted.current) return;
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        creditAssessment: {
          ...prevState.creditAssessment!,
          limits: updatedCreditAssessmentLimits,
        },
      }));
    },
    [mounted.current]
  );

  const setOnboardingDocuments = useCallback(
    (updatedOnboardingDocuments: OnboardingDocument[]) => {
      if (!mounted.current) return;
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        onboardingDocuments: updatedOnboardingDocuments,
      }));
    },
    [mounted.current]
  );

  const updateOnboardingDocument = useCallback(
    (updatedDocument: OnboardingDocument) => {
      if (!mounted.current) return;
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        onboardingDocuments: prevState.onboardingDocuments.map((doc) =>
          doc.id === updatedDocument.id ? updatedDocument : doc
        ),
      }));
    },
    [mounted.current]
  );

  const updateRepresentative = useCallback(
    (updatedRep: RelatedPerson) => {
      fetchRelatedPersons();
      if (!mounted.current) return;
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        representatives: prevState.representatives.map((rep) =>
          rep.id.value === updatedRep.id.value ? updatedRep : rep
        ),
      }));
    },
    [mounted.current]
  );

  const setUnderwritingCase = useCallback(
    (updatedUnderwritingCase: CreditAssessmentUnderwritingCase) => {
      if (!mounted.current) return;
      setCreditAndComplianceState((prevState) => ({
        ...prevState,
        creditAssessment: {
          ...prevState.creditAssessment!,
          underwritingCase: updatedUnderwritingCase,
        },
      }));
    },
    [mounted.current]
  );

  const value = useMemo(
    () => ({
      actions: {
        addRepresentative,
        archiveDocument,
        completeCompliance,
        completeCreditAssessment,
        completePreCheck,
        fetchBankSubmissions,
        fetchCrefoReportsMetaData,
        fetchExternalReportsMetaData,
        fetchOnboardingDocuments,
        fetchRelatedPersons,
        refetchCompleteContext: getData,
        refetchComplianceCompany: fetchComlianceCompany,
        refetchMonitoringStatuses: async () =>
          await synchMonitoringStatuses(
            creditAndComplianceState.creditProfile!
          ),
        requestAlltDocumentsInDraft,
        revertOrganizationStatus,
        setComplianceRating,
        setCreditAssessmentLimits,
        setOnboardingDocuments,
        setUnderwritingCase,
        updateOnboardingDocument,
        updateRepresentative,
      },
      state: {
        ...creditAndComplianceState,
        canDocumentsSectionBeEdited: [
          OrganizationStatus.onboardingCompliance,
          OrganizationStatus.onboardingDetails,
          OrganizationStatus.onboardingDirectDebit,
          OrganizationStatus.onboardingRiskData,
          OrganizationStatus.onboardingRiskReview,
          OrganizationStatus.onboarding,
        ].includes(organization!.status),
        canDocumentsBeRequestedByCategory: {
          [OnboardingDocumentCategory.COMPLIANCE]: creditAndComplianceState.onboardingDocuments.some(
            (doc) =>
              doc.status === OnboardingDocumentStatus.DRAFT &&
              doc.type.category === OnboardingDocumentCategory.COMPLIANCE
          ),
          [OnboardingDocumentCategory.RISK]: creditAndComplianceState.onboardingDocuments.some(
            (doc) =>
              doc.status === OnboardingDocumentStatus.DRAFT &&
              doc.type.category === OnboardingDocumentCategory.RISK
          ),
        },
        isNonCustomer: organization!.status === OrganizationStatus.nonCustomer,
      },
    }),
    [
      addRepresentative,
      archiveDocument,
      completeCompliance,
      completeCreditAssessment,
      completePreCheck,
      creditAndComplianceState,
      creditAndComplianceState,
      fetchBankSubmissions,
      fetchComlianceCompany,
      fetchCrefoReportsMetaData,
      fetchExternalReportsMetaData,
      fetchOnboardingDocuments,
      fetchRelatedPersons,
      getData,
      organization!.status,
      requestAlltDocumentsInDraft,
      revertOrganizationStatus,
      setComplianceRating,
      setCreditAssessmentLimits,
      setOnboardingDocuments,
      setUnderwritingCase,
      synchMonitoringStatuses,
      updateOnboardingDocument,
      updateRepresentative,
    ]
  );

  return (
    <CreditAndComplianceContext.Provider value={value}>
      {children}
    </CreditAndComplianceContext.Provider>
  );
};

const useCreditAndComplianceContext = () => {
  const context = useContext(CreditAndComplianceContext);
  if (context === null) {
    throw new Error(
      `useCreditAndComplianceContext must be used within a CreditAndComplianceProvider`
    );
  }
  return context;
};

const withCreditAndComplianceContext = (
  Component: React.ComponentType
) => () => {
  return (
    <CreditAndComplianceProvider>
      <Component />
    </CreditAndComplianceProvider>
  );
};

export default useCreditAndComplianceContext;
export { withCreditAndComplianceContext };
